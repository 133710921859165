import { useNavigate, useSearchParams } from "react-router-dom";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { Title } from "../../components/molecules/header";
import { SubTitle } from "../../components/molecules/subtitle";
import { useEffect } from "react";

const OrderSuccess = () => {
  const [searchParams] = useSearchParams();
  const order_id = searchParams.get("order_id");
  const navigate = useNavigate();

  const navigateToStoryListPage = () => {
    navigate("/select-child?tab=story");
  };

  useEffect(() => {
    if (!order_id) {
      navigateToStoryListPage();
    }
  }, [order_id]);

  return (
    <div className="bg-[url('./assets/order_success_bg.svg')] h-[76vh] bg-no-repeat bg-cover mx-auto mt-20 p-[40px]">
      <div className="max-w-[754px] m-auto text-center">
        <Title>Woohoo! Your order is processed!</Title>
        <br></br>
        <br></br>
        <SubTitle className="font-[600] text-[24px]">
          Shipping details will be shared with you over Email as soon as your
          storybook is printed.
        </SubTitle>
        <br></br>
        <br></br>
        <strong>
          <p>Order No. : {order_id}</p>
        </strong>
        <br></br>
        <PrimaryButton handleButtonClick={() => navigateToStoryListPage()}>
          Back To Dashboard
        </PrimaryButton>
      </div>
    </div>
  );
};
export default OrderSuccess;
