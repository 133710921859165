export const TRACK_DETAILS = "tds";
export const ANANOMOUS_USER_ID = "AAA_9553";

export function addStoryIfNew(storyId, userId) {
  try {
    let trackDetails = JSON.parse(localStorage.getItem(TRACK_DETAILS));
    if (!trackDetails) {
      trackDetails = {};
    }
    let key = userId ? userId : ANANOMOUS_USER_ID;
    if (!trackDetails[key]) {
      trackDetails[key] = [];
    }
    if (!trackDetails[key].includes(storyId)) {
      trackDetails[key].push(storyId);
    }
    localStorage.setItem(TRACK_DETAILS, JSON.stringify(trackDetails));
  } catch (error) {
    return;
  }
}

export function isStoryTracked(storyId, userId) {
  try {
    let trackDetails = JSON.parse(localStorage.getItem(TRACK_DETAILS));
    if (!trackDetails) {
      return false;
    }
    let key = userId ? userId : ANANOMOUS_USER_ID;
    if (!trackDetails[key]) {
      return false;
    }
    return trackDetails[key].includes(storyId);
  } catch (error) {
    return false;
  }
}
