export const SOCKET_OPERATIONS = {
  ECHO: "echo",
  TEXT_CHANGE: "page_text_change",
  UPDATE_PAGE_TEXT_ELEMENT_CHANGE: "update_page_text_element",
  PAGE_COMPOSITION_CHANGE: "page_composition_change",
  PAGE_CHARACTER_CLOTHING_CHANGE: "page_character_clothing_change",
  PAGE_CHARACTER_EXPRESSION_CHANGE: "page_character_expression_change",
  PAGE_FONT_CHANGE: "page_font_change",
  PAGE_FONT_SIZE_CHANGE: "page_font_size_change",
  PAGE_TEXT_COLOR_CHANGE: "page_text_color_change",
  PAGE_BACKGROUND_CHANGE: "page_background_change",
  PAGE_TEXT_ALIGNMENT_CHANGE: "page_text_alignment_change",
  PAGE_SHADOW_COLOR_CHANGE: "page_shadow_color_change",
  PAGE_SHADOW_STRENTH_CHANGE: "page_shadow_strength_change",
  BOOK_LAYOUT_CHANGE: "book_layout_change",
  PAGE_IMAGE_GENERATION: "page_image_generation",
  STORY_CHARACTER_APPERANCE: "story_character_appearance_change",
  PAGE_IMAGE_CHANGE: "page_image_change",
  ADD_PAGE_TEXT_ELEMENT: "add_page_text_element",
  DELETE_PAGE_TEXT_ELEMENT: "delete_page_text_element",

  //user
  USER_CREDIT_BALANCE: "user_credit_balance",
  SUBSCRIPTION_STATUS_CHANGE: "subscription_status_change",

  PAGE_TEXT_POSITION_CHANGE: "page_text_position_change",
  STORY_PRIVACY_CHANGE: "story_privacy_change",

  //style page
  STYLE_IMAGE_GENERATION: "style_image_generation",

  //page editor
  ADD_NEW_PAGE: "add_story_page",
  DELETE_PAGE: "delete_story_page",
  REORDER_PAGES: "reorder_story_pages",
};

export const PAGE_TEXT_LOADING_STATE = {
  [SOCKET_OPERATIONS["PAGE_COMPOSITION_CHANGE"]]: "IS_COMPOSITION_LOADING",
  [SOCKET_OPERATIONS["STORY_CHARACTER_APPERANCE"]]:
    "IS_CHARACTER_APPERANCE_LOADING",
  [SOCKET_OPERATIONS["PAGE_CHARACTER_CLOTHING_CHANGE"]]:
    "IS_CHARACTER_CLOTHING_LOADING",
  [SOCKET_OPERATIONS["PAGE_CHARACTER_EXPRESSION_CHANGE"]]:
    "IS_CHARACTER_EXPRESSION_LOADING",
};

export const SOCKET_OPERATIONS_SUCCESS = {
  ECHO_SUCCESS: "echo_success",
  TEXT_CHANGE_SUCCESS: "page_text_change_success",
  UPDATE_PAGE_TEXT_ELEMENT_SUCCESS: "update_page_text_element_success",
  PAGE_COMPOSITION_CHANGE_SUCCESS: "page_composition_change_success",
  PAGE_CHARACTER_CLOTHING_CHANGE_SUCCESS:
    "page_character_clothing_change_success",
  PAGE_CHARACTER_EXPRESSION_CHANGE_SUCCESS:
    "page_character_expression_change_success",
  PAGE_FONT_CHANGE_SUCCESS: "page_font_change_success",
  PAGE_FONT_SIZE_CHANGE_SUCCESS: "page_font_size_change_success",
  PAGE_TEXT_COLOR_CHANGE_SUCCESS: "page_text_color_change_success",
  PAGE_BACKGROUND_CHANGE_SUCCESS: "page_background_change_success",
  PAGE_TEXT_ALIGNMENT_CHANGE_SUCCESS: "page_text_alignment_change_success",
  PAGE_SHADOW_COLOR_CHANGE_SUCCESS: "page_shadow_color_change_success",
  PAGE_SHADOW_STRENTH_CHANGE_SUCCESS: "page_shadow_strength_change_success",
  BOOK_LAYOUT_CHANGE_SUCCESS: "book_layout_change_success",
  COVER_TITLE_GENERATION_SUCCESS: "cover_title_generation_success",
  PAGE_IMAGE_GENERATION_SUCCESS: "page_image_generation_success",
  STORY_CHARACTER_APPERANCE_SUCCESS:
    "story_character_appearance_change_success",
  PAGE_IMAGE_CHANGE_SUCCESS: "page_image_change_success",
  PAGE_GENERATION_SUCCESS: "page_generation_success",
  PAGE_TEXT_POSITION_CHANGE_SUCCESS: "page_text_position_change_success",
  STORY_PRIVACY_CHANGE_SUCCESS: "privacy_change_success",
  STYLE_IMAGE_GENERATION_SUCCESS: "style_image_generation_success",
  ADD_NEW_PAGE_SUCCESS: "add_story_page_success",
  DELETE_PAGE_SUCCESS: "delete_story_page_success",
  REORDER_PAGES_SUCCESS: "reorder_story_pages_success",
  ADD_PAGE_TEXT_ELEMENT_SUCCESS: "add_page_text_element_success",
  DELETE_PAGE_TEXT_ELEMENT_SUCCESS: "delete_page_text_element_success",
};

export const SOCKET_OPERATIONS_ERROR = {
  ECHO_FAILURE: "echo_failure",
  TEXT_CHANGE_FAILURE: "page_text_change_failure",
  PAGE_COMPOSITION_CHANGE_FAILURE: "page_composition_change_failure",
  PAGE_CHARACTER_CLOTHING_CHANGE_FAILURE:
    "page_character_clothing_change_failure",
  PAGE_CHARACTER_EXPRESSION_CHANGE_FAILURE:
    "page_character_expression_change_failure",
  PAGE_FONT_CHANGE_FAILURE: "page_font_change_failure",
  PAGE_FONT_SIZE_CHANGE_FAILURE: "page_font_size_change_failure",
  PAGE_TEXT_COLOR_CHANGE_FAILURE: "page_text_color_change_failure",
  PAGE_BACKGROUND_CHANGE_FAILURE: "page_background_change_failure",
  PAGE_TEXT_ALIGNMENT_CHANGE_FAILURE: "page_text_alignment_change_failure",
  PAGE_SHADOW_COLOR_CHANGE_FAILURE: "page_shadow_color_change_failure",
  PAGE_SHADOW_STRENTH_CHANGE_FAILURE: "page_shadow_strength_change_failure",
  BOOK_LAYOUT_CHANGE_FAILURE: "book_layout_change_failure",
  PAGE_IMAGE_GENERATION_FAILURE: "page_image_generation_failure",
  PAGE_IMAGE_CHANGE_FAILURE: "page_image_change_failure",
  PAGE_TEXT_POSITION_CHANGE_FAILURE: "page_text_position_change_failure",
  STORY_PRIVACY_CHANGE_FAILURE: "privacy_change_failure",
  STYLE_IMAGE_GENERATION_FAILURE: "style_image_generation_failure",
  STORY_CHARACTER_APPERANCE_FAILURE:
    "story_character_appearance_change_failure",
  ADD_NEW_PAGE_FAILURE: "add_story_page_failure",
  DELETE_PAGE_FAILURE: "delete_story_page_failure",
  REORDER_PAGES_FAILURE: "reorder_story_pages_failure",
  UPDATE_PAGE_TEXT_ELEMENT_FAILURE: "update_page_text_element_failure",
  ADD_PAGE_TEXT_ELEMENT_FAILURE: "add_page_element_text_failure",
  DELETE_PAGE_TEXT_ELEMENT_FAILURE: "delete_page_text_element_failure",
};

export const SOCKET_OPERATIONS_PENDING = {
  PAGE_IMAGE_GENERATION_PENDING: "page_image_generation_pending",
  STYLE_IMAGE_GENERATION_PENDING: "style_image_generation_pending",
};

export const errorMessages = {
  [SOCKET_OPERATIONS_ERROR.PAGE_IMAGE_CHANGE_FAILURE]: "Error changing image",
  [SOCKET_OPERATIONS_ERROR.PAGE_IMAGE_GENERATION_FAILURE]:
    "Error generating illustration",
  [SOCKET_OPERATIONS_ERROR.STORY_PRIVACY_CHANGE_FAILURE]:
    "Error changing privacy",
  [SOCKET_OPERATIONS_ERROR.TEXT_CHANGE_FAILURE]: "Error changing text",
  [SOCKET_OPERATIONS_ERROR.PAGE_COMPOSITION_CHANGE_FAILURE]:
    "Error changing composition",
  [SOCKET_OPERATIONS_ERROR.STORY_CHARACTER_APPERANCE_FAILURE]:
    "Error changing character appearance",
  [SOCKET_OPERATIONS_ERROR.PAGE_CHARACTER_CLOTHING_CHANGE_FAILURE]:
    "Error changing character clothing",
  [SOCKET_OPERATIONS_ERROR.PAGE_CHARACTER_EXPRESSION_CHANGE_FAILURE]:
    "Error changing character expression",
  [SOCKET_OPERATIONS_ERROR.PAGE_FONT_CHANGE_FAILURE]: "Error changing font",
  [SOCKET_OPERATIONS_ERROR.PAGE_FONT_SIZE_CHANGE_FAILURE]:
    "Error changing font size",
  [SOCKET_OPERATIONS_ERROR.PAGE_TEXT_COLOR_CHANGE_FAILURE]:
    "Error changing text color",
  [SOCKET_OPERATIONS_ERROR.PAGE_BACKGROUND_CHANGE_FAILURE]:
    "Error changing background",
  [SOCKET_OPERATIONS_ERROR.PAGE_TEXT_ALIGNMENT_CHANGE_FAILURE]:
    "Error changing text alignment",
  [SOCKET_OPERATIONS_ERROR.PAGE_SHADOW_COLOR_CHANGE_FAILURE]:
    "Error changing shadow color",
  [SOCKET_OPERATIONS_ERROR.PAGE_SHADOW_STRENTH_CHANGE_FAILURE]:
    "Error changing shadow strength",
  [SOCKET_OPERATIONS_ERROR.BOOK_LAYOUT_CHANGE_FAILURE]:
    "Error changing book layout",
  [SOCKET_OPERATIONS_ERROR.PAGE_TEXT_POSITION_CHANGE_FAILURE]:
    "Error changing text position",
  [SOCKET_OPERATIONS_ERROR.UPDATE_PAGE_TEXT_ELEMENT_FAILURE]:
    "Error changing text element",
  [SOCKET_OPERATIONS_ERROR.ADD_PAGE_TEXT_ELEMENT_FAILURE]:
    "Error adding new text element",
  [SOCKET_OPERATIONS_ERROR.DELETE_PAGE_TEXT_ELEMENT_FAILURE]:
    "Error while deleting text element",
};
