import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Success1 from "../../assets/Success.svg";
import Header from "../../components/Header";

const Success = () => {
  const STORY_BASE_URL = import.meta.env.VITE_BUBBLE_BASE_URL;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("story_id");
  const internal_order_id = searchParams.get("internal_order_id");
  const [data, setData] = useState();
  const [countdown, setCountdown] = useState(7);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${STORY_BASE_URL}api/1.1/wf/get_story_details?story_id=${id}&api_token=36dd66413d5fb2b0ea15c423dadc0b75`
      );

      setData(response.data.response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchDataAndCountdown = async () => {
      try {
        await fetchData();

        let countdownValue = 7;
        const intervalId = setInterval(() => {
          setCountdown(countdownValue);
          countdownValue -= 1;

          if (countdownValue === 0) {
            clearInterval(intervalId);
            if (id && id !== "null") {
              window.location.href = `${STORY_BASE_URL}storyboard/${id}`;
            } else {
              window.location.href = `${STORY_BASE_URL}stories`;
            }
          }
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAndCountdown();
  }, [id]);

  return (
    <>

      <div className="md:w-[50%] w-[100%] mx-auto flex flex-col justify-center mt-9 md:px-0 px-3">
        <div className="flex flex-col gap-3 justify-center items-center">
          <div className="flex gap-3 items-center justify-center">
            <h1 className="md:text-3xl text-2xl font-bold">
              Payment Successful!
            </h1>
            <img src={Success1} alt="success" className="w-[26px]" />
          </div>
          <h2 className="text-xl font-medium">
            Your Order ID is: {internal_order_id}
          </h2>
          <p className="text-base">Adding just a few extra touches!</p>

          {id !== "null" ? (
            <div className="relative">
              <div
                className={`md:w-[22rem] lg:w-[22rem] w-[20rem] md:h-[22rem] lg:h-[22rem] h-[20rem] mx-auto rounded-xl theme_box_shadow ${loading ? "bg-gray-200" : ""
                  }`}
              >
                {loading && (
                  <div className="flex items-center justify-center w-full h-full">
                    <CircularProgress />
                  </div>
                )}
                {!loading && (
                  <>
                    <img
                      src={data?.cover_image}
                      alt="story_image"
                      className="md:w-[22rem] lg:w-[22rem] w-[20rem] mx-auto rounded-xl theme_box_shadow"
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.5) -22px 28px 18px -5px, rgba(0, 0, 0, 0.5) 0px 8px 10px -5px",
                      }}
                    />
                    <div
                      className="absolute inset-0"
                      style={{
                        background:
                          "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 86.64%)",
                        mixBlendMode: "multiply",
                        filter: "blur(2px)",
                        zIndex: 2,
                        width: "15px",
                        left: "10px",
                      }}
                    ></div>
                    <div className="absolute top-[70%] md:top-[75%] text-lg md:text-2xl lg:text-4xl text-center text-white font-bold left-1/2 transform -translate-x-1/2 -translate-y-3/2 w-[18rem] md:w-[22rem] lg:w-[22rem] mx-auto">
                      <div
                        className={`blur-background blur-background-sm`}
                      ></div>
                      <div className="text-container">{data?.title}</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="text-xl font-bold text-center mt-2">
            Redirecting in {countdown} seconds...
            <br />
            <br />
            If not redirected, click{" "}
            {id !== "null" ? (
              <a
                href={`${STORY_BASE_URL}storyboard/${id}`}
                className="text-blue-500 font-bold"
                rel="noopener noreferrer"
              >
                here
              </a>
            ) : (
              <a
                href={`${STORY_BASE_URL}stories`}
                className="text-blue-500 font-bold"
                rel="noopener noreferrer"
              >
                here
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
