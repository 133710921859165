/* eslint-disable react/prop-types */
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles({
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
    color: "#fffff",
  },
});

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
  const classes = useStyle();
  const isMobileView = window.innerWidth < 768;
  return (
    <Pagination
      sx={{
        "& .MuiPaginationItem-root": {
          color: "#a1a1a1",
        },
        "& .Mui-selected": {
          color: "#ffffff",
        },
      }}
      className={classes.pagination}
      color="primary"
      count={totalPages}
      size={isMobileView ? "medium" : "large"}
      onChange={onPageChange}
      page={currentPage}
    />
  );
};

export default CustomPagination;
