/* eslint-disable no-case-declarations */
import toast from "react-hot-toast";
import * as Sentry from "@sentry/browser";

import { create } from "zustand";
import { getStoryDetails } from "../ApiServices/Story/storyApis";
import { debounced } from "../services/debounce";
import {
  errorMessages,
  SOCKET_OPERATIONS,
  SOCKET_OPERATIONS_ERROR,
  SOCKET_OPERATIONS_PENDING,
  SOCKET_OPERATIONS_SUCCESS,
} from "../services/socketoOperations";
import useAdvanceStoryStore from "../store/AdvanceStoryStore";
import socketStore from "../store/SocketStore";
import appInfoStore from "../store/AppInfoStore";

const useStore = create((set, get) => ({
  loadingStory: true,
  progress: 0,
  formState: {
    appearance: "",
    clothing: "",
    image_url: "",
  },
  story: null,
  generatingImage: false,
  message: "",
  setFormState: (newFormState) => set({ formState: newFormState }),
  onCostumeChange: (e) => {
    const value = e.target.value;
    set({ formState: { ...get().formState, clothing: value } });
    debounced(value, (val) => {
      socketStore.getState().storySocket?.sendMessage({
        op: SOCKET_OPERATIONS.PAGE_CHARACTER_CLOTHING_CHANGE,
        data: {
          clothing: val,
          page_id: get().story.pages[0].id,
        },
      });
    });
  },
  onApperanceChange: (e) => {
    const value = e.target.value;
    set({ formState: { ...get().formState, appearance: value } });
    debounced(value, (val) => {
      socketStore.getState().storySocket?.sendMessage({
        op: SOCKET_OPERATIONS.STORY_CHARACTER_APPERANCE,
        data: {
          appearance: val,
          character_id: get().story?.characters[0].id,
        },
      });
    });
  },
  generateImage: (pageId) => {
    socketStore.getState().storySocket?.sendMessage({
      op: SOCKET_OPERATIONS.PAGE_IMAGE_GENERATION,
      data: {
        page_id: pageId,
      },
    });
  },
  setProgress: (progress) => set((state) => ({ ...state, progress })),
  reset: () => {
    set({
      message: "",
      progress: 0,
      generateData: { story_id: null, cover: { image_url: null, title: null } },
    });
  },
  getStoryDetails: async (id) => {
    set({ loadingStory: true, progress: 0 });
    get().reset();
    getStoryDetails(id)
      .then((response) => {
        if (response.data["status-code"] === 1) {
          const data = response.data.data;
          set({ story: data });
          useAdvanceStoryStore.getState().setStory(data);
          const pageDetails = data?.pages?.[0];
          if (data.pages[0]?.text) {
            get().setProgress(50);
            const newGenerateData = {
              ...get().generateData,
              cover: {
                ...get().generateData.cover,
                title: data.pages[0].text,
              },
            };
            get().setGenerateData(newGenerateData);
          }
          if (
            pageDetails &&
            pageDetails.text &&
            pageDetails?.image_urls.length
          ) {
            const newGenerateData = {
              ...get().generateData,
              cover: {
                image_url:
                  pageDetails.image_urls[pageDetails.current_image_index],
                title: pageDetails.text,
                failure_code: pageDetails?.failure_code,
              },
            };
            get().setGenerateData(newGenerateData);
            get().setProgress(100);
          }

          set({
            formState: {
              appearance: data?.characters?.[0]?.visual_description_plaintext,
              clothing: pageDetails?.clothing,
              image_url:
                pageDetails?.image_urls[pageDetails?.current_image_index],
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      })
      .finally(() => {
        set({ loadingStory: false });
      });
  },
  globalInterval: null,
  initializeSocket: (roomId) => {
    useAdvanceStoryStore
      .getState()
      .getStoryDetails(roomId.split("_")[1])
      .then((response) => {
        let interval = get().globalInterval;
        clearInterval();
        let progress = get().progress;

        //connect to socket if only image and text is not available
        if (response?.data?.["status-code"] === 1) {
          const data = response.data.data;
          set({ story: data });
          if (data.pages[0]?.text) {
            progress = 50;
            get().setProgress(50);
            const newGenerateData = {
              ...get().generateData,
              cover: {
                ...get().generateData.cover,
                title: data.pages[0].text,
              },
            };
            get().setGenerateData(newGenerateData);
          }
          if (data.pages.length && data.pages[0]?.text) {
            if (data.pages?.[0]?.failure_code) {
              const newGenerateData = {
                ...get().generateData,
                cover: {
                  title: data.pages[0].text,
                  failure_code: data.pages[0]?.failure_code,
                },
              };
              get().setGenerateData(newGenerateData);
            }
            if (data.pages[0]?.image_urls.length) {
              const newGenerateData = {
                ...get().generateData,
                cover: {
                  image_url:
                    data.pages[0].image_urls[data.pages[0].current_image_index],
                  title: data.pages[0].text,
                  failure_code: data.pages[0]?.failure_code,
                },
              };
              get().setGenerateData(newGenerateData);
              get().setProgress(100);
              progress = 100;
            }
          }
          const dataExist = progress >= 100;
          if (dataExist) {
            clearInterval(interval);
          }
          if (!dataExist) {
            if (interval) clearInterval(interval);
            interval = setInterval(() => {
              progress = get().progress;
              progress = progress >= 50 ? progress + 1 : progress + 5;
              get().setProgress(progress);
              if (progress >= 90) {
                clearInterval(interval);
              }
            }, 1000);
          }
        }
      });
  },
  coverCallback: (message) => {
    if (Object.values(SOCKET_OPERATIONS_SUCCESS).includes(message.op)) {
      switch (message.op) {
        case SOCKET_OPERATIONS_SUCCESS.COVER_TITLE_GENERATION_SUCCESS:
          set({ progress: 50 });
          // eslint-disable-next-line no-case-declarations
          let updatedData = { ...get().generateData };
          updatedData.cover.title = message.data.text;
          get().setGenerateData(updatedData);
          toast.dismiss();
          toast("Cover title generated successfully!");

          setTimeout(() => {
            toast.dismiss();
            set({
              message: "Creating awesome cover image. Please wait...",
            });
          }, 4000);
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_IMAGE_GENERATION_SUCCESS:
          const dataExist = get().progress >= 100;
          let interval = get().globalInterval;
          if (!dataExist && interval) clearInterval(interval);
          // eslint-disable-next-line no-case-declarations
          let updatedDatas = { ...get().generateData };
          updatedDatas.cover = {
            ...updatedDatas.cover,
            image_url: message.data.image_url,
          };
          // eslint-disable-next-line no-case-declarations
          const pageIndex = get().story?.pages?.findIndex(
            (page) => page.id === message.data.page_id
          );
          if (pageIndex === 0) {
            toast.dismiss();
            toast.success("Cover image generated successfully!");
          }
          set({
            generatingImage: false,
            generateData: updatedDatas,
          });
          get().setFormState({
            ...get().formState,
            image_url: message.data.image_url,
          });
          if (!dataExist) {
            set({ progress: 99 });
            setTimeout(() => set({ progress: 100 }), 1000);
          }
          break;
        default:
          break;
      }
    }
    if (Object.values(SOCKET_OPERATIONS_PENDING).includes(message.op)) {
      switch (message.op) {
        case SOCKET_OPERATIONS_PENDING.PAGE_IMAGE_GENERATION_PENDING:
          const pageIndex = get().story?.pages?.findIndex(
            (page) => page.id === message.data.page_id
          );
          if (pageIndex === 0) {
            set({ generatingImage: true });
          }
          break;
        default:
          break;
      }
    }
    if (Object.values(SOCKET_OPERATIONS_ERROR).includes(message.op)) {
      switch (message.op) {
        case SOCKET_OPERATIONS_ERROR.PAGE_IMAGE_GENERATION_FAILURE:
          const pageIndex = get().story?.pages?.findIndex(
            (page) => page.id === message.data.page_id
          );
          if (pageIndex <= 0) {
            get().handleSocketError(message.op, message);
            const isContentPolicyViolation =
              message.failure_reason === "content_policy_violation";
            let updatedDatas = { ...get().generateData };
            updatedDatas.cover = {
              ...updatedDatas.cover,
              failure_code: isContentPolicyViolation
                ? "1401"
                : message.failure_reason,
            };
            set({
              generatingImage: false,
              generateData: updatedDatas,
            });
          }
          break;
        default:
          break;
      }
    }
  },
  handleSocketError(op, message) {
    toast.dismiss();
    if (op === SOCKET_OPERATIONS_ERROR.PAGE_IMAGE_GENERATION_FAILURE) {
      const isContentPolicyViolation =
        message.failure_reason === "content_policy_violation";
      const errorText = isContentPolicyViolation
        ? `Content policy violation for cover page. Please try to edit and change the composition`
        : `Error generating illustration for cover page`;

      appInfoStore.getState().setAppInfo(errorText);
      Sentry.captureMessage(errorText);
    } else {
      const errorMessage = errorMessages[op] || "Unknown operation";
      toast.error(errorMessage);
      Sentry.captureMessage(errorMessage);
    }
  },
  //for payment
  checkingout: false,
  setCheckingout: (checkingout) => set((state) => ({ ...state, checkingout })),
  loading: false,
  setLoading: (loading) => set((state) => ({ ...state, loading })),
  couponValidity: false,
  setCouponValidity: (couponValidity) =>
    set((state) => ({ ...state, couponValidity })),
  couponMessage: "",
  setCouponMessage: (couponMessage) =>
    set((state) => ({ ...state, couponMessage })),
  currency: "USD",
  setCurrency: (currency) => set((state) => ({ ...state, currency })),
  offers: "",
  setoffers: (offers) => set((state) => ({ ...state, offers })),

  dataTitle: "",
  setDataTitle: (dataTitle) => set((state) => ({ ...state, dataTitle })),

  discountResponse: null,
  setDiscountResponse: (discountResponse) =>
    set((state) => ({ ...state, discountResponse })),
  resetPaymentDetails: () => {
    set({
      offers: "",
      dataTitle: "",
      discountResponse: null,
      couponMessage: "",
      couponValidity: false,
      loading: false,
      checkingout: false,
    });
  },

  //for cover generation
  textColor: "#FFFF",
  setTextColor: (textColor) => set((state) => ({ ...state, textColor })),

  background: "#EB5757",
  setBackground: (background) => set((state) => ({ ...state, background })),

  shadow: "#EB5757",
  setShadow: (shadow) => {
    set((state) => ({ ...state, shadow }));
  },

  alignment: "center",
  setAlignment: (alignment) => set((state) => ({ ...state, alignment })),

  FontType: "Berkshire Swash",
  setFontType: (FontType) => set((state) => ({ ...state, FontType })),

  FontSize: "22px",
  setFontSize: (FontSize) => set((state) => ({ ...state, FontSize })),

  generateData: {
    story_id: null,
    cover: { image_url: null, title: null, failure_code: null },
  },
  setGenerateData: (generateData) =>
    set((state) => ({ ...state, generateData })),
}));
export default useStore;
