/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import { subscriptionStore } from "../../store/SubscriptionStore";
import { SUBSCRIPTION_CONFIG } from "./subscriptionConfig";
import SubscriptionTile from "./SubscriptionTile";
import { useSubscriptionHook } from "./useSubscriptionHook";
import { serializeSubscription } from "./serializer";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { SubTitle } from "../molecules/subtitle";
import { DialogWrapper } from "../Dialog";
import { PrimaryButton } from "../Button/PrimaryButton";
import { TransparentButton } from "../Button/TransparentButton";

const Subscriptions = () => {
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [subscriptionPlan, plan, currentsubscriptionStatusDetails, loading] =
    subscriptionStore((state) => [
      state.subscriptionPlan,
      state.tenure,
      state.currentsubscriptionStatusDetails,
      state.loading,
    ]);
  const planDetails =
    currentsubscriptionStatusDetails?.subscription?.plan_details;
  const activeId = planDetails?.notes?.plan_key;
  const [isUpgradePlan, setIsUpgradePlan] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState(null);
  const navigate = useNavigate();
  useSubscriptionHook();

  const canUpdate =
    currentsubscriptionStatusDetails?.actions?.can_update_subscription;
  const canCreate =
    currentsubscriptionStatusDetails?.actions?.can_create_subscription;
  if (loading) {
    return (
      <div className="m-auto w-full">
        <CircularProgress color="primary" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className="ease-in-out duration-200 grid grid-cols-1 lg:grid-cols-3 justify-center place-items-center items-center align-content-center gap-4">
      <DialogWrapper
        isOpen={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
      >
        {!canUpdate && !canCreate ? (
          <div className="m-8">
            <SubTitle>
              Contact <strong>contact@pictogen.com</strong> for adjusting yout
              plan.
            </SubTitle>
            <br />
            <div className="flex justify-around">
              <PrimaryButton handleButtonClick={() => setShowInfoDialog(false)}>
                Close
              </PrimaryButton>
            </div>
          </div>
        ) : (
          <div className="m-8">
            <SubTitle>
              {isUpgradePlan
                ? "You'll be charged a pro-rated amount for rest of your billing cycle. Your upgraded plan will be activated immediately."
                : "Your plan changes will take effect from next billing cycle onwards."}
            </SubTitle>
            <br />
            <SubTitle>Are you sure you want to continue?</SubTitle>
            <div className="flex justify-around">
              <TransparentButton
                handleButtonClick={() => setShowInfoDialog(false)}
              >
                Cancel
              </TransparentButton>

              <PrimaryButton
                handleButtonClick={() => {
                  selectedSubscriptionPlan;
                  navigate(
                    `/paymentV2?plan_id=${selectedSubscriptionPlan.id}&type=${selectedSubscriptionPlan.period}`
                  );
                }}
              >
                Change Plan
              </PrimaryButton>
            </div>
          </div>
        )}
      </DialogWrapper>
      <SubscriptionTile
        currentPlan={planDetails}
        isActive={!activeId}
        subscriptionDetails={SUBSCRIPTION_CONFIG?.[plan]?.[0]}
        onClick={() => {}}
      />
      {subscriptionPlan?.map((item, index) => (
        <SubscriptionTile
          currentPlan={planDetails}
          isActive={
            activeId === item.notes.plan_key ||
            activeId?.includes(item.notes.plan_key)
          }
          key={index}
          subscriptionDetails={serializeSubscription(item)}
          onClick={() => {
            if (activeId) {
              setShowInfoDialog(true);
              if (!canUpdate) {
                return;
              }
              if (planDetails?.item?.amount > item?.item?.amount) {
                setIsUpgradePlan(false);
              } else {
                setIsUpgradePlan(true);
              }
              setSelectedSubscriptionPlan(item);
              return;
            }

            navigate(`/paymentV2?plan_id=${item.id}&type=${item.period}`);
          }}
        />
      ))}
    </div>
  );
};
export default Subscriptions;
