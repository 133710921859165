/* eslint-disable react/prop-types */
const CustomButton = (props) => {
  const isMyKidsButton = props.children === "My Kids";

  return (
    <div>
      <button
        id={props.id}
        type={props.type}
        disabled={props.disabled}
        onKeyDown={props.onKeyDown}
        className={`${props.disabled ?
          "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none cursor-not-allowed" : ""
          } ${props.className
            ? props.className
            : " font-semibold border-2 border-white "
          } ${isMyKidsButton
            ? "bg-[#3CC3AA] hover:bg-[#fff] text-[#fff] hover:text-[#3CC3AA]"
            : "bg-white hover:bg-transparent text-[#3CC3AA] hover:text-[#fff]"
          } text-center block px-10 py-2 rounded-3xl`}
        onClick={props.onClick}
      >
        {props.children} {props.title}
      </button>
    </div>
  );
};

export default CustomButton;
