import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import "./index.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { initSentry } from "./services/sentryInitialize.js";
const theme = createTheme({
  palette: {
    primary: {
      main: "#3cc3aa",
    },
    light: {
      main: "#f7f7f7",
    },
    success: {
      main: "#3cc3aa",
    },
  },
});

//initialize sentry
initSentry();

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
