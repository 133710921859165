import { create } from "zustand";
import CustomSocket from "../../services/socket";
import appInfoStore from "../AppInfoStore";

var timeout;
var userTimeout;

export const SOCKET_ID = {
  user: "user",
  story: "story",
};

const socketStore = create((set, get) => ({
  userSocket: null,
  storySocket: null,
  //will be used to store the socket id of the disconnected socket i.e user or story
  disconectedSockets: [],
  setDisconnectedSocket: (socketId) => {
    appInfoStore
      .getState()
      .setAppInfo(`Failed to reach our servers. Please reload..`);
    set((state) => {
      return {
        disconectedSockets: [...state.disconectedSockets, socketId],
      };
    });
  },
  removeDisconnectedSocket: (socketId) => {
    const disconectedSockets = get().disconectedSockets.filter(
      (socket) => socket !== socketId
    );
    if (disconectedSockets.length === 0) {
      appInfoStore.getState().setAppInfo("");
    }
    set({ disconectedSockets });
  },
  disconnectUserSocket: () => {
    if (get().userSocket) {
      get().userSocket?.disconnect();
      get().userSocket = null;
    }
  },
  disconnectStorySocket: () => {
    if (get().storySocket) {
      get().storySocket.disconnect();
      get().storySocket = null;
    }
  },
  disconnectAllSockets: () => {
    get().disconnectUserSocket();
    get().disconnectStorySocket();
  },
  initializeUserSocket: (userId, callbacks) => {
    if (userTimeout) clearTimeout(userTimeout);
    userTimeout = setTimeout(() => {
      if (get().userSocket && get().userSocket.url.includes(userId)) return;
      get().disconnectUserSocket();
      let socket = new CustomSocket(userId, "user");
      if (callbacks?.length > 0) {
        callbacks.forEach((callback) => {
          socket.subscribe(callback);
        });
      }
      socket.connect();
      set({ userSocket: socket });
    }, 500);
  },
  initializeStorySocket: (roomId, callbacks) => {
    //disconnect before connecting to another socket
    //add a debounce to prevent multiple socket connections
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      get().disconnectStorySocket();
      let socket = new CustomSocket(roomId, "story");
      if (callbacks?.length > 0) {
        callbacks.forEach((callback) => {
          socket.subscribe(callback);
        });
      }
      socket.connect();
      set({ storySocket: socket });
    }, 500);
  },
  subscribeToUserSocket: (callback) => {
    get().userSocket?.subscribe(callback);
  },
  subscribeToStorySocket: (callback) => {
    get().storySocket?.subscribe(callback);
  },
  unsubscribeFromUserSocket: (callback) => {
    get().userSocket?.unsubscribe(callback);
  },
  unsubscribeFromStorySocket: (callback) => {
    get().storySocket?.unsubscribe(callback);
    if (!get().storySocket?.isSubscribed()) {
      get().storySocket?.disconnect();
    }
  },
}));

export default socketStore;
