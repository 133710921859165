/* eslint-disable react/prop-types */
import { useState } from "react";
import CustomPagination from "../../pages/SelectChild/pagination";

const Collapsible = ({
  children,
  title,
  defaultOpen = false,
  showPagination,
  pageSetting = {
    currentPage: 1,
    totalPages: 10,
    onPageChange: () => {},
  },
  showCollapsibleHeader = true,
  scrollRef = null,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="pt-2 md:pt-8 container m-auto w-[100%]  ">
      {showCollapsibleHeader && (
        <button
          className="flex items-center justify-between w-full focus:outline-none bg-[#F4F7FA] p-4 rounded-lg "
          onClick={toggleCollapsible}
        >
          <h2 className="text-lg font-bold">{title}</h2>
          <svg
            className={`w-5 h-5 transition-transform ${
              isOpen ? "transform rotate-180" : ""
            }`}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 9l-7 7-7-7" />
          </svg>
        </button>
      )}
      {isOpen && (
        <div
          ref={scrollRef}
          className="flex flex-col md:flex-row gap-8 items-center justify-center p-8 flex-wrap cursor-pointer"
        >
          {children}
        </div>
      )}
      {isOpen && showPagination && (
        <CustomPagination
          currentPage={pageSetting.currentPage}
          totalPages={pageSetting.totalPages}
          onPageChange={pageSetting.onPageChange}
        />
      )}
      <br></br>
      <br></br>
    </div>
  );
};

export default Collapsible;
