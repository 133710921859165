/* eslint-disable react/prop-types */
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
// import { CircularProgress } from "@mui/material";
// import { useEffect, useState } from "react";
// import useStoryList from "../../pages/SelectChild/useStoryList";
import historyStore, { TabConstant } from "../../store/HistoryStore";
import { Tooltip } from "@mui/material";

const OrderHistoryFilters = () => {
  // const [showFilter, setShowFilter] = useState(false);
  // const [pageNo, setPageNo] = useState(1);
  const [activeTab, setActiveTab, setFilter, getFilter] = historyStore(
    (state) => [
      state.activeTab,
      state.setActiveTab,
      state.setFilter,
      state.getFilter,
    ]
  );
  // const { storyList, loading } = useStoryList("purchased", pageNo);
  // const [finalStoryList, setFinalStoryList] = useState([]);

  // useEffect(() => {
  //   if (loading) return;
  //   setFinalStoryList([...finalStoryList, ...storyList]);
  // }, [storyList, loading]);

  // let timeout;
  const FilterButtons = ({ onClick, isActive, children, Icon, text }) => {
    return (
      <div
        onClick={onClick}
        className={` cursor-pointer p-2 px-6 m-2  text-center font-[600] text-[16px] text-[#36324A] rounded-md items-center ${
          isActive
            ? "border border-[#3cc3aa] bg-[#3cc3aa] bg-opacity-40"
            : "bg-slate-100"
        }`}
      >
        <div className="flex justify-center items-center">
          {Icon && (
            <div className="mr-2">
              <Icon />
            </div>
          )}
          {text}
        </div>
        {children}
      </div>
    );
  };
  // const handleScroll = (e) => {
  //   const { scrollTop, scrollHeight, clientHeight } = e.target;
  //   const position = Math.ceil(
  //     (scrollTop / (scrollHeight - clientHeight)) * 100
  //   );
  //   if (position === 100 && pageNo < totalPages) {
  //     // fetch more data
  //     if (timeout) clearInterval(timeout);
  //     timeout = setTimeout(() => {
  //       setPageNo(pageNo + 1);
  //     }, 500);
  //   }
  // };

  return (
    <div className="w-full pt-8 ">
      <div className="grid md:grid-cols-2 ">
        <div className="flex">
          <FilterButtons
            onClick={() => setActiveTab(TabConstant.PRINT_ORDER)}
            isActive={activeTab === TabConstant.PRINT_ORDER}
            text={"Order History"}
          />
          <FilterButtons
            onClick={() => setActiveTab(TabConstant.CREDITS)}
            isActive={activeTab === TabConstant.CREDITS}
            text={"StoryCoins Transactions"}
          />
        </div>
        <div className="flex flex-row-reverse">
          {/* <div className="relative  ">
            <FilterButtons
              isActive={showFilter || getFilter().story_id}
              onClick={() => setShowFilter(!showFilter)}
              Icon={FilterAltOutlinedIcon}
              text={"Filter By"}
            />
            <div
              className={`absolute -bottom-12 -left-24 min-w-[250px] bg-white ${
                showFilter ? "block" : "hidden"
              }  shadow-lg `}
            >
              <div className="p-4 hover:bg-slate-100 cursor-pointer group relative shadow-lg  ">
                <p> Stories</p>
                <div
                  onScroll={handleScroll}
                  className="hidden overflow-x-auto group-hover:block absolute top-0 -left-[250px] w-[250px] max-h-[60vh] bg-white  shadow-lg"
                >
                  {finalStoryList?.map((story, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setShowFilter(false);
                        setFilter({ story_id: story.id });
                      }}
                    >
                      <p
                        className={`p-4 cursor-pointer ${
                          getFilter().story_id === story.id
                            ? "bg-slate-100"
                            : "bg-white hover:bg-slate-100"
                        } `}
                        dangerouslySetInnerHTML={{
                          __html: story?.cover_page?.text,
                        }}
                      ></p>
                      <hr />
                    </div>
                  ))}
                  <div className="flex justify-center h-100px">
                    <CircularProgress size={30} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <Tooltip arrow placement="top-start" title={"Sort by Date"}>
            <div>
              <FilterButtons
                text={"Sort"}
                onClick={() => {
                  if (getFilter().sort === "ASC") setFilter({ sort: "DESC" });
                  else setFilter({ sort: "ASC" });
                }}
                Icon={
                  getFilter().sort === "ASC"
                    ? SortDescendingOutlined
                    : SortAscendingOutlined
                }
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default OrderHistoryFilters;
