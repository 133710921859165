/* eslint-disable no-case-declarations */
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";
import { create } from "zustand";
import {
  getPublicStoryDetails,
  getStoryDetails,
} from "../../ApiServices/Story/storyApis";
import { styleEditorStore } from "../../pages/StyleEditor/styleEditorStore";
import { debounced } from "../../services/debounce";
import {
  PAGE_TEXT_LOADING_STATE,
  SOCKET_OPERATIONS,
  SOCKET_OPERATIONS_ERROR,
  SOCKET_OPERATIONS_PENDING,
  SOCKET_OPERATIONS_SUCCESS,
  errorMessages,
} from "../../services/socketoOperations";
import appInfoStore from "../AppInfoStore";
import socketStore from "../SocketStore";

const updatePageData = (state, newData, page_id) => {
  const updatedStory = { ...state.story }; // Shallow clone of the story object
  //find the index of the page in the pages array using pageid
  const page = state.story.pages.findIndex((page) => page.id === page_id);
  if (!updatedStory.pages) return;
  updatedStory.pages[page] = {
    ...updatedStory.pages[page], // Shallow clone of the page object
    ...newData,
  };
  return { story: updatedStory };
};

const findIndex = (array, id) => {
  return array.findIndex((element) => element.id === id);
};

const useAdvanceStoryStore = create((set, get) => ({
  isAuthenticated: false,
  loadingStory: true,
  generatingImage: false,
  activeBookview: "merged",
  setActiveBookview: (newBookview) => set({ activeBookview: newBookview }),
  story: null,
  storyNotFound: false,
  page: 0,
  isCoverPage: () => true,
  setStory: (newStory) => set({ story: newStory }),
  setPage: (newPage) => {
    if (newPage === 0) {
      set({ isCoverPage: true });
    } else {
      set({ isCoverPage: false });
    }
    if (get().story)
      set({
        activeTextElementId:
          get().story.pages?.[newPage]?.text_elements?.[0]?.id,
        page: newPage,
        formState: {
          ...get().story.pages[newPage],
          appearance: get().story?.characters[0]?.visual_description_plaintext,
        },
        changedImage: "",
      });
  },
  changedImage: "",
  activeTextElementId: null,
  setActiveTextElementId: (activeTextElementId) => set({ activeTextElementId }),
  isStoryPublic: true,
  setStoryPrivacy: (data) => {
    const dataToSend = {
      op: SOCKET_OPERATIONS.STORY_PRIVACY_CHANGE,
      data: data,
    };

    socketStore.getState().storySocket?.sendMessage(dataToSend);
  },
  storyCallback: (message) => {
    const setAppInfo = appInfoStore.getState().setAppInfo;
    const updateFormLoadingState = get().updateFormLoadingState;
    if (Object.values(SOCKET_OPERATIONS_SUCCESS).includes(message.op)) {
      toast.dismiss();
      //will update the respective page details based on the message received
      switch (message.op) {
        case SOCKET_OPERATIONS_SUCCESS.ECHO_SUCCESS:
          console.log("Echo success");
          break;

        case SOCKET_OPERATIONS_SUCCESS.TEXT_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              { text: message?.data?.text },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_COMPOSITION_CHANGE_SUCCESS:
          updateFormLoadingState(
            SOCKET_OPERATIONS.PAGE_COMPOSITION_CHANGE,
            false
          );
          set((state) =>
            updatePageData(
              state,
              {
                composition: message?.data?.composition,
              },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_CHARACTER_CLOTHING_CHANGE_SUCCESS:
          updateFormLoadingState(
            SOCKET_OPERATIONS.PAGE_CHARACTER_CLOTHING_CHANGE,
            false
          );
          set((state) =>
            updatePageData(
              state,
              { clothing: message?.data?.clothing },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_CHARACTER_EXPRESSION_CHANGE_SUCCESS:
          updateFormLoadingState(
            SOCKET_OPERATIONS.PAGE_CHARACTER_EXPRESSION_CHANGE,
            false
          );
          set((state) =>
            updatePageData(
              state,
              { expression: message?.data?.expression },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_FONT_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              { font: message?.data?.font },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_FONT_SIZE_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              { font_size: message?.data?.font_size },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_TEXT_COLOR_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              { text_color: message?.data?.text_color },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_BACKGROUND_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              { background: message?.data?.background },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_TEXT_POSITION_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              {
                text_position: message?.data?.text_position,
              },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;
        case SOCKET_OPERATIONS_SUCCESS.PAGE_TEXT_ALIGNMENT_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              {
                text_alignment: message?.data?.text_alignment,
              },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_SHADOW_COLOR_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              {
                shadow_color: message?.data?.shadow_color,
              },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_SHADOW_STRENTH_CHANGE_SUCCESS:
          set((state) =>
            updatePageData(
              state,
              {
                shadow_strength: message?.data?.shadow_strength,
              },
              message?.data?.page_id
            )
          );
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.BOOK_LAYOUT_CHANGE_SUCCESS:
          set((state) => {
            const updatedStory = { ...state.story };
            updatedStory.story = {
              ...updatedStory.story,
              layout: message?.data?.layout,
            };
            return { story: updatedStory };
          });
          toast("Book Layout changed!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_IMAGE_CHANGE_SUCCESS:
          // find the index and change the image_url and the current_image_index
          const findIndex = get().story?.pages.findIndex(
            (page) => page.id === message?.data?.page_id
          );
          if (findIndex === -1) return;
          const updatedStory = { ...get().story };
          updatedStory.pages[findIndex] = {
            ...updatedStory.pages[findIndex],
            current_image_index: message?.data?.current_image_index,
          };

          set({
            changedImage: message?.data?.image_url,
            story: updatedStory,
          });
          set((state) => {
            return {
              formState: {
                ...state.formState,
              },
            };
          });
          toast("Image changed!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_IMAGE_GENERATION_SUCCESS:
          setAppInfo("");

          let pageIndex = get().story?.pages?.findIndex(
            (page) => page.id === message.data.page_id
          );
          //update each page in the storie's pages array with the new image urls and increase the active image index by 1 using the pageIndex
          if (pageIndex === -1) return;
          //have to check this beacause getting same image update event multiple times
          const imageAlreadyInList = get().story.pages[
            pageIndex
          ].image_urls.includes(message.data.image_url);
          set((state) => {
            const updatedStory = { ...state.story };
            updatedStory.pages[pageIndex] = {
              ...updatedStory.pages[pageIndex],
              image_urls: imageAlreadyInList
                ? [...updatedStory.pages[pageIndex].image_urls]
                : [
                  ...updatedStory.pages[pageIndex].image_urls,
                  message.data.image_url,
                ],
              current_image_index: message.data.current_image_index,
              is_generation_pending: false,
              failure_code: null,
            };
            return { story: updatedStory };
          });
          const layout = get().story?.story.layout;
          if (layout === "split") {
            pageIndex = pageIndex * 2;
          }
          if (pageIndex !== 0) {
            toast.dismiss();
            toast(`Illustration for page ${pageIndex} is ready`);
          }

          break;

        case SOCKET_OPERATIONS_SUCCESS.PAGE_GENERATION_SUCCESS:
          setAppInfo("");
          set((state) => {
            const updatedStory = { ...state.story };
            //push if the page doesnot exist
            if (
              !updatedStory.pages.find((page) => page.id === message.data.id)
            ) {
              updatedStory.pages.push(message.data);
            }
            return { story: updatedStory };
          });
          break;
        case SOCKET_OPERATIONS_SUCCESS.STORY_PRIVACY_CHANGE_SUCCESS:
          set({ isStoryPublic: message.data.is_public });
          break;

        case SOCKET_OPERATIONS_SUCCESS.STORY_CHARACTER_APPERANCE_SUCCESS:
          updateFormLoadingState(
            SOCKET_OPERATIONS.STORY_CHARACTER_APPERANCE,
            false
          );
          //change the story character visual_description_plaintext
          set((state) => {
            const updatedStory = { ...state.story };
            updatedStory.characters[0] = {
              ...updatedStory.characters[0],
              visual_description_plaintext: message?.data?.appearance,
            };
            return { story: updatedStory };
          });
          toast("Saved!");
          break;

        case SOCKET_OPERATIONS_SUCCESS.STYLE_IMAGE_GENERATION_SUCCESS:
          setAppInfo("");
          const styleStore = styleEditorStore.getState();
          //find page details in the story pages array
          const pageDetail = get().story?.pages.find(
            (page) => page.id === message.data.page_id
          );
          const hasImage = pageDetail.image_urls.includes(
            message.data.image_url
          );
          styleStore.setStyledImageList({
            ...pageDetail,
            is_generation_pending: false,
            current_image_index: message.data.current_image_index,
            image_urls: hasImage
              ? [...pageDetail.image_urls]
              : [...pageDetail.image_urls, message.data.image_url],
          });
          //also update the storypage with the new image urls
          set((state) => {
            const updatedStory = { ...state.story };
            const pageIndex = updatedStory.pages.findIndex(
              (page) => page.id === message.data.page_id
            );
            if (pageIndex === -1) return;
            updatedStory.pages[pageIndex] = {
              ...pageDetail,
              is_generation_pending: false,
              current_image_index: message.data.current_image_index,
              image_urls: hasImage
                ? [...pageDetail.image_urls]
                : [...pageDetail.image_urls, message.data.image_url],
            };
            return { story: updatedStory };
          });

          break;

        //for page edit and new page
        case SOCKET_OPERATIONS_SUCCESS.ADD_NEW_PAGE_SUCCESS:
          toast.success("Page Added!");
          appInfoStore.getState().setAppInfo("");
          const findReferenceIndex = get().story?.pages.findIndex(
            (page) => page.id === message.data.reference_page_id
          );
          // we get the page object from the message data new_page and update it to the story pages array
          set((state) => {
            const updatedStory = { ...state.story };
            updatedStory.pages.splice(
              findReferenceIndex + 1,
              0,
              message.data.new_page
            );
            return { story: updatedStory };
          });
          break;

        case SOCKET_OPERATIONS_SUCCESS.DELETE_PAGE_SUCCESS:
          toast.success("Page deleted!");
          const findPageIndex = get().story?.pages.findIndex(
            (page) => page.id === message.data.page_id
          );
          //remove the page from the story pages array
          set((state) => {
            const updatedStory = { ...state.story };
            updatedStory.pages.splice(findPageIndex, 1);
            return { story: updatedStory };
          });
          break;

        case SOCKET_OPERATIONS_SUCCESS.REORDER_PAGES_SUCCESS:
          toast("Saved");
          set((state) => {
            const updatedStory = { ...state.story };
            //rearrange the pages array based on the ordered_page_ids
            updatedStory.pages = [
              updatedStory?.pages.shift(),
              ...message.data.ordered_page_ids.map((pageId) =>
                updatedStory.pages.find((page) => page.id === pageId)
              ),
            ];
            return { story: updatedStory };
          });
          break;

        case SOCKET_OPERATIONS_SUCCESS.UPDATE_PAGE_TEXT_ELEMENT_SUCCESS:
          toast("Saved!");
          set((state) => {
            const updatedStory = { ...state.story };
            const pageIndex = updatedStory.pages.findIndex(
              (page) => page.id === message.data.page_id
            );
            if (pageIndex === -1) return;
            updatedStory.pages[pageIndex] = {
              ...updatedStory.pages[pageIndex],
              text_elements: updatedStory.pages[pageIndex].text_elements.map(
                (element) => {
                  if (element.id === message.data.text_element_id) {
                    return {
                      ...element,
                      [message.data.attribute]: message.data.attribute_value,
                    };
                  }
                  return element;
                }
              ),
            };
            return { story: updatedStory };
          });
          break;
        case SOCKET_OPERATIONS_SUCCESS.ADD_PAGE_TEXT_ELEMENT_SUCCESS:
          toast("New text element added");
          set((state) => {
            const updatedStory = { ...state.story };
            const pageIndex = updatedStory.pages.findIndex(
              (page) => page.id === message.data.page.id
            );
            if (pageIndex === -1) return { story: updatedStory };
            updatedStory.pages[pageIndex] = {
              ...updatedStory.pages[pageIndex],
              text_elements: message.data.page.text_elements,
            };
            return { story: updatedStory };
          });

          break;

        case SOCKET_OPERATIONS_SUCCESS.DELETE_PAGE_TEXT_ELEMENT_SUCCESS:
          set((state) => {
            const updatedStory = { ...state.story };
            const pageIndex = updatedStory.pages.findIndex(
              (page) => page.id === message.data.page_id
            );
            if (pageIndex === -1) return { story: updatedStory };
            updatedStory.pages[pageIndex] = {
              ...updatedStory.pages[pageIndex],
              text_elements: message.data.page.text_elements,
            };
            return { story: updatedStory };
          });
          break;

        default:
          break;
      }
    }

    if (Object.values(SOCKET_OPERATIONS_PENDING).includes(message.op)) {
      switch (message.op) {
        case SOCKET_OPERATIONS_PENDING.PAGE_IMAGE_GENERATION_PENDING:
          let pageIndex = get().story?.pages?.findIndex(
            (page) => page.id === message.data.page_id
          );
          if (pageIndex !== -1 && pageIndex != undefined) {
            set((state) => {
              const updatedStory = { ...state.story };
              updatedStory.pages[pageIndex] = {
                ...updatedStory.pages[pageIndex],
                is_generation_pending: true,
              };
              return { story: updatedStory };
            });
          }
          break;

        case SOCKET_OPERATIONS_PENDING.STYLE_IMAGE_GENERATION_PENDING:
          const styleStore = styleEditorStore.getState();
          //find page details in the story pages array
          const pageDetail = get().story?.pages.find(
            (page) => page.id === message.data.page_id
          );
          styleStore.setStyledImageList({
            ...pageDetail,
            is_generation_pending: true,
          });
          break;
        default:
          break;
      }
    }
    if (Object.values(SOCKET_OPERATIONS_ERROR).includes(message.op)) {
      get().handleSocketError(message.op, message);
    }
  },
  initializeSocket: (roomId) => {
    const socketStory = socketStore.getState();
    if (roomId) socketStory.initializeStorySocket(roomId);
  },
  pageMangementFailed: false,
  //function to handle all the socket error messages
  handleSocketError(op, message) {
    toast.dismiss();
    if (op === SOCKET_OPERATIONS_ERROR.PAGE_IMAGE_GENERATION_FAILURE) {
      const pageIndex = findIndex(get().story?.pages, message.data.page_id);
      const layout = get().story?.story.layout;
      const adjustedIndex = layout === "split" ? pageIndex * 2 : pageIndex;

      const errorText =
        message.failure_reason === "content_policy_violation"
          ? `Content policy violation for page ${adjustedIndex}`
          : `Error generating illustration for page ${adjustedIndex}`;

      set((state) => {
        const updatedStory = { ...state.story };
        updatedStory.pages[pageIndex] = {
          ...updatedStory.pages[pageIndex],
          failure_code: "1401",
          is_generation_pending: false,
        };
        return { story: updatedStory };
      });

      toast.error(errorText);
      Sentry.captureMessage(errorText);
    } else if (op === SOCKET_OPERATIONS_ERROR.REORDER_PAGES_FAILURE) {
      toast.error("Error reordering pages");
      Sentry.captureMessage("Error reordering pages");
      set({ pageMangementFailed: true });
      setTimeout(() => {
        set({ pageMangementFailed: false });
      }, 1000);
      //revert the rearrange order
    } else {
      const errorMessage = errorMessages[op] || "Unknown operation";
      toast.error(errorMessage);
      Sentry.captureMessage(errorMessage);
    }
  },
  getStoryDetails: async (id, pageNo) => {
    set({ loadingStory: true });
    return getStoryDetails(id)
      .then((response) => {
        if (response?.data?.["status-code"] === 1) {
          const userId = localStorage.getItem("u_d");
          const userIdFromStory = response.data.data.story.user;
          const isAuthenticated = userId && userIdFromStory == userId;

          set({
            story: response.data.data,
            activeBookview: response.data.data.story.layout,
            loadingStory: false,
            isStoryPublic: response.data.data.story.is_public,
            isAuthenticated: isAuthenticated,
            activeTextElementId:
              response.data.data.pages[pageNo || get().page]?.text_elements[0]
                ?.id,
          });
          if (pageNo) {
            if (pageNo === get().page) {
              set({
                formState: {
                  ...response.data.data.pages[pageNo],
                  appearance:
                    response.data.data.characters[pageNo]
                      ?.visual_description_plaintext,
                },
              });
            }
          } else {
            set({
              formState: {
                ...response.data.data.pages[get().page],
                appearance:
                  response.data.data.characters[get().page]
                    ?.visual_description_plaintext,
              },
            });
          }
          //check in all the pages if it has failure_code
          if (response.data.data.pages.some((page) => page?.failure_code)) {
            if (isAuthenticated) {
              appInfoStore
                .getState()
                .setAppInfo(
                  "One of your page failed during generation due to a technical issue. Please contact us at contact@pictogen.com."
                );
            }
          } else {
            appInfoStore.getState().setAppInfo("");
          }
          if (window.location.pathname.includes("/storyboard")) {
            //if user is not authenticated and story is public navigate to story page
            if (!isAuthenticated && response.data.data.story.is_public) {
              window.location.href = `/story/${response.data.data.story.id}`;
            } else if (
              !isAuthenticated &&
              !response.data.data.story.is_public
            ) {
              window.location.href = `/select-child?tab=story`;
            }
          }
          if (isAuthenticated) {
            get().initializeSocket(`story_${id}`);
          }
        }
        return response;
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
        return error;
      });
  },
  getPublicStoryDetails: async (id) => {
    set({ loadingStory: true });
    getPublicStoryDetails(id)
      .then((response) => {
        appInfoStore.getState().setAppInfo("");

        if (response.data["status-code"] === 1) {
          set({
            story: response.data.data,
            activeBookview: response.data.data.story.layout,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data["status-code"] === 1200) {
          set({ storyNotFound: true });
        }
        console.error("Error fetching product data:", error);
      })
      .finally(() => {
        set({ loadingStory: false });
      });
  },
  reset: () => {
    set({ page: 0, changedImage: "", isCoverPage: true, story: null });
  },
  formState: {
    text_elements: [
      {
        id: null,
        text: "",
        font: "",
        font_size: "",
        text_color: "",
        background: "",
        text_alignment: "",
        shadow_color: "",
        shadow_strength: "",
        text_position: "",
      },
    ],
    composition: "",
    appearance: "",
    clothing: "",
    expression: "",
    layout: "",
  },
  updateFormState: (newData) => {
    set({ formState: { ...newData } });
  },
  updateFormLoadingState: (operation, value) => {
    const isLoadingState = Object.keys(PAGE_TEXT_LOADING_STATE).includes(
      operation
    );
    if (isLoadingState) {
      set((state) => ({
        formState: {
          ...state.formState,
          [PAGE_TEXT_LOADING_STATE[operation]]: value,
        },
      }));
    }
  },
  onApperanceChange: (e) => {
    const value = e.target.value;
    const operation = SOCKET_OPERATIONS.STORY_CHARACTER_APPERANCE;
    const isLoadingState = Object.keys(PAGE_TEXT_LOADING_STATE).includes(
      operation
    );
    const formState = {
      ...get().formState,
      appearance: value,
    };
    if (isLoadingState) {
      formState[PAGE_TEXT_LOADING_STATE[operation]] = true;
    }
    set({ formState: formState });
    debounced(value, (val) => {
      socketStore.getState().storySocket?.sendMessage({
        op: SOCKET_OPERATIONS.STORY_CHARACTER_APPERANCE,
        data: {
          appearance: val,
          character_id: get().story?.characters[0].id,
        },
      });
    });
  },
  onImageSwitch: (imageIndex, pageId) => {
    socketStore.getState().storySocket?.sendMessage({
      op: SOCKET_OPERATIONS.PAGE_IMAGE_CHANGE,
      data: {
        image_index: imageIndex,
        page_id: pageId || get().story?.pages[get().page].id,
      },
    });
  },
  generateImage: () => {
    if (get().story && get().story?.pages) {
      let pageIndex = get().page;
      set((state) => {
        const updatedStory = { ...state.story };
        updatedStory.pages[pageIndex] = {
          ...updatedStory.pages[pageIndex],
          is_generation_pending: true,
        };
        return { story: updatedStory };
      });
      socketStore.getState().storySocket?.sendMessage({
        op: SOCKET_OPERATIONS.PAGE_IMAGE_GENERATION,
        data: {
          page_id: get().story?.pages[pageIndex].id,
        },
      });
    }
  },

  //for handing text inout fields
  handleInputChange: (e, operation, page) => {
    const value = e.target.value;
    const key = e.target.name;
    const isLoadingState = Object.keys(PAGE_TEXT_LOADING_STATE).includes(
      operation
    );
    const formState = {
      ...get().formState,
      [key]: value,
    };
    if (isLoadingState) {
      formState[PAGE_TEXT_LOADING_STATE[operation]] = true;
    }
    set({ formState: formState });

    debounced(value, (val) => {
      socketStore.getState().storySocket?.sendMessage({
        op: operation,
        data: {
          [e.target.name]: val,
          page_id: get().story?.pages[page ?? get().page].id,
        },
      });
    });
  },
  //for handling other form events for other form elements
  handleFormEvents: (object, operation, page) => {
    const key = object.key;
    const value = object.value;

    const isLoadingState = Object.keys(PAGE_TEXT_LOADING_STATE).includes(
      operation
    );
    const formState = {
      ...get().formState,
      [key]: value,
    };
    if (isLoadingState) {
      formState[PAGE_TEXT_LOADING_STATE[operation]] = true;
    }
    set({
      formState: formState,
    });
    debounced(value, (val) => {
      let data;
      if (key === "layout") {
        set({ activeBookview: val });
        data = {
          op: operation,
          data: {
            [key]: val,
          },
        };
      } else {
        data = {
          op: operation,
          data: {
            [key]: val,
            page_id: get().story?.pages[page ?? get().page].id,
          },
        };
      }
      if (data) socketStore.getState().storySocket?.sendMessage(data);
    });
  },
  handleTextDetailsChange: (object, elementId) => {
    const key = object.key;
    const value = object.value;

    //handle local state first
    const formState = {
      ...get().formState,
      text_elements: get().formState.text_elements.map((element) => {
        if (element.id === elementId) {
          return {
            ...element,
            [key]: value,
          };
        }
        return element;
      }),
    };
    set({ formState: formState });
    debounced(value, (val) => {
      const data = {
        op: SOCKET_OPERATIONS.UPDATE_PAGE_TEXT_ELEMENT_CHANGE,
        data: {
          page_id: get().story?.pages[get().page].id,
          text_element_id: elementId,
          attribute: key,
          attribute_value: val,
        },
      };
      if (data) socketStore.getState().storySocket?.sendMessage(data);
    });
  },
  addNewTextElement: () => {
    const data = {
      op: SOCKET_OPERATIONS.ADD_PAGE_TEXT_ELEMENT,
      data: {
        page_id: get().story?.pages[get().page].id,
        text_element: {},
      },
    };
    socketStore.getState().storySocket?.sendMessage(data);
  },
  deleteTextElement: (elementId) => {
    const data = {
      op: SOCKET_OPERATIONS.DELETE_PAGE_TEXT_ELEMENT,
      data: {
        page_id: get().story?.pages[get().page].id,
        text_element_id: elementId,
      },
    };
    socketStore.getState().storySocket?.sendMessage(data);
  },
  handlePageAdd: (referencePageId) => {
    const data = {
      op: SOCKET_OPERATIONS.ADD_NEW_PAGE,
      data: {
        reference_page_id: referencePageId,
        position: "after",
      },
    };
    socketStore.getState().storySocket?.sendMessage(data);
  },

  deletePageId: null,
  setDeletePageId: (pageId) => {
    set({ deletePageId: pageId });
  },
  handlePageDelete: (pageId) => {
    const data = {
      op: SOCKET_OPERATIONS.DELETE_PAGE,
      data: {
        page_id: pageId,
      },
    };
    socketStore.getState().storySocket?.sendMessage(data);
  },
  handlePageReorder: (orderedPageIds) => {
    const data = {
      op: SOCKET_OPERATIONS.REORDER_PAGES,
      data: {
        ordered_page_ids: orderedPageIds,
      },
    };
    socketStore.getState().storySocket?.sendMessage(data);
  },
  reorder: (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex + 1, 1);
    result.splice(endIndex + 1, 0, removed);

    //send data to websocket
    const orderedPageIds = result.map((page) => page.id);
    orderedPageIds.shift();
    get().handlePageReorder(orderedPageIds);

    return result;
  },
}));

export default useAdvanceStoryStore;
