import Cookies from "js-cookie";
import { useEffect } from "react";
import useStore from "../components/UseStore";
import socketStore from "../store/SocketStore";
import { whiteListForSocketInitialize } from "./socket";
// import useAdvanceStoryStore from "../store/AdvanceStoryStore";
// import useSocketStore from "../store/SocketStore/useSocketStore";
import useUserDetailsStore from "../store/UserStore";

//this service will handle the network connection and the socket connection as per the connection status
const useNetworkStatus = () => {
  const [
    initializeCoverSocket,
    // coverCallback
  ] = useStore((state) => [state.initializeSocket, state.coverCallback]);
  // const storyCallback = useAdvanceStoryStore((state) => state.storyCallback);
  const [disconnectAllSockets, storySocket, disconnectStorySocket] =
    socketStore((state) => [
      state.disconnectAllSockets,
      state.storySocket,
      state.disconnectStorySocket,
    ]);
  const [fetchUserDetail, setIsOnline, isOnline] = useUserDetailsStore(
    (state) => [state.fetchUserDetail, state.setIsOnline, state.isOnline]
  );
  const tokenExists = Cookies.get("story_token");
  const isUserAuthenticated = tokenExists;
  // useSocketStore([coverCallback, storyCallback]);

  const initializeCoverAndAdvanceSocket = () => {
    const storyId = window.location.pathname.split("/").pop();
    const pathname = window.location.pathname;
    const shouldSocketInitialize = whiteListForSocketInitialize.some((path) =>
      pathname.startsWith(path)
    );
    //if user is authenticated and route is as per the white list and socket has not been yet initialize then initialize the socket
    if (isUserAuthenticated && shouldSocketInitialize) {
      //here initializeCoverSocket will untimately call teh socketStore initializeStorySocket initializing and subscirbing the callbacks for cover and advance socket
      initializeCoverSocket(`story_${storyId}`);
    }
  };

  const updateNetworkStatus = (ev) => {
    setIsOnline(navigator.onLine);
    if (ev.type === "offline") {
      console.log("You are offline");
    } else {
      // check white list for socket initialization
      initializeCoverAndAdvanceSocket();
      if (isUserAuthenticated) fetchUserDetail();
    }
  };

  //diconnect socket if the user is not authenticated and route is not as per the white list
  const disconnectSockets = () => {
    const userIsNotInSocketWhiteList = whiteListForSocketInitialize.every(
      (path) => !window.location.pathname.startsWith(path)
    );

    if (!isUserAuthenticated) {
      disconnectAllSockets();
    } else {
      if (userIsNotInSocketWhiteList && storySocket) {
        disconnectStorySocket();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  //initialize both socket on page load and page change
  useEffect(() => {
    initializeCoverAndAdvanceSocket();
    disconnectSockets();
    //if user is not authenticated and  route is not as per the white list then disconnect the socket
  }, [window.location.pathname]);

  return { isOnline };
};

export default useNetworkStatus;
