/* eslint-disable react/prop-types */
export const PrimaryButton = ({
  children,
  handleButtonClick,
  className,
  ...rest
}) => {
  const isDisabled = rest.disabled;
  return (
    <button
      disabled={isDisabled}
      onClick={(e) => {
        e.preventDefault();
        handleButtonClick();
      }}
      className={`${
        isDisabled
          ? " bg-slate-300 border-white cursor-not-allowed hover:cursor-not-allowed"
          : "bg-[#3CC3AA] hover:bg-[#fff] hover:border-[#E3E9ED] hover:text-[#000]  border-[#3CC3AA] "
      } px-6 py-2 md:py-3 mt-6  rounded-full text-[#fff]  font-bold text-lg border-2 border-solid quicksand-text ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
