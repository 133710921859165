/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { DeleteFilled, LockFilled } from "@ant-design/icons";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { host, notAllowedHosts } from "..";
import { API } from "../../../ApiServices/apis";
import referenceImage4 from "../../../assets/aiboy1.webp";
import { default as referenceImage1 } from "../../../assets/aigirl.webp";
import referenceImage2 from "../../../assets/aigirl2.webp";
import referenceImage3 from "../../../assets/aigirl3.webp";
import { TransparentButton } from "../../../components/Button/TransparentButton";
import CustomButton from "../../../components/CustomButton";
import CustomReveal from "../../../components/CustomReveal";
import CustomerFileUploader from "../../../components/CustomUpload";
import { TextFiedWithLabelTag } from "../../../components/TextEditor/TextFieldWithLabelTag";
import api from "../../../composables/app";
import { randomString } from "../../../services/randommizer";
import useUserDetailsStore from "../../../store/UserStore";
const actionType = {
  name: "add_child_name",
  age: "add_child_age",
  gender: "add_child_gender",
  language: "add_language",
  file: "add_child_photo",

  privacyPolicy: "add_child_parental_consent",
};
const referenceImageUrl = [
  referenceImage1,
  referenceImage2,
  referenceImage3,
  referenceImage4,
];
const RenderForm = ({
  questions,
  question,
  kidsData,
  trigger,
  setTrigger,
  currentQuestion,
  setCurrentQuestion,
  handleGtag,
  setImageUrl,
  imageUrl,
  setImageId,
}) => {
  const navigate = useNavigate();
  const canAddHuman = useUserDetailsStore((state) => state.canAddHuman);
  const formik = useFormikContext();
  const [imageUploading, setImageUploading] = useState(false);
  const [fileName, setFileName] = useState("");
  const validateImage = async () => {
    setImageUploading(true);
    const base64Data = imageUrl.replace(
      /^data:image\/(png|jpeg|webp|svg);base64,/,
      ""
    );
    if (!formik.errors.file) {
      try {
        const response = await api.post(`${API.story.addChildImage}`, {
          image_name: fileName,
          image_data: base64Data,
        });
        if (response.data["status-code"] === 1) {
          setImageId(response?.data?.data?.image?.id);
          handleNextQuestion();
        } else {
          throw new Error(response?.data?.["status-message"]);
        }
      } catch (error) {
        formik.setFieldError("file", error?.response?.data?.["status-message"]);
      } finally {
        setImageUploading(false);
      }
    } else {
      setImageUploading(false);
    }
  };
  const handleNextQuestion = async () => {
    const newQuestion = questions[currentQuestion - 1];
    handleGtag(actionType[newQuestion.key]);
    if (typeof formik.errors[newQuestion.key] === "string") {
      formik.setFieldTouched(newQuestion.key, true);
    }
    const hasErrors = Object.keys(formik.errors).some((key) => {
      const isErrorForKey = formik.errors[key] && key === newQuestion.key;
      if (key === "visual_description_json" && isErrorForKey) {
        for (const visual_des_key in formik.errors.visual_description_json) {
          formik.setFieldTouched(
            `visual_description_json.${visual_des_key}`,
            true
          );
        }
      }
      return isErrorForKey;
    });

    if (!hasErrors) {
      if (currentQuestion === questions.length - 1) {
        if (!notAllowedHosts.includes(host))
          window.gtag("event", "parental_consent", {
            value: true,
          });
        formik.handleSubmit();
      } else {
        if (newQuestion.type === "file") {
          setCurrentQuestion((prevQuestion) => prevQuestion + 2);
        } else {
          setCurrentQuestion((prevQuestion) => prevQuestion + 1);
        }
        setTrigger(true);
      }
    }
  };
  useEffect(() => {
    if (imageUrl && fileName) {
      validateImage();
    }
  }, [imageUrl, fileName]);
  const handleDeleteImage = async () => {
    if (!canAddHuman) {
      toast(
        "You have hit the limit for uploading images. Please purchase a story/credits pack to increase your limit."
      );
      return;
    }
    formik.setFieldTouched("file", true);
    setFileName("");
    formik.setFieldValue("file", "");
    setImageUrl("");
    setImageId("");
  };

  function handleKeyDownInput(event) {
    if (event.key === "Enter") {
      handleNextQuestion();
    }
  }

  const handleChange = (e) => {
    formik.handleChange("language")(e);
    handleNextQuestion();
  };

  const handleGender = (gender) => {
    if (!kidsData?.gender) {
      formik.setFieldValue("gender", gender);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      handleGtag("add_child_gender");
    }
  };
  const handlePrevious = () => {
    if (formik.values.uploadFile && currentQuestion === questions.length - 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 2);
    } else {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    }
    setTrigger(true);
  };

  const handleCheckboxChange = () => {
    const newCheckboxState = !formik.values.consent;
    formik.setFieldValue("consent", newCheckboxState);
  };

  const handleFileUpload = async (name, fileUploaded) => {
    formik.setFieldTouched("file", true);
    //change the file name using the random text as a prefix in the file name
    const randomText = randomString();
    const file = new File(
      [fileUploaded],
      `${randomText}_${fileUploaded.name}`,
      {
        type: fileUploaded.type,
      }
    );

    formik.setFieldValue("file", file);

    setFileName(file?.name);
    const fileUrl = URL.createObjectURL(file);
    const base64String = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (error) {
        reject(error);
      };
      xhr.open("GET", fileUrl);
      xhr.responseType = "blob";
      xhr.send();
    });
    setImageUrl(base64String);
  };

  function extractNameAfterFirstUnderscore(inputString) {
    if (typeof inputString === "string") {
      const parts = inputString?.split("/");
      const lastPart = parts ? parts[parts.length - 1] : null;
      const firstUnderscoreIndex = lastPart?.indexOf("_");
      if (firstUnderscoreIndex !== -1) {
        return lastPart?.substring(firstUnderscoreIndex + 1);
      } else {
        return lastPart;
      }
    } else if (inputString && inputString.name) {
      return inputString.name;
    } else {
      return null;
    }
  }

  function extractName(url) {
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];
    const fileNameWithoutQueryParams = fileName.split("?")[0];
    return fileNameWithoutQueryParams;
  }
  // question.type = "visual_description";
  if (question?.type === "text") {
    return (
      <CustomReveal
        id={question.key}
        currentQuestion={currentQuestion}
        direction="up"
        questions={questions}
        onKeyDown={handleKeyDownInput}
        nextButton="Next"
        gaps="gap-[72px]"
        pressEnter="or Press Enter"
        onClick={handleNextQuestion}
        headingtext="What’s Your Child’s Name?"
        percent="17"
      >
        <input
          className="md:w-1/2 w-full p-4 placeholder-white text-white font-bold md:text-3xl text-xl border-b bg-transparent border-white mt-4 outline-none"
          type="text"
          placeholder="Name"
          required
          name="name"
          value={formik.values.name}
          autoFocus
          onChange={formik.handleChange}
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-red-500">{formik.errors.name}</div>
        ) : null}
      </CustomReveal>
    );
  } else if (question?.type === "number") {
    return (
      <>
        <CustomReveal
          id={question.key}
          in={trigger}
          direction="up"
          back="Back"
          nextButton="Next"
          gaps="gap-[60px]"
          handlePrevious={handlePrevious}
          currentQuestion={currentQuestion}
          pressEnter="or Press Enter"
          questions={questions}
          onClick={handleNextQuestion}
          onKeyDown={handleKeyDownInput}
          headingtext={`How Old Is ${formik.values.name}?`}
          percent="34"
        >
          <input
            className="md:w-1/2 w-full p-4 placeholder-white text-white font-bold md:text-3xl text-xl border-b bg-transparent border-white mt-4 outline-none"
            type="number"
            placeholder="Age"
            name="age"
            id="age"
            required
            autoFocus
            value={formik.values.age}
            onChange={formik.handleChange}
          />
          {formik.touched.age && formik.errors.age ? (
            <div className="text-red-500 text-bold">{formik.errors.age}</div>
          ) : null}
        </CustomReveal>
      </>
    );
  } else if (question?.type === "selectGender") {
    return (
      <>
        <CustomReveal
          id={question.key}
          in={trigger}
          direction="up"
          back="Back"
          nextButton="Next"
          gaps="gap-20"
          handlePrevious={handlePrevious}
          currentQuestion={currentQuestion}
          pressEnter={formik?.values.gender && "or Press Enter"}
          questions={questions}
          onClick={handleNextQuestion}
          onKeyDown={handleKeyDownInput}
          headingtext={`Is ${formik.values.name} A Boy or A Girl?`}
          percent="51"
        >
          <div className="flex gap-10">
            <button
              type="button"
              className={` font-semibold border-white border-2 border-solid px-10 py-2 rounded-full cursor-pointer ${formik.values.gender === "male"
                ? "bg-white text-[#3CC3AA]"
                : "bg-transparent hover:bg-[white] hover:text-[#3CC3AA] text-[white]"
                }`}
              required
              autoFocus={formik.values.gender === "male"}
              onClick={() => handleGender("male")}
              onFocus={formik.handleBlur}
            >
              Boy
            </button>
            <button
              type="button"
              className={` font-semibold border-white border-2 border-solid px-10 py-2 rounded-full cursor-pointer ${formik.values.gender === "female"
                ? "bg-white text-[#3CC3AA]"
                : "bg-transparent hover:bg-[white] hover:text-[#3CC3AA] text-[white]"
                }`}
              onClick={() => handleGender("female")}
              autoFocus={formik.values.gender === "female"}
              onFocus={formik.handleBlur}
            >
              Girl
            </button>
          </div>
          {formik.touched.gender && formik.errors.gender ? (
            <div className="text-red-500">{formik.errors.gender}</div>
          ) : null}
        </CustomReveal>
      </>
    );
  } else if (question?.type === "dropdown") {
    return (
      <>
        <CustomReveal
          id={question.key}
          in={trigger}
          direction="up"
          back="Back"
          gaps="gap-14"
          pressEnter="or Press Enter"
          nextButton="Next"
          handlePrevious={handlePrevious}
          currentQuestion={currentQuestion}
          questions={questions}
          onClick={handleNextQuestion}
          onKeyDown={handleKeyDownInput}
          percent="68"
          headingtext="Please select default language for your storybooks"
        >
          <FormControl>
            <Select
              labelId="demo-simple-select-label"
              className="w-60  rounded-3xl border-2 bg-transparent border-white"
              id="demo-simple-select"
              value={formik.values.language}
              onChange={handleChange}
              autoFocus
              onFocus={formik.handleBlur}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>Select language</em>
              </MenuItem>
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Bengali">Bengali</MenuItem>
              <MenuItem value="French">French</MenuItem>
              <MenuItem value="German">German</MenuItem>
              <MenuItem value="Hindi">Hindi</MenuItem>
              <MenuItem value="Italian">Italian</MenuItem>
              <MenuItem value="Japanese">Japanese</MenuItem>
              <MenuItem value="Portuguese">Portuguese</MenuItem>
              <MenuItem value="Russian">Russian</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
            </Select>
          </FormControl>

          {formik.touched.language && formik.errors.language ? (
            <div className="text-red-500">{formik.errors.language}</div>
          ) : null}
        </CustomReveal>
      </>
    );
  } else if (question?.type === "visual_description") {
    console.log("touched", formik.touched);
    return (
      <CustomReveal
        back="Back"
        handlePrevious={handlePrevious}
        id={question.key}
        currentQuestion={currentQuestion}
        direction="up"
        questions={questions}
        onKeyDown={handleKeyDownInput}
        gaps="gap-[24px]"
        onClick={handleNextQuestion}
        headingtext="Please Describe your Child's Apperance"
        percent="85"
        nextButton="Next"
        handleNextQuestion={handleNextQuestion}
        additionalControls={
          <div className="flex flex-col">
            <TransparentButton
              style={{ margin: 0 }}
              className={"bg-transparent text-white border-white border-2 "}
              onClick={() => {
                formik.setFieldValue("uploadFile", true);
                setCurrentQuestion(currentQuestion - 1);
              }}
            >
              Use Picture Instead
            </TransparentButton>
            <p className="text-sm max-w-[225px] text-center mt-2  text-white font-[600]">
              In case you don't feel like filling all the details
            </p>
          </div>
        }
      >
        <div className="grid grid-cols-visualDescAuto gap-4  gap-y-4 p-2 mb-[140px]">
          <TextFiedWithLabelTag
            label="Skin Color"
            placeholder="E.g.: fair"
            name="visual_description_json.skin_color"
          />
          <TextFiedWithLabelTag
            label="Face Shape"
            name="visual_description_json.face_shape"
            placeholder="E.g.: round"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.eye_color"
            label="Eye Color"
            placeholder="E.g.: brown"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.eye_shape"
            label="Eye Shape"
            placeholder="E.g.: almond"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.ethnicity"
            label="Ethnicity"
            placeholder="E.g.: Caucasian"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.hair_color"
            label="Hair Color"
            placeholder="E.g.: black"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.hair_length"
            label="Hair Length"
            placeholder="E.g.: short"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.hair_style"
            label="Hair Style"
            placeholder="E.g.: curly"
          />
          <TextFiedWithLabelTag
            name="visual_description_json.physique"
            label="Physique"
            placeholder="E.g.: slim"
          />
          <TextFiedWithLabelTag
            className="lg:col-span-3"
            label="Additional Feature"
            maxLine={4}
            multiLine
            name="visual_description_json.distinctive_features"
            placeholder="E.g.: scar on the left cheek"
          />
        </div>
      </CustomReveal>
    );
  } else if (question?.type === "file") {
    return (
      <CustomReveal
        id={question.key}
        in={trigger}
        direction="up"
        back="Back"
        handlePrevious={() => {
          formik.setFieldValue("uploadFile", false);
          handlePrevious();
        }}
        currentQuestion={currentQuestion}
        nextButton={
          imageUploading ? <CircularProgress size={20} /> : <span>Next</span>
        }
        questions={questions}
        disabled={Boolean(formik.errors.file) || imageUploading}
        pressEnter={"or Press Enter"}
        gaps="gap-1"
        onClick={handleNextQuestion}
        onKeyDown={handleKeyDownInput}
        additionalControls={
          <div className="flex flex-col items-center">
            <TransparentButton
              style={{ margin: 0 }}
              className={"bg-transparent  text-white border-white border-2 "}
              onClick={() => {
                formik.setFieldValue("uploadFile", false);
                setCurrentQuestion(currentQuestion + 1);
              }}
            >
              Manually Describe Instead
            </TransparentButton>
            <p className="text-sm max-w-[225px] text-center mt-2  text-white font-[600]">
              In case you don't feel like uploading you child's picture
            </p>
          </div>
        }
        percent="85"
        headingtext="Please Upload Your Child’s Solo Pic"
      >
        <div className="flex gap-5 mt-5 items-start justify-start">
          <div className="relative w-48 h-48  border-2 border-solid border-gray-200 rounded-md flex justify-center items-center overflow-hidden">
            <span
              className={
                imageUrl ? "hidden" : "block text-white cursor-pointer"
              }
            >
              Upload an image
            </span>
            <CustomerFileUploader
              required={true}
              autoFocus={true}
              onFileSelect={(files) => {
                handleFileUpload("file", files[0]);
              }}
              selectedFile={formik.values.file}
              value={extractNameAfterFirstUnderscore(formik.values.file)}
              infoName={
                typeof formik.values.file !== "string"
                  ? extractNameAfterFirstUnderscore(formik.values.file)
                  : extractName(formik.values.file)
              }
              id={"Business_Proof"}
              title="Click to upload or Browse Files"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer "
              fileType={"image/png, image/gif, image/jpeg"}
            />
            {imageUrl && (
              <>
                <div>
                  <img
                    src={imageUrl}
                    alt="Uploaded"
                    autoFocus
                    className="w-full h-full object-cover"
                  />
                </div>
                {!imageUploading && (
                  <button
                    type="button"
                    onClick={handleDeleteImage}
                    className=" cursor-pointer absolute top-0 right-0 mt-2 mr-2 bg-red-500 text-white px-2 py-1 rounded-full"
                  >
                    {canAddHuman ? " Delete" : " Locked"}{" "}
                    {canAddHuman ? <DeleteFilled /> : <LockFilled />}
                  </button>
                )}
              </>
            )}
          </div>
          <div className="grid grid-cols-2 gap-3 items-start">
            {referenceImageUrl?.map((item, index) => (
              <div key={index}>
                <img src={item} alt="Reference" className="w-24 rounded" />
              </div>
            ))}
            <p className="text-white text-xs">Example photos</p>
          </div>
        </div>
        {formik.touched.file && formik.errors.file ? (
          <div className="text-red-500">{formik.errors.file}</div>
        ) : null}
      </CustomReveal>
    );
  } else if (question?.type === "privacyPolicy") {
    return (
      <>
        <CustomReveal
          id={question.key}
          in={trigger}
          direction="up"
          back="Back"
          handlePrevious={handlePrevious}
          currentQuestion={currentQuestion}
          gaps="gap-4"
          nextButton={
            formik.isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <span>Agree</span>
            )
          }
          disabled={!formik.values.consent || formik.isSubmitting}
          disagreeButton="Disagree"
          diagreeDisabled={formik.isSubmitting}
          questions={questions}
          onClick={handleNextQuestion}
          headingtext="Terms & Conditions"
          percent="100"
          onKeyDown={handleKeyDownInput}
        >
          <div className="">
            <div className=" flex gap-2 items-center  checkbox-containe">
              <Checkbox
                sx={{
                  color: "white",
                  "&.Mui-checked": {
                    color: "white",
                  },
                }}
                name="consent"
                id="consent"
                autoFocus
                // ref={checkboxRef}
                checked={formik.values.consent}
                onChange={handleCheckboxChange}
                onBlur={formik.handleBlur}
              />

              <p className="font-Poppins text-[white]  md:text-base sm:text-[10px] text-[10px]">
                I am the parent/legal guardian of the child whose information
                has been entered.
              </p>
            </div>
            <div className="text-white md:text-base sm:text-[10px] text-[10px]">
              <p>
                By checking this box, I acknowledge and consent to the
                following:
              </p>
              <ol className="list-decimal ml-8 md:text-sm ">
                <li className="mb-[2px] ">
                  Collection of Information: I understand that StoryGenius will
                  collect, use, and store personal information about my child,
                  as described in the Privacy Policy.
                </li>
                <li className="mb-[2px]">
                  This information includes name, age, gender and their picture.
                </li>
                <li className="mb-[2px] ">
                  Purpose of Collection: I understand that this information is
                  collected for generating storybooks and other interactive
                  multimedia content as requested by me.
                </li>
                <li className="mb-[2px] ">
                  Disclosure of Information: I understand that this information
                  may be shared with third parties, and if it is, the types of
                  parties with whom it will be shared are detailed in the
                  Privacy Policy.
                </li>
                <li className="mb-[2px]">
                  Parental Rights: I understand that I have the right to review
                  my child’s personal information, request it be deleted, and
                  refuse further collection or use of the information.
                  Instructions for exercising these rights are provided in the
                  Privacy Policy.
                </li>
                <li className="mb-[2px]">
                  Consent to Terms: I agree to the terms of the Privacy Policy
                  and affirm that I am the legal parent/guardian of the child
                  being registered.
                </li>
              </ol>
            </div>
            {formik.touched.consent && (
              <div className="text-red-500">{formik.errors.consent}</div>
            )}
          </div>
        </CustomReveal>
      </>
    );
  } else if (question?.type === "done") {
    return (
      <CustomReveal
        id={question.key}
        in={trigger}
        direction="up"
        gaps="gap-8"
        handlePrevious={handlePrevious}
        currentQuestion={currentQuestion}
        questions={questions}
        reupload="Re-Upload"
        kiddata={kidsData}
      >
        <center>
          <div className="max-w-[750px] items-center flex flex-col gap-16">
            <h1 className="text-center md:text-5xl md:w-full sm:w-full w-full sm:text-[24px] text-[24px] font-medium text-white">
              Hooray! Let's Get Started With Your StoryBook!
            </h1>
            {kidsData?.image_urls.length ? (
              <div className="flex gap-5 items-start justify-start">
                <div className="w-[243px] h-[243px]">
                  <img
                    src={kidsData?.image_urls}
                    alt=""
                    className="w-[100%] h-[100%] object-cover border-4 rounded-lg border-white"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            <CustomButton
              type="button"
              className="sticky bottom-10"
              onClick={() => navigate(`/choose-story?id=${kidsData.id}`)}
            >
              Let's Go
            </CustomButton>
          </div>
        </center>
      </CustomReveal>
    );
  }

  return null;
};

export default RenderForm;
