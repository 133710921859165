/* eslint-disable react/prop-types */
export const TransparentButton = ({
  children,
  handleButtonClick,
  className,
  ...rest
}) => {
  return (
    <button
      disabled={rest.disabled}
      onClick={handleButtonClick}
      className={`${
        rest.disabled ? "cursor-not-allowed opacity-60" : ""
      } px-6 md:py-2 py-3 mt-6  rounded-full ${
        rest.theme === "red"
          ? "text-[#d33b3b] hover:bg-[#d33b3b] border-[#d33b3b]"
          : "text-[#3CC3AA] hover:bg-[#3CC3AA] border-[#3CC3AA]"
      }  hover:text-[#fff]  font-bold text-sm md:text-lg border-2 border-solid  quicksand-text bg-[#fff]  ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
