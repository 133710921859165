/* eslint-disable react/prop-types */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Success1 from "../../assets/Success.svg";
import Wrong from "../../assets/Wrong.svg";
import { debounced } from "../../services/debounce";
import { PrimaryButton } from "../Button/PrimaryButton";
import ModalCompV2 from "../CouponCode/indexV2";
import { CountrySelector } from "../CreditPricingComponents/chooseCurrency";
import useStore from "../UseStore";
import { countrycurrencycode, getCurrencySymbol } from "./Assets";
import useUserDetailsStore from "../../store/UserStore";
import useShippingOptionsHook from "../../pages/Preview/hooks/useShippingOptions";
import { subscriptionStore } from "../../store/SubscriptionStore";

export const PaymentSectionV2 = ({
  productDetail,
  onCheckout,
  handleoffersBlur,
}) => {
  const [searchParams] = useSearchParams();
  const storyId = searchParams.get("storyId");
  const orderId = searchParams.get("orderId");

  const currencyFromParams = searchParams.get("currency");
  const userDetail = useUserDetailsStore((state) => state.userDetail);
  const navigate = useNavigate();

  const planId = searchParams.get("plan_id");
  const [selectedPlanDetails, currentsubscriptionStatusDetails] =
    subscriptionStore((state) => [
      state.selectedPlanDetails,
      state.currentsubscriptionStatusDetails,
    ]);

  const [
    offers,
    setCurrency,
    setoffers,
    currency,
    couponMessage,
    setCouponMessage,
    couponValidity,
    loading,
    setLoading,
    discountResponse,
    checkingout,
    resetPaymentDetails,
  ] = useStore((state) => [
    state.offers,
    state.setCurrency,
    state.setoffers,
    state.currency,
    state.couponMessage,
    state.setCouponMessage,
    state.couponValidity,
    state.loading,
    state.setLoading,
    state.discountResponse,
    state.checkingout,
    state.resetPaymentDetails,
  ]);

  const promoCode = currentsubscriptionStatusDetails?.packages?.discount_code;
  useEffect(() => {
    if (promoCode && !planId && productDetail) {
      setoffers(promoCode);
      handleoffersBlur(promoCode);
    }
  }, [promoCode, planId, productDetail]);

  useEffect(() => {
    if (currencyFromParams && countrycurrencycode[currencyFromParams]) {
      setCurrency(currencyFromParams);
    } else if (userDetail?.user?.currency) {
      setCurrency(userDetail.user.currency);
    }
  }, [currencyFromParams, userDetail]);

  let currencySymbol = countrycurrencycode[currency];
  const { shippingOptions, selectedShipment } = useShippingOptionsHook(orderId);

  const shippingDetails = shippingOptions?.find(
    (option) => option.id === selectedShipment
  );
  const onInputChange = (e) => {
    e.preventDefault();
    const code = e.target.value.toUpperCase();

    setoffers(code);
    debounced(
      code,
      (value) => {
        handleoffersBlur(value);
      },
      500
    );
  };
  useEffect(() => {
    resetPaymentDetails();
  }, []);

  useEffect(() => {
    if (productDetail && productDetail.include_shipping && !orderId) {
      navigate("/");
    }
  }, [productDetail, orderId]);

  const GSTPercentage = productDetail?.prices?.[currency]?.gst?.percent || 0;

  let GSTPrice =
    couponMessage === "true"
      ? discountResponse?.data?.prices?.[currency]?.gst?.amount
      : productDetail?.prices?.[currency]?.gst?.amount || 0;

  if (orderId) {
    GSTPrice = shippingDetails?.prices?.[currency]?.gst?.amount || 0;
  }

  const shippingCost = shippingDetails?.prices?.[currency]?.shipping_cost || 0;

  let total = 0;
  if (couponMessage === "true") {
    total = discountResponse?.data?.prices?.[currency]?.total;
  } else if (orderId) {
    total = shippingDetails?.prices?.[currency]?.total;
  } else {
    total = productDetail?.prices?.[currency]?.total;
  }
  const subtotal = total - GSTPrice;

  const isSubscriptionUpdate =
    planId && currentsubscriptionStatusDetails?.subscription?.id;
  return (
    <>
      <div className="w-[100%] mx-auto mt-4 px-4">
        {!planId && (
          <div className="flex justify-center">
            <CountrySelector
              currency={currency}
              currencyList={
                productDetail?.prices ? Object.keys(productDetail?.prices) : []
              }
              onCurrencyChange={(value) => {
                setCurrency(value);
              }}
            />
          </div>
        )}
        <div className="flex flex-col gap-4 mt-8">
          <div className="flex justify-between gap-4 ">
            {!planId && <h1 className="text-lg font-bold">Quantity</h1>}
            <h1 className="text-lg font-bold">
              {planId ? "Plan Title" : "Description"}
            </h1>

            <h1 className="text-lg font-bold">
              Price{" "}
              {isSubscriptionUpdate ? (
                <span className="text-md text-slate-400 font-[400]">
                  (per billing cycle)
                </span>
              ) : (
                ""
              )}
            </h1>
          </div>
          <hr />
          <div>
            <div className="flex justify-between gap-4">
              {!planId && (
                <h1 className="text-lg ">{productDetail?.quantity}</h1>
              )}
              <div className="flex flex-col gap-1 justify-center items-center ml-12">
                <h1 className="text-lg ">
                  {planId
                    ? selectedPlanDetails?.item?.name
                    : productDetail?.name}
                </h1>
                {!planId && couponValidity && (
                  <p className="text-[#128807] ">
                    DISCOUNT APPLIED (
                    <strong>
                      -{discountResponse?.data?.discount_percent}%
                    </strong>
                    )
                  </p>
                )}
              </div>
              <div>
                <h1 className="text-lg ">
                  {planId
                    ? `$${
                        selectedPlanDetails?.item?.amount
                          ? selectedPlanDetails?.item?.amount / 100
                          : 0
                      }`
                    : `${currencySymbol} ${
                        productDetail?.prices?.[currency]?.price ?? ""
                      }`}
                </h1>

                {!planId && couponValidity && (
                  <p className="text-[#128807]">
                    <strong>
                      {`${currencySymbol} ${discountResponse?.data?.prices?.[currency]?.discounted}`}
                    </strong>
                  </p>
                )}
              </div>
            </div>
          </div>

          <hr />
          {isSubscriptionUpdate ? (
            <note>
              <p className="text-sm text-slate-400">
                <strong>Note:</strong> Your card will be charged the balance
                amount for current month automatically. You may be charged a
                small amount now to confirm your payment details.
              </p>
            </note>
          ) : (
            ""
          )}

          {!planId && orderId && (
            <>
              <div className="flex justify-between gap-4">
                <h1 className="text-lg font-bold">Shipping</h1>
                <h1 className="text-lg font-bold">
                  {`${shippingDetails?.carrier_service_name ?? ""}`}
                </h1>
                <h1 className="text-lg font-bold">
                  {`${getCurrencySymbol(currency)} ${shippingCost ?? 0}`}
                </h1>
              </div>

              <hr />
            </>
          )}
          {!planId && currency === "INR" && (
            <>
              <div className="flex justify-between gap-4">
                <h1 className="text-lg font-bold">Sub Total</h1>
                <h1 className="text-lg font-bold"></h1>
                <h1 className="text-lg font-bold">
                  {`${currencySymbol} ${subtotal.toFixed(2)}`}
                </h1>
              </div>
              <hr />
            </>
          )}
          {!planId && currency === "INR" && (
            <>
              <div className="flex justify-between gap-4">
                <h1 className="text-lg font-bold">GST</h1>
                <h1 className="text-lg font-bold">{`${GSTPercentage}%`}</h1>
                <h1 className="text-lg font-bold">{`₹ ${GSTPrice}`}</h1>
              </div>
              <hr />
            </>
          )}
          {!planId && (
            <div className="flex justify-between gap-4">
              <h1 className="text-lg font-bold">Total</h1>
              <h1 className="text-lg font-bold"></h1>
              <h1 className="text-lg font-bold">
                {`${currencySymbol} ${total ?? 0}`}
              </h1>
            </div>
          )}

          {(productDetail && productDetail.include_shipping) || planId ? (
            <></>
          ) : (
            <div className="w-[100%] p-4 border border-gray rounded-md mt-4 flex justify-between gap-8">
              <div className="flex w-[100%]">
                <input
                  className="outline-none w-[100%]"
                  type="text"
                  placeholder="Have a discount code? "
                  value={offers}
                  onChange={onInputChange}
                />
              </div>

              <div>
                {loading ? (
                  <CircularProgress style={{ height: "25px", width: "25px" }} />
                ) : couponMessage === "true" ? (
                  <img src={Success1} className="w-[24px]" />
                ) : couponMessage === "false" && loading === false ? (
                  <img
                    src={Wrong}
                    className="w-[24px]"
                    onClick={() => {
                      setCouponMessage("");
                      setoffers("");
                      setLoading(false);
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
          {!planId && couponMessage === "false" ? (
            <p className="text-red-500 text-center">
              Oops! Seems like you used a wrong discount code!
            </p>
          ) : null}

          {!planId && productDetail && !productDetail.include_shipping && (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <span className="text-[#3CC3AA] font-bold text-lg">
                    % &nbsp;
                  </span>{" "}
                  View all available offers
                </AccordionSummary>
                <AccordionDetails>
                  <ModalCompV2
                    additionalTask={(offer) => {
                      handleoffersBlur(offer);
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          )}

          <PrimaryButton
            disabled={loading || checkingout}
            className={` ${(loading || checkingout) && "cursor-wait"}`}
            handleButtonClick={() => {
              onCheckout(storyId);
            }}
          >
            Proceed to Checkout
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
