/* eslint-disable react/prop-types */
import { CircularProgress } from "@mui/material";
import useShippingOptionsHook from "../../../pages/Preview/hooks/useShippingOptions";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { DialogWrapper } from "../../Dialog";
import { Title } from "../../molecules/header";
import DeliveryOptionItem from "./DeliveryOptionTile";
import { useState } from "react";

const SelectDeliveryOptionDialog = ({ open, onClose, orderId }) => {
  const [selectedId, setSelectedId] = useState(null);
  const { shippingOptions, submitShipment, submitting } =
    useShippingOptionsHook(orderId);

  return (
    <DialogWrapper
      className={`w-[100%] max-h-[90vh] overflow-auto max-w-[920px]`}
      isOpen={open}
      onClose={onClose}
    >
      <div className="flex flex-col items-center p-4">
        <Title className={" text-[24px] text-center flex mb-8"}>
          Please Select Delivery Option
        </Title>
        {shippingOptions?.map((item) => (
          <DeliveryOptionItem
            key={item.id}
            item={item}
            checked={selectedId === item.id}
            onClick={() => setSelectedId(item.id)}
          />
        ))}
        <PrimaryButton
          disabled={!selectedId || submitting}
          className={"max-w-[200px] w-[100%] mb-16"}
          handleButtonClick={() => {
            submitShipment(selectedId);
          }}
        >
          {submitting ? (
            <CircularProgress sx={{ color: "#3CC3AA" }} size={24} />
          ) : (
            "Proceed"
          )}
        </PrimaryButton>
      </div>
    </DialogWrapper>
  );
};
export default SelectDeliveryOptionDialog;
