import { useEffect, useState } from "react";
import {
  getCountriesList,
  getStateAsPerCountry,
} from "../../../../ApiServices/Story/storyApis";

export const useCountryHook = (countryCode) => {
  const [countries, setCountryList] = useState({});
  const [states, setStateList] = useState({});

  const countryList = countries ? Object.keys(countries).map((key) => key) : [];
  const stateList = states ? Object.keys(states).map((key) => key) : [];

  const getCountryList = () => {
    getCountriesList().then((response) => {
      setCountryList(response.data.data.countries);
    });
  };
  useEffect(() => {
    const getStatesAsPerCountry = () => {
      getStateAsPerCountry(countryCode).then((response) => {
        setStateList(response.data.data.states);
      });
    };

    if (countryCode) getStatesAsPerCountry();
  }, [countryCode]);

  useEffect(() => {
    getCountryList();
  }, []);

  return { stateList, countryList, countries, states };
};
