/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import "./index.css";
import { CircularProgress } from "@mui/material";
import { FailedToLoadImage } from "../ImageNotFound";
const CustomCover = ({
  backgroundImage,
  text,
  backgroundColor,
  pageDetails,
  imageLoading,
}) => {
  const [previewAspectRatio, setPreviewAspectRatio] = useState(0);
  const previewRef = useRef(null);
  const textElements = pageDetails?.text_elements;

  const getContainerWidth = () => {
    if (previewRef.current) {
      const containerWidthPx = previewRef.current.offsetWidth;
      const dpi = 96; // Assume 96 DPI which is standard used in web development
      const widthMm = (containerWidthPx / dpi) * 25.4;
      let aspectRatio = widthMm / 281;
      setPreviewAspectRatio(aspectRatio);
    }
  };

  useEffect(() => {
    getContainerWidth();
  }, [previewRef.current]);

  return (
    <div
      ref={previewRef}
      className={` left-offset border pr-0.5 rounded-r-md pb-0.5 border-solid ${backgroundColor || "bg-[#008DC2]"
        } -mt-1 border-l-0`}
      style={{ borderWidth: "0.5px" }}
    >
      <div className="border pr-0.2  rounded-r-md pb-0.5 mr-0.2 pl-0 border-solid border-[#ccc] bg-white -mt-1 border-l-0">
        <div className="border pr-0.2   rounded-r-md pb-0.5 mr-0.2 border-solid border-[#ccc] bg-white -mt-1 border-l-0">
          <div className="border pr-0.2  rounded-r-md pb-0.5 mr-0.2 border-solid border-[#ccc] bg-white -mt-1 border-l-0">
            <div className="border pr-0.2 rounded-r-md pb-0.5 mr-0.2 border-solid border-[#ccc] bg-white -mt-1 border-l-0">
              <div className="border pr-0.2  rounded-r-md pb-0.5 mr-0.2 border-solid border-[#ccc] bg-white -mt-1 border-l-0">
                <div className="relative">
                  <div className="-mt-1 md:w-[18rem] lg:w-[18rem] w-[18rem] md:h-[18rem] lg:h-[18rem] h-[18rem] mx-auto rounded-xl theme_box_shadow">
                    {!backgroundImage &&
                      pageDetails?.failure_code === "1401" ? (
                      <div className="h-[287px] overflow-hidden">
                        <FailedToLoadImage />
                      </div>
                    ) : (
                      backgroundImage && (
                        <img
                          src={backgroundImage}
                          alt="story_image"
                          className="md:w-[18rem] lg:w-[18rem] w-[18rem] mx-auto rounded-r-md theme_box_shadow"
                          style={{
                            boxShadow:
                              "rgba(0, 0, 0, 0.5) 22px 24px 30px -14px, rgba(0, 0, 0, 0.5) 0px -8px 10px -16px",
                          }}
                        />
                      )
                    )}
                    <div
                      className="absolute inset-0"
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.3) 5px 0px 3px inset",
                        mixBlendMode: "multiply",
                        filter: "blur(2px)",
                        zIndex: 2,
                        width: "15px",
                        left: "10px",
                      }}
                    ></div>
                    {textElements?.map((textElement) => (
                      <div
                        key={textElement?.id}
                        className="absolute w-[18rem] h-[18rem] top-0 left-0 flex "
                        style={{
                          padding: "12%",
                          justifyContent: "center",
                          alignItems: `${textElement?.text_position ?? "end"}`,
                        }}
                      >
                        <div className="text-lg md:text-xl lg:text-2xl z-10 text-center text-white w-[95%] ">
                          <div
                            className="text-style text-wrap overflow-hidden"
                            style={{
                              width: "100%",
                              color: textElement?.text_color,
                              fontFamily: `${textElement?.font ?? "Berkshire Swash"
                                }, sans-serif  `,
                              fontSize: `${textElement?.font_size * previewAspectRatio
                                }px`,
                              textShadow: `0 0 3px ${textElement?.shadow_color ?? "#dfdfdf"
                                }, 0 0 5px #fffe, 0 0 10px #3c3b3bdd`,
                              textAlign: `${textElement?.text_alignment ?? "center"
                                }`,
                              lineHeight: "normal",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: textElement?.text ?? text,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                    {imageLoading && (
                      <div className="z-50 flex justify-center items-center absolute top-0 left-0 w-[100%] h-[100%] bg-[#dfdfdf] opacity-80 ">
                        <CircularProgress sx={{ color: "#3CC3AA" }} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCover;
