import { SubTitle } from "../molecules/subtitle";
import CheckTag from "./CheckTag";
import ImageCard from "./ImageCard";
import { styleEditorStore } from "../../pages/StyleEditor/styleEditorStore";
import Coin from "../../assets/coin.webp";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useStoryCoinHelper } from "../StoryCoin/storyCoinHook";
import useUserDetailsStore from "../../store/UserStore";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const SelectPageSection = () => {
  const [
    setImageSelected,
    imageSelected,
    toggleImageSelect,
    generateStyleImages,
    imageToCompare,
  ] = styleEditorStore((state) => [
    state.setImageSelected,
    state.imageSelected,
    state.toggleImageSelect,
    state.generateStyleImages,
    state.imageToCompare,
  ]);
  const { storyId } = useParams();
  const [searchParams] = useSearchParams();
  const pageId = searchParams.get("pageId");

  const [storyDetail, getStoryDetails] = useAdvanceStoryStore((state) => [
    state.story,
    state.getStoryDetails,
  ]);
  const {
    showCoinShortDialog,
    showCoinDetailDialog,
    setModelType,
    dontShowConfirmation,
  } = useStoryCoinHelper();
  const credit = useUserDetailsStore((state) => state.credit);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!storyDetail) return;
    if (pageId) {
      setImageSelected(pageId);
    }
    getStoryDetails(storyId);
  }, [storyId, pageId]);

  const allSelected =
    imageSelected.length ===
    storyDetail?.pages.filter((page) => page.image_urls.length).length;

  const handleGenerate = () => {
    const coinCost = imageSelected.length;
    const navigateUrl = `/credit-packs?storyId=${storyId}&redirecturl=${window.location.pathname}`;

    if (dontShowConfirmation) {
      if (credit < coinCost) {
        showCoinShortDialog({
          onProceedPayment: () => {
            navigate(navigateUrl);
            setModelType("");
          },
        });
      } else {
        generateStyleImages();
        setModelType("");
      }
      return;
    }

    showCoinDetailDialog({
      productType: "Regenerating Image",
      coinCost: coinCost,
      callback: () => {
        if (credit < coinCost) {
          showCoinShortDialog({
            onProceedPayment: () => {
              navigate(navigateUrl);
              setModelType("");
            },
          });
        } else {
          generateStyleImages();
          setModelType("");
        }
      },
    });
  };
  return (
    <div className=" relative flex mt-4 pt-0 text-[#36324A] flex-col items-center rounded-lg border border-[#E1E1E1] p-4">
      <div className=" pt-4 sticky top-0 bg-white z-10 flex items-center justify-between w-full border-b border-slate-400 ">
        <SubTitle>Select Page to Apply Style</SubTitle>
        <div
          onClick={() => {
            toggleImageSelect(storyDetail.pages);
          }}
          className=" hover:bg-slate-100 relative mb-2 px-6 py-4 border cursor-pointer  rounded-xl"
        >
          Select All
          {allSelected && (
            <div className="absolute -right-[10px] -top-[10px]">
              <CheckTag />
            </div>
          )}
        </div>
      </div>

      <div className="  min-h-[208px] max-h-[398px] overflow-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-center justify-center mt-4">
        {storyDetail?.pages
          .filter((page) => page.image_urls.length)
          ?.map((page) => {
            const active = imageSelected.includes(page.id);
            return (
              <ImageCard
                key={page.id}
                id={page.id}
                active={active}
                onClick={() => setImageSelected(page.id)}
                image={page.image_urls[page.current_image_index]}
                size="auto"
              />
            );
          })}
      </div>
      {imageSelected.length ? (
        <div className=" absolute bottom-10 right-5 mt-4 border-[2px] border-[#3cc3aa] bg-[#D6F2ED] flex items-center rounded-full text-[#36324A]">
          <p className="mx-4 font-bold">
            {imageSelected.length} Pages Selected
          </p>
          <LightTooltip
            arrow
            placement="top-start"
            title={
              imageToCompare?.id
                ? ""
                : "Select/Upload the image first before generating "
            }
          >
            <div
              onClick={() => {
                if (!imageToCompare?.id) return;
                handleGenerate();
              }}
              className={`${
                !imageToCompare?.id
                  ? "cursor-not-allowed"
                  : "cursor-pointer hover:bg-opacity-100"
              } bg-[#3CC3AA] bg-opacity-70  flex justify-center items-center font-bold text-white rounded-full min-w-[180px] py-2`}
            >
              <p>
                Apply Style{" "}
                {imageSelected.length ? `${imageSelected.length} ×` : ""}
              </p>
              {imageSelected.length ? (
                <img className="h-8 w-8" src={Coin} alt="coin" />
              ) : (
                ""
              )}
            </div>
          </LightTooltip>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default SelectPageSection;
