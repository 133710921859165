import * as Yup from "yup";

const addressScheme = Yup.object().shape({
  recipient_name: Yup.string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters long")
    .max(50, "Name cannot be more than 50 characters long"),
  country: Yup.string().required("Country is required"),
  country_code: Yup.string().required("Country Code is required"),
  city: Yup.string().required("City is required"),
  postcode: Yup.string()
    .required("Postal Code is required")
    .matches(
      /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/,
      "Postal Code must be a valid ZIP code"
    ),
  phone: Yup.string()
    .required("Phone Number is required")
    .matches(
      /^\+?[\d\s-./()]{8,20}$/,
      "Phone Number must be a valid international phone number"
    ),
  street1: Yup.string()
    .required("Address Line 1 is required")
    .max(100, "Address Line 1 cannot be more than 100 characters long"),
  street2: Yup.string().max(
    100,
    "Address Line 2 cannot be more than 100 characters long"
  ),
});

export const validationSchema = Yup.object().shape({
  shipping_address: addressScheme,
  is_billing_same_as_shipping: Yup.boolean(),
  // billing_address: Yup.object().shape({}),
  billing_address: Yup.object().when("is_billing_same_as_shipping", {
    is: false,
    then: () => addressScheme,
    otherwise: () => Yup.object().shape({}),
  }),
});

export default validationSchema;
