//@ts-check
import { create } from "zustand";
import {
  getPrintOrderHistory,
  getStoryCoinsHistory,
} from "../../ApiServices/History";
import { checkSuccessStatus } from "../../ApiServices/handleErrors";

export const TabConstant = {
  PRINT_ORDER: "0",
  CREDITS: "1",
};

const historyStore = create((set, get) => ({
  printOrderHistory: {
    loading: true,
    data: {},
    error: null,
    filter: {
      // story_id: null,
      sort: "DESC",
      page: 1,
    },
  },
  storyCoinsHistory: {
    loading: true,
    data: [],
    error: null,
    filter: {
      // story_id: null,
      sort: "DESC",
      page: 1,
    },
  },
  getFilter: () => {
    const tab = get().activeTab;
    switch (tab) {
      case TabConstant.PRINT_ORDER:
        return get().printOrderHistory.filter;
      case TabConstant.CREDITS:
        return get().storyCoinsHistory.filter;
      default:
        return get().printOrderHistory.filter;
    }
  },
  setFilter: (filter) => {
    const tab = get().activeTab;

    switch (tab) {
      case TabConstant.PRINT_ORDER:
        var newFilter = { ...get().printOrderHistory.filter, ...filter };
        set((state) => ({
          ...state,
          printOrderHistory: {
            ...get().printOrderHistory,
            filter: { ...newFilter },
          },
        }));
        break;

      case TabConstant.CREDITS:
        var newFilter2 = { ...get().storyCoinsHistory.filter, ...filter };
        set((state) => ({
          ...state,
          storyCoinsHistory: {
            ...get().storyCoinsHistory,
            filter: { ...newFilter2 },
          },
        }));
        break;
    }
  },

  activeTab: TabConstant.PRINT_ORDER,
  setActiveTab: (tab) => set((state) => ({ ...state, activeTab: tab })),
  setPrintOrderHistory: (data) =>
    set((state) => ({
      ...state,
      printOrderHistory: { ...state.printOrderHistory, data },
    })),

  setStoryCoinsHistory: (data) =>
    set((state) => ({
      ...state,
      storyCoinsHistory: { ...state.storyCoinsHistory, data },
    })),
  setLoading: (tab) =>
    set((state) => ({
      ...state,
      [tab]: { ...state[tab], loading: true },
    })),
  setError: (tab, error) =>
    set((state) => ({
      ...state,
      [tab]: { ...state[tab], error },
    })),
  clearError: (tab) =>
    set((state) => ({
      ...state,
      [tab]: { ...state[tab], error: null },
    })),
  getPrintOrderList: async (params) => {
    set((state) => ({
      ...state,
      printOrderHistory: { ...state.printOrderHistory, loading: true },
    }));
    try {
      const response = await getPrintOrderHistory(params);
      if (checkSuccessStatus(response)) {
        set((state) => ({
          ...state,
          printOrderHistory: {
            ...state.printOrderHistory,
            data: response.data.data,
            loading: false,
            error: null,
          },
        }));
      } else {
        throw new Error(response.data?.["status-message"]);
      }
    } catch (error) {
      set((state) => ({
        ...state,
        printOrderHistory: { ...state.printOrderHistory, error },
      }));
    }
  },
  getStoryCoinsHistoryList: async (params) => {
    set((state) => ({
      ...state,
      storyCoinsHistory: { ...state.storyCoinsHistory, loading: true },
    }));
    try {
      const response = await getStoryCoinsHistory(params);
      if (checkSuccessStatus(response)) {
        set((state) => ({
          ...state,
          storyCoinsHistory: {
            ...state.storyCoinsHistory,
            data: response.data.data,
            loading: false,
            error: null,
          },
        }));
      } else {
        throw new Error(response.data?.["status-message"]);
      }
    } catch (error) {
      set((state) => ({
        ...state,
        storyCoinsHistory: { ...state.storyCoinsHistory, error },
      }));
    }
  },
}));

export default historyStore;
