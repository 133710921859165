/* eslint-disable react/prop-types */
import toast from "react-hot-toast";
import { styleEditorStore } from "../../pages/StyleEditor/styleEditorStore";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { PrimaryButton } from "../Button/PrimaryButton";
import { DialogWrapper } from "../Dialog";
import { Title } from "../molecules/header";
import ImageCard from "./ImageCard";

//@ts-check
const StyleReferenceModel = ({ isOpen, onClose }) => {
  const [imageToCompare, setImageToCompare] = styleEditorStore((state) => [
    state.imageToCompare,
    state.setImageToCompare,
  ]);
  const storyDetails = useAdvanceStoryStore((state) => state.story);
  return (
    <DialogWrapper
      className="max-w-[1000px]"
      isOpen={isOpen}
      onClose={onClose}
      header={null}
    >
      <div className="p-2 md:p-8 flex flex-col items-center">
        <div className="relative flex flex-col justify-center w-full">
          <Title className={"text-center text-md"}>
            Select Page for Style Reference
          </Title>
          {imageToCompare && (
            <PrimaryButton
              handleButtonClick={onClose}
              style={{ marginTop: "0px" }}
              className={"hidden lg:flex lg:absolute  top-0 right-0 px-14 "}
            >
              Done
            </PrimaryButton>
          )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-8 border border-slate-400 rounded-r-lg max-h-[60vh] overflow-auto">
          {storyDetails?.pages
            ?.filter((page) => page.image_urls.length)
            ?.map((page, index) => {
              let imageUrl = page.image_urls?.[page.current_image_index];
              let imageId = page.image_ids?.[page.current_image_index];
              return (
                <ImageCard
                  key={index}
                  image={imageUrl}
                  active={imageId && imageToCompare?.id === imageId}
                  id={imageId}
                  onClick={() => {
                    if (imageId && imageUrl) {
                      setImageToCompare({ id: imageId, imageUrl: imageUrl });
                    } else {
                      toast.error(
                        "Cannot select the current image. Please try another illustration."
                      );
                    }
                  }}
                  size="auto"
                />
              );
            })}
        </div>
        {imageToCompare && (
          <PrimaryButton
            handleButtonClick={onClose}
            className={"block lg:hidden max-w-[400px] w-full "}
          >
            Done
          </PrimaryButton>
        )}
      </div>
    </DialogWrapper>
  );
};
export default StyleReferenceModel;
