import historyStore, { TabConstant } from "../../store/HistoryStore";
import PrintOrder from "./PrintOrder";
import StoryCoins from "./StoryCoinsHistory";

/* eslint-disable react/prop-types */
const OrderHistoryList = () => {
  const activeTab = historyStore((state) => state.activeTab);

  const VIEW = {
    [TabConstant.PRINT_ORDER]: <PrintOrder />,
    [TabConstant.CREDITS]: <StoryCoins />,
  };
  return <section className="w-full">{VIEW[activeTab]}</section>;
};
export default OrderHistoryList;
