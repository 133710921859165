import CheckTag from "./CheckTag";
import CrossTag from "./CrossTag";
/**
 * @typedef {"large" | "medium" | "small" | "auto"} Size
 */

/**
 * MyComponent props
 * @typedef {Object} ComponentProps
 * @property {string} image - The image URL.
 * @property {boolean} active - Whether the component is active.
 * @property {Function} onClick - Click handler function.
 * @property {number} id - Unique identifier.
 * @property {Size} size - Size of the component.
 * //make onDelete optional
 * @property {Function} onDelete - Delete handler function.
 */

/**
 * MyComponent function
 * @param {ComponentProps} props
 * @returns {JSX.Element}
 */
/* eslint-disable react/prop-types */
const ImageCard = ({ image, active, onClick, id, size, onDelete, loading }) => {
  const className = {
    large: "h-[200px] w-[200px]",
    medium: "h-[180px] w-[180px]",
    small: "h-[114px] w-[114px]",
    auto: "h-auto w-auto",
  };
  return (
    <div
      key={id}
      className={`relative ${
        active ? "ring-[3px] ring-[#3cc3aa]" : " "
      } rounded-lg cursor-pointer relative flex items-center justify-between m-2 `}
      onClick={() => onClick(id)}
    >
      <div className="flex items-center ">
        <img
          className={`${className[size || "small"]} rounded-lg`}
          src={image}
          alt="cover"
        />
      </div>
      {active && (
        <div className="absolute -right-[5px] -top-[7px]">
          <CheckTag />
        </div>
      )}
      {onDelete && !loading ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          className="absolute -right-[5px] -top-[7px]"
        >
          <CrossTag />
        </div>
      ) : (
        ""
      )}
      {loading && (
        <div className="absolute top-0 w-full h-full rounded-lg overflow-hidden">
          <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#3cc3aa]"></div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ImageCard;
