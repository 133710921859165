import GoogleLogin from "../GoogleLogin";
import Headerlogo from "../../assets/Headerlogo.webp";
import AppSumoLogoDark from "../../assets/appsumo-logo-dark.webp";

import LoginCoverImg from "../../assets/loginCoverImage.webp";
// @ts-check
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useSearchParams } from "react-router-dom";
import { SubTitle } from "../../components/molecules/subtitle";
const Login = () => {
  const [params] = useSearchParams();

  const appSumoPromo = params.get("appsumo_code");
  const hasAppSumoPromo = Boolean(appSumoPromo);

  const checkIsMobile = () => {
    return window.matchMedia("(max-width: 1024px)").matches;
  };
  return (
    <div>
      <div className="h-[100vh] flex flex-row ">
        {checkIsMobile() ? (
          <></>
        ) : (
          <div className="relative hidden lg:inline basis-4/6 bg-gray-200 bg-contain add-child-background overflow-hidden">
            <div className="absolute -bottom-10 w-[100%] h-[100%] ">
              <img
                src={LoginCoverImg}
                alt="cover image"
                className="w-[100%] h-[100%] object-cover"
              />
            </div>
          </div>
        )}
        <div className="basis-full lg:basis-2/6 ">
          <div className="flex flex-col items-center gap-3 justify-center h-[100%]">
            <div className="flex items-center">
              <img
                src={Headerlogo}
                alt="logo"
                className="md:w-32 sm:w-20 w-20"
              />

              <h1 className="md:text-3xl sm:text-lg text-lg  font-medium">
                StoryGenius
              </h1>
            </div>
            {hasAppSumoPromo && (
              <div className="flex flex-col justify-center items-center p-4">
                <h1 className="md:text-3xl sm:text-lg text-lg  font-medium">
                  +
                </h1>
                <br />
                <img
                  src={AppSumoLogoDark}
                  alt="appsumo logo"
                  className="max-w-[300px] "
                />
                <br></br>
                <SubTitle>Sign Up/Login below to redeem your code</SubTitle>
              </div>
            )}
            <GoogleLogin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
