/* eslint-disable react/no-unescaped-entities */
import { DialogWrapper } from "../../Dialog";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { useStoryCoinHelper } from "../storyCoinHook";
import useUserDetailsStore from "../../../store/UserStore";
import coinPng from "../../../assets/coin.webp";
import { TransparentButton } from "../../Button/TransparentButton";
import Checkbox from "@mui/material/Checkbox";
import { usePostCheckboxStatus } from "./usePostCheckboxStatus";
// eslint-disable-next-line react/prop-types
export const CoinDetailDialog = ({ open, onClose }) => {
  const [coinCost, onCreditConfirmation, productType] = useStoryCoinHelper(
    (state) => [state.coinCost, state.onCreditConfirmation, state.productType]
  );
  const credit = useUserDetailsStore((state) => state.credit);

  const { checked, changeStatus } = usePostCheckboxStatus();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const Heading = () => {
    return (
      <div className="bg-[#3CC3AA] rounded-t-xl p-3">
        <p className="w-[100%] text-center text-2xl text-[#fff]">
          <b>Just a Moment!</b>
        </p>
      </div>
    );
  };
  const onCheckBoxChange = () => {
    changeStatus();
  };
  return (
    <DialogWrapper isOpen={open} onClose={onClose} header={<Heading />}>
      <div className="p-8 pb-4 bg-white text-[#36324A] relative flex flex-col items-center justify-center gap-1">
        <p className="flex flex-wrap justify-center items-center text-center">
          Generating this illustration would cost{" "}
          <span className="flex items-center">
            <img src={coinPng} className="h-10 w-10" alt="coin image" />
            <b>{coinCost} StoryCoin</b>
          </span>
        </p>
        <p className="text-center">Are you sure you want to continue?</p>
        <br />
        <p className=" flex flex-wrap justify-center align-center text-center">
          You Currently Have :
          <span className="flex items-end items-center">
            <img src={coinPng} className="h-8 w-8" alt="coin image" />
            <b>{credit} StoryCoins</b>
          </span>
        </p>
        {productType === "Quick Generate" ? (
          <></>
        ) : (
          <p className="pt-4">
            <Checkbox
              sx={{
                color: " #3CC3AA",
                "&.Mui-checked": {
                  color: "#3CC3AA",
                },
              }}
              checked={checked}
              onChange={onCheckBoxChange}
              {...label}
            />
            <span className="px-4">Don't show me this message again</span>
          </p>
        )}
      </div>
      <center>
        <div className="flex justify-between item-center p-4 pt-0 max-w-[330px]">
          <TransparentButton
            handleButtonClick={() => {
              onClose();
            }}
          >
            <div className="flex">Cancel</div>
          </TransparentButton>
          <PrimaryButton
            handleButtonClick={() => {
              onCreditConfirmation();
            }}
          >
            <div className="flex">Continue</div>
          </PrimaryButton>
        </div>
      </center>
    </DialogWrapper>
  );
};
