/* eslint-disable react/prop-types */
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { DialogWrapper } from "../Dialog";
import left from "../../assets/left.svg";
import right from "../../assets/right.svg";
import { styleEditorStore } from "../../pages/StyleEditor/styleEditorStore";
import { useState } from "react";

const ImageViewer = ({ pageId, newImageIndex, onClose }) => {
  const isOpen = pageId !== null;
  const [storyDetails, onImageSwitch] = useAdvanceStoryStore((state) => [
    state.story,
    state.onImageSwitch,
  ]);
  const compareConfig = styleEditorStore((state) => state.compareConfig);
  const page_index = storyDetails?.pages.findIndex(
    (page) => page.id === pageId
  );
  const [imageIndex, setImageIndex] = useState(newImageIndex);
  const imageUrl =
    storyDetails &&
    page_index != null &&
    storyDetails?.pages[page_index]?.image_urls[imageIndex];
  const disablePrevButton = compareConfig[pageId] === imageIndex;
  const disableNextButton = newImageIndex === imageIndex;
  const handleLeftClick = () => {
    setImageIndex(compareConfig[pageId]);
    onImageSwitch(compareConfig[pageId], pageId);
  };
  const handleRightClick = () => {
    setImageIndex(newImageIndex);
    onImageSwitch(newImageIndex, pageId);
  };

  const Controls = ({ disabled, text, Icon, onClick }) => {
    return (
      <div className="m-4 mb-0">
        <img
          src={Icon}
          className={`
${disabled ? "opacity-50 cursor-not-allowed" : " cursor-pointer"}
bg-[#F4F7FA] rounded-lg p-2 hover:bg-[#E5E5E5]`}
          onClick={() => {
            if (!disabled) onClick();
          }}
        />
        <p className="font-bold">{text}</p>
      </div>
    );
  };

  return (
    <DialogWrapper
      className="relative max-w-[800px] max-h-[90vh] overflow-scroll"
      isOpen={isOpen}
      onClose={onClose}
      header={null}
    >
      {Object.keys(compareConfig).length ? (
        <div className="sticky top-0 flex items-center justify-center overflow-scroll">
          <Controls
            disabled={disablePrevButton}
            text="Undo"
            Icon={left}
            onClick={handleLeftClick}
          />
          <Controls
            disabled={disableNextButton}
            text="Redo"
            Icon={right}
            onClick={handleRightClick}
          />
        </div>
      ) : (
        ""
      )}
      <div className="m-4 rounded-2xl">
        <img className="rounded-2xl" src={imageUrl} alt="image" />
      </div>
    </DialogWrapper>
  );
};
export default ImageViewer;
