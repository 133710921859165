import { HelmetProvider, Helmet } from "react-helmet-async";

export const helmetContext = {};
export function Head({ children }) {
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <title>StoryGenius</title>
      </Helmet>
      {children}
    </HelmetProvider>
  );
}
