import {
  DownloadOutlined,
  EditFilled,
  MoonFilled,
  PrinterFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import StoryEmptyImg from "../../assets/storyEmpty.svg";
import PaginationProgress from "../../components/CustomPagination";
import PreviewComponent from "../../components/PreviewSection/PreviewComponent";
import ShareDialog from "../../components/PreviewSection/ShareDialog";
import { Title } from "../../components/molecules/header";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import useThemeStore from "../../store/Theme";
import useUserDetailsStore from "../../store/UserStore";
import { useDownloadPdf } from "./downloadPdf";
import PreviewButton from "./modules/PreviewButton";
import PrintOptionDialog from "../../components/PreviewSection/PrintOption";
import { trackStory } from "../../ApiServices/Story/storyApis";
import {
  ANANOMOUS_USER_ID,
  addStoryIfNew,
  isStoryTracked,
} from "../../components/PreviewSection/storyTrackHelper";
import SelectDeliveryOptionDialog from "../../components/PreviewSection/ShippingOption";
import useSocketStore from "../../store/SocketStore/useSocketStore";

export const PreviewPage = () => {
  const { storyId } = useParams();
  const userDetail = useUserDetailsStore((state) => state.userDetail);
  const { downloadPdf, loading: downloadingPdf } = useDownloadPdf();
  const [showPrintDialog, setShowPrintDialog] = useState(false);
  const [
    story,
    page,
    storyNotFound,
    loadingStory,
    storyCallback,
    getPublicStoryDetails,
  ] = useAdvanceStoryStore((state) => [
    state.story,
    state.page,
    state.storyNotFound,
    state.loadingStory,
    state.storyCallback,
    state.getPublicStoryDetails,
  ]);
  const layout = story?.story?.layout ?? "merged";
  const navigate = useNavigate();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  let containerWidth =
    page === 0 || layout === "merged"
      ? "max-w-[386px] xl:max-w-[666px]"
      : "max-w-[765px] xl:max-w-[1332px]";

  const [toggleTheme, theme, setTheme] = useThemeStore((state) => [
    state.toggleTheme,
    state.theme,
    state.setTheme,
  ]);
  const darkTheme = theme === "dark";
  const tokenExists = Cookies.get("story_token");
  const isUserAuthenticated =
    tokenExists && story?.story?.user === userDetail?.user?.id;
  useSocketStore([storyCallback]);

  useEffect(() => {
    if (!tokenExists) {
      getPublicStoryDetails(storyId);
    }
    if (window.location.pathname.includes("/story/")) {
      setTheme(localStorage.getItem("theme") ?? "light");
    }
    return () => {
      setTheme("light");
    };
  }, [tokenExists]);

  useEffect(() => {
    if (isStoryTracked(storyId, userDetail?.user?.id || ANANOMOUS_USER_ID))
      return;
    trackStory(storyId).then((response) => {
      if (response.status === 200) {
        if (response.data?.["status-code"] === 1) {
          addStoryIfNew(storyId, userDetail?.user?.id);
        }
      }
    });
  }, []);

  const toggleShareDialog = () => {
    setShowShareDialog(!showShareDialog);
  };

  const goToAdvanceStory = () => {
    navigate(`/storyboard/${storyId}`);
  };

  if (loadingStory) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center h-full min-h-[100vh] m-auto">
          <CircularProgress style={{ color: "#3CC3AA" }} />
        </div>
      </div>
    );
  }
  if (storyNotFound) {
    return (
      <div>
        <div className="flex flex-col items-center justify-center h-[100vh] m-auto">
          <Title>Sorry!</Title>
          <img src={StoryEmptyImg} alt="empty story" />
          <p className="text-md my-4">
            The story you are looking for is not public. Please contact the
            Author{" "}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col justify-center items-center h-full min-h-[100vh] ${
        darkTheme && "bg-black"
      }`}
    >
      <SelectDeliveryOptionDialog
        open={orderId}
        onClose={() => setSearchParams({})}
        orderId={orderId}
      />
      <PrintOptionDialog
        open={showPrintDialog}
        onClose={() => setShowPrintDialog(false)}
        storyId={storyId}
      />
      <Helmet>
        <title>{story?.story?.title}</title>
      </Helmet>
      <ShareDialog
        show={showShareDialog}
        onClose={() => {
          toggleShareDialog();
        }}
      />
      <div className="flex flex-row-reverse w-[100%] my-8">
        <PreviewButton
          active={darkTheme}
          onClick={() => {
            toggleTheme();
            localStorage.setItem("theme", darkTheme ? "light" : "dark");
          }}
          icon={<MoonFilled />}
        />
        <PreviewButton
          onClick={() => {
            toggleShareDialog();
          }}
          icon={<ShareAltOutlined />}
        />
        <PreviewButton
          disabled={downloadingPdf}
          onClick={() => downloadPdf(storyId)}
          icon={
            downloadingPdf ? (
              <CircularProgress size={20} />
            ) : (
              <DownloadOutlined style={{ fontSize: "24px" }} />
            )
          }
        />
        {isUserAuthenticated && (
          <PreviewButton
            disabled={downloadingPdf}
            onClick={() => goToAdvanceStory()}
            icon={<EditFilled style={{ fontSize: "24px" }} />}
          />
        )}
        {isUserAuthenticated && (
          <PreviewButton
            onClick={() => {
              setShowPrintDialog(true);
            }}
            icon={<PrinterFilled />}
          />
        )}
      </div>
      <div
        className={`${containerWidth} w-[100%] h-[100%] rounded-xl overflow-hidden `}
      >
        <PreviewComponent />
      </div>
      <PaginationProgress />
      <p className={`${darkTheme && "text-white"} p-6`}>
        Created with StoryGenius.me
      </p>
    </div>
  );
};
