import { useEffect } from "react";
import socketStore from ".";

const useSocketStore = (callbacks) => {
  const [subscribeToStorySocket, storySocket, unsubscribeFromStorySocket] =
    socketStore((state) => [
      state.subscribeToStorySocket,
      state.storySocket,
      state.unsubscribeFromStorySocket,
    ]);

  useEffect(() => {
    if (storySocket) {
      callbacks.forEach((callback) => {
        subscribeToStorySocket(callback);
      });
    }

    return () => {
      if (storySocket) {
        callbacks.forEach((callback) => {
          unsubscribeFromStorySocket(callback);
          // storySocket.unsubscribe(callback);
        });
      }
    };
  }, [storySocket]);
};
export default useSocketStore;
