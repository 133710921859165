/* eslint-disable react/prop-types */
import { Close } from "@mui/icons-material";
import { DialogWrapper } from "../Dialog";
import Cookies from "js-cookie";

import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { LinkPreview } from "./LinkPreview";
import { shareList } from "./constant";
import "./index.css";
import useShareHelpers from "./shareHelpers";
import useUserDetailsStore from "../../store/UserStore";

const ShareDialog = ({ show, onClose }) => {
  const [storyDetails, isStoryPublic, changePrivacy] = useAdvanceStoryStore(
    (state) => [state.story, state.isStoryPublic, state.setStoryPrivacy]
  );
  const userDetail = useUserDetailsStore((state) => state.userDetail);

  const tokenExists = Cookies.get("story_token");

  const isUserAuthenticated =
    tokenExists && storyDetails?.story?.user === userDetail?.user?.id;

  const { shareToFacebook, shareToLinkedIn, shareToTwitter, shareToWhatsApp } =
    useShareHelpers(storyDetails);

  const shareFn = {
    Facebook: shareToFacebook,
    LinkedIn: shareToLinkedIn,
    Twitter: shareToTwitter,
    WhatsApp: shareToWhatsApp,
  };
  const Heading = () => {
    return (
      <div className=" relative rounded-t-xl p-3 flex h-16 justify-between items-start ">
        <p className=" flex w-[100%] items-center text-[#3D5A80] text-xl">
          <b>Share with</b>
        </p>
        <div onClick={onClose} className="cursor-pointer">
          <Close style={{ color: "#3D5A80" }} />
        </div>
      </div>
    );
  };
  const Chip = ({ text, active, ...rest }) => {
    return (
      <div
        {...rest}
        className={`${
          active
            ? "border border-[#3CC3AA] bg-[#3CC3AA] bg-opacity-30 text-[#3D5A80]"
            : "bg-[#FAFAFA] hover:bg-[#E5E5E5] "
        } p-2 px-4 font-bold rounded-md m-2 cursor-pointer`}
      >
        <p className="text-[13px]"> {text}</p>
      </div>
    );
  };

  const ShareChip = ({ icon, text, ...rest }) => {
    return (
      <div
        {...rest}
        className={`font-bold text-sm hover:text-[#3CC3AA] text-[#3D5A80] p-2 cursor-pointer flex flex-col items-center justify-center m-2`}
      >
        <div className="h-[72px] w-[72px] bg-[#FAFAFA] flex items-center justify-center rounded-full">
          {icon}
        </div>
        <br />
        {text}
      </div>
    );
  };
  return (
    <DialogWrapper isOpen={show} onClose={onClose} header={<Heading />}>
      <div className=" share-dialog flex justify-center flex-col items-center">
        {isUserAuthenticated && (
          <p className="font-bold text-[#3D5A80]">
            Select who can see your story
          </p>
        )}
        {isUserAuthenticated && (
          <div className="flex pt-2">
            <Chip
              onClick={() => {
                changePrivacy({ is_public: true });
              }}
              active={isStoryPublic}
              text="Visible to anyone with link"
            />
            <Chip
              onClick={() => {
                changePrivacy({ is_public: false });
              }}
              active={!isStoryPublic}
              text="Only visible to you"
            />
          </div>
        )}
        <div className="flex m-4">
          {shareList.map((item, index) => {
            return (
              <ShareChip
                key={index}
                text={item.name}
                icon={<item.icon style={{ fontSize: "25px" }} />}
                onClick={() => {
                  //check if it is dev or prod and set the shareUrl accordingly
                  const shareUrl = window.location.href.includes("localhost")
                    ? "https://dev-app.storygenius.me/story/70e2a5b6adb745e68be459f91f4ad82f"
                    : window.location.href;
                  shareFn[item.name](shareUrl);
                }}
              />
            );
          })}
        </div>
        <p className="font-bold text-[12px] text-[#3D5A80] opacity-50">
          Or share with link
        </p>
        <LinkPreview link={window.location.href} />
      </div>
    </DialogWrapper>
  );
};
export default ShareDialog;
