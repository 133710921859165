import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUX56SrDIwB2g2qRlKxnUYzrjTjPRgBjk",
  authDomain: "storygenius-v1.firebaseapp.com",
  projectId: "storygenius-v1",
  storageBucket: "storygenius-v1.appspot.com",
  messagingSenderId: "967049030459",
  appId: "1:967049030459:web:3ae68cdc7dcca5e81e651e",
  measurementId: "G-KD2GB9NLR2",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
