import { CircularProgress } from "@mui/material";
import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../ApiServices/apis";
import { logSentryMessage } from "../../ApiServices/handleErrors";
import GoogleIcon from "../../assets/google_icon.svg";
import { auth, googleProvider } from "../../components/Config/config";
import { host, notAllowedHosts } from "../AddChild";
const baseURL = import.meta.env.VITE_BACKEND_BASE_URL;

const GoogleLogin = () => {
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const appSumoPromo = params.get("appsumo_code");
  const hasAppSumoPromo = Boolean(appSumoPromo);
  const redirectUrl = params.get("redirect_url");

  const handleGoogleLogin = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      setToken(result?.user?.accessToken);
      setEmail(result?.user?.email);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const attachAppSumoPromo = () => {
    if (hasAppSumoPromo) {
      return `&appsumo_code=${appSumoPromo}`;
    }
    return "";
  };

  const handleLogin = async () => {
    if (token) {
      setLoading(true);
      try {
        const response = await axios.post(`${baseURL}${API.login}/`, {
          token: token,
        });
        Cookies.set("story_token", response?.data?.data?.access_token, {
          expires: 30,
        });
        if (response?.data?.data?.is_new_user) {
          if (!notAllowedHosts.includes(host))
            window.dataLayer.push({
              event: "SignUp",
              value: email,
            });
        }
        if (redirectUrl) {
          window.location.href = redirectUrl;
          return;
        }
        window.location.href = "/select-child?tab=story" + attachAppSumoPromo();
      } catch (error) {
        toast.error(error?.response?.data?.message);
        logSentryMessage(
          "Error logging in with google: " + error?.response?.data?.message
        );
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (token && email) handleLogin();
  }, [token, email]);

  return (
    <div className="p-4" key="google-login-button">
      <div
        key="google-login"
        className={`min-w-[360px] flex flex-col justify-center items-center py-4 mt-12 font-medium tracking-normal text-blue-500 whitespace-nowrap bg-white rounded-xl border border-solid shadow-sm border-[color:var(--1,#367AFF)] leading-[120%] cursor-pointer ${
          loading ? "opacity-50 cursor-progress" : ""
        }`}
        onClick={handleGoogleLogin}
      >
        <div className=" w-[100%] flex justify-center">
          <img
            loading="lazy"
            alt=""
            src={GoogleIcon}
            className="m-2 object-center justify-center items-center w-6 aspect-square"
          />

          <div className=" flex justify-center items-center">
            {" "}
            {loading ? (
              <CircularProgress style={{ height: "30px", width: "30px" }} />
            ) : (
              <span>Sign up/Login with Google</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleLogin;
