export const FAILED_CODE = {
  // Invalid inputs
  100: "Invalid inputs",
  101: "Too many requests",

  // Photo uploads
  200: "Unable to upload photo",
  201: "Document not found",
  205: "Unsupported file format",

  // User-related errors
  300: "Invalid login token",
  301: "Unauthorized",
  302: "User not found",

  // S3-related errors
  500: "S3 unable to download document",
  501: "S3 unable to upload document",
  502: "S3 unable to delete document",

  // AI Model-related errors
  603: "Face not found",
  604: "Multiple faces",
  605: "Invalid image URL",

  // Credit-related errors
  802: "Insufficient credits",

  // Story Genius Prediction-related errors
  1000: "Prediction in progress",
  1001: "Prediction failed",
  1002: "Vision server error",

  // PDF generation-related errors
  1003: "PDF generation failure",

  // Razorpay-related errors
  1004: "Razorpay server error",
  1005: "Razorpay invalid signature",
  1006: "Razorpay order not found",
  1007: "Razorpay bad request error",

  // Promo Code-related errors
  1008: "Invalid promo code",
  1009: "Promo code expired",
  1010: "Promo code limit exceeded",
  1011: "Promo code not found",
  1012: "Promo code unsupported currency",

  // Product-related errors
  1100: "Product not found",

  // Story-related errors
  1200: "Story not found",
  1201: "Storyline not found",
  1202: "Story page not found",

  // Human-related errors
  1300: "Human not found",
  1301: "Consent required",
  1302: "Character not found",

  // OpenAI-related errors
  1400: "rate limited",
  1401: "content policy violation",

  // Image-related errors
  1500: "Image not found",

  1703: "Subscription already exists",
};

export const SUCCESS_CODE = 1;
