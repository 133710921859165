/* eslint-disable react/prop-types */
import { IconButton } from "@mui/material";
import { DialogWrapper } from "../../Dialog";
import { Title } from "../../molecules/header";
import { DedicationForm } from "./DedicationForm";
import { PAGE_VIEW_OPTION } from "./constants";

import { PrintDetailsForm } from "./printDetails";
import { useState } from "react";
import { ChevronLeftSharp, Close } from "@mui/icons-material";
import useGetProductDetails from "../../CreditPricingComponents/useGetProductDetails";
import { CountrySelector } from "../../CreditPricingComponents/chooseCurrency";
import { useSearchParams } from "react-router-dom";

const PrintOptionDialog = ({ open, onClose, storyId }) => {
  const [printOption, setPrintOption] = useState(null);
  const [selectedPageView, setSelectedPageView] = useState(
    PAGE_VIEW_OPTION.OPTION_SELECT
  );
  const { productDetails, printOptions, currency, setCurrency } =
    useGetProductDetails();
  const handlePrintOption = (option) => {
    setPrintOption(option);
  };
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const resetDialog = () => {
    setSelectedPageView(PAGE_VIEW_OPTION.OPTION_SELECT);
  };

  const CoverCard = ({ img, title, subTitle, price, ...rest }) => {
    return (
      <div
        className="max-w-[280px] w-[100%] m-8 relative cursor-pointer group place-self-center"
        {...rest}
      >
        <img src={img} alt="Hard Binding" />
        <div className="rounded-lg absolute top-0 w-full h-full flex flex-col justify-end items-center bg-gradient-to-t group-hover:from-[#38b29cb1] from-[#1A1A1A] group-hover:from-0% from-0% group-hover:via-[#38b29cb1] via-[#1A1A1A] group-hover:via-50% via-20% group-hover:to-100% to-90% opacity-60 group-hover:opacity-100 transition duration-300 ease-in"></div>
        <div className="absolute top-0 w-full h-full flex flex-col justify-end items-center">
          <p className="text-md font-bold text-[#fff] font-quicksand group-hover:mb-0 mb-8">
            {title}
          </p>
          <div className="hidden group-hover:flex font-poppins flex-col items-center justify-center mb-4 text-[#fff] ">
            <p className=" ">{subTitle}</p>
            <div className="relative">
              <p className="text-lg font-bold ">{price}</p>
              <p className="text-sm opacity-60 absolute top-[5px] -right-20 flex">
                {"+ shipping"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const CoverCardForMobile = ({ img, title, subTitle, price, ...rest }) => {
    return (
      <div
        className="flex justify-between m-4 p-4 bg-[#FAFAFA] rounded-xl "
        {...rest}
      >
        <div className="max-w-[128px] w-[100%]">
          <img src={img} alt="Hard Binding" />
        </div>
        <div className="flex flex-grow font-poppins flex-col items-center justify-center  text-[#36324A] ">
          <p className="text-[12px] font-bold font-quicksand pb-2">{title}</p>
          <p className="text-center text-[12px] font-light leading-4 pb-2 px-4">
            {subTitle}
          </p>
          <p className="text-[16px] font-bold relative text-[#3CC3AA] ">
            {price}
            <span className="flex text-[8px] text-[#36324A] font-normal opacity-60 absolute top-[6px] -right-[50px]">
              + shipping
            </span>
          </p>
        </div>
      </div>
    );
  };

  const PAGEVIEW = {
    [PAGE_VIEW_OPTION.OPTION_SELECT]: (
      <>
        <div className="flex flex-col m-8 justify-between items-center">
          Choose your currency
          <CountrySelector
            currency={currency}
            currencyList={
              productDetails?.products.length
                ? Object.keys(productDetails?.products?.[0]?.prices)
                : []
            }
            onCurrencyChange={(value) => {
              setCurrency(value);
              setSearchParams({ currency: value });
            }}
          />
        </div>
        <div className="m-8 w-full hidden sm:flex items center justify-center">
          {printOptions
            ? printOptions?.map((option) => (
                <CoverCard
                  key={option.id}
                  img={option.img}
                  title={option.title}
                  subTitle={
                    option.title.includes("Hard")
                      ? "Casewrap, Premium paper"
                      : "Saddle Stitch, Premium paper"
                  }
                  price={option.price}
                  onClick={() => {
                    setSelectedPageView(PAGE_VIEW_OPTION.DEDICATION_FORM);
                    handlePrintOption(option);
                  }}
                />
              ))
            : ""}
        </div>
        <div className="flex flex-col sm:hidden w-[100%] mt-4">
          {printOptions
            ? printOptions?.map((option) => (
                <CoverCardForMobile
                  key={option.id}
                  img={option.img}
                  title={option.title}
                  subTitle={
                    option.title.includes("Hard")
                      ? "Casewrap, Premium paper"
                      : "Saddle Stitch, Premium paper"
                  }
                  price={option.price}
                  onClick={() => {
                    setSelectedPageView(PAGE_VIEW_OPTION.DEDICATION_FORM);
                    handlePrintOption(option);
                  }}
                />
              ))
            : ""}
        </div>
      </>
    ),
    [PAGE_VIEW_OPTION.DEDICATION_FORM]: (
      <DedicationForm
        onCancel={() => {
          setSelectedPageView(PAGE_VIEW_OPTION.DETAILS_FORM);
        }}
      />
    ),
    [PAGE_VIEW_OPTION.DETAILS_FORM]: (
      <PrintDetailsForm
        onProceed={() => {
          resetDialog();
          onClose();
        }}
        product={printOption?.id}
        storyId={storyId}
      />
    ),
  };
  return (
    <DialogWrapper
      className={`w-[100%] max-h-[90vh] overflow-auto max-w-[920px]`}
      isOpen={open}
      onClose={() => {
        resetDialog();
        onClose();
      }}
      header={
        selectedPageView !== PAGE_VIEW_OPTION.OPTION_SELECT ? (
          <div className="flex justify-between p-4">
            <div
              onClick={() => {
                if (selectedPageView === PAGE_VIEW_OPTION.DEDICATION_FORM) {
                  setSelectedPageView(PAGE_VIEW_OPTION.OPTION_SELECT);
                } else {
                  setSelectedPageView(PAGE_VIEW_OPTION.DEDICATION_FORM);
                }
              }}
              className="text-[#76777E] flex cursor-pointer hover:scale-105 transition-all duration-200 "
            >
              <ChevronLeftSharp fontSize="large" />
              <span className="m-auto pl-2 ">Back</span>
            </div>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>
        ) : (
          ""
        )
      }
    >
      <div className="flex flex-col items-center mb-8 w-[100%] px-2 md:px-8">
        <Title
          className={" text-[18px] md:text-[24px] text-center flex flex-wrap"}
        >
          {" "}
          {selectedPageView}
          {selectedPageView === PAGE_VIEW_OPTION.DEDICATION_FORM && (
            <span className=" ml-4 h-[36px] font-[500]  text-sm hidden md:flex text-[#3cc3aa] border-2 border-[#3cc3aa] w-fit px-6 rounded-lg  items-center justify-center">
              Free
            </span>
          )}
        </Title>
        {PAGEVIEW[selectedPageView]}
      </div>
    </DialogWrapper>
  );
};
export default PrintOptionDialog;
