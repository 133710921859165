import { useState } from "react";
import { API } from "../../../ApiServices/apis";
import api from "../../../composables/app";
import { useStoryCoinHelper } from "../storyCoinHook";

export const usePostCheckboxStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dontShowConfirmation, setDontShowConfirmation] = useStoryCoinHelper(
    (state) => [state.dontShowConfirmation, state.setDontShowConfirmation]
  );
  const [checked, setChecked] = useState(dontShowConfirmation ?? false);

  const changeStatus = async () => {
    setChecked(!checked);
    return await api
      .put(`${API.user.updateUser}`, { show_credit_dialog: checked })
      .then((response) => {
        if (response.status === 200) {
          setDontShowConfirmation(true);
          return response.data;
        } else throw new Error(response.data["status-message"]);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setChecked(checked);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { checked, setChecked, loading, error, changeStatus };
};
