//@ts-check
import Box from "@mui/material/Box";
import {
  PAGE_TEXT_LOADING_STATE,
  SOCKET_OPERATIONS,
} from "../services/socketoOperations";
import useAdvanceStoryStore from "../store/AdvanceStoryStore";
import React from "react";
import { TextFieldWithSpinner } from "./TextEditor/TextFieldWithSpinner";
export default function CharacterDetails() {
  const [formState, handleInputChange, onApperanceChange, story] =
    useAdvanceStoryStore((state) => [
      state.formState,
      state.handleInputChange,

      state.onApperanceChange,
      state.story,
    ]);
  return (
    <React.Fragment>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="flex flex-col gap-6 justify-center items-center">
          <TextFieldWithSpinner
            id="outlined-disabled"
            label={`${story?.characters?.[0]?.name}'s Appearance`}
            name="appearance"
            multiline
            rows={4}
            value={formState.appearance}
            onChange={(e) => {
              onApperanceChange(e);
            }}
            loading={
              formState[
                PAGE_TEXT_LOADING_STATE[
                  SOCKET_OPERATIONS.STORY_CHARACTER_APPERANCE
                ]
              ]
            }
          />

          <TextFieldWithSpinner
            id="outlined-disabled"
            label="Clothing"
            name="clothing"
            value={formState.clothing}
            onChange={(e) => {
              handleInputChange(
                e,
                SOCKET_OPERATIONS.PAGE_CHARACTER_CLOTHING_CHANGE
              );
            }}
            multiline
            rows={4}
            loading={
              formState[
                PAGE_TEXT_LOADING_STATE[
                  SOCKET_OPERATIONS.PAGE_CHARACTER_CLOTHING_CHANGE
                ]
              ]
            }
          />
          <TextFieldWithSpinner
            id="outlined-disabled"
            label="Expression"
            value={formState.expression}
            name="expression"
            onChange={(e) => {
              handleInputChange(
                e,
                SOCKET_OPERATIONS.PAGE_CHARACTER_EXPRESSION_CHANGE
              );
            }}
            loading={
              formState[
                PAGE_TEXT_LOADING_STATE[
                  SOCKET_OPERATIONS.PAGE_CHARACTER_EXPRESSION_CHANGE
                ]
              ]
            }
          />
        </div>
      </Box>
    </React.Fragment>
  );
}
