const useShareHelpers = (story) => {
  const message = encodeURIComponent(
    `Checkout this story ${story?.story?.title} featuring  ${story?.characters?.[0]?.name} created by AI`
  );
  const shareToFacebook = (url) => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}&quote=${message}`,
      "_blank"
    );
  };

  const shareToTwitter = (url) => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        url
      )}&text=${message} ${url}`,
      "_blank"
    );
  };

  const shareToLinkedIn = (url) => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        url
      )}&title=${encodeURIComponent(story?.story?.title)}&summary=${message}`,
      "_blank"
    );
  };

  const shareToWhatsApp = (url) => {
    window.open(
      `https://wa.me/?text=${message + " " + encodeURIComponent(" " + url)}`,
      "_blank"
    );
  };

  return { shareToFacebook, shareToTwitter, shareToLinkedIn, shareToWhatsApp };
};
export default useShareHelpers;
