import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IoMdClose } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import useStore from "../../components/UseStore";
import { CircularProgress } from "@mui/material";
import useUserDetailsStore from "../../store/UserStore";
import { useStoryCoinHelper } from "../../components/StoryCoin/storyCoinHook";
import useSocketStore from "../../store/SocketStore/useSocketStore";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { FailedToLoadImage } from "../../components/ImageNotFound";
import Coin from "../../assets/coin.webp";
export default function Customise() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    story,
    getStoryDetails,
    formState,
    onCostumeChange,
    onApperanceChange,
    generateImage,
    generatingImage,
    coverCallback,
  } = useStore((state) => state);
  const storyCallback = useAdvanceStoryStore((state) => state.storyCallback);
  const { storyId } = useParams();
  const pageDetails = story?.pages?.[0];
  const credit = useUserDetailsStore((state) => state.credit);
  const outOfBalance = credit < 1;
  const {
    showCoinShortDialog,
    setModelType,
    showCoinDetailDialog,
    dontShowConfirmation,
  } = useStoryCoinHelper();
  const navigate = useNavigate();

  useSocketStore([coverCallback, storyCallback]);

  useEffect(() => {
    if (storyId) {
      getStoryDetails(storyId);
    }
  }, [storyId]);

  const handleGenerate = () => {
    if (dontShowConfirmation) {
      if (outOfBalance) {
        return showCoinShortDialog({
          onProceedPayment: () => {
            navigate(`/credit-packs`);
            setModelType("");
          },
        });
      }
      generateImage(pageDetails?.id);
      setModelType("");
      window.history.back();
      return;
    }
    showCoinDetailDialog({
      productType: "Regenerating Image",
      coinCost: 1,
      callback: () => {
        if (outOfBalance) {
          return showCoinShortDialog({
            onProceedPayment: () => {
              navigate(`/credit-packs`);
              setModelType("");
            },
          });
        }
        generateImage(pageDetails?.id);
        setModelType("");
        window.history.back();
      },
    });
  };

  return (
    <>
      <div className="story-concept-background p-6">
        <div className="  md:w-[50%] w-[100%] bg-[#fff] mx-auto text-center rounded-xl shadow-lg-sm md:mt-10 mt-4 ">
          <div className="bg-[#3CC3AA]  p-4 rounded-t-xl text relative">
            <h1 className="md:text-3xl text-2xl font-bold text-white">
              Customize {story?.characters?.[0]?.name}’s Look?
            </h1>
            <IoMdClose
              onClick={() => {
                window.history.back();
              }}
              color="white"
              className="cursor-pointer absolute top-3 right-3"
            />
          </div>

          <div className="py-1 relative mt-8">
            <div className="md:w-[40%] w-[90%] mx-auto my-4 rounded-xl">
              {!formState?.image_url ? (
                <FailedToLoadImage />
              ) : (
                <img
                  src={formState?.image_url}
                  alt="cover image"
                  className="rounded-xl"
                />
              )}
            </div>

            {generatingImage && (
              <div className=" flex justify-center items-center absolute top-0 left-0 w-[100%] h-[100%] bg-[#dfdfdf] opacity-80 ">
                <CircularProgress sx={{ color: "#3CC3AA" }} />
              </div>
            )}
          </div>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: isSmallScreen ? "90%" : "70%",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="flex flex-col gap-6 justify-center items-center">
              <TextField
                id="outlined-disabled"
                name="appearance"
                label="Child Appearance"
                defaultValue="2 year old girl with long straight here. She has a round face with big honey-brown eyes and thin eyebrows."
                multiline
                value={formState?.appearance}
                rows={4}
                onChange={onApperanceChange}
              />
              <TextField
                id="outlined-disabled"
                name="c"
                label="Costume"
                defaultValue="Blue turquioise dress, holding a teddy bear. Has earrings."
                multiline
                value={formState?.clothing}
                onChange={onCostumeChange}
                rows={6}
              />
            </div>
          </Box>

          <div className="flex flex-col gap-6 mt-6">
            {credit <= 5 && (
              <p>
                You{" "}
                {credit === 0
                  ? "can't generate any"
                  : `can only regenerate ${credit} `}
                more cover without placing an order.
              </p>
            )}
            <div className="flex  justify-center items-center md:gap-8 gap-4 py-8">
              <button
                onClick={() => {
                  window.history.back();
                }}
                className="px-12   md:py-2 py-3  rounded-full text-[#000]   md:text-lg text-base border-2 border-solid border-[#E3E9ED] quicksand-text hover:bg-[#3CC3AA] hover:text-[#fff]"
              >
                Cancel
              </button>
              <button
                disabled={generatingImage}
                onClick={() => {
                  handleGenerate();
                }}
                className={`
                ${generatingImage && "cursor-not-allowed opacity-50 "}
                  flex items-center justify-center
                px-6  md:py-2 py-3  rounded-full text-[#fff] bg-[#3CC3AA] md:text-lg text-base border-2 border-solid border-[#3CC3AA] quicksand-text hover:bg-[#fff] hover:border-[#E3E9ED] hover:text-[#000]`}
              >
                Re-Generate
                <img className="h-8 w-8" src={Coin} alt="coin" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
