import { Check } from "@mui/icons-material";

const CheckTag = () => {
  return (
    <div className="h-[32px] w-[32px] shadow-md shadow-[#3CC3AA] text-center rounded-full bg-[#3CC3AA] border-2 border-[#ffff]">
      <Check fontSize="small" color="light" />
    </div>
  );
};
export default CheckTag;
