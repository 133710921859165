import { create } from "zustand";

export const useStoryCoinHelper = create((set) => ({
  modelType: "",
  coinCost: 0,
  productType: "",
  dontShowConfirmation: false,
  setDontShowConfirmation: (dontShowConfirmation) =>
    set({ dontShowConfirmation }),

  onCreditConfirmation: () => {},
  onProceedPayment: () => {},
  setProductType: (productType) => set({ productType }),
  setModelType: (modelType) => set({ modelType }),
  setCoinCost: (coinCost) => set({ coinCost }),
  showCoinShortDialog: ({ onProceedPayment }) =>
    set({ modelType: "short", onProceedPayment }),
  showCoinDetailDialog: ({ coinCost, productType, callback }) => {
    if (coinCost) {
      set({
        modelType: "detail",
        coinCost,
        productType: productType,
        onCreditConfirmation: callback,
      });
    }
  },
}));
