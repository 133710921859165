/* eslint-disable react/prop-types */
import { Card } from "@mui/material";

const OrderHistroryContainer = ({ children }) => {
  // const { orders, isLoading, isError } = useOrders();
  // if (isLoading) return <div>Loading...</div>;
  // if (isError) return <div>Error</div>;
  // return <OrderHistory orders={orders} />;
  // }
  return (
    <div className="bg-[url('./assets/order_success_bg.svg')] h-[76vh] bg-no-repeat bg-cover mx-auto  p-2 md:p-[20px] lg:p-[40px] text-[#36324A]">
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div className="p-4 md:p-[40px] w-full">{children}</div>
      </Card>
    </div>
  );
};
export default OrderHistroryContainer;
