/* eslint-disable react/prop-types */
import HobbyImg from "../../assets/hobby_bg.webp";
import ProfessionalImg from "../../assets/professional.webp";
import FreeImg from "../../assets/free_bg.webp";

const PLAN_TYPE = {
  HOBBY: { bg: HobbyImg, text: "Hobby Plan" },
  PROFESSIONAL: { bg: ProfessionalImg, text: "Professional" },
  FREE: { bg: FreeImg, text: "Free Plan" },
};

const PlanIndicatorTile = ({ plan }) => {
  return (
    <div className="relative">
      <img src={PLAN_TYPE[plan]?.bg} />
      <p className="text-white font-[800] text-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 drop-shadow-lg">
        {PLAN_TYPE[plan]?.text}
      </p>
    </div>
  );
};
export default PlanIndicatorTile;
