import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[A-Za-z\s'-]+$/, "Must be alphabets")
    .required("Name is required"),
  gender: Yup.string().required("Gender is required"),
  language: Yup.string().required("Language is required"),
  uploadFile: Yup.boolean(),
  file: Yup.mixed().when("uploadFile", {
    is: true,
    then: () =>
      Yup.mixed()
        .required("File is required")
        .test("fileSize", "File size should be less than 10 MB", (value) => {
          // Check if value is not a file
          if (!(value instanceof File || value instanceof Blob)) {
            return true; // No error for non-file values
          }

          if (value) {
            return value.size <= 1024 * 1024 * 10;
          }
          return true;
        }),
    otherwise: () => Yup.mixed(),
  }),

  age: Yup.string()
    .matches(/^[0-9]+$/, "Must be a number")
    .test("below-21", "Age must be below 21", (value) => {
      if (!value) return true;
      return parseInt(value, 10) < 21;
    })
    .required("Age is required"),
  consent: Yup.boolean()
    .required("Need to accept terms to continue")
    .test(
      "consent",
      "Need to accept terms to continue",
      (value) => value === true
    ),
  visual_description_json: Yup.object().when("uploadFile", {
    is: false,
    then: () =>
      Yup.object().shape({
        eye_shape: Yup.string()
          .required("Eye shape is required")
          .max(20, "Eye shape is too long"),
        eye_color: Yup.string()
          .required("Eye color is required")
          .max(20, "Eye color is too long"),
        skin_color: Yup.string()
          .required("Skin Color is required")
          .max(20, "Skin color is too long"),
        face_shape: Yup.string()
          .required("Face Shape is required")
          .max(20, "Face shape is too long"),
        hair_length: Yup.string()
          .required("Hair length is required")
          .max(20, "Hair length is too long"),
        hair_color: Yup.string()
          .required("Hair color is required")
          .max(20, "Hair color is too long"),
        hair_style: Yup.string()
          .required("Hair style is required")
          .max(50, "Hair style is too long"),
        ethnicity: Yup.string()
          .required("Ethnicity is required")
          .max(20, "Ehinicity is too long"),
        distinctive_features: Yup.string().max(
          50,
          "Distinctive features is too long"
        ),
      }),
    otherwise: () => Yup.object(),
  }),
});
