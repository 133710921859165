import { DownOutlined } from "@ant-design/icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../ApiServices/apis";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import api from "../../composables/app";
import { useStoryCoinHelper } from "../../components/StoryCoin/storyCoinHook";
import useUserDetailsStore from "../../store/UserStore";
import { trackStoryline } from "../../ApiServices/Story/storyApis";
import { logSentryMessage } from "../../ApiServices/handleErrors";

export default function ChooseStory() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const kid_id = searchParams.get("id");
  const navigate = useNavigate();

  const storylineFromLocationState = location.state?.storyline;

  const [storyData, setStoryData] = useState([]);
  const [textareaValue, setTextareaValue] = useState(
    storylineFromLocationState || ""
  );
  const [humandata, setHumandata] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [gettingStoryline, setGettingStoryline] = useState(true);
  const [loading, setLoading] = useState(false);
  const [textareaError, setTextareaError] = useState(
    storylineFromLocationState ? true : false
  );
  const [storySelected, setStorySelected] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [newStory, setNewStory] = useState([]);
  const [showViewMore, setShowViewMore] = useState(false);

  const buttonDisabled = textareaError || !textareaValue;
  const itemListRef = useRef(null);
  const {
    showCoinShortDialog,
    showCoinDetailDialog,
    setModelType,
    dontShowConfirmation,
  } = useStoryCoinHelper();
  const credit = useUserDetailsStore((state) => state.credit);

  useEffect(() => {
    const fetchData = async () => {
      await fetchHumanData();
    };
    if (kid_id === "undefined") {
      return navigate(`/select-child`);
    }
    if (kid_id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(humandata).length !== 0) {
      chooseStory();
    }
  }, [humandata, currentPage]);

  useEffect(() => {
    if (currentPage > 1 && itemListRef.current) {
      if (itemListRef.current) {
        const firstIndexLatestSet = Math.max(0, storyData.length - 5);

        const firstItemOfLatestSet =
          itemListRef.current.children[firstIndexLatestSet];
        firstItemOfLatestSet.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [storyData.length]);
  const fetchHumanData = async () => {
    try {
      const url = `${API.story.addChild}/${kid_id}`;
      const response = await api.get(url);
      setHumandata(response?.data?.data?.human);
    } catch (error) {
      toast.error("Error in fetching human data");
      logSentryMessage("Error in fetching human data");
    }
  };

  const chooseStory = async () => {
    setGettingStoryline(true);
    try {
      const url = `${API.story.getStoryLine}?age=${humandata?.age}&gender=${humandata?.gender}&language=${humandata?.language}&page=${currentPage}`;

      const response = await api.get(url);
      if (currentPage === 1) {
        setStoryData(response?.data?.data?.storylines);
      } else {
        setStoryData((prevData) => [
          ...prevData,
          ...response.data.data.storylines,
        ]);
      }
      setShowViewMore(response.data?.data?.next != null);
      setTotalPages(response?.data?.data?.count);
    } catch (error) {
      toast.error("Error in fetching story data");
      logSentryMessage("Error in fetching story data");
    } finally {
      setGettingStoryline(false);
    }
  };
  const onConfirm = async () => {
    if (credit < 1) {
      showCoinShortDialog({
        onProceedPayment: () => {
          navigate(`/credit-packs`);
          setModelType("");
        },
      });
    } else {
      setModelType("");
      if (textareaValue.trim() === "") {
        setTextareaError(true);
        return;
      }
      setLoading(true);
      try {
        const url = `${API.story.generateCover}`;
        const requestdata = {
          storyline: textareaValue,
          human_id: kid_id,
        };
        const response = await api.post(url, requestdata);
        toast.success("Cover generated successfully");
        setLoading(false);
        navigate(`/crafting-page/${response?.data.data.story_id} `);
      } catch (error) {
        toast.error("Error in generating cover");
        logSentryMessage("Error in generating cover");
        setLoading(false);
      }
    }
  };

  const generatecover = async () => {
    if (dontShowConfirmation) {
      onConfirm();
      return;
    }
    showCoinDetailDialog({
      productType: "Creating Story",
      coinCost: 1,
      callback: () => {
        onConfirm();
      },
    });
  };

  const handleCloseConfirmation = () => {
    setConfirmation(false);
  };

  const handleContinue = () => {
    setTextareaValue(newStory.storyline);
    setStorySelected(newStory.id);
    setConfirmation(false);
  };

  const handleClick = (story) => {
    trackStoryline(story.id);
    if (textareaValue.trim() !== "") {
      setConfirmation(true);
      setNewStory(story);
    } else {
      setTextareaValue(story.storyline);
      setTextareaError(false);
      setStorySelected(story.id);
    }
  };

  const loadMoreStories = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "previous" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />
      <div className="md:p-8 p-4">
        <div className="flex flex-col gap-6 justify-center items-center">
          <h1 className="text-4xl text-[#36324A] font-bold quicksand-text">
            Choose A Story
          </h1>
          <p className="text-lg text-[#36324A] font-medium text-center quicksand-text">
            Now to the fun part! <br /> Pick a story for your child to star in!
          </p>
        </div>
        <div className="flex md:flex-row flex-col mt-6 gap-10">
          <div className="flex flex-col gap-6 md:w-[50%] w-[100%]">
            <p className="text-[#36324A] text-base font-medium text-center quicksand-text">
              Do you have a story idea in mind? Write it here:
            </p>
            <div className="w-full">
              <textarea
                className={`w-full border h-[10rem] p-4 rounded-md outline-none quicksand-text ${
                  textareaError ? "border-red-500" : ""
                } ${storySelected ? "textarea-highlight" : ""}`}
                type="text"
                placeholder="After finding a lone, shimmering feather, the girl embarks on a journey to find the Phoenix to whom it belongs, learning about renewal and change."
                value={textareaValue}
                onChange={(e) => {
                  if (e.target.value === storylineFromLocationState) {
                    setTextareaError(true);
                  } else {
                    setTextareaError(false);
                  }
                  setTextareaValue(e.target.value);
                  setStorySelected(null);
                }}
              />

              {textareaError && (
                <p className="text-red-500 text-sm">
                  {storylineFromLocationState
                    ? "Please change the storyline and try again"
                    : "A storyline is required"}
                </p>
              )}
            </div>
            <div className="flex md:justify-end justify-center">
              <button
                className={`px-6 md:py-2 py-4 w-[12rem] rounded-full text-[#fff] bg-[#3CC3AA] font-bold text-lg border-2 border-solid border-[#3CC3AA] quicksand-text ${
                  buttonDisabled && "cursor-not-allowed"
                }${
                  buttonDisabled &&
                  " bg-[#e1e1e1] border-2 border-solid  border-[#efefef] text-[#000]"
                }`}
                onClick={generatecover}
                disabled={buttonDisabled}
              >
                {loading ? (
                  <CircularProgress
                    style={{ height: "25px", width: "25px", color: "#fff" }}
                  />
                ) : (
                  "Use this Story"
                )}
              </button>
            </div>
          </div>
          <div className="border-l border-gray-300 hidden md:block"></div>
          <div className="flex flex-col gap-6 md:w-[50%] w-[100%]">
            <p className="text-[#36324A] text-base font-medium text-center quicksand-text">
              Or, you can choose from one of our suggestions:
            </p>
            {storyData.length < 1 && gettingStoryline && (
              <div className="flex align-center justify-center h-[100px]">
                <CircularProgress />
              </div>
            )}
            <div ref={itemListRef} className="h-[58vh] overflow-y-auto">
              {storyData.map((story) => (
                <div
                  key={story.id}
                  className={`${
                    storySelected === story.id
                      ? "border-[3px] border-[#3CC3AA] bg-[#3CC3AA] bg-opacity-5"
                      : ""
                  } flex flex-col gap-6 border rounded-md p-8 justify-center items-center m-1`}
                  onClick={() => handleClick(story)}
                  style={{ cursor: "pointer" }}
                >
                  <h1 className=" quicksand-text text-lg font-medium">
                    {story?.title}
                    <span className="pl-2"> {story?.emoji_summary}</span>
                  </h1>
                  <p className="quicksand-text">{story?.storyline}</p>
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-4">
              {showViewMore && (
                <PrimaryButton
                  handleButtonClick={() => loadMoreStories("next")}
                >
                  View More
                  <span className="px-2">
                    {gettingStoryline ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DownOutlined />
                    )}
                  </span>
                </PrimaryButton>
              )}
            </div>
          </div>
        </div>
      </div>

      {confirmation && (
        <div className="  fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center flex-col">
          <div className=" md:w-[40%] w-[90%] mx-auto bg-white rounded-xl ">
            <div className="bg-[#3CC3AA]  p-4 rounded-t-xl text">
              <h1 className="md:text-3xl text-2xl font-bold text-white text-center">
                Just a Moment!
              </h1>
            </div>

            <div className="bg-white  p-4 rounded-b-xl  text relative flex flex-col items-center justify-center gap-4">
              <p className=" ">
                You’ll lose your draft storyline that you typed if you continue
              </p>
              <p>Are you sure you want to continue?</p>
              <div className="flex  justify-center items-center md:gap-4 gap-4 py-6">
                <button
                  className="px-12   md:py-2 py-3  rounded-full text-[#000]   md:text-lg text-base border-2 border-solid border-[#E3E9ED] quicksand-text hover:bg-[#3CC3AA] hover:text-[#fff]"
                  onClick={handleCloseConfirmation}
                >
                  Cancel
                </button>
                <button
                  className="px-12   md:py-2 py-3  rounded-full text-[#000]   md:text-lg text-base border-2 border-solid border-[#E3E9ED] quicksand-text hover:bg-[#3CC3AA] hover:text-[#fff]"
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
