import {
  FacebookFilled,
  LinkedinOutlined,
  TwitterOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

export const shareList = [
  {
    name: "Facebook",
    icon: FacebookFilled,
  },
  {
    name: "Twitter",
    icon: TwitterOutlined,
  },
  {
    name: "WhatsApp",
    icon: WhatsAppOutlined,
  },
  {
    name: "LinkedIn",
    icon: LinkedinOutlined,
  },
];
