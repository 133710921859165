/* eslint-disable react/prop-types */
import { Select } from "antd";
import { currencyFlags } from "../PaymentSection/Assets";

export const CountrySelector = ({
  currencyList,
  className,
  onCurrencyChange,
  currency,
  ...rest
}) => {
  return (
    <Select
      defaultValue="United States"
      className={`border border-[#EFF0F6
      ] bg-[#fffff] rounded-lg b outline-none shadow-md h-[60px] min-w-[134px] cursor-pointer  ${className}`}
      onChange={(value) => {
        onCurrencyChange && onCurrencyChange(value);
      }}
      value={currency}
      showSearch={true}
      {...rest}
    >
      {Object.values(currencyList)
        .sort((a, b) => a.localeCompare(b))
        .map((currency) => {
          return (
            <Select.Option key={currency} value={currency}>
              <div className="flex items-center gap-5">
                {currencyFlags[currency]}
                <span className="text-lg font-medium">{currency}</span>
              </div>
            </Select.Option>
          );
        })}
    </Select>
  );
};
