import toast from "react-hot-toast";

export const goToPreviewPage = (storyId) => {
  window.open(`/story/${storyId}`, "_blank");
};
export const copyToClipBoard = (link) => {
  navigator.clipboard.writeText(link);
  toast.success("Link copied to clipboard");
};
//service function to check if the array is empty
export const checkIfArrayIsEmpty = (array = []) => {
  return array.length > 0 ? false : true;
};

export function extractContent(s, space = true) {
  var span = document.createElement("span");
  span.innerHTML = s;
  if (space) {
    var children = span.querySelectorAll("*");
    for (var i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += " ";
      else children[i].innerText += " ";
    }
  }
  return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
}

export const downloadFromLink = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${filename}`);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const convertDate = (utcDateString) => {
  const date = new Date(utcDateString);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  }).format(date);

  return formattedDate;
};
