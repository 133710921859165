import { create } from "zustand";

const appInfoStore = create((set) => ({
  infoText: "",
  setAppInfo: (infoText) => {
    set({ infoText });
  },
  resetAppInfo: () => {
    set({ infoText: "" });
  },
}));

export default appInfoStore;
