import axios from "axios";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useSearchParams } from "react-router-dom";
import Email from "../../assets/Mail1.svg";
import Phone from "../../assets/Phone1.svg";
import { PaymentSectionV2 } from "../../components/PaymentSection/paymentSectionV2";
import useStore from "../../components/UseStore";
import { subscriptionStore } from "../../store/SubscriptionStore";
import { useProductDetails } from "./useProductDetails";

const PaymentPage = () => {
  const BUBBLE_BASEURL = import.meta.env.VITE_BUBBLE_BASE_URL;

  const [isMobile, setIsMobile] = useState(false);
  const [setDataTitle] = useStore((state) => [state.setDataTitle]);

  const [searchParams] = useSearchParams();
  const storyId = searchParams.get("storyId");
  const product_id = searchParams.get("productId");
  const orderId = searchParams.get("orderId");
  const planId = searchParams.get("plan_id");

  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const {
    productDetails,
    onCheckout,
    handleoffersBlur,
    selectedPlanDetails,
    updateCurrentSubscription,
  } = useProductDetails(product_id, orderId);

  const productDescription = productDetails?.description;

  const [data, setData] = useState();

  const currentsubscriptionStatusDetails = subscriptionStore(
    (state) => state.currentsubscriptionStatusDetails
  );
  const currentSubscriptionId =
    currentsubscriptionStatusDetails?.subscription?.id;
  const isSubscriptionUpdate =
    planId && currentsubscriptionStatusDetails?.subscription?.id;

  const StroryData = async () => {
    if (storyId === null) return;
    try {
      const response = await axios.get(
        `${BUBBLE_BASEURL}api/1.1/wf/get_story_details?story_id=${storyId}&api_token=36dd66413d5fb2b0ea15c423dadc0b75`
      );
      setData(response?.data?.response);
      setDataTitle(response?.data?.response?.title);
    } catch (error) {
      console.log(error);
    }
  };

  const checkIsMobile = () => {
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    setIsMobile(isMobile);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    StroryData();
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const DetailSection = () => {
    return (
      <div className="md:w-[50%] xs:w-[100%] mx-auto ">
        <div className="flex flex-col gap-4 ">
          <h1 className="text-3xl text-black font-bold">
            {planId
              ? selectedPlanDetails?.item?.name
              : (storyId && productDetails?.show_story_title) ||
                (productDetails?.show_story_title && product_id)
                ? data?.title || "Your Personlized Story book"
                : productDetails?.name}
          </h1>
          <hr className="my-4 border-b-4 border-[#3CC3AA] w-[90%]" />
          <h2 className="text-xl font-medium">What you’ll get</h2>
          <Markdown>
            {planId
              ? selectedPlanDetails?.items?.description || ""
              : productDescription
                ? productDescription
                : ""}
          </Markdown>
          <p>
            For any questions, suggestions, feedback, please feel free to
            contact using the following contact details.
          </p>
        </div>

        <div className="flex flex-col gap-4 mt-6">
          <h2 className="text-xl font-medium">Contact Us :</h2>
          <div className="flex gap-4">
            <img src={Email} alt="" />

            <a href="mailto:contact@pictogen.com" className=" text-medium">
              contact@pictogen.com
            </a>
          </div>
          <div className="flex gap-4">
            <img src={Phone} alt="" />

            <a href="tel:+ +91 9111225657" className=" text-medium">
              +91 9111225657
            </a>
          </div>
        </div>
        <div className="mt-6">
          <h2 className="text-xl font-medium">Frequently asked questions:</h2>
          <div className="mt-3">
            Click{" "}
            <a
              href={`https://storygenius.me#faqs`}
              className="text-blue-500 font-bold underline"
              rel="noopener noreferrer"
              target="_blank"
            >
              here
            </a>{" "}
            to view
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-6">
          <h2 className="text-xl font-medium">Terms & Conditions :</h2>

          <a
            className="text-blue-500 font-bold underline"
            href="https://storygenius.me/terms-and-conditions.html"
            target="_blank"
          >
            storygenius.me/terms-and-conditions
          </a>
          <p>
            You agree to share information entered on this page with
            StoryGenius.me (owner of this page) and Razorpay, adhering to
            applicable laws.
          </p>
        </div>
        <button
          className="rounded-full p-2 bg-[#3CC3AA] text-white w-[100%] mx-auto mt-4 md:hidden block"
          onClick={() => {
            setShowPaymentPage(true);
          }}
        >
          Next
        </button>
      </div>
    );
  };
  return (
    <>
      <div className="w-[90%] mx-auto py-12 flex justify-between  gap-8 ">
        {!isMobile && (
          <>
            <DetailSection />
            <div className="md:w-[50%] sm-[100%] mx-auto lg:mt-12">
              <PaymentSectionV2
                productDetail={productDetails}
                onCheckout={(storyId) => {
                  if (isSubscriptionUpdate) {
                    updateCurrentSubscription(currentSubscriptionId);
                    return;
                  }
                  onCheckout(storyId);
                }}
                handleoffersBlur={handleoffersBlur}
              />
            </div>
          </>
        )}
        {isMobile && (
          <>
            {!showPaymentPage && <DetailSection />}
            {showPaymentPage && (
              <PaymentSectionV2
                productDetail={productDetails}
                onCheckout={(storyId) => {
                  if (isSubscriptionUpdate) {
                    updateCurrentSubscription(currentSubscriptionId);
                    return;
                  }
                  onCheckout(storyId);
                }}
                handleoffersBlur={handleoffersBlur}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
export default PaymentPage;
