import { Link, useLocation } from "react-router-dom";
import Wrong from "../../assets/Wrong.svg";
const PaymentFailed = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const story_id = searchParams.get("story_id");
  const productId = searchParams.get("productId");
  const orderId = searchParams.get("orderId");
  const currency = searchParams.get("currency");

  const planId = searchParams.get("plan_id");
  const type = searchParams.get("type") || "monthly";

  const redirectUrl = () => {
    if (planId) {
      return `/paymentv2?plan_id=${planId}&type=${type}`;
    }
    return `/paymentv2?currency=${currency}${
      story_id ? `&storyId=${story_id}` : ""
    }${productId ? `&productId=${productId}` : ""}${
      orderId ? `&orderId=${orderId}` : ""
    }`;
  };
  return (
    <>
      <div className="w-[100%] md:w-[50%] mx-auto flex flex-col justify-center mt-20 items-center text-center px-4">
        <div className="flex flex-col gap-4 justify-center items-center">
          <div className="flex gap-3 items-center justify-center ">
            <h1 className="text-lg md:text-3xl font-bold ">
              Payment Unsuccessful
            </h1>
            <img src={Wrong} alt="failure" className="w-[26px]" />
          </div>
          <h2 className="text-lg md:text-xl font-bold mt-6 ">
            Uh-oh! Seems like your payment could not be completed
          </h2>
          <p className="text-base">
            Nothing to worry about! You can try again by clicking{" "}
            <Link to={redirectUrl()} className="text-blue-500 font-bold">
              here
            </Link>
          </p>
          <p className="text-base mt-6">
            Need help? you can reach out to us at{" "}
            <a
              href="mailto:contact@pictogen.com"
              className=" font-bold text-medium"
            >
              contact@pictogen.com
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
