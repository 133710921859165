/* eslint-disable react/prop-types */
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const DialogWrapper = ({
  isOpen,
  onClose,
  children,
  header,
  className = "max-w-[558px]",
}) => {
  if (!isOpen) return null;
  return (
    <div className=" z-50 fixed top-0 left-0 w-full h-full bg-black bg-opacity-40 flex justify-center items-center flex-col">
      <div
        className={` w-[90%] mx-auto bg-white rounded-xl ${
          className ? className : ""
        } `}
      >
        <div className="w-[100%] ">
          {header || (
            <div className="sticky top-0 grid justify-items-end">
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};
