import { API } from "../../../ApiServices/apis";
import api from "../../../composables/app";
import { useState } from "react";
import toast from "react-hot-toast";
import useAdvanceStoryStore from "../../../store/AdvanceStoryStore";
import { logSentryMessage } from "../../../ApiServices/handleErrors";

export const useDownloadPdf = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const story = useAdvanceStoryStore((state) => state.story);
  const downloadPdf = async (storyId) => {
    setLoading(true);
    api
      .post(
        `${API.story.downloadPDF}/${storyId}`,
        {},
        {
          responseType: "blob",
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${story.story.title}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Failed to download PDF");
        logSentryMessage("Failed to download PDF");
        console.log(err);
        setError(err);
        setLoading(false);
      });
  };
  return { downloadPdf, loading, error };
};
