import { useEffect, useState } from "react";
import { API } from "../../ApiServices/apis";
import api from "../../composables/app";
import appInfoStore from "../../store/AppInfoStore";
import toast from "react-hot-toast";

const useStoryList = (status, pageNo) => {
  const [loading, setLoading] = useState(true);
  const [stories, setStories] = useState([]);
  const storyList = stories.stories || [];
  const totalPages = stories.pages || 1;
  const setAppInfo = appInfoStore((state) => state.setAppInfo);
  const [deletingStoryList, setDeletingStoryList] = useState([]);

  const deleteStory = async (storyId) => {
    setDeletingStoryList([...deletingStoryList, storyId]);
    try {
      await api.delete(`${API.story.deleteStory}${storyId}`);
    } catch (e) {
      toast.error("Failed to delete story");
    } finally {
      setDeletingStoryList(deletingStoryList.filter((id) => id !== storyId));
    }
  };

  const getStoryList = async () => {
    setLoading(true);
    try {
      const url = `${API.story.storyLists}?status=${status}&page=${pageNo}`;
      const response = await api.get(url);
      const data = response?.data?.data;

      setStories(data);

      //check if any story has the failure_code and show toast if any
      const failureCode =
        data && data?.stories?.some((story) => story.failure_code);
      if (failureCode) {
        setAppInfo(
          "One of your stories failed during generation due to a technical issue. Please contact us at contact@pictogen.com."
        );
      } else {
        setAppInfo("");
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStoryList();
  }, [pageNo]);
  return {
    loading,
    storyList,
    getStoryList,
    totalPages,
    deleteStory,
    deletingStoryList,
  };
};
export default useStoryList;
