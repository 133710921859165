import { useEffect, useState } from "react";
import { API } from "../../ApiServices/apis";
import api from "../../composables/app";

const useChidDetails = () => {
  const [loading, setLoading] = useState(true);
  const [kidsData, setKidsData] = useState([]);

  const childDate = async () => {
    setLoading(true);
    try {
      const response = await api.get(API.story.getChildDetails);
      const data = response?.data?.data?.humans || [];
      setKidsData(data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteKid = async (kidId) => {
    try {
      const url = `${API.story.getChildDetails}/${kidId}`;

      await api.delete(url);
      setKidsData(kidsData.filter((kid) => kid.id !== kidId));
    } catch (error) {
      console.error("Error deleting kid:", error);
    }
  };

  useEffect(() => {
    childDate();
  }, []);

  return { loading, kidsData, deleteKid };
};
export default useChidDetails;
