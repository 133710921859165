import { create } from "zustand";
import {
  cancelSubscription,
  getActiveSubscription,
  getSubscriptionPlans,
} from "../../ApiServices/Subscription";
import toast from "react-hot-toast";
import { checkSuccessStatus } from "../../ApiServices/handleErrors";

export const subscriptionStore = create((set, get) => ({
  tenure: "monthly",
  loading: true,
  cancelingSubscription: false,
  toggleTenure: () => {
    const { tenure } = get();
    if (tenure === "monthly") {
      set({ tenure: "yearly" });
      get().setSubscriptionPlans(get().subscriptionPlanDetails?.["yearly"]);
    }
    if (tenure === "yearly") {
      set({ tenure: "monthly" });
      get().setSubscriptionPlans(get().subscriptionPlanDetails?.["monthly"]);
    }
  },
  subscriptionPlanDetails: null,
  subscriptionPlan: null,
  selectedPlanDetails: null,
  setSelectedPlanDetails: (plan) => set({ selectedPlanDetails: plan }),
  setSubscriptionPlans: (plans) => {
    set({
      subscriptionPlan: plans.sort((a, b) => a.item.amount - b.item.amount),
    });
  },
  fetchSubscriptionPlans: async () => {
    set({ loading: true });
    try {
      const response = await getSubscriptionPlans();
      set({ subscriptionPlanDetails: response.data?.data?.plans });
      get().setSubscriptionPlans(response.data?.data?.plans[get().tenure]);
    } catch (e) {
      toast.error("Failed to fetch subscription plans");
    } finally {
      set({ loading: false });
    }
  },
  currentsubscriptionStatusDetails: {
    actions: {},
    packages: {},
    subscription: {},
  },

  fetchActiveSubscription: async () => {
    const response = await getActiveSubscription();
    set({ currentsubscriptionStatusDetails: response.data?.data });
    if (response?.data?.data?.subscription?.period === "yearly") {
      set({ tenure: "yearly" });
    }
  },
  cancelSubscription: async (subscriptionId) => {
    set({ cancelingSubscription: true });
    try {
      const response = await cancelSubscription(subscriptionId);
      if (checkSuccessStatus(response)) {
        toast.success(response.data?.["status-message"]);
        get().fetchActiveSubscription();
      } else {
        throw new Error("Failed to cancel subscription");
      }
    } catch (e) {
      toast.error("Failed to cancel subscription");
    } finally {
      set({ cancelingSubscription: false });
    }
  },
}));
