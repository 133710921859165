import api from "../../composables/app";
import { API } from "../apis";

export const getPrintOrderHistory = async (params) => {
  return await api.get(`${API.history.printOrderHistory}`, { params });
};

export const getStoryCoinsHistory = async (params) => {
  return await api.get(`${API.history.storyCoinsHistory}`, { params });
};
