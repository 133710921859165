import { useState } from "react";
import api from "../../composables/app";
import { API } from "../../ApiServices/apis";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { logSentryMessage } from "../../ApiServices/handleErrors";

const usePurchaseStory = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [purchasingStortList, setPurchasingStoryList] = useState([]); // [storyId1, storyId2, ...
  const navigate = useNavigate();

  const purchaseStory = (storyId) => {
    setLoading(true);
    setPurchasingStoryList((prev) => [...prev, storyId]);
    api
      .post(`${API.story.generateAllPages}/${storyId}/generate_all_pages`)
      .then(() => {
        toast.success("Story is being generated. Please wait...");
        navigate(`/storyboard/${storyId}`);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        toast.error("Failed to generate story");
        logSentryMessage("Failed to generate story");
      })
      .finally(() => {
        setPurchasingStoryList((prev) => prev.filter((id) => id !== storyId));
        setLoading(false);
      });
  };
  return { purchaseStory, loading, error, purchasingStortList };
};
export default usePurchaseStory;
