import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProductList } from "../../ApiServices/Payment";
import { API } from "../../ApiServices/apis";
import {
  checkSuccessStatus,
  logSentryMessage,
} from "../../ApiServices/handleErrors";
import Headerlogo from "../../assets/Headerlogo.webp";
import { useSubscriptionHook } from "../../components/Subscription/useSubscriptionHook";
import useStore from "../../components/UseStore";
import api from "../../composables/app";
import useUserDetailsStore from "../../store/UserStore";
import { subscriptionStore } from "../../store/SubscriptionStore";
import toast from "react-hot-toast";
const BASE_RAZORPAY_URL = import.meta.env.VITE_RAZORPAY_KEY_ID;

export const useProductDetails = (product_id, order_id) => {
  const [productDetails, setProductDetails] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirecturl = searchParams.get("redirecturl");
  const planId = searchParams.get("plan_id");
  const type = searchParams.get("type") || "monthly";
  const { selectedPlanDetails, createSubscription, updateSubscription } =
    useSubscriptionHook();
  console.log(selectedPlanDetails);
  const [
    offers,
    setoffers,
    setCouponMessage,
    setCouponValidity,
    couponValidity,
    setLoading,
    setDiscountResponse,
    currency,
    dataTitle,
    setCheckingout,
  ] = useStore((state) => [
    state.offers,
    state.setoffers,
    state.setCouponMessage,
    state.setCouponValidity,
    state.couponValidity,
    state.setLoading,
    state.setDiscountResponse,
    state.currency,
    state.dataTitle,
    state.setCheckingout,
  ]);
  const [userDetail] = useUserDetailsStore((state) => [state.userDetail]);
  const [currentsubscriptionStatusDetails] = subscriptionStore((state) => [
    state.currentsubscriptionStatusDetails,
  ]);

  const getProductLists = useCallback(async () => {
    getProductList().then((response) => {
      const products = response?.data?.data?.products;
      const product = products.find((product) => product.id === product_id);
      if (product) {
        setProductDetails(product);
      } else {
        setProductDetails(products.find((product) => product.show_story_title));
      }
    });
  }, [product_id]);

  useEffect(() => {
    if (!planId) getProductLists();
  }, [product_id, planId]);

  const navigateToPaymentSuccess = (story_id) => {
    if (planId) {
      navigate(`/payment-success?plan_id=${planId}`);
      return;
    }
    navigate(
      `/payment-success?${story_id ? "story_id=" + story_id : ""}${
        redirecturl ? `&redirecturl=${redirecturl}` : ""
      }${order_id ? "&order_id=" + order_id : ""}`
    );
  };
  const navigateToPaymentFailure = (story_id) => {
    if (planId) {
      navigate(`/payment-failure?plan_id=${planId}&type=${type}`);
      return;
    }
    navigate(
      `/payment-failure?currency=${currency}${
        story_id ? "&story_id=" + story_id : ""
      }${product_id ? "&productId=" + product_id : ""}${
        order_id ? "&orderId=" + order_id : ""
      }`
    );
  };

  const navigateToOrderSuccessPage = (email_order_id) => {
    navigate(`/order-success?order_id=${email_order_id}`);
  };
  const updateCurrentSubscription = async () => {
    setCheckingout(true);
    const currentSubscriptionId =
      currentsubscriptionStatusDetails?.subscription?.id;
    try {
      var response = await updateSubscription(currentSubscriptionId);
      if (checkSuccessStatus(response)) {
        toast.success("Subscription updated successfully");
        navigate("/select-child?tab=story");
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
    } finally {
      setCheckingout(false);
    }
  };

  const createOrder = async (story_id) => {
    const url = `${API.payment.createOrder}`;
    const requestData = {
      product_id: productDetails.id,
      promo_code: couponValidity === true ? offers : null,
      currency: currency,
    };
    if (order_id) {
      delete requestData.promo_code;
      requestData.print_order_id = order_id;
    } else if (story_id) {
      requestData.story_id = story_id;
    }
    return await api.post(url, requestData);
  };
  const onCheckout = async (story_id) => {
    setCheckingout(true);

    try {
      const responseCheckout = planId
        ? await createSubscription()
        : await createOrder(story_id);
      if (!planId && responseCheckout.data?.data?.order?.amount === 0) {
        toast.success("Payment successful");
        if (order_id && responseCheckout.data?.data?.order?.email_order_id) {
          navigateToOrderSuccessPage(
            responseCheckout.data?.data?.order?.email_order_id
          );
          return;
        }
        navigateToPaymentSuccess(story_id);
        return;
      }
      const rz_subscriptionId =
        responseCheckout.data?.data?.subscription?.subscription_id;
      const amount = planId
        ? responseCheckout.data?.data?.subscription?.plan_details?.item
            ?.amount / 100
        : responseCheckout.data?.data?.order?.amount;
      const currency = planId
        ? responseCheckout.data?.data?.subscription?.plan_details?.item
            ?.currency
        : responseCheckout.data?.data?.order?.currency;

      const id = planId ? selectedPlanDetails?.item?.id : productDetails?.id;
      const price = planId
        ? responseCheckout.data?.data?.subscription?.plan_details?.item
            ?.amount / 100
        : productDetails?.prices?.[currency];
      const name = planId
        ? selectedPlanDetails?.item?.name
        : productDetails?.name;
      const quantity = planId ? 1 : productDetails?.quantity;
      const responsecurrency = planId
        ? responseCheckout.data?.data?.subscription?.plan_details?.item
            ?.currency
        : responseCheckout.data?.data?.order?.currency;

      const host = window.location.hostname;
      const notAllowedHosts = ["localhost", "127.0.0.1"];
      if (!notAllowedHosts.includes(host)) {
        window.gtag("event", "begin_checkout", {
          value: amount,
          currency: currency,
          coupon: offers,
          items: [
            {
              id: id,
              name: name,
              quantity: quantity,
              price: price,
            },
          ],
        });
      }

      const options = {
        key: BASE_RAZORPAY_URL,
        amount: amount,
        currency: responsecurrency,
        order_id: responseCheckout.data?.data?.order?.id || "",
        name: "Story genius",
        description: dataTitle,
        image: Headerlogo,
        handler: function (response) {
          try {
            {
              if (response.razorpay_payment_id) {
                const host = window.location.hostname;
                const notAllowedHosts = ["localhost", "127.0.0.1"];
                if (!notAllowedHosts.includes(host)) {
                  window.gtag("event", "purchase", {
                    transaction_id: "",
                    value: amount,
                    currency: responsecurrency,
                    coupon: couponValidity === true ? offers : "",
                  });

                  window.dataLayer.push({
                    event: "paymentSuccess",
                    conversionValue: amount,
                  });
                }
                toast.success("Payment successful");
                if (
                  !planId &&
                  order_id &&
                  responseCheckout.data?.data?.order?.email_order_id
                ) {
                  navigateToOrderSuccessPage(
                    responseCheckout.data?.data?.order?.email_order_id
                  );
                  return;
                }
                navigateToPaymentSuccess(story_id);
              } else {
                toast.error(`Payment failed: ${response.error}`);
              }
            }
          } catch (error) {
            navigateToPaymentFailure(story_id);
            console.error("Error handling payment response:", error);
            logSentryMessage("Error handling payment response");
            toast.error(
              "An unexpected error occurred while processing the payment"
            );
          }
        },
        prefill: {
          email: userDetail?.user?.email ?? "",
        },
        notes: {
          address: "",
        },
        theme: {
          color: "#3CC3AA",
        },
        modal: {
          ondismiss: function () {
            toast.error("Payment failed");
            logSentryMessage("User close the payment modal");
            const razorPayContainer =
              document.getElementsByClassName("razorpay-container");
            if (razorPayContainer.length > 0) {
              razorPayContainer[0].style.display = "none";
            }
            setCheckingout(false);
          },
        },
      };

      if (planId) {
        delete options.order_id;
        options.subscription_id = rz_subscriptionId;
      }

      const razorpay = new window.Razorpay(options);
      razorpay.open();

      razorpay.on("payment.failed", function () {
        logSentryMessage("User payment not successful");
        navigateToPaymentFailure(story_id);
        razorpay.close();
      });
    } catch (error) {
      console.error("Error creating order:", error);
      setoffers("");
      setCouponValidity(false);
    } finally {
      setCheckingout(false);
    }
  };

  const handleoffersBlur = async (couponCode) => {
    const offer = couponCode ?? offers;
    if (offer.length > 0) {
      setLoading(true);
      await couponData(offer);
    } else if (offer) {
      setoffers(offer);
    } else {
      getProductList();
      setCouponValidity(null);
      setDiscountResponse(null);
      setLoading(false);
      setCouponMessage(null);
    }
  };
  const couponData = async (code) => {
    try {
      const url = `${API.payment.discount}/`;
      const requestData = {
        product_id: productDetails.id,
        promo_code: code,
        currency: currency,
      };

      const response = await api.post(url, requestData);

      setCouponValidity(true);
      setCouponMessage("true");
      setDiscountResponse(response.data);
    } catch (error) {
      setLoading(false);
      setCouponValidity(false);
      setCouponMessage("false");
    } finally {
      setLoading(false);
    }
  };
  return {
    productDetails,
    onCheckout,
    handleoffersBlur,
    selectedPlanDetails,
    updateCurrentSubscription,
  };
};
