import { Field } from "formik";

/* eslint-disable react/prop-types */
export const TextFiedWithLabelTag = ({
  label,
  multiLine,
  maxLine,
  className,
  ...rest
}) => {
  return (
    <div className={className}>
      <Field name={rest.name}>
        {({
          field, // { name, value, onChange, onBlur }
          meta,
        }) => (
          <div>
            <div className={`relative  border border-white rounded-lg `}>
              <div className="p-1 bg-white absolute text-[10px] rounded-sm font-semibold -top-2 left-4">
                {label}
              </div>
              {multiLine ? (
                <textarea
                  className=" w-full p-4 placeholder-slate-300 text-white font-bold text-xl bg-transparent mt-2 outline-none"
                  type="text"
                  rows={maxLine}
                  required
                  {...rest}
                  {...field}
                />
              ) : (
                <input
                  className=" w-full p-4 placeholder-slate-300 text-white font-bold text-xl bg-transparent mt-2 outline-none"
                  type="text"
                  required
                  {...rest}
                  {...field}
                />
              )}
            </div>
            {meta.touched && meta.error && (
              <div className="text-red-600">{meta.error}</div>
            )}
          </div>
        )}
      </Field>
    </div>
  );
};
