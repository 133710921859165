import { IconButton } from "@mui/material";
import appInfoStore from "../../store/AppInfoStore";
import { Close } from "@mui/icons-material";

const InfoToast = () => {
  const [infotext, setAppInfo] = appInfoStore((state) => [
    state.infoText,
    state.setAppInfo,
  ]);

  return (
    <>
      {infotext && (
        <div
          className={`sticky top-0 bg-orange-400 text-white font-bold p-4 text-center z-50 w-full ${
            infotext ? "fade-out" : "fade-in"
          }`}
        >
          {infotext}
          <div className="absolute right-0 top-[-10px] md:top-[10px]">
            <IconButton onClick={() => setAppInfo("")}>
              <Close sx={{ color: "#ffff" }} />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};

export default InfoToast;
