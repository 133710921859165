import { useEffect, useState } from "react";
import { API } from "../../ApiServices/apis";
import api from "../../composables/app";
import HardBinding from "../../assets/hardBinding.webp";
import SoftBinding from "../../assets/softBinding.webp";
import { countrycurrencycode } from "../PaymentSection/Assets";
import Cookies from "js-cookie";

const PRINT_OPTIONS_CONFIG = {
  Hardcover: HardBinding,
  Paperback: SoftBinding,
};
const useGetProductDetails = () => {
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState("USD");
  const tokenExists = Cookies.get("story_token");

  const printOptions = serializeProductDetailsForPrint(
    productDetails?.products,
    currency
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`${API.product.getAll}`);

        const data = response.data.data;
        setProductDetails(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details: ", error);
        setLoading(false);
      }
    };
    if (tokenExists) {
      fetchData();
    }
  }, []);

  function serializeProductDetailsForPrint(
    productDetails = [],
    currency = "USD"
  ) {
    return (
      !loading &&
      productDetails.length &&
      productDetails
        ?.filter((product) => product.include_shipping)
        ?.map((product) => ({
          label: product.name,
          id: product.id,
          img: PRINT_OPTIONS_CONFIG[product.name],
          title: product.name,
          subTitle: product.description,
          price: `${countrycurrencycode[currency]} ${product.prices[currency]?.price}`,
        }))
    );
  }

  return { productDetails, loading, printOptions, currency, setCurrency };
};

export default useGetProductDetails;
