/* eslint-disable react/no-unescaped-entities */
//@ts-check
// eslint-disable-next-line no-unused-vars
import { CircularProgress, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { quickGenerate } from "../../ApiServices/Story/storyApis";
import AdvanceData from "../../components/AdvanceData";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import CustomCover from "../../components/CustomCover";
import PreviewSection from "../../components/PreviewSection";
import "../../components/PreviewSection/index.css";
import { useStoryCoinHelper } from "../../components/StoryCoin/storyCoinHook";
import useStore from "../../components/UseStore";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import useSocketStore from "../../store/SocketStore/useSocketStore";
import useUserDetailsStore from "../../store/UserStore";
import useStoryHelpers from "./useStoryHelpers";
// @ts-ignore
import coinImg from "../../assets/coin.webp";
import { TransparentButton } from "../../components/Button/TransparentButton";
import { DialogWrapper } from "../../components/Dialog";
import PageMangement from "../../components/PageManagement";
import { SubTitle } from "../../components/molecules/subtitle";

export default function StoryBoard() {
  const [
    storyDetails,
    loadingStory,
    updateFormState,
    page,
    setPage,
    storyCallback,
    reset,
    deletePageId,
    setDeletePageId,
    handlePageDelete,
  ] = useAdvanceStoryStore((state) => [
    state.story,
    state.loadingStory,
    state.updateFormState,
    state.page,
    state.setPage,
    state.storyCallback,
    state.reset,
    state.deletePageId,
    state.setDeletePageId,
    state.handlePageDelete,
  ]);

  const { id } = useParams();
  const storyId = storyDetails?.story?.id;
  const characterDetail = storyDetails?.characters?.[0];
  const credit = useUserDetailsStore((state) => state.credit);
  const [showCoinDetailDialog, setModelType, showCoinShortDialog] =
    useStoryCoinHelper((state) => [
      state.showCoinDetailDialog,
      state.setModelType,
      state.showCoinShortDialog,
    ]);
  const navigate = useNavigate();
  const [generatingImage, initializeSocket, coverCallback] = useStore(
    (state) => [
      state.generatingImage,
      state.initializeSocket,
      state.coverCallback,
    ]
  );
  const isAllPagesGenerated = storyDetails?.pages?.every(
    (page) => page.image_urls.length > 0
  );

  const { activeButton, setActiveButton } = useStoryHelpers(
    storyDetails?.pages
  );
  const [searchParms] = useSearchParams();
  const pageId = searchParms.get("pageId");

  useSocketStore([coverCallback, storyCallback]);

  useEffect(() => {
    initializeSocket(`story_${id}`);
    return reset();
  }, [id]);

  useEffect(() => {
    if (storyDetails?.pages && storyDetails.pages.length > 0)
      if (pageId) {
        const pageNumber = storyDetails?.pages.findIndex(
          (page) => page.id === pageId
        );
        if (pageNumber !== -1) {
          setPage(pageNumber);
        }
      } else {
        setPage(0);
      }
  }, [pageId, storyDetails]);

  const totalItems = storyDetails?.pages?.length - 1;
  const allPageCreated = totalItems === storyDetails?.story?.num_pages;
  // const setAppInfo = appInfoStore((state) => state.setAppInfo);

  // if (!loadingStory) {
  //   if (!allPageCreated) {
  //     setAppInfo("Pages are being created. Please wait...");
  //   }
  // }

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const calculateCreditRequired = () => {
    //calculate the credit required for generating the story by looping through the pages and checking the image_urls length index, if it is 0 then oncrease the coin cost by one
    let coinCost = 0;
    storyDetails?.pages?.forEach((page) => {
      if (page.image_urls.length === 0) {
        coinCost += 1;
      }
    });
    return coinCost;
  };

  const triggeredCallback = (coinCost) => {
    setModelType("");
    toast.dismiss();
    if (credit < coinCost) {
      return showCoinShortDialog({
        onProceedPayment: () => {
          navigate(`/credit-packs?storyId=${storyId}`);
          setModelType("");
        },
      });
    }
    quickGenerate(storyId)
      .then((res) => {
        if (res.status === 200) {
          toast(
            `You will be redirected to preview pages.You will be notified once images are generated.`
          );
          goToPreviewPage();
        }
      })
      .catch(() => {
        toast("Failed to generate story. Please try again.");
      });
  };
  const handleQuickGenerate = () => {
    const coinCost = calculateCreditRequired();
    showCoinDetailDialog({
      productType: "Quick Generate",
      coinCost: coinCost,
      callback: () => {
        triggeredCallback(coinCost);
      },
    });
  };
  const goToPreviewPage = () => {
    window.open(`/story/${storyId}`, "_blank");
  };
  if (loadingStory) {
    return (
      <div className="flex absolute items-center justify-center w-full h-full bg-grey">
        <CircularProgress />
      </div>
    );
  }
  const onTabChange = (tab) => {
    if (tab === "Quick Generate") {
      if (storyDetails) {
        updateFormState({
          ...storyDetails.pages[0],
          appearance: storyDetails.characters[0]?.visual_description_plaintext,
        });
      }
      handleButtonClick("Quick Generate");
    } else {
      handleButtonClick("Advanced");
      if (storyDetails)
        updateFormState({
          ...storyDetails.pages[page],
          appearance:
            storyDetails.characters[page]?.visual_description_plaintext,
        });
    }
  };

  const allPageHasImages =
    storyDetails?.pages?.length > 1 &&
    storyDetails?.pages?.every((page) => page.image_urls.length > 0);
  const Header = () => {
    return (
      <>
        <h1 className="text-4xl text-[#36324A] font-bold quicksand-text text-center">
          Your Storyboard
        </h1>

        <div className=" flex justify-center items-center  rounded-full bg-white py-2 relative mt-4 px-2">
          <div className="absolute border-2 text-[10px] font-[600] bg-white text-[#FF8C00] border-[#FF8C00] rounded-full px-4 py-2 -top-2 -right-2 md:-right-8">
            In a Hurry?
          </div>
          <div
            className="flex justify-center items-center rounded-full p-1"
            style={{
              maxWidth: "fit-content",
              boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <button
              disabled={loadingStory}
              onClick={() => onTabChange("Advanced")}
              className={`${loadingStory ? "cursor-not-allowed opacity-20" : ""
                }  px-8 md:py-2 py-4 rounded-full text-lg  font-medium ${activeButton === "Advanced" ? "bg-[#3CC3AA] text-white" : ""
                }`}
            >
              Advanced
            </button>
            <Tooltip
              title="Quick generate all illustrations using AI suggestions."
              arrow
            >
              <div>
                <button
                  disabled={allPageHasImages || loadingStory}
                  onClick={() => {
                    onTabChange("Quick Generate");
                  }}
                  className={`${allPageHasImages ? "cursor-not-allowed opacity-20" : ""
                    } px-8 md:py-2 py-4 rounded-full text-lg  font-medium ${activeButton === "Quick Generate"
                      ? "bg-[#3CC3AA] text-white"
                      : ""
                    }`}
                >
                  Quick Generate
                </button>
              </div>
            </Tooltip>
          </div>
        </div>
      </>
    );
  };
  const closeDeletePageDialog = () => {
    setDeletePageId(null);
  };
  return (
    <>
      <Helmet>
        <title>{storyDetails?.story?.title}</title>
      </Helmet>
      {activeButton !== "Quick Generate" && <PageMangement />}
      <DialogWrapper
        header={null}
        isOpen={deletePageId}
        onClose={closeDeletePageDialog}
      >
        <div className="flex flex-col items-center justify-center p-4">
          <SubTitle>Are you sure you want to delete this page</SubTitle>
          <div className="flex justify-center gap-4 my-4">
            <TransparentButton
              className={"hover:bg-red-500 border border-red-500 text-red-500"}
              handleButtonClick={() => {
                handlePageDelete(deletePageId);
                closeDeletePageDialog();
              }}
            >
              Delete
            </TransparentButton>
            <TransparentButton
              className={
                "hover:bg-slate-300 border border-slate-200 text-slate-500"
              }
              handleButtonClick={() => {
                closeDeletePageDialog();
              }}
            >
              Cancel
            </TransparentButton>
          </div>
        </div>
      </DialogWrapper>
      <div className="flex mt-10  w-[95%] md:w-[85%] mx-auto gap-8 flex-col md:flex-row ">
        <div className="flex flex-col gap-6 md:hidden  jusitfy-center items-center">
          <Header />
        </div>
        {activeButton === "Quick Generate" ? (
          <div className=" md:w-[40%] w-[90%] mx-auto flex flex-col  gap-4  md:mt-28 mt-0 ">
            <div className="flex flex-col md:flex-row gap-8 items-center justify-center p-8 flex-wrap cursor-pointer">
              <CustomCover
                imageLoading={
                  generatingImage ||
                  storyDetails?.pages?.[0]?.is_generation_pending
                }
                backgroundImage={
                  storyDetails?.pages?.[0]?.image_urls?.[
                  storyDetails?.pages?.[0].current_image_index
                  ]
                }
                text={storyDetails?.pages?.[0]?.text}
                backgroundColor="bg-[#008DC2]"
                pageDetails={storyDetails?.pages?.[0]}
              />
            </div>
            <p className="text-center">
              Doesn’t look like {characterDetail?.name}?
            </p>
            <div className="flex justify-center  items-center">
              {storyDetails?.pages?.[0]?.image_urls?.[
                storyDetails?.pages?.[0].current_image_index
              ] ? (
                <Link
                  to={`/customize/${id}`}
                  className=" blur-shadow text-center px-12 md:py-2 py-3  rounded-full text-[#000]  font-bold text-lg border-2 border-solid border-[#E3E9ED] quicksand-text hover:bg-[#3CC3AA] hover:text-[#fff]"
                >
                  Edit Cover
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className=" md:w-[40%] w-[100%] mx-auto flex flex-col  gap-4  md:mt-28 mt-0">
            <PreviewSection />
          </div>
        )}

        <div className="md:w-[50%] w-[90%] mx-auto mb-24">
          <div className="flex-col gap-6 hidden md:flex jusitfy-center items-center">
            <Header />
          </div>
          <div className="flex flex-col gap-8 md:mt-6 mt-0">
            {activeButton === "Quick Generate" ? (
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
                noValidate
                autoComplete="off"
              >
                <div className="bg-[#3cc3aa] bg-opacity-20 rounded-lg border-2 border-[#3cc3aa] p-4">
                  <SubTitle className="text-start">
                    <ul>
                      <li className="flex mr-2">
                        <p>
                          Click <strong>below</strong> to generate
                          <strong> all illustrations</strong> with the{" "}
                          <strong>AI</strong> suggestions
                        </p>
                      </li>
                      <br />
                      <li>
                        We <strong>recommend</strong> reviewing each page's
                        settings (<strong>Advanced Option</strong>)
                        individually, to conserve your{" "}
                        <strong>StoryCoins</strong>
                      </li>
                    </ul>
                  </SubTitle>
                </div>
                <div className="flex justify-center md:justify-start items-center">
                  <PrimaryButton
                    disabled={!allPageCreated}
                    className={`flex items-center justify-center ${!allPageCreated ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                    handleButtonClick={() => {
                      isAllPagesGenerated
                        ? goToPreviewPage()
                        : handleQuickGenerate();
                    }}
                  >
                    {isAllPagesGenerated
                      ? "Go To Preview"
                      : "Quick Generate All Illustrations"}
                    {!isAllPagesGenerated && (
                      <img className="h-8 w-8" src={coinImg} alt="story coin" />
                    )}{" "}
                  </PrimaryButton>
                </div>
              </Box>
            ) : (
              <AdvanceData />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
