/* eslint-disable react/prop-types */
import { Download } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import left from "../../assets/left.svg";
import right from "../../assets/right.svg";
import { downloadFromLink } from "../../services/serviceFunction";
import {
  PAGE_TEXT_LOADING_STATE,
  SOCKET_OPERATIONS,
} from "../../services/socketoOperations";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import useUserDetailsStore from "../../store/UserStore";
import { TransparentButton } from "../Button/TransparentButton";
import PaginationProgress from "../CustomPagination";
import { useStoryCoinHelper } from "../StoryCoin/storyCoinHook";
import PreviewComponent from "./PreviewComponent";
import "./index.css";
import coinImg from "../../assets/coin.webp";

const confirm = Modal.confirm;

export default function PreviewSection() {
  const [
    story,
    page,
    handleFormEvents,
    isCoverPage,
    onImageSwitch,
    generateImage,
    changedImage,
    formState,
  ] = useAdvanceStoryStore((state) => [
    state.story,
    state.page,
    state.handleFormEvents,
    state.isCoverPage,
    state.onImageSwitch,
    state.generateImage,
    state.changedImage,
    state.formState,
  ]);
  const storyId = story?.story?.id;
  const activeBookview = story?.story?.layout;
  const pageDetails = story?.pages?.[page];
  const backgroundImage =
    changedImage != "" && changedImage != undefined
      ? changedImage
      : pageDetails?.image_urls?.[pageDetails?.current_image_index];
  const credit = useUserDetailsStore((state) => state.credit);

  const {
    showCoinShortDialog,
    showCoinDetailDialog,
    setModelType,
    dontShowConfirmation,
  } = useStoryCoinHelper();
  const navigate = useNavigate();

  const disablePrevButton = pageDetails?.current_image_index === 0;
  const disableNextButton =
    pageDetails?.image_urls?.length === 0 ||
    pageDetails?.current_image_index === pageDetails?.image_urls?.length - 1;

  const contentChanging = Object.values(PAGE_TEXT_LOADING_STATE).some((key) =>
    Boolean(formState[key])
  );

  const loadingImage = pageDetails?.is_generation_pending || contentChanging;

  function showConfirmDialog(bookview) {
    confirm({
      okText: "Confirm",
      title: "Do you want to switch the layout?",
      content:
        "When the layout is switched, it will impact appearance of the whole storybook.",
      okButtonProps: {
        className: "custom-ok-button",
      },
      cancelButtonProps: {
        className: "custom-cancel-button",
      },
      onOk() {
        handleFormEvents(
          { key: "layout", value: bookview },
          SOCKET_OPERATIONS.BOOK_LAYOUT_CHANGE
        );
      },
    });
  }

  const handleLeftClick = () => {
    onImageSwitch(pageDetails?.current_image_index - 1);
  };

  const handleRightClick = () => {
    onImageSwitch(pageDetails?.current_image_index + 1);
  };

  const GenerateButton = () => {
    const handleGenerate = () => {
      if (loadingImage) return;
      if (dontShowConfirmation) {
        if (credit < 1) {
          showCoinShortDialog({
            onProceedPayment: () => {
              navigate(`/credit-packs?storyId=${storyId}`);
              setModelType("");
            },
          });
        } else {
          generateImage();
          setModelType("");
        }
        return;
      }
      showCoinDetailDialog({
        productType: "Regenerating Image",
        coinCost: 1,
        callback: () => {
          if (credit < 1) {
            showCoinShortDialog({
              onProceedPayment: () => {
                navigate(`/credit-packs?storyId=${storyId}`);
                setModelType("");
              },
            });
          } else {
            generateImage();
            setModelType("");
          }
        },
      });
    };
    return (
      <Tooltip
        arrow
        placement="top"
        title={`${
          !loadingImage
            ? "Click here to generate an illustration for this page using the composition and character settings."
            : contentChanging
            ? "You will be able to regenerate after content change"
            : "Image generation on progress"
        }`}
        sx={{ fontSize: "18px" }}
      >
        <button
          onClick={handleGenerate}
          className={`flex justify-center
        ${loadingImage && "cursor-not-allowed opacity-50"}
        blur-shadow px-6 md:py-2 py-3 my-2 w-[100%] lg:w-[50%] rounded-full text-[#fff] bg-[#3CC3AA] font-bold text-lg border-2 border-solid border-[#3CC3AA] quicksand-text`}
        >
          <div className=" flex items-center md:px-2 lg:px-8">
            {pageDetails?.image_urls.length > 0 ? (
              <span>Regenerate</span>
            ) : (
              <span>Generate</span>
            )}
            <img className="h-8 w-8" src={coinImg} alt="story coin" />
          </div>
        </button>
      </Tooltip>
    );
  };
  const DownloadButton = () => {
    return (
      <Tooltip arrow placement="top" title="Download illustration">
        <div
          onClick={() => {
            downloadFromLink(
              backgroundImage,
              story?.characters?.[0]?.name + "illustration" + page + ".jpg"
            );
          }}
          className={` p-2cursor-pointer
         bg-[#F4F7FA] rounded-lg p-2 hover:bg-[#E5E5E5]`}
        >
          <Download />
        </div>
      </Tooltip>
    );
  };
  const StyleTransferButton = () => {
    return (
      <Tooltip
        className="hidden md:flex"
        arrow
        placement="top"
        title="Style Transfer"
      >
        <div
          onClick={() => {
            navigate(`/style-editor/${storyId}?pageId=${pageDetails?.id}`);
          }}
          className={`cursor-pointer
         bg-[#F4F7FA] rounded-lg p-2  hover:bg-[#E5E5E5]`}
        >
          <EditIcon color="primary" />
        </div>
      </Tooltip>
    );
  };
  const isSplitView = activeBookview === "split";
  return (
    <>
      <div className="relative flex  gap-4 justify-end items-center">
        {backgroundImage && (
          <StyleTransferButton
            storyId={storyId}
            pageId={pageDetails?.id}
            navigate={navigate}
          />
        )}
        {backgroundImage && <DownloadButton />}
        <Tooltip arrow placement="top" title="Undo">
          <img
            src={left}
            className={`
          ${
            disablePrevButton
              ? "opacity-50 cursor-not-allowed"
              : " cursor-pointer"
          }
         bg-[#F4F7FA] rounded-lg p-2 hover:bg-[#E5E5E5]`}
            onClick={() => {
              if (!disablePrevButton) handleLeftClick();
            }}
          />
        </Tooltip>
        <Tooltip arrow placement="top" title="Redo">
          <img
            src={right}
            className={`
          ${
            disableNextButton
              ? "opacity-50 cursor-not-allowed"
              : " cursor-pointer"
          }
          bg-[#F4F7FA] rounded-lg p-2 hover:bg-[#E5E5E5]`}
            onClick={() => {
              if (!disableNextButton) handleRightClick();
            }}
          />
        </Tooltip>
      </div>
      <div className="flex flex-col gap-4 justify-center items-center md:sticky md:top-0">
        <div
          className={`flex justify-center relative w-[100%] h-[100%] ${
            (!isSplitView || isCoverPage) && "max-w-[412px]"
          } w-[100%]`}
        >
          <PreviewComponent isBackgroundChangeable={true} />
        </div>
        <PaginationProgress />

        {!isCoverPage && (
          <div className="relative flex justify-center gap-4 mt-4 w-full">
            <div
              className={` p-4  rounded-lg cursor-pointer ${
                activeBookview === "merged" || isCoverPage
                  ? "bg-[#3CC3AA]"
                  : "bg-[#fff] border border-[#3CC3AA]"
              } `}
              onClick={() => {
                showConfirmDialog("merged");
              }}
            >
              <div
                className={` p-2 ${
                  isCoverPage
                    ? "bg-[#fff]"
                    : activeBookview === "merged"
                    ? "bg-[#fff]"
                    : "bg-[#3CC3AA]"
                }`}
              ></div>
            </div>
            <div
              className={`py-4 px-2 flex gap-1 rounded-lg cursor-pointer ${
                activeBookview === "split"
                  ? "bg-[#3CC3AA]"
                  : "bg-[#fff] border border-[#3CC3AA]"
              } `}
              onClick={() => {
                showConfirmDialog("split");
              }}
            >
              <div
                className={`  p-2  ${
                  activeBookview === "split" ? "bg-[#fff]" : "bg-[#3CC3AA]"
                }`}
              ></div>
              <div
                className={` p-2 ${
                  activeBookview === "split" ? "bg-[#fff]" : "bg-[#3CC3AA]"
                }`}
              ></div>
            </div>
          </div>
        )}
        <div className="w-[100%] md:inline-flex flex-col hidden items-center justify-center">
          <GenerateButton />
          <TransparentButton
            style={{ marginTop: "5px" }}
            className={"px-6 md:py-2 py-3 my-2 w-[100%] lg:w-[50%]"}
            onClick={() => {
              navigate(`/story/${storyId}`);
            }}
          >
            Preview
          </TransparentButton>
        </div>
        {/* sticky bottom generate button */}
        <div className=" z-50 w-[100%] md:hidden  flex align-center justify-center gap-2 p-2 fixed bottom-0 bg-[#fff]">
          <GenerateButton />
          <TransparentButton
            style={{ marginTop: "5px" }}
            className={"px-6 md:py-2 py-3 my-2 w-[100%] lg:w-[50%]"}
            onClick={() => {
              navigate(`/story/${storyId}`);
            }}
          >
            Preview
          </TransparentButton>
        </div>
      </div>
    </>
  );
}
