import { useState } from "react";
import CharacterDetails from "../CharacterDetails";
import CompositionDetails from "../CompositionDetails";
import TextDetails from "../TextDetails";
export default function AdvanceData() {
  const [activeButton, setActiveButton] = useState("Composition");
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  // eslint-disable-next-line react/prop-types
  const TabButton = ({ buttonName }) => {
    return (
      <button
        className={`p-3 w-[100%] max-w-[135px] rounded-lg   ${
          activeButton === buttonName
            ? "bg-[#3CC3AA]  text-[#fff] shadow-lg shadow-[#3CC3AA] bg-blend-lighten"
            : "bg-[#F4F7FA]"
        }`}
        style={{
          boxShadow:
            activeButton === buttonName
              ? "0px 0px 10px 2px rgba(60, 195, 170, 0.3)"
              : "none",
        }}
        onClick={() => {
          handleButtonClick(buttonName);
        }}
      >
        {buttonName}
      </button>
    );
  };

  return (
    <>
      <div>
        <div className="flex flex-row gap-6 items-center mb-4">
          <TabButton buttonName="Composition" />
          <TabButton buttonName="Character" />
          <TabButton buttonName="Text" />
        </div>
        {activeButton === "Character" && <CharacterDetails />}
        {activeButton === "Text" && <TextDetails />}
        {activeButton === "Composition" && <CompositionDetails />}
      </div>
    </>
  );
}
