/* eslint-disable react/prop-types */
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, IconButton, ListItemAvatar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { signOut } from "firebase/auth";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Headerlogo from "../../assets/Headerlogo.webp";
import coinHam from "../../assets/coin_ham.png";
import useUserDetailsStore from "../../store/UserStore";
import { PrimaryButton } from "../Button/PrimaryButton";
import { auth } from "../Config/config";
import PlanIndicatorTile from "../PlanIndicator/PlanIndicatorTile";
import { subscriptionStore } from "../../store/SubscriptionStore";

export const CustomDrawer = ({ showCoinDetail }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const onMenuItemsClick = (path) => {
    navigate(path);
    setIsDrawerOpen(false);
  };
  const userStore = useUserDetailsStore();
  const currentsubscriptionStatusDetails = subscriptionStore(
    (state) => state.currentsubscriptionStatusDetails
  );
  const planDetails =
    currentsubscriptionStatusDetails.subscription?.plan_details;
  // eslint-disable-next-line react/prop-types
  const DrawerItem = ({ title, onTap }) => {
    return (
      <ListItem
        className="border-2 self-center border-[#D9D9D9] rounded-lg my-2 w-[80%]"
        onClick={onTap}
        sx={{ justifyContent: "center", width: "95%" }}
      >
        <p className="p-6 flex item-center text-lg font-bold color-[#36324A">
          {title}
        </p>
      </ListItem>
    );
  };
  const handleLogout = () => {
    Cookies.remove("story_token");
    localStorage.removeItem("u_d");
    signOut(auth);
    window.location.href = "/login";
    toggleDrawer();
  };
  return (
    <div>
      <Button onClick={toggleDrawer}>
        <MenuIcon sx={{ color: "#36324A" }} />
      </Button>
      <Drawer anchor="top" open={isDrawerOpen} onClose={toggleDrawer}>
        <Box sx={{ width: "auto", height: "100vh" }} role="presentation">
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListItem
              secondaryAction={
                <IconButton onClick={toggleDrawer}>
                  <Close />
                </IconButton>
              }
            >
              <div className="flex items-center gap-3 ">
                <img
                  src={Headerlogo}
                  alt="logo"
                  className="md:w-32 sm:w-20 w-20"
                />
                <h1 className="md:text-3xl sm:text-lg text-lg  font-medium">
                  StoryGenius
                </h1>
              </div>
            </ListItem>
            <div className="flex justify-end h-[100px] overflow-hidden w-full">
              <PlanIndicatorTile
                plan={
                  planDetails?.notes?.plan_key
                    ? planDetails?.notes?.plan_key?.includes("hobby")
                      ? "HOBBY"
                      : "PROFESSIONAL"
                    : "FREE"
                }
              />
            </div>

            <ListItem
              className="my-8"
              secondaryAction={
                <button
                  onClick={() => {
                    showCoinDetail();
                    toggleDrawer();
                  }}
                  className="p-2 flex rounded-lg border-2 border-[#FFD600] "
                >
                  <img className="px-1" src={coinHam} alt="coin" />
                  {userStore.credit}
                </button>
              }
            >
              <ListItemAvatar>
                {userStore.userDetail?.user?.email_picture_url ? (
                  <Avatar src={userStore.userDetail?.user?.email_picture_url} />
                ) : (
                  <Avatar>{userStore.userDetail?.user?.name?.charAt(0)}</Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={`Hi ${
                  userStore.userDetail?.user?.name.split(" ")[0]
                }!`}
              />
            </ListItem>

            <DrawerItem
              title={"Kids"}
              onTap={() => onMenuItemsClick("/select-child?tab=kids")}
            />
            <DrawerItem
              title={"Stories"}
              onTap={() => onMenuItemsClick("/select-child?tab=story")}
            />
            <DrawerItem
              title={"My account"}
              onTap={() => onMenuItemsClick("/order-history")}
            />

            <DrawerItem
              title={"Subscription"}
              onTap={() => onMenuItemsClick("/subscription")}
            />
            <PrimaryButton
              handleButtonClick={handleLogout}
              className="w-[95%]  "
            >
              Logout
            </PrimaryButton>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};
