/* eslint-disable react/no-unescaped-entities */
import { PrimaryButton } from "../../Button/PrimaryButton";
import { DialogWrapper } from "../../Dialog";
import coinHam from "../../../assets/coin_ham.png";
import { useStoryCoinHelper } from "../storyCoinHook";

// eslint-disable-next-line react/prop-types
const CoinShortPopUp = ({ open, onClose }) => {
  const [onProceedPayment] = useStoryCoinHelper((state) => [
    state.onProceedPayment,
  ]);
  return (
    <DialogWrapper isOpen={open} onClose={onClose}>
      <div className="bg-white  p-4 rounded-b-xl  text relative flex flex-col items-center justify-center gap-4">
        <strong className="text-bold">Uh-Oh! You're out of StoryCoins!</strong>
        <p className="max-w-[340px] text-center">
          Dont't worry! You can top-up your <b>StoryCoins</b> to continue
          editing
        </p>
      </div>
      <div className="p-4 flex justify-center item-center">
        <PrimaryButton handleButtonClick={onProceedPayment}>
          <div className="flex">
            <img className="px-2" src={coinHam} alt="coin" />
            Buy StoryCoins
          </div>
        </PrimaryButton>
      </div>
    </DialogWrapper>
  );
};

export default CoinShortPopUp;
