import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteStory } from "../../ApiServices/Story/storyApis";
import Smile from "../../assets/smile.png";
import CustomCover from "../../components/CustomCover";
import useStore from "../../components/UseStore";
import useSocketStore from "../../store/SocketStore/useSocketStore";
import FailedToGeneratedStoryDialog from "./FailedToGeneratedStoryDialog";
let intervalId;
export default function CraftingPage() {
  const navigate = useNavigate();

  const { storyId } = useParams();

  const [
    loadingStory,
    generateData,
    progress,
    message,
    getStoryDetails,
    coverCallback,
    story,
  ] = useStore((state) => [
    state.loadingStory,
    state.generateData,
    state.progress,
    state.message,
    state.getStoryDetails,
    state.coverCallback,
    state.story,
  ]);
  // const storyCallback = useAdvanceStoryStore((state) => state.storyCallback);
  const generatingImage = useStore((state) => state.generatingImage);
  const completed = progress >= 100;
  console.log("progress", progress);
  console.log("completed", completed);
  useSocketStore([coverCallback]);
  const redirecturl = "/storyboard/" + storyId;
  const [countdown, setCountdown] = useState(7);

  useEffect(() => {
    getStoryDetails(storyId);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let temp = countdown;
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (
      !generateData?.cover?.failure_code &&
      generateData?.cover?.image_url &&
      generateData?.cover?.title
    ) {
      intervalId = setInterval(() => {
        temp -= 1;
        setCountdown(temp);
        if (temp === 0) {
          clearInterval(intervalId);
          navigate(redirecturl);
          return;
        }
      }, 1000);
    }
  }, [
    generateData?.cover?.failure_code,
    generateData?.cover?.image_url,
    generateData?.cover?.title,
  ]);

  // ${story.characters[0].id}
  const goBackWithStoryline = () => {
    deleteStory(storyId);
    navigate(`/choose-story?id=${story?.story?.human}`, {
      replace: true,
      state: {
        storyline: story?.story?.storyline,
      },
    });
  };
  return (
    <>
      <FailedToGeneratedStoryDialog
        onClose={goBackWithStoryline}
        open={generateData?.cover?.failure_code}
      />
      <div className="story-concept-background ">
        {completed ? (
          <div className="flex flex-col justify-center items-center gap-8 p-8 ">
            <h1 className="text-3xl text-[#36324A] font-bold quicksand-text text-center">
              Preview Your Magical Book Cover
            </h1>
            <br />
            {!loadingStory && (
              <CustomCover
                backgroundImage={generateData?.cover?.image_url}
                text={generateData?.cover?.title}
                imageLoading={generatingImage}
                pageDetails={generateData?.cover}
              />
            )}
            {!generateData?.cover?.failure_code && (
              <div className="text-xl font-bold text-center mt-2">
                Redirecting in {countdown} seconds...
                <br />
                <br />
                If not redirected, click{" "}
                <a
                  href={`${redirecturl}`}
                  className="text-blue-500 font-bold"
                  rel="noopener noreferrer"
                >
                  here
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center md:gap-8 gap-12 p-8">
            <h1 className="text-3xl text-[#36324A] font-bold quicksand-text text-center">
              Crafting A Cover For Your Book
            </h1>
            <p className="quicksand-text text-[#747474]">
              It’ll take 1-2 minutes
            </p>
            <h1
              className="text-2xl text-[#36324A] font-bold craft-heading text-center"
              dangerouslySetInnerHTML={{ __html: generateData?.cover?.title }}
            ></h1>
            <Stack spacing={2} direction="row" className="relative ">
              <CircularProgress
                variant="determinate"
                className="custom-progress"
                value={progress}
              />
              <img
                src={Smile}
                alt=""
                className="w-12 h-12  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                style={{ marginLeft: "0px" }}
              />
            </Stack>
            <p className="quicksand-text text-[#747474]">
              {message || "Adding a dash of magic"}
            </p>
          </div>
        )}
      </div>
    </>
  );
}
