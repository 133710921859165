/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import { EyeFilled } from "@ant-design/icons";
import { Delete, Edit } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import StoryEmptyImg from "../../assets/storyEmpty.svg";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import Collapsible from "../../components/Collapsible";
import CustomCover from "../../components/CustomCover";
import ChooseStarDialog from "../../components/SelectChild/chooseStarModal";
import { goToPreviewPage } from "../../services/serviceFunction";
import appInfoStore from "../../store/AppInfoStore";
import useUserDetailsStore from "../../store/UserStore";
import PreviewButton from "../Preview/modules/PreviewButton";
import { KidTile } from "./kidTile";
import useChidDetails from "./useChildDetails";
import usePurchaseStory from "./usePurchaseStory";
import useStoryList from "./useStoryList";
import { DialogWrapper } from "../../components/Dialog";
import { TransparentButton } from "../../components/Button/TransparentButton";
import { SubTitle } from "../../components/molecules/subtitle";
import useAppSomo from "../../Hooks/useAppSomo";
import { Title } from "../../components/molecules/header";

const SelectChild = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("My kids");
  const [pageNoForDraft, setPageNoForDraft] = useState(1);
  const [pageNoForGenerated, setPageNoForGenerated] = useState(1);

  const {
    storyList: generatedStories,
    loading: purchasedStoryListLoading,
    totalPages: totalGeneratedPage,
    getStoryList: fetchGeneratedStories,
  } = useStoryList("purchased", pageNoForGenerated);
  const {
    storyList: draftStories,
    loading: draftstoryListLoading,
    totalPages: totalDraftPage,
    getStoryList: fetchDraftStories,
    deleteStory,
    deletingStoryList,
  } = useStoryList("drafts", pageNoForDraft);
  const {
    kidsData,
    deleteKid,
    loading: childDetailsLoading,
  } = useChidDetails();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const {
    purchaseStory,
    loading: purchasingStory,
    purchasingStortList,
  } = usePurchaseStory();
  const [canAddHuman, canAddBook] = useUserDetailsStore((state) => [
    state.canAddHuman,
    state.canAddBook,
  ]);
  const [openSelectChildDialog, setOpenSelectChildDialog] = useState(false);
  const setAppInfo = appInfoStore((state) => state.setAppInfo);
  const [deleteChildId, setdeleteChildId] = useState(null);

  useEffect(() => {
    if (tab === "story") {
      setActiveButton("My stories");
    } else {
      setActiveButton("My kids");
    }
    return () => {
      setAppInfo("");
    };
  }, [tab]);

  const draftScrollRef = useRef(null);
  useEffect(() => {
    if (draftScrollRef.current) {
      const firstItemOfLatestSet = draftScrollRef.current.children[0];
      firstItemOfLatestSet.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [pageNoForDraft]);

  const purchaseScrollRef = useRef(null);

  const {
    createAppSumoSubscription,
    subscriptionUpdateMessage,
    setSubscriptionUpdateMessage,
    reedeemSuccess,
    setReedeemSuccess,
  } = useAppSomo();

  useEffect(() => {
    createAppSumoSubscription();
  }, []);

  useEffect(() => {
    if (purchaseScrollRef.current) {
      const firstItemOfLatestSet = purchaseScrollRef.current.children[0];
      firstItemOfLatestSet.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [pageNoForGenerated]);

  const FailureOverlay = ({ failureCode, onDelete, isLoading }) => {
    if (failureCode)
      return (
        <div className="grid place-content-center w-full h-full absolute top-0 left-0 bg-[red] bg-opacity-20 rounded-xl border-2 border-red-500 p-2 backdrop-blur-sm z-10">
          {isLoading ? (
            <div className="flex justify-center">
              <CircularProgress className="self-center" />
            </div>
          ) : (
            <Tooltip title="DELETE STORY" placement="top" arrow={true}>
              <div
                onClick={onDelete && onDelete}
                className="hover:bg-[red] ease-in-out duration-300 shadow-md hover:shadow-lg hover:shadow-white  hover:text-white bg-white rounded-full text-[red] h-14 w-14 text-center leading-[48px] m-auto "
              >
                <Delete color="danger" />
              </div>
            </Tooltip>
          )}
          <br />
          <p className="font-bold text-[#fff]">Creation failed</p>
        </div>
      );
    return null;
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName === "My kids") setSearchParams({ tab: "kids" });
    else setSearchParams({ tab: "story" });
  };

  const renderAddButton = () => {
    if (activeButton === "My kids") {
      return (
        <button
          className="px-10 py-2 rounded-full text-[#3CC3AA] font-bold text-lg border-2 border-solid border-[#3CC3AA]"
          onClick={() => {
            if (canAddHuman) {
              navigate("/add-child");
            } else {
              toast(
                "You have hit the plan limit. See subscription details for more info"
              );
            }
          }}
        >
          + Add Kid
        </button>
      );
    } else if (activeButton === "My stories") {
      if (generatedStories.length === 0) {
        return (
          <PrimaryButton
            style={{ marginTop: "0px" }}
            className="animate-pulse"
            onClick={() => {
              if (canAddBook) {
                showChooseStarDialog();
              } else {
                toast(
                  "You have hit the limit for creating storybook. See subscription details for more info"
                );
              }
            }}
          >
            <span>Start here</span>
          </PrimaryButton>
        );
      }
      return (
        <button
          onClick={() => {
            if (canAddBook) {
              showChooseStarDialog();
            } else {
              toast(
                "You have hit the limit for creating storybook. See subscription details for more info"
              );
            }
          }}
          className=" px-10 py-2 rounded-full text-[#3CC3AA] font-bold text-lg border-2 border-solid border-[#3CC3AA]"
        >
          + New <span className="hidden lg:inline">Story</span>
        </button>
      );
    }
  };
  const FullPageLoading = () => {
    return (
      <div className="relative w-[100%] h-[80vh]">
        <div className="z-50 flex justify-center items-center absolute top-0 left-0 w-[100%] h-[100%] opacity-80 ">
          <CircularProgress sx={{ color: "#3CC3AA" }} />
        </div>
      </div>
    );
  };
  const renderChildView = () => {
    if (childDetailsLoading && kidsData.length === 0) {
      return <FullPageLoading />;
    }
    if (kidsData.length === 0 || !kidsData) {
      return (
        <div className="flex flex-col items-center justify-center">
          <img src={StoryEmptyImg} alt="empty story" />
          <p className="text-md my-4">
            You have no child added. Let’s get started by adding a child!
          </p>
          {renderAddButton()}
        </div>
      );
    }
    const openDeleteDialog = (id) => {
      setdeleteChildId(id);
    };
    const closeDeleteKidDialog = () => {
      setdeleteChildId(null);
    };

    return (
      <div className="flex items-center relative justify-center p-8 mt-8">
        <DialogWrapper isOpen={deleteChildId} onClose={closeDeleteKidDialog}>
          <div className="flex flex-col items-center justify-center p-4">
            <SubTitle>
              Are you sure you want to delete <br />
              <strong>
                {kidsData.find((kid) => kid.id === deleteChildId)?.name || ""}{" "}
              </strong>{" "}
              from your child list?
            </SubTitle>
            <div className="flex justify-center gap-4 my-4">
              <TransparentButton
                className={
                  "hover:bg-red-500 border border-red-500 text-red-500"
                }
                handleButtonClick={() => {
                  deleteKid(deleteChildId);
                  closeDeleteKidDialog();
                }}
              >
                Delete
              </TransparentButton>
              <TransparentButton
                className={
                  "hover:bg-slate-300 border border-slate-200 text-slate-500"
                }
                handleButtonClick={() => {
                  closeDeleteKidDialog();
                }}
              >
                Cancel
              </TransparentButton>
            </div>
          </div>
        </DialogWrapper>

        <div className="grid grid-cols-2 gap-x-10 gap-y-4 justify-center items-center cursor-pointer">
          {kidsData?.map((kid) => (
            <KidTile key={kid.id} kid={kid} deleteKid={openDeleteDialog} />
          ))}
        </div>
      </div>
    );
  };
  const renderStoryView = () => {
    if (!purchasedStoryListLoading && !draftstoryListLoading) {
      if (generatedStories.length === 0 && draftStories.length === 0) {
        return (
          <div className="flex flex-col items-center justify-center">
            <img src={StoryEmptyImg} alt="empty story" />
            <p className="text-md my-4">
              You have a blank canvas. Let’s get started on your first
              storybook!
            </p>
            {renderAddButton()}
          </div>
        );
      }
    }
    const Shimmer = ({ type }) => {
      return (
        <>
          {Array(20)
            .fill(0)
            .map((_, index) => (
              <div key={index} className="h-[406px]">
                <div className="rounded-xl bg-slate-200 h-[288px] w-[299px] mt-2 mb-2  animate-pulse flex flex-col item-center justify-center relative"></div>
                <div className="flex justify-center">
                  {type == "Purchased" ? (
                    <div className="my-4 mx-2 rounded-full w-[50px] h-[50px] bg-slate-200 animate-pulse"></div>
                  ) : (
                    <div className="my-4 mx-2 rounded-full w-[150px] h-[50px] bg-slate-200 animate-pulse"></div>
                  )}{" "}
                  <div className="my-4 mx-2 rounded-full w-[50px] h-[50px] bg-slate-200 animate-pulse"></div>
                </div>
              </div>
            ))}
        </>
      );
    };

    const deleteAndRefetch = async (id, type) => {
      await deleteStory(id, type);
      if (type === "purchased") fetchGeneratedStories();
      else fetchDraftStories();
    };
    return (
      <>
        {Boolean(generatedStories.length || purchasedStoryListLoading) && (
          <Collapsible
            scrollRef={purchaseScrollRef}
            showPagination={totalGeneratedPage > 1}
            pageSetting={{
              currentPage: pageNoForGenerated,
              totalPages: totalGeneratedPage,
              onPageChange: (e, pageNo) => setPageNoForGenerated(pageNo),
            }}
            title="Purchased"
            defaultOpen={true}
            showCollapsibleHeader={false}
          >
            {purchasedStoryListLoading ? (
              <Shimmer type={"Purchased"} />
            ) : (
              generatedStories?.map((data, index) => (
                <div
                  key={index}
                  className="flex flex-col item-center justify-center relative"
                >
                  <Link
                    className="p-4 pt-10 hover:bg-[#E2F6F2] rounded-2xl"
                    to={`/storyboard/${data.id}`}
                  >
                    <CustomCover
                      backgroundImage={
                        data?.cover_page?.image_urls?.[
                        data?.cover_page?.current_image_index
                        ]
                      }
                      text={data?.cover_page?.text || data?.title}
                      backgroundColor="bg-[#008DC2]"
                      pageDetails={data?.cover_page}
                    />
                  </Link>
                  <div className="flex h-auto justify-center mt-2 mb-2">
                    <PreviewButton
                      style={{ height: "50px", width: "50px" }}
                      className="mx-2"
                      onClick={() => {
                        navigate(`/storyboard/${data.id}`);
                      }}
                      icon={<Edit />}
                    />
                    <PreviewButton
                      className="mx-2"
                      style={{ height: "50px", width: "50px" }}
                      onClick={() => {
                        goToPreviewPage(data.id);
                      }}
                      icon={<EyeFilled />}
                    />
                  </div>
                  <FailureOverlay
                    failureCode={
                      data?.failure_code ||
                      (data.cover_page?.failure_code &&
                        data?.cover_page?.image_urls?.length === 0)
                    }
                    isLoading={deletingStoryList.includes(data.id)}
                    onDelete={() => {
                      deleteAndRefetch(data.id, "purchased");
                    }}
                  />
                </div>
              ))
            )}
          </Collapsible>
        )}
        {Boolean(draftStories.length || draftstoryListLoading) && (
          <Collapsible
            scrollRef={draftScrollRef}
            showPagination={totalDraftPage > 1}
            pageSetting={{
              currentPage: pageNoForDraft,
              totalPages: totalDraftPage,
              onPageChange: (e, pageNo) => setPageNoForDraft(pageNo),
            }}
            title="Drafts"
            defaultOpen={true}
          >
            {draftstoryListLoading ? (
              <Shimmer />
            ) : (
              draftStories?.map((data, index) => (
                <div
                  key={index}
                  className=" relative flex flex-col item-center justify-center"
                >
                  <Link
                    className="p-4 pt-10 hover:bg-[#E2F6F2] rounded-2xl"
                    onClick={() => purchaseStory(data.id)}
                  >
                    <CustomCover
                      backgroundImage={
                        data?.cover_page?.image_urls?.[
                        data?.cover_page?.current_image_index
                        ]
                      }
                      text={data?.cover_page?.text || data?.title}
                      backgroundColor="bg-[#008DC2]"
                      pageDetails={data?.cover_page}
                    />
                  </Link>
                  <div className="flex justify-center items-center mt-2 mb-2">
                    <PrimaryButton
                      style={{ marginTop: "0px", minWidth: "50%" }}
                      handleButtonClick={() => {
                        purchaseStory(data.id);
                      }}
                    >
                      {purchasingStory &&
                        purchasingStortList.includes(data.id) ? (
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      ) : (
                        "Generate"
                      )}
                    </PrimaryButton>
                  </div>
                  <FailureOverlay
                    onDelete={async () => {
                      deleteAndRefetch(data.id, "draft");
                    }}
                    isLoading={deletingStoryList.includes(data.id)}
                    failureCode={
                      data?.failure_code ||
                      (data.cover_page?.failure_code &&
                        data?.cover_page?.image_urls?.length === 0)
                    }
                  />
                </div>
              ))
            )}
          </Collapsible>
        )}
      </>
    );
  };
  function showChooseStarDialog() {
    setOpenSelectChildDialog(true);
  }
  return (
    <>
      <ChooseStarDialog
        childList={kidsData}
        show={openSelectChildDialog}
        getStartedFn={(childId) => {
          navigate(`/choose-story?id=${childId}`);
        }}
        onClose={() => setOpenSelectChildDialog(false)}
      />
      <DialogWrapper
        isOpen={Boolean(subscriptionUpdateMessage)}
        onClose={() => {
          setSubscriptionUpdateMessage(null);
        }}
      >
        <div className="p-4 md:p-8 py-8">
          <SubTitle>You're already subscribed, the code was not used.</SubTitle>
          <SubTitle>
            Contact <strong>contact@pictogen.com</strong> for any questions
          </SubTitle>
        </div>
      </DialogWrapper>
      <DialogWrapper
        isOpen={reedeemSuccess}
        onClose={() => {
          setReedeemSuccess(false);
        }}
      >
        <div className="p-4 md:p-8 py-8 flex flex-col justify-center items-center">
          <Title className="text-center">Thank you, Sumoling!</Title>
          <br />
          <SubTitle>
            Your Annual Hobby plan is now activated! We're excited to have you
            onboard. Please reach out for any feedback:{" "}
            <strong>contact@pictogen.com</strong>.
          </SubTitle>
          <TransparentButton
            className="rounded-lg w-1/2"
            handleButtonClick={() => {
              searchParams.delete("appsumo_code");
              setSearchParams(searchParams);
              setReedeemSuccess(false);
            }}
          >
            Close
          </TransparentButton>
        </div>
      </DialogWrapper>
      <div className="items-center justify-center gap-4 p-6 sm:p-8">
        <div className=" flex justify-end md:float-right mb-4 md:m-0 md:mt-2  ">
          {renderAddButton()}
        </div>
        <div className="w-auto ">
          <div className="w-fit  m-auto items-center shadow-div rounded-full bg-white p-1 relative">
            <button
              onClick={() => handleButtonClick("My kids")}
              className={`md:px-12 px-8 py-3 rounded-full text-lg md:font-medium font-small ${activeButton === "My kids" ? "bg-[#3CC3AA] text-white" : ""
                }`}
            >
              My kids
            </button>
            <button
              onClick={() => handleButtonClick("My stories")}
              className={`md:px-12 px-8 py-3 rounded-full text-lg md:font-medium font-small ${activeButton === "My stories" ? "bg-[#3CC3AA] text-white" : ""
                }`}
            >
              My stories
            </button>
          </div>
        </div>
      </div>
      <>{activeButton === "My kids" ? renderChildView() : renderStoryView()}</>
    </>
  );
};

export default SelectChild;
