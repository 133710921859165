import { useEffect, useState } from "react";
import {
  getShippingOptions,
  submitShippingOption,
} from "../../../ApiServices/Story/storyApis";
import { useNavigate, useSearchParams } from "react-router-dom";

const useShippingOptionsHook = (orderId) => {
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currency = searchParams.get("currency");

  const getShippingOption = () => {
    setLoading(true);
    getShippingOptions(orderId)
      .then((response) => {
        if (response.data?.["status-code"] === 1)
          setShippingOptions(response.data.data.print_order.shipping_options);
        setSelectedShipment(response.data.data.print_order.shipping_option_id);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitShipment = (shippingId) => {
    setSubmitting(true);
    submitShippingOption(orderId, {
      shipping_option_id: shippingId,
    })
      .then((response) => {
        if (response.data?.["status-code"] === 1) {
          // redirect to next page
          navigate(
            `/paymentv2?orderId=${orderId}&storyId=${response.data.data.print_order.story}&productId=${response.data.data.print_order.product}&currency=${currency}`
          );
        } else {
          setError(response.data?.["status-message"]);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (orderId) getShippingOption();
  }, [orderId]);

  return {
    shippingOptions,
    loading,
    error,
    submitShipment,
    submitting,
    selectedShipment,
  };
};

export default useShippingOptionsHook;
