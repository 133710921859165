import { useEffect, useState } from "react";

const useStoryHelpers = (pageList) => {
  const [activeButton, setActiveButton] = useState("Advanced");

  const [pageWithImageCount, setPageWithImageCount] = useState(0);

  useEffect(() => {
    if (pageList === undefined) return;
    const count = pageList.filter(
      (page) => page.image_urls?.length > 0
    )?.length;
    setPageWithImageCount(count);
  }, [pageList?.length]);

  return { pageWithImageCount, activeButton, setActiveButton };
};
export default useStoryHelpers;
