import { useEffect } from "react";
import { subscriptionStore } from "../../store/SubscriptionStore";
import { useSearchParams } from "react-router-dom";
import {
  createNewSubscription,
  updateCurrentSubscription,
} from "../../ApiServices/Subscription";

export const useSubscriptionHook = () => {
  const [searchParams] = useSearchParams();
  const planId = searchParams.get("plan_id");
  const type = searchParams.get("type") || "monthly";

  const [
    getSubscriptionPlans,
    subscriptionPlanDetails,
    selectedPlanDetails,
    setSelectedPlanDetails,
  ] = subscriptionStore((state) => [
    state.fetchSubscriptionPlans,
    state.subscriptionPlanDetails,
    state.selectedPlanDetails,
    state.setSelectedPlanDetails,
  ]);

  useEffect(() => {
    if (subscriptionPlanDetails && planId) {
      setSelectedPlanDetails(
        subscriptionPlanDetails[type].find((plan) => plan.id === planId)
      );
    }
  }, [subscriptionPlanDetails, planId, setSelectedPlanDetails, type]);

  useEffect(() => {
    getSubscriptionPlans();
  }, [getSubscriptionPlans]);

  const createSubscription = () => {
    var requestBody = {
      plan_id: planId,
    };
    const response = createNewSubscription(requestBody);

    return response;
  };
  const updateSubscription = (currentSubscriptionId) => {
    var requestBody = {
      plan_id: planId,
    };
    const response = updateCurrentSubscription(
      currentSubscriptionId,
      requestBody
    );

    return response;
  };

  return {
    subscriptionPlanDetails,
    selectedPlanDetails,
    createSubscription,
    updateSubscription,
  };
};
