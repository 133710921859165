import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import { signOut } from "firebase/auth";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import coinIcon from "../assets/coin.svg";
import Headerlogo from "../assets/Headerlogo.webp";
import useUserDetailsStore from "../store/UserStore";
import { PrimaryButton } from "./Button/PrimaryButton";
import { auth } from "./Config/config";
import { CustomDrawer } from "./Drawer";
import { PlanIndicator } from "./PlanIndicator";
import PlanIndicatorTile from "./PlanIndicator/PlanIndicatorTile";
import CoinInformationDialog from "./StoryCoin/Dialog/CoinInformationDialog";
import { subscriptionStore } from "../store/SubscriptionStore";
const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showInfoDialog, setShowInfoDialog] = React.useState(false);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const tokenExists = Cookies.get("story_token");
  const userStore = useUserDetailsStore();
  const [fetchActiveSubscription, currentsubscriptionStatusDetails] =
    subscriptionStore((state) => [
      state.fetchActiveSubscription,
      state.currentsubscriptionStatusDetails,
    ]);

  const isRootPage = window.location.pathname === "/";
  const isLoginPage = window.location.pathname === "/login";

  //check location with all items in the paymentV1Routes array

  const isPreviewPage = window.location.pathname.includes("/story/");

  const planDetails =
    currentsubscriptionStatusDetails?.subscription?.plan_details;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("story_token");
    localStorage.removeItem("u_d");
    signOut(auth);
    window.location.href = "/login";
    setAnchorEl(null);
  };

  const onMenuItemsClick = (path) => {
    navigate(path);
    setAnchorEl(null);
  };

  // eslint-disable-next-line react/prop-types
  const ItemWrapper = ({ children }) => {
    return <div className="py-1 w-[200px]">{children}</div>;
  };

  useEffect(() => {
    if (tokenExists) {
      userStore.fetchUserDetail();
      fetchActiveSubscription();
    }
  }, [tokenExists]);

  const goToHomePage = () => {
    if (tokenExists) {
      return navigate("/select-child?tab=story");
    }
    navigate("/login");
  };

  const showCoinInformation = () => {
    setShowInfoDialog(true);
  };
  if (isRootPage || isLoginPage || isPreviewPage) {
    return null;
  }

  return (
    <>
      <CoinInformationDialog
        open={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
      />

      <div className=" flex justify-between items-center sticky z-20 shadow-md px-4 py-2">
        <div
          onClick={goToHomePage}
          className="flex items-center gap-3 cursor-pointer "
        >
          <img src={Headerlogo} alt="logo" className="md:w-32 sm:w-20 w-20" />
          <h1 className="md:text-3xl sm:text-lg text-lg  font-medium">
            StoryGenius
          </h1>
        </div>
        {tokenExists && (
          <div className="lg:hidden">
            <CustomDrawer showCoinDetail={showCoinInformation} />
          </div>
        )}
        {tokenExists && (
          <div className="hidden lg:flex items-center gap-4">
            <PlanIndicator plan={planDetails?.item?.name || "Free Plan"} />

            <div
              onClick={showCoinInformation}
              className="group flex items-center gap-4 cursor-pointer transition-transform transform-gpu "
            >
              <img
                src={coinIcon}
                alt="coin"
                className=" group-hover:animate-bounce w-8"
              />
              <p className="font-bold">{userStore?.credit + "  "} StoryCoins</p>
            </div>

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <div className="flex items-center cursor-pointer">
                {userStore.userDetail?.user?.email_picture_url ? (
                  <Avatar src={userStore.userDetail?.user?.email_picture_url} />
                ) : (
                  <Avatar>{userStore.userDetail?.user?.name?.charAt(0)}</Avatar>
                )}
                <KeyboardArrowDownIcon />
              </div>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <ItemWrapper>
                <MenuItem onClick={() => onMenuItemsClick("/select-child")}>
                  Kids
                </MenuItem>
              </ItemWrapper>

              <ItemWrapper>
                <MenuItem
                  onClick={() => onMenuItemsClick("/select-child?tab=story")}
                >
                  Stories
                </MenuItem>
              </ItemWrapper>
              <ItemWrapper>
                <MenuItem onClick={() => onMenuItemsClick("/order-history")}>
                  My Account
                </MenuItem>
              </ItemWrapper>
              <ItemWrapper>
                <MenuItem onClick={() => onMenuItemsClick("/subscription")}>
                  Subscription
                </MenuItem>
              </ItemWrapper>

              <ItemWrapper>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </ItemWrapper>
              <ItemWrapper>
                <div className="relative -mb-3">
                  <PlanIndicatorTile
                    plan={
                      planDetails?.notes?.plan_key
                        ? planDetails?.notes?.plan_key?.includes("hobby")
                          ? "HOBBY"
                          : "PROFESSIONAL"
                        : "FREE"
                    }
                  />
                </div>
              </ItemWrapper>
            </Menu>
          </div>
        )}
        {!tokenExists && (
          <PrimaryButton
            className={"pr-16 pl-16"}
            style={{ margin: "0" }}
            onClick={() => navigate("/login")}
          >
            Get Started
          </PrimaryButton>
        )}
      </div>
    </>
  );
};

export default Header;
