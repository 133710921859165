import { Link } from "react-router-dom";
import Illustration404 from "../../assets/illustration404.webp";
const NotFoundPage = () => {
  return (
    <div className="flex-center content-center h-[90vh]">
      <div className="text-center mt-16">
        <h1 className="text-2xl md:text-4xl  font-bold text-[#36324A] mb-4">
          Oops! <br className="md:hidden" /> Nothing to show here
        </h1>
        <div className="max-w-[500px] mx-auto">
          <img src={Illustration404} alt="404" className=" mx-auto " />
        </div>
        <Link to="/select-child?tab=story">
          <button className='hover:bg-[#3CC3AA] hover:text-[#ffff] mt-8 class=" px-10 md:py-2 py-4 rounded-full text-[#3CC3AA] font-bold text-lg border-2 border-solid border-[#3CC3AA]'>
            Go to Home
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
