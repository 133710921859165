import {
  Header,
  SelectPageSection,
  StyleReferenceModel,
  UploadSection,
} from "../../components/StyleEditor";
import StylishedSection from "../../components/StyleEditor/StylisedSection";
import { styleEditorStore } from "./styleEditorStore";
import useSocketStore from "../../store/SocketStore/useSocketStore";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { useEffect } from "react";

const StyleEditor = () => {
  // eslint-disable-next-line react/prop-types
  const [showStyleReferenceModel, setShowStyleReferenceModel, reset] =
    styleEditorStore((state) => [
      state.showStyleReferenceModel,
      state.setShowStyleReferenceModel,
      state.reset,
    ]);
  const storyCallback = useAdvanceStoryStore((state) => state.storyCallback);
  useSocketStore([storyCallback]);
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);
  return (
    <div className="px-12 py-4">
      {" "}
      <Header />
      <div className="relative">
        <StyleReferenceModel
          isOpen={showStyleReferenceModel}
          onClose={() => {
            setShowStyleReferenceModel(false);
          }}
        />
        {/* <ToolSection /> */}
        <section className="grid lg:grid-cols-2 grid-cols-1 gap-5 ">
          {/* upload section */}
          <section>
            <UploadSection />
            <SelectPageSection />
          </section>
          {/* Preivew section */}
          <StylishedSection />
        </section>
      </div>
    </div>
  );
};

export default StyleEditor;
