/* eslint-disable react/prop-types */
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { extractContent } from "../../services/serviceFunction";
import { CustomCircularProgress } from "../Spinner/InputSpinner";

const filter = createFilterOptions({
  ignoreCase: true,
  startFrom: "start",
});

export const FontAutoCompleteDropdown = ({
  value,
  options = [],
  onSelect,
  label,
  exampleText,
  ...rest
}) => {
  return (
    <div className="w-[100%] relative">
      <Autocomplete
        value={value}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            onSelect(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            onSelect(newValue.inputValue);
          } else {
            onSelect(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => (
          <li {...props}>
            <div>
              <p>{option}</p>
              <div
                style={{
                  fontFamily: `${typeof option === "string" ? option : ""}`,
                }}
                className=" w-full"
              >
                <p className="text-sm">
                  {`${extractContent(exampleText).slice(0, 15)}${
                    exampleText.length > 15 ? "..." : ""
                  }`}
                </p>
              </div>
            </div>
          </li>
        )}
        sx={{ width: "98%" }}
        freeSolo
        renderInput={(params) => {
          return <TextField {...params} label={label} />;
        }}
        {...rest}
      />
      {rest?.loading ? (
        <div className="absolute bottom-0 right-0 p-2">
          <CustomCircularProgress />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
