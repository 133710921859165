/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import { useState } from "react";
import { PrimaryButton } from "../Button/PrimaryButton";
import { DialogWrapper } from "../Dialog";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { checkIfArrayIsEmpty } from "../../services/serviceFunction";
import { useNavigate } from "react-router-dom";
import { TransparentButton } from "../Button/TransparentButton";
import { FailedToLoadImage } from "../ImageNotFound";

const ChooseStarDialog = ({ childList, getStartedFn, show, onClose }) => {
  const [selectedChild, setSelectedChild] = useState(null);
  const navigate = useNavigate();
  const Heading = () => {
    return (
      <div className=" relative bg-[#3CC3AA] rounded-t-xl p-3 flex h-16 justify-center items-center ">
        <p className=" flex w-[100%] text-xl text-[#fff] justify-center text-center">
          <b>Choose Star of the story!</b>
        </p>
        <div className="absolute top-0 right-0 text-right float-end">
          <IconButton onClick={onClose}>
            <Close sx={{ color: "#ffff" }} />
          </IconButton>
        </div>
      </div>
    );
  };
  const ChildChip = ({ name, image_urls, id }) => {
    return (
      <div className="flex flex-col items-center cursor-pointer mt-4  ">
        <div
          onClick={() => {
            setSelectedChild(id);
          }}
          onDoubleClick={() => {
            getStartedFn(id);
          }}
          className={`${
            id === selectedChild
              ? "bg-[#3CC3AA] hover:bg-[#3CC3AA]"
              : "hover:bg-[#E2F6F2]"
          } w-[87px] h-[87px] md:w-[180px] md:h-[180px] rounded-full  `}
        >
          {!image_urls[0] ? (
            <FailedToLoadImage />
          ) : (
            <img
              src={image_urls[0]}
              alt="child"
              className="rounded-full w-full h-full p-2"
            />
          )}
        </div>
        <p className="pt-1 font-bold">{name}</p>
      </div>
    );
  };

  return (
    <DialogWrapper isOpen={show} onClose={onClose} header={<Heading />}>
      <div className="flex justify-center flex-col min-h-[300px]">
        {checkIfArrayIsEmpty(childList) ? (
          <div className="grid items-center p-16 ">
            <p className="text-center text-lg text-[#3CC3AA] mt-8">
              You have no kid added yet!
            </p>
            <PrimaryButton
              onClick={() => {
                navigate("/add-child");
              }}
            >
              + Add Kid
            </PrimaryButton>
          </div>
        ) : (
          <>
            {" "}
            <div className="max-h-[70vh] overflow-scroll   items-center">
              <div className="grid grid-cols-2 px-8 py-2 ">
                {childList.map((child, index) => (
                  <ChildChip key={index} {...child} />
                ))}
              </div>
              <div className="grid items-center">
                <TransparentButton
                  className="m-auto"
                  onClick={() => {
                    navigate("/add-child");
                  }}
                >
                  + Add Kid
                </TransparentButton>
              </div>
            </div>
            <PrimaryButton
              disabled={!selectedChild}
              style={{ marginTop: "5px" }}
              className={`mx-auto mb-8 mt-2`}
              onClick={() => getStartedFn(selectedChild)}
            >
              Let's Get Started!
            </PrimaryButton>
          </>
        )}
      </div>
    </DialogWrapper>
  );
};

export default ChooseStarDialog;
