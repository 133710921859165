import { Title } from "../../components/molecules/header";
import { SubTitle } from "../../components/molecules/subtitle";
import Subscriptions from "../../components/Subscription/Subcriptions";
import { subscriptionStore } from "../../store/SubscriptionStore";

const Subscription = () => {
  const [tenure, toggleTenure] = subscriptionStore((state) => [
    state.tenure,
    state.toggleTenure,
  ]);
  const changeSubscriptionPlan = () => {
    toggleTenure();
  };
  return (
    <div className="bg-[url('./assets/order_success_bg.svg')] bg-cover">
      <div className="m-auto container items-center text-center mt-12 lg:mt-24">
        <Title>Subscription Plans</Title>

        <SubTitle className="flex mt-12 lg:mt-18 font-[600] justify-center items-center">
          <p className="font-[600] pr-2">Monthly</p>
          <input
            type="checkbox"
            checked={tenure === "yearly"}
            className="sr-only peer cursor-pointer"
            onChange={changeSubscriptionPlan}
          />
          <div
            onClick={changeSubscriptionPlan}
            className="cursor-pointer relative w-11 h-6 bg-slate-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-[#3cc3aa] dark:peer-focus:ring-[#3cc3aa] dark:bg-[#3cc3aa] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-[#3cc3aa] after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-[#3cc3aa] peer-checked:bg-[#3cc3aa]"
          ></div>
          <p className="font-[600] pl-2">Yearly</p>
        </SubTitle>
        <section className="mt-12 max-w-[1200px] m-auto">
          <Subscriptions />
        </section>
      </div>
    </div>
  );
};

export default Subscription;
