/* eslint-disable react/prop-types */
import coinIcon from "../../assets/coin.webp";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import historyStore from "../../store/HistoryStore";
import { useEffect } from "react";
import { convertDate } from "../../services/serviceFunction";
import { HistoryLoader } from "./HistoryLoader";
import CustomPagination from "../../pages/SelectChild/pagination";
import StoryEmptyImg from "../../assets/storyEmpty.svg";
import { SubTitle } from "../molecules/subtitle";
import { Tooltip } from "@mui/material";

const StoryCoins = () => {
  const [getStoryCoinsHistoryList, storyCoinsHistory, getFilter, setFilter] =
    historyStore((state) => [
      state.getStoryCoinsHistoryList,
      state.storyCoinsHistory,
      state.getFilter,
      state.setFilter,
    ]);

  const filters = getFilter();
  const page = filters.page;

  useEffect(() => {
    getStoryCoinsHistoryList(filters);
  }, [getStoryCoinsHistoryList, filters]);
  const ListTile = ({ type, transactionAmount, date }) => {
    const transactionType = transactionAmount > 0 ? "credit" : "debit";
    return (
      <section className="py-4 px-4  md:p-8 md:px-8 m-1 my-2 border border-[#e1e1e1] rounded-lg ">
        <div className="hidden md:grid grid-cols-5 items-center font-[600] text-[18px]">
          <Tooltip title={"Transaction Date"} placement="top" arrow>
            <p>{convertDate(date)}</p>
          </Tooltip>
          <Tooltip title={"Transaction Type"} placement="top" arrow>
            <div className="flex justify-center items-center col-span-2">
              <div className="bg-[#3cc3aa] bg-opacity-10 px-8 py-4 rounded-full">
                {type}
              </div>
            </div>
          </Tooltip>
          <div className="flex items-center">
            <img src={coinIcon} alt="coinIcon" className="h-10 w-10 " />
            <p className="p-2">{transactionAmount} StoryCoins</p>
            {transactionType === "credit" ? (
              <p className="text-green-500 p-2">
                <ArrowDropUpOutlinedIcon fontSize="large" />
              </p>
            ) : (
              <p className="text-red-500">
                <ArrowDropDownOutlinedIcon fontSize="large" />
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 md:hidden">
          <p className=" col-span-3">{convertDate(date)}</p>
          <SubTitle className="text-start flex items-center">{type}</SubTitle>
          <div className="justify-items-end col-span-2">
            <div className="flex justify-end">
              <img src={coinIcon} alt="coinIcon" className="h-10 w-10 " />
              <p className="p-2">{transactionAmount} StoryCoins</p>
              {transactionType === "credit" ? (
                <p className="text-green-500 p-2">
                  <ArrowDropUpOutlinedIcon fontSize="large" />
                </p>
              ) : (
                <p className="text-red-500">
                  <ArrowDropDownOutlinedIcon fontSize="large" />
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };
  if (
    !storyCoinsHistory.loading &&
    storyCoinsHistory.data?.transactions?.length === 0
  ) {
    return (
      <div className="flex flex-col items-center justify-center">
        <img src={StoryEmptyImg} alt="empty story" />
        <p className="text-md my-4">
          You currently have no Transaction History
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="grid grid-cols-5 items-center font-[600] text-[18px]">
        <p></p>
        <p className="col-span-2"></p>
        <p className="self-end">Transaction</p>
      </div>
      {storyCoinsHistory.loading ? (
        <HistoryLoader />
      ) : (
        <>
          {storyCoinsHistory.data?.transactions?.map((transaction) => (
            <ListTile
              key={transaction.id}
              type={transaction?.transaction_type}
              transactionAmount={transaction?.credit}
              date={transaction?.created_date}
            />
          ))}
          <CustomPagination
            currentPage={page}
            totalPages={storyCoinsHistory.data?.pages}
            onPageChange={(e, page) => {
              setFilter({ ...filters, page });
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </>
      )}
    </div>
  );
};
export default StoryCoins;
