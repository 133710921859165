/* eslint-disable react/prop-types */
export const Title = ({ children, className, ...rest }) => {
  return (
    <h1
      className={` text-2xl md:text-4xl  text-[#36324A] font-bold quicksand-text ${
        className ? className : ""
      }`}
      {...rest}
    >
      {children}
    </h1>
  );
};
