import { useNavigate } from "react-router-dom";
import useUserDetailsStore from "../../../store/UserStore";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { TransparentButton } from "../../Button/TransparentButton";
import { DialogWrapper } from "../../Dialog";
import coinPng from "../../../assets/coin.webp";
// eslint-disable-next-line react/prop-types
const CoinInformationDialog = ({ open, onClose }) => {
  const { credit } = useUserDetailsStore();
  const navigate = useNavigate();
  return (
    <DialogWrapper
      isOpen={open}
      onClose={() => {
        onClose();
      }}
    >
      <div className="flex flex-col items-center p-4">
        <p className=" flex flex-wrap justify-center align-center item-center text-center">
          You Currently Have :
          <span className="flex items-center">
            <img className="h-6 w-6" src={coinPng} alt="coin image" />
            <b>{credit} StoryCoins</b>
          </span>
        </p>
        <br />
        <p className="text-center">
          {" "}
          This means you can generate <strong>{credit}</strong> illustrations
        </p>
        <p className="text-center">
          {" "}
          If you need more, you can topup your StoryCoins
        </p>
      </div>
      <div className="flex items-center justify-between w-full md:w-[70%] m-auto p-4">
        <TransparentButton onClick={() => onClose()}>Cancel</TransparentButton>
        <PrimaryButton
          onClick={() => {
            navigate("/credit-packs");
            onClose();
          }}
        >
          Buy StoryCoins
        </PrimaryButton>
      </div>
    </DialogWrapper>
  );
};
export default CoinInformationDialog;
