import { useStoryCoinHelper } from "./storyCoinHook";
import CoinShortPopUp from "./Dialog/CoinShortDialog";
import { CoinDetailDialog } from "./Dialog/CostDetailDialog";

const StoryCoinClient = () => {
  const { modelType, setModelType } = useStoryCoinHelper();

  const renderView = () => {
    if (modelType === "short") {
      return <CoinShortPopUp open={true} onClose={() => setModelType("")} />;
    }
    if (modelType === "detail") {
      return <CoinDetailDialog open={true} onClose={() => setModelType("")} />;
    }
    return null;
  };
  return renderView();
};

export default StoryCoinClient;
