import { useSearchParams } from "react-router-dom";
import { postAppSumoSubscription } from "../ApiServices/Subscription";
import { useState } from "react";
import { subscriptionStore } from "../store/SubscriptionStore";
import useUserDetailsStore from "../store/UserStore";

const useAppSomo = () => {
  const [params, setSearchParams] = useSearchParams();

  const appSumoPromo = params.get("appsumo_code");
  const hasAppSumoPromo = Boolean(appSumoPromo);
  const [subscriptionUpdateMessage, setSubscriptionUpdateMessage] =
    useState(null);
  const [reedeemSuccess, setReedeemSuccess] = useState(false);

  const createAppSumoSubscription = async () => {
    if (hasAppSumoPromo) {
      // Call the API to create the subscription
      try {
        const response = await postAppSumoSubscription(appSumoPromo);
        console.log("appsumoresponse", response);
        //update the subscription details
        subscriptionStore.getState().fetchActiveSubscription();
        //update the user details
        useUserDetailsStore.getState().fetchUserDetail();
        setReedeemSuccess(true);
      } catch (error) {
        params.delete("appsumo_code");
        setSearchParams(params);
        if (error.response?.data?.["status-code"] === 1703) {
          setSubscriptionUpdateMessage(
            error.response?.data?.["status-message"]
          );
        }
      }
    }
  };
  return {
    createAppSumoSubscription,
    appSumoPromo,
    hasAppSumoPromo,
    subscriptionUpdateMessage,
    setSubscriptionUpdateMessage,
    reedeemSuccess,
    setReedeemSuccess,
  };
};
export default useAppSomo;
