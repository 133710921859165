/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import { Form, Formik } from "formik";
import { PrimaryButton } from "../../Button/PrimaryButton";
import validationSchema from "./validationSchema";
import { printOrderRequest } from "../../../ApiServices/Story/storyApis";
import { CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import { DetailsForm } from "./DetailForm";
import CheckBoxWithLabel from "../../molecules/checkbox";
import { useState } from "react";
import useUserDetailsStore from "../../../store/UserStore";
import { logSentryMessage } from "../../../ApiServices/handleErrors";

const initialAddress = {
  recipient_name: "",
  country: "",
  country_code: "",
  city: "",
  postcode: "",
  state: "",
  state_code: "",
  phone: "",
  street1: "",
  street2: "",
};

export const PrintDetailsForm = ({ onProceed, storyId, product }) => {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  //useFormik hook and pass the initial with name, country, address line 1, addressline 2, state,city, postal Code and phone number
  const [isShippingAvailable, setIsShippingAvailable] = useState(true);
  const [userDetail, fetchUserDetail] = useUserDetailsStore((state) => [
    state.userDetail,
    state.fetchUserDetail,
  ]);
  let initialBillingAddress = initialAddress;
  let initialShippingAddress = initialAddress;
  let update_user_address = false;
  if (userDetail?.user?.address.billing && userDetail?.user?.address.shipping) {
    initialBillingAddress = userDetail.user.address.billing;
    initialShippingAddress = userDetail.user.address.shipping;
    update_user_address = true;
  }
  return (
    <Formik
      initialValues={{
        story: storyId,
        product: product,
        is_billing_same_as_shipping:
          userDetail?.user?.address?.is_billing_same_as_shipping ?? true,
        update_user_address: update_user_address,
        quantity: 1,
        shipping_address: initialShippingAddress,
        billing_address: initialBillingAddress,
      }}
      validateOnMount={true}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        printOrderRequest(values)
          .then((response) => {
            setSubmitting(false);
            if (response.data?.["status-code"] === 1) {
              //set response data data to the url params
              if (response.data.data.print_order.shipping_options) {
                setSearchParams({
                  orderId: response.data.data.print_order.id,
                  productId: product,
                  currency: searchParams.get("currency") ?? "USD",
                });
                fetchUserDetail();
                onProceed();
              } else {
                setIsShippingAvailable(false);
                toast.error("No shipping options available");
                logSentryMessage("No shipping options available");
              }
            } else {
              toast.error(response.data?.["status-message"]);
              logSentryMessage(response.data?.["status-message"]);
            }
          })
          .catch((error) => {
            setIsShippingAvailable(true);
            toast.error(error.message);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        validateForm,
        setFieldValue,
      }) => (
        <Form className="flex flex-col items-start w-[100%] py-8">
          <DetailsForm parent={"shipping_address"} />
          <CheckBoxWithLabel
            checked={values?.is_billing_same_as_shipping}
            label="Billing address same as shipping address"
            onChange={async (_, checked) => {
              await setFieldValue("is_billing_same_as_shipping", checked);
              validateForm();
            }}
          />
          {!values?.is_billing_same_as_shipping && (
            <DetailsForm parent={"billing_address"} />
          )}
          <CheckBoxWithLabel
            checked={values?.update_user_address}
            label="Save my details for faster checkout next time"
            onChange={async (_, checked) => {
              await setFieldValue("update_user_address", checked);
              validateForm();
            }}
          />

          {!isShippingAvailable && (
            <div className="my-4 rounded-2xl border-2 border-[#FF0000] bg-[#FFEAEA]  px-4 py-1 flex items-center mx-auto">
              <p className="text-[#FF0000] font-[600]   max-w-[100%]  text-[14px] p-2">
                Sorry! We don't ship to your location currently!
              </p>
            </div>
          )}
          <div className="w-full flex justify-center">
            <PrimaryButton
              disabled={isSubmitting}
              handleButtonClick={handleSubmit}
              className={"max-w-[400px] w-[100%] "}
            >
              {isSubmitting ? (
                <CircularProgress sx={{ color: "#3CC3AA" }} size={24} />
              ) : (
                "Proceed"
              )}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
