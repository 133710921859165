export const FontOption = [
  "Abril Fatface",
  "Alfa Slab One",
  "Anonymous Pro",
  "Azeret Mono",
  "Bebas Neue",
  "Berkshire Swash",
  "Caveat",
  "Cedarville Cursive",
  "Cinzel",
  "Comfortaa",
  "Cormorant",
  "Courier Prime",
  "Dancing Script",
  "Fira Sans",
  "Homemade Apple",
  "IBM Plex Mono",
  "Inconsolata",
  "Indie Flower",
  "JetBrains Mono",
  "La Belle Aurore",
  "League Script",
  "Libre Baskerville",
  "Lobster",
  "Lora",
  "Merriweather Sans",
  "Merriweather",
  "Montserrat",
  "Noto Sans Mono",
  "Noto Serif",
  "Old Standard TT",
  "Open Sans",
  "Oswald",
  "PT Serif",
  "Pacifico",
  "Playfair Display SC",
  "Playfair Display",
  "Poppins",
  "Proza Libre",
  "Quicksand",
  "Raleway",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Roboto",
  "Shadows Into Light",
  "Slabo 13px",
  "Source Code Pro",
  "Source Sans 3",
  "Space Mono",
  "Teko",
  "Potta One",
  "Work Sans",
  "Dela Gothic One",
  "DotGothic16",
  "Monomaniac One",
  "Noto Sans JP",
  "Noto Serif JP",
  "Rock 3D",
  "Shippori Mincho",
  "Train One",
  "Zen Antique",

  // Link 2 (Devanagari Fonts)
  "Amita",
  "Dekko",
  "Gajraj One",
  "Gotu",
  "Halant",
  "IBM Plex Sans Devanagari",
  "Kurale",
  "Ranga",
  "Rozha One",
  "Yatra One",

  // Link 5 (Cyrillic Extended Fonts)
  "Black Ops One",
  "LXGW WenKai Mono TC",
  "Rubik Bubbles",
  "Rubik Moonrocks",
];
export const FontSizeOption = ["20", "24", "30", "42", "48", "68", "80"];

export const shadowOption = [
  { color: "#000000", gradient: false },
  { color: "#dfdfdf", gradient: false },
  {
    color:
      "conic-gradient(from 180deg at 50% 50%, #FF0000 0deg, #FFE600 56.25deg, #05FF00 118.12deg, #00FFFF 180deg, #001AFF 245.62deg, #DB00FF 307.5deg, #FF0000 360deg)",
    gradient: true,
  },
];
export const colorOptions = [
  { color: "#EB5757", gradient: false },
  { color: "#F2994A", gradient: false },
  { color: "#F2C94C", gradient: false },
  { color: "#27AE60", gradient: false },
  { color: "#2ab5e8", gradient: false },
  { color: "#9B51E0", gradient: false },
  {
    color:
      "conic-gradient(from 180deg at 50% 50%, #FF0000 0deg, #FFE600 56.25deg, #05FF00 118.12deg, #00FFFF 180deg, #001AFF 245.62deg, #DB00FF 307.5deg, #FF0000 360deg)",
    gradient: true,
  },
];

export const COLOR_PICKER = {
  TEXT_COLOR: "text_color",
  BACKGROUND_COLOR: "background",
  SHADOW_COLOR: "shadow_color",
};
