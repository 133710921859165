import { useEffect, useState } from "react";

const useCheckDevice = () => {
  const [isMobile, setIsMobile] = useState(false);
  const checkIsMobile = () => {
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    setIsMobile(isMobile);
  };
  useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return { isMobile };
};

export default useCheckDevice;
