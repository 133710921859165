/* eslint-disable react/no-unescaped-entities */
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../../ApiServices/apis";
import { logSentryMessage } from "../../ApiServices/handleErrors";

import api from "../../composables/app";
import useUserDetailsStore from "../../store/UserStore";
import RenderForm from "./AddChildForm";
import { validationSchema } from "./validationSchema";

export const notAllowedHosts = ["localhost", "127.0.0.1"];
export const host = window.location.hostname;

const AddChild = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [trigger, setTrigger] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [imageId, setImageId] = useState("");
  const [kidsData, setKidsData] = useState(null);
  const navigate = useNavigate();
  const canAddHuman = useUserDetailsStore((state) => state.canAddHuman);

  const shouldBeAbleToAddChild = canAddHuman ?? true;

  const questions = [
    { text: "", type: "text", key: "name" },
    {
      text: "",
      type: "number",
      key: "age",
    },
    {
      text: "",
      type: "selectGender",
      options: ["male", "female"],
      key: "gender",
    },
    {
      type: "dropdown",
      options: ["English", "Spanish", "French"],
      key: "language",
    },
    { type: "file", key: "file" },
    {
      type: "visual_description",
      key: "visual_description_json",
    },

    { type: "privacyPolicy", key: "consent" },
    { type: "done", key: "done" },
  ];
  const initialValues = {
    name: kidsData?.name ?? "",
    age: kidsData?.age ?? "",
    gender: kidsData?.gender ?? "",
    language: kidsData?.language ?? ("" || "English"),
    file:
      kidsData?.image_urls && kidsData.image_urls.length > 0
        ? kidsData.image_urls[0]
        : "",
    consent: false,
    uploadFile: true,
    visual_description_json: {
      age: kidsData?.age ?? "",
      gender: kidsData?.gender ?? "",
      eye_color: kidsData?.visual_description_json?.eye_color ?? "",
      eye_shape: kidsData?.visual_description_json?.eye_shape ?? "",
      skin_color: kidsData?.visual_description_json?.skin_color ?? "",
      face_shape: kidsData?.visual_description_json?.face_shape ?? "",
      hair_color: kidsData?.visual_description_json?.hair_color ?? "",
      hair_length: kidsData?.visual_description_json?.hair_length ?? "",
      hair_style: kidsData?.visual_description_json?.hairstyle ?? "",
      ethnicity: kidsData?.visual_description_json?.ethnicity ?? "",
      physique: kidsData?.visual_description_json?.physique ?? "",
      distinctive_features:
        kidsData?.visual_description_json?.distinctive_features ?? "",
    },
  };

  useEffect(() => {
    if (!id && !shouldBeAbleToAddChild) {
      toast(
        "You have hit the limit for adding child. Please purchase a story/credits pack to increase your limit."
      );
      navigate("/select-child?tab=kid");
    }
  }, [shouldBeAbleToAddChild, id]);

  const ImageData = async (human) => {
    if (id) {
      try {
        const url = `${API.story.getChildDetails}/${human}`;
        const response = await api.get(url);
        setKidsData(response?.data?.data?.human);
        setImageUrl(response?.data?.data?.human?.image_urls?.[0]);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    }
  };
  useEffect(() => {
    if (id) ImageData(id);
  }, [id]);

  const handleGtag = (action) => {
    if (!notAllowedHosts.includes(host)) {
      if (typeof window.gtag === "function") {
        window.gtag("event", "add_child", {
          step: action,
        });
      } else {
        console.error("Google Analytics is not loaded or initialized.");
      }
    }
  };

  return (
    <div className="h-[90.9vh] overflow-hidden">
      <div className="add-child-background w-full h-full ">
        <div className=" relative max-w-6xl mx-auto md:py-6 sm:py-12 py-12 h-full  w-[100%] flex flex-col justify-center md:px-8 sm:px-4 px-4  gap-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
            validateOnChange={true}
            onSubmit={async (values, { setFieldError, setSubmitting }) => {
              try {
                const FormData = {
                  name: values.name,
                  age: values.age,
                  gender: values.gender,
                  language: values.language,
                  consent: values.consent,
                  image_ids: [imageId || kidsData?.image_ids?.[0]],
                };
                if (!values.uploadFile) {
                  delete FormData.image_ids;
                  FormData.visual_description_json = {
                    ...values.visual_description_json,
                    age: values.age,
                    gender: values.gender,
                    hairstyle: values.visual_description_json.hair_style,
                  };
                }
                setSubmitting(true);
                if (id) {
                  const response = await api.put(
                    `${API.story.addChild}/${id}`,
                    FormData
                  );
                  if (response?.data?.["status-code"] === 1) {
                    setKidsData(response?.data?.data?.human);
                    ImageData(id);
                    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
                  } else {
                    throw new Error(response?.data?.["status-message"]);
                  }
                } else {
                  const response = await api.post(
                    `${API.story.addChild}`,
                    FormData
                  );
                  if (response?.data?.["status-code"] === 1) {
                    setKidsData(response?.data?.data?.human);
                    ImageData(response?.data?.data?.human?.id);
                    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
                  } else {
                    throw new Error(response?.data?.["status-message"]);
                  }
                }
              } catch (error) {
                toast.error(
                  "Sorry! Failed to update child info. Please try again."
                );
                logSentryMessage("Failed to update child info.");
                setFieldError(
                  "consent",
                  error?.response?.data?.["status-message"]
                );
              } finally {
                setSubmitting(false);
              }
            }}
          >
            <RenderForm
              questions={questions}
              currentQuestion={currentQuestion}
              question={questions[currentQuestion - 1]}
              kidsData={kidsData}
              trigger={trigger}
              setTrigger={setTrigger}
              setCurrentQuestion={setCurrentQuestion}
              handleGtag={handleGtag}
              setImageUrl={setImageUrl}
              imageUrl={imageUrl}
              setImageId={setImageId}
            />
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddChild;
