/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import useStore from "../UseStore";
import api from "../../composables/app";

const ModalCompV2 = ({ additionalTask }) => {
  const [couponData, setCouponData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currency, setoffers] = useStore((state) => [
    state.currency,
    state.setoffers,
  ]);

  const fetchCouponData = async () => {
    try {
      const currencyCode = currency.split(" ")[0];
      const response = await api.get(
        `/story/v1/promo_code/?currency=${currencyCode}`
      );
      const data = response.data;
      const promoCodes = data?.data?.promo_codes || [];
      setCouponData(promoCodes);
    } catch (error) {
      console.error("Error fetching coupon data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCouponData();
  }, [currency]);

  const handleApplyCoupon = (couponCode) => {
    setoffers(couponCode);
    additionalTask && additionalTask(couponCode);
  };

  return (
    <>
      <div className="p-2 border border-2 rounded-2xl">
        {isLoading ? (
          <p>Loading...</p>
        ) : couponData.length === 0 ? (
          <p>No offers available</p>
        ) : (
          couponData.map((coupon) => (
            <div key={coupon.code} className="flex flex-col gap-3 mt-4 p-4">
              <div className="flex flex-row justify-between">
                <div className="flex flex-col gap-2">
                  <h1 className="text-base font-bold">
                    Get {coupon.discount}% off on your purchase
                  </h1>
                </div>

                <button
                  className="text-lg font-bold text-[#3CC3AA] border border-[#3CC3AA] p-2 rounded-md"
                  onClick={() => handleApplyCoupon(coupon.code)}
                >
                  {coupon.code}
                </button>
              </div>
              <hr />
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default ModalCompV2;
