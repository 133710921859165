import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorMessage, Field, useFormikContext } from "formik";
const useStyles = makeStyles({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D4D7E3", // default border color
        borderRadius: "12px",
        borderWidth: "2px",
        padding: "20px",
      },
      "&:hover fieldset": {
        borderColor: "#3CC3AA", // hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3CC3AA", // focus border color
        boxShadow: "0 0 0 2px rgba(60, 195, 170, 0.5)", // focus ring color
      },
      "&.Mui-error fieldset": {
        borderColor: "#EF4444", // error border color
      },
      "&.Mui-error.Mui-focused fieldset": {
        borderColor: "#EF4444", // error focus border color
        boxShadow: "0 0 0 2px rgba(239, 68, 68, 0.5)", // error focus ring color
      },
      //input background
      backgroundColor: "#F7F8FF",
      //input background on error
      "&.Mui-error": {
        backgroundColor: "#FFEEEE",
      },
    },
  },
});

// component hould be render inside formik provider
/* eslint-disable react/prop-types */
const FormInput = ({ label, ...props }) => {
  const classes = useStyles();
  const formik = useFormikContext();
  return (
    <div className="flex flex-col mr-4 mb-4 ">
      <label
        className="m-1 text-md font-[500] text-[#0C1421]"
        htmlFor={props.name}
      >
        {label}
      </label>
      <Field name={props.name}>
        {({ field, meta }) => {
          const hasError = meta.touched ? meta.error : false;
          if (props.type === "textarea") {
            return (
              <textarea
                rows="10"
                placeholder={props.placeholder || label}
                className={`${
                  hasError
                    ? " border-[#f82a2a] bg-red-50 bg-opacity-40 focus:ring-red-400 focus:ring-2 focus:ring-opacity-50"
                    : "border-[#D4D7E3] focus:ring-[#3CC3AA] focus:ring-2 bg-[#F7F8FF]"
                } border rounded-2xl p-4 mt-1 m-4 mb-0 w-[90%] focus:outline-none `}
                {...props}
                {...field}
              />
            );
          } else {
            return (
              <TextField
                placeholder={props.placeholder || label}
                className={classes.textField}
                error={hasError}
                onChange={(value) => {
                  formik?.setFieldValue(props.name, value.target.value);
                }}
                value={formik?.values[props.name] || ""}
                {...props}
                {...field}
              />
            );
          }
        }}
      </Field>
      <ErrorMessage
        name={props.name}
        component="div"
        className="text-red-500"
      />
    </div>
  );
};
export default FormInput;
