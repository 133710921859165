import { TextField } from "@mui/material";
import { CustomCircularProgress } from "../Spinner/InputSpinner";

export const TextFieldWithSpinner = ({ ...rest }) => {
  const maxLength = rest?.maxLength || 1000;
  const value = rest?.value ?? "";
  const maxLengthReached = value?.length >= maxLength;
  return (
    <div className="relative w-full ">
      <TextField
        {...rest}
        value={rest.value}
        onChange={(e) => {
          if (maxLength) {
            let event = e;
            if (e.target.value.length > maxLength) {
              event = { ...e };
              event.target.value = e.target.value.slice(0, maxLength);
            }
            if (value !== event.target.value) rest.onChange(event);
          } else {
            rest.onChange(e);
          }
        }}
      />
      {rest?.loading ? (
        <div className="absolute bottom-0 right-0 p-2">
          <CustomCircularProgress />
        </div>
      ) : (
        ""
      )}
      {maxLength && (
        <div className="absolute -bottom-[24px] -right-2 p-2">
          <p
            className={`text-sm  ${
              maxLengthReached ? "text-red-500" : "text-slate-500"
            }`}
          >
            {value.length + `/${maxLength}`}
          </p>
        </div>
      )}
    </div>
  );
};
