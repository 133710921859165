import React, { useEffect, useState } from "react";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import LeftDrawer from "../LeftDrawer";
import { SubTitle } from "../molecules/subtitle";
import PageTile from "./PageTile";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSearchParams } from "react-router-dom";
import useUserDetailsStore from "../../store/UserStore";
import toast from "react-hot-toast";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "#3cc3aa50" : "",
  opacity: isDragging ? "0.5" : "1",
  transform: isDragging ? "rotate(5deg)" : "rotate(0deg)",
  // styles we need to apply on draggables
  ...draggableStyle,
});

const PageMangement = () => {
  const [
    storyDetails,
    page,
    activeBookview,
    handlePageAdd,
    setDeletePageId,
    setPage,
    reorder,
    pageManagementFailed,
  ] = useAdvanceStoryStore((state) => [
    state.story,
    state.page,
    state.activeBookview,
    state.handlePageAdd,
    state.setDeletePageId,
    state.setPage,
    state.reorder,
    state.pageManagementFailed,
  ]);

  const [credit, fixedLength] = useUserDetailsStore((state) => [state.credit, state.fixedLength]);
  const [_, setSearchParams] = useSearchParams();

  const [tempStoryDetails, setTempStoryDetails] = useState(storyDetails.pages);

  useEffect(() => {
    setTempStoryDetails(storyDetails.pages);
  }, [storyDetails?.pages]);

  useEffect(() => {
    if (pageManagementFailed) {
      setTempStoryDetails(storyDetails.pages);
    }
  }, [pageManagementFailed]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      tempStoryDetails,
      result.source.index,
      result.destination.index
    );

    setTempStoryDetails(items);
  };

  const isSpiltView = activeBookview === "split";
  const alterPagingationView = (index) => {
    const currentPage = !isSpiltView
      ? index
      : `${index * 2 - 1}-${Number(index) * 2}`;

    return `${currentPage}`;
  };

  return (
    <LeftDrawer>
      <div className="h-full w-full overflow-y-scroll">
        <div className="sticky top-0 py-4 w-full bg-white z-30">
          <SubTitle>Pages</SubTitle>
        </div>
        <br />
        <div className="relative">
          <PageTile
            pageDetails={tempStoryDetails?.[0]}
            isSelected={0 === page}
            isRemovable={false}
            isCoverPage={true}
            story={storyDetails}
            onAdd={() => {
              handlePageAdd(tempStoryDetails?.[0].id);
            }}
            onClick={() => {
              setPage(0);
              setSearchParams({ pageId: tempStoryDetails?.[0].id });
            }}
          />
          <div className="absolute -top-1 right-12 ring-white ring-2 border border-1 font-[500] border-[#3cc3aa] bg-[#3cc3aa] text-white w-min p-1 px-4 rounded-full">
            Cover
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {tempStoryDetails
                  ?.slice(1, tempStoryDetails?.length)
                  .map((pageDetail, index) => (
                    <Draggable
                      key={pageDetail.id}
                      draggableId={pageDetail.id}
                      index={index}
                      isDragDisabled={credit < 40 && fixedLength}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="relative"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <PageTile
                            onClick={() => {
                              setPage(index + 1);
                              setSearchParams({ pageId: pageDetail.id });
                            }}
                            key={index}
                            story={storyDetails}
                            pageDetails={pageDetail}
                            isSelected={index + 1 === page}
                            isDragging={snapshot.isDragging}
                            isRemovable={true}
                            isCoverPage={false}
                            onAdd={() => {
                              if (fixedLength && credit < 40) {
                                toast("Your plan does not allow you to add more pages")
                                return
                              }
                              handlePageAdd(pageDetail.id);
                            }}
                            onRemove={() => {
                              if (fixedLength && credit < 40) {
                                toast("Your plan does not allow you to delete pages")
                                return
                              }
                              setDeletePageId(pageDetail.id);
                            }}
                          />
                          {!snapshot.isDragging && (
                            <p className="absolute text-[14px] top-14 left-4 font-[500]">
                              {alterPagingationView(index + 1)}
                            </p>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </LeftDrawer >
  );
};
export default PageMangement;
