/* eslint-disable react/prop-types */
import { Form, Formik } from "formik";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { TransparentButton } from "../../Button/TransparentButton";
import * as Yup from "yup";
import TextEditor from "../../TextEditor";
import { submitDedicationForm } from "../../../ApiServices/Story/storyApis";
import { useParams } from "react-router-dom";
import useAdvanceStoryStore from "../../../store/AdvanceStoryStore";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { logSentryMessage } from "../../../ApiServices/handleErrors";

export const DedicationForm = ({ onCancel }) => {
  const { storyId } = useParams();
  const story = useAdvanceStoryStore((state) => state.story);
  const childName = story?.characters[0]?.name;
  return (
    <Formik
      validateOnMount={true}
      validationSchema={Yup.object().shape({
        dedication_message: Yup.string().required(""),
      })}
      initialValues={{
        dedication_message: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        values.dedicated_to = childName;
        setSubmitting(true);
        submitDedicationForm(storyId, values)
          .then((res) => {
            setSubmitting(false);
            if (res?.data?.["status-code"] === 1) {
              toast.success(res.data["status-message"]);
              return onCancel();
            }
            throw new Error({
              message: "Error adding dedication message. Please try again.",
            });
          })
          .catch((err) => {
            setSubmitting(false);
            toast.error(err.message);
            logSentryMessage(err.message);
          });
      }}
    >
      {({ handleSubmit, isValid, isSubmitting, values, setFieldValue }) => (
        <Form className="flex flex-col items-center max-w-[500px] w-[100%] py-8">
          <div className="grid grid-cols-1 w-[100%]">
            <TextEditor
              height="200px"
              title="Personalized Message"
              placeholder={`Ex:
To ${childName},
Hope you go on many adventures like this.
Love,
Mom and Dad`}
              onChange={(value) => {
                setFieldValue("dedication_message", value);
              }}
              value={values?.dedication_message}
            />
          </div>
          <div className="flex w-full">
            <TransparentButton
              className={
                "flex flex-grow max-w-[201px] m-2 items-center justify-center text-center"
              }
              handleButtonClick={onCancel}
            >
              Skip
            </TransparentButton>

            <PrimaryButton
              disabled={!isValid || isSubmitting}
              className={
                "flex flex-grow m-2 ml-8 max-w-[201px] items-center justify-center text-center"
              }
              handleButtonClick={handleSubmit}
            >
              {isSubmitting ? (
                <CircularProgress size={24} style={{ color: "#3cc3aa" }} />
              ) : (
                "Proceed"
              )}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
