/* eslint-disable react/prop-types */
import { EnterOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import { Fade } from "react-awesome-reveal";
import CustomButton from "../CustomButton";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CustomReveal = (props) => {
  const navigate = useNavigate();
  const handleRefresh = () => {
    navigate("/select-child?tab=story");
  };

  useEffect(() => {
    if (
      props.questions?.[props.currentQuestion - 1]?.type === "file" &&
      document.getElementById("next-button")
    ) {
      document.getElementById("next-button").focus();
    }
  }, [props.currentQuestion, document.getElementById("next-button")]);
  return (
    <div onKeyDown={props.onKeyDown} className="relative w-full h-full">
      <Fade
        key={props.id}
        in={props.in}
        triggerOnce
        direction={props.direction}
        cascade
        className="h-full "
      >
        <div className={`flex flex-col h-full  ${props.gaps}`}>
          <div
            className="text-white md:text-lg sm:text-base text-base font-semibold cursor-pointer"
            onClick={props.handlePrevious}
          >
            {props.back && <h3>&lt; {props.back}</h3>}
          </div>
          <div className=" overflow-x-hidden">
            <h1 className="md:text-5xl mb-8 md:w-full sm:w-full w-full sm:text-[24px] text-[24px] font-medium text-white">
              {props.headingtext}
            </h1>
            <div className="">{props.children}</div>
          </div>

          <div className="mt-2 w-full absolute bottom-[2%] flex flex-col bg-[#3cc3aa] ">
            {props.currentQuestion < props.questions.length ? (
              <div className="flex gap-4 items-start bg-[#3cc3aa]">
                {props.disagreeButton && (
                  <CustomButton
                    type="button"
                    onClick={handleRefresh}
                    disabled={props.diagreeDisabled}
                  >
                    {props.disagreeButton}
                  </CustomButton>
                )}
                {props.additionalControls ? props.additionalControls : <></>}
                <div className="flex items-center">
                  {props.nextButton && (
                    <CustomButton
                      id="next-button"
                      type="button"
                      onClick={props.onClick}
                      disabled={props.disabled}
                    >
                      {props.nextButton}
                    </CustomButton>
                  )}
                  {props.pressEnter && (
                    <p className="text-white hidden md:block pl-2">
                      {props.pressEnter} <EnterOutlined />
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div>
              {props.percent && (
                <Progress
                  percent={props.percent}
                  className="h-3"
                  showInfo={false}
                />
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default CustomReveal;
