/* eslint-disable react/prop-types */
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
  checkbox: {
    "& .MuiSvgIcon-root": {
      borderRadius: "15px", // Add border radius to the checkbox
      boxSizing: "border-box", // Ensure padding doesn't affect the size of the checkbox
    },
    "& .Mui-checked .MuiSvgIcon-root": {
      padding: "10px", // Add padding to the checked icon inside the checkbox
    },
    color: "#3CC3AA",
    "&.Mui-checked": {
      color: "#3CC3AA",
    },
  },
}));

const CheckBoxWithLabel = ({ label, checked, onChange }) => {
  const classes = useStyles();
  return (
    <div className="flex items-center justify-center">
      <Checkbox
        size="large"
        checked={checked}
        value={checked}
        onChange={onChange}
        className={classes.checkbox}
      />
      <label>{label}</label>
    </div>
  );
};
export default CheckBoxWithLabel;
