/* eslint-disable react/prop-types */
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import childIllustration from "../../assets/child-illustration.webp";
export const KidTile = ({ kid, handleActionClick, deleteKid }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-3 justify-center items-center hover:bg-[#3CC3AA26] md:p-10 p-6 rounded-full">
      <div
        onClick={() => navigate(`/choose-story?id=${kid.id}`)}
        className="relative w-[8rem] h-[8rem] md:w-[12rem] md:h-[12rem] overflow-hidden rounded-full"
      >
        {kid.image_urls[0] ? (
          <img
            className="w-full h-full object-cover"
            src={kid.image_urls[0]}
            alt={kid.name}
          />
        ) : (
          <img src={childIllustration} alt="child illustration" />
        )}
      </div>
      <h1 className="text-xl font-medium">{kid.name}</h1>
      <div className="flex gap-2" onClick={handleActionClick}>
        <button
          className="md:px-10 px-6 py-2 rounded-full text-[#3CC3AA] hover:bg-[#ffff] font-bold text-lg border-2 border-solid border-[#3CC3AA]"
          onClick={() => navigate(`/add-child?id=${kid.id}`)}
        >
          Edit
        </button>
        <RiDeleteBinLine
          className=" delete-icon cursor-pointer z-30"
          onClick={() => deleteKid(kid.id)}
        />
      </div>
    </div>
  );
};
