import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { logSentryMessage } from "../ApiServices/handleErrors";
import socketStore from "../store/SocketStore";

const BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;
const logSocketStatus = BASE_URL.includes("staging");

export const whiteListForSocketInitialize = [
  "/storyboard",
  "/story/",
  "/style-editor",
  "/customize",
  "/crafting-page",
];

class CustomSocket {
  constructor(roomId, type) {
    this.roomId = roomId;
    this.callbacks = [];
    //this will be user/story
    this.type = type;
    this.protocol = window.location.protocol === "https:" ? "wss" : "ws";
    this.url = `${this.protocol}://${BASE_URL.split("://").pop()}ws/${roomId}/`;
    this.webSocket = null;
    this.token = Cookies.get("story_token");
    this.sentMessages = new Map();
    this.lastSuccessTimestamp = Date.now();
    this.connectionCheckerInterval = null;

    // Bind methods to ensure 'this' context is correct
    this.connect = this.connect.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.startConnectionChecker = this.startConnectionChecker.bind(this);
    this.stopConnectionChecker = this.stopConnectionChecker.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onError = this.onError.bind(this);
  }

  connect() {
    if (this.webSocket && this.webSocket.readyState !== WebSocket.CLOSED) {
      return;
    }

    logSocketStatus && console.log("Connecting socket " + this.type);
    this.webSocket = new WebSocket(
      `${this.url}${this.token ? `?token=${this.token}` : ""}`
    );

    this.webSocket.onopen = this.onOpen;
    this.webSocket.onmessage = this.onMessage;
    this.webSocket.onclose = this.onClose;
    this.webSocket.onerror = this.onError;
  }

  disconnect() {
    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      logSocketStatus && console.log("Disconnecting socket: " + this.type);
      this.webSocket.close();
      this.webSocket = null;
      this.callbacks = [];
      if (this.connectionCheckerInterval) {
        this.stopConnectionChecker();
      }
    }
  }

  onOpen() {
    logSocketStatus && console.log("Socket connection opened " + this.type);
    if (this.callbacks.length > 0) {
      this.startConnectionChecker();
    }
  }

  onMessage(event) {
    const socketStoreState = socketStore.getState();
    //check the disconnected socket array from the socketStore and if the current socket is in the array, remove it
    if (socketStoreState.disconectedSockets.includes(this.type)) {
      socketStoreState.removeDisconnectedSocket(this.type);
    }

    logSocketStatus &&
      console.log(`Socket message received ${this.type}`, event.data);
    const message = JSON.parse(event.data);
    this.lastSuccessTimestamp = Date.now();

    if (message.type === "websocket.accept") return;

    if (message.msg_id && this.sentMessages.has(message.msg_id)) {
      logSocketStatus && console.log("Message verified:", message);
      this.sentMessages.delete(message.msg_id);
    }

    if (this.callbacks.length > 0) {
      this.callbacks.forEach((callback) => callback && callback(message));
    }
  }

  onClose() {
    logSocketStatus && console.log("Socket connection closed " + this.type);
  }

  onError(err) {
    logSocketStatus && console.log("Socket error", err);
  }

  subscribe(callback) {
    const callbackExists = this.callbacks.some(
      (cb) => cb.name === callback.name
    );
    if (callbackExists) return;

    this.callbacks.push(callback);

    if (!this.connectionCheckerInterval && this.callbacks.length > 0) {
      this.startConnectionChecker();
    }

    logSocketStatus && console.log("Callbacks", this.callbacks);
  }

  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter((cb) => cb.name !== callback.name);
    if (this.callbacks.length === 0) {
      this.stopConnectionChecker();
    }
  }

  isSubscribed() {
    return this.callbacks.length > 0;
  }

  sendMessage(message) {
    if (this.webSocket.readyState === WebSocket.OPEN) {
      const msg_id = uuidv4();
      const messageWithId = { ...message, msg_id };
      this.sentMessages.set(msg_id, messageWithId);
      this.webSocket?.send(JSON.stringify(messageWithId));
      logSocketStatus &&
        console.log(`Socket message sent ${this.type}`, messageWithId);
    }
  }

  startConnectionChecker(newTimeStamp) {
    if (this.connectionCheckerInterval) {
      clearInterval(this.connectionCheckerInterval);
    }
    if (newTimeStamp) {
      this.lastSuccessTimestamp = newTimeStamp;
    }
    this.connectionCheckerInterval = setInterval(() => {
      if (this.callbacks.length === 0 || !this.webSocket) {
        this.stopConnectionChecker();
        return;
      }
      const now = Date.now();
      const timeSinceLastSuccess = now - this.lastSuccessTimestamp;

      const socketStateClosed = this.webSocket.readyState === WebSocket.CLOSED;
      if (timeSinceLastSuccess > 8500) {
        if (socketStateClosed) {
          //TODO:push the type of the disconnected socket to the disconnectedSocket array in the socketStore
          const socketStore = socketStore.getState(); //get the socketStore
          socketStore.setDisconnectedSocket(this.type); //set the disconnected socket array in the socketStore

          logSentryMessage("Failed to reach our servers." + this.type);
          logSocketStatus && console.log("Reconnecting socket " + this.type);
          this.connect();
        }
      } else if (timeSinceLastSuccess > 5500) {
        if (socketStateClosed) {
          logSocketStatus && console.log("Reconnecting socket " + this.type);
          this.connect();
        }
      } else if (timeSinceLastSuccess > 2500) {
        logSocketStatus && console.log("Sending echo message " + this.type);
        this.sendMessage({
          op: "echo",
          data: {
            msg: "Check connection",
          },
        });
      }
    }, 3000);
  }

  stopConnectionChecker() {
    if (this.connectionCheckerInterval) {
      clearInterval(this.connectionCheckerInterval);
      this.connectionCheckerInterval = null;
    }
  }
}

export default CustomSocket;
