import api from "../../composables/app";
import { API } from "../apis";

export const getSubscriptionPlans = async (params) => {
  return api.get(`${API.subscriptions.plansList}`, params);
};

export const createNewSubscription = async (body) => {
  return api.post(`${API.subscriptions.create}`, body);
};

export const cancelSubscription = async (subscriptionId) => {
  return api.post(`${API.subscriptions.cancel}${subscriptionId}/cancel`);
};

export const updateCurrentSubscription = async (subscriptionId, body) => {
  return api.put(`${API.subscriptions.update}${subscriptionId}/update`, body);
};

export const getActiveSubscription = async () => {
  return api.get(`${API.subscriptions.activeSubscription}`);
};

export const postAppSumoSubscription = async (code) => {
  return api.post(`${API.appSumo.create}`, { code: code });
};
