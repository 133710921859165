/* eslint-disable react/prop-types */
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/Button/PrimaryButton";
import { TransparentButton } from "../../components/Button/TransparentButton";
import { DialogWrapper } from "../../components/Dialog";

const FailedToGeneratedStoryDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  return (
    <DialogWrapper header={<></>} isOpen={open} onClose={onClose}>
      <div className="bg-white mt-4 p-4 rounded-xl  text relative flex flex-col items-center justify-center gap-4">
        <strong className="text-bold">
          Uh-Oh! Your story ran into a content policy violation
        </strong>
        <p className="px-8 py-4 text-center">
          This typically happens if you have used some copyrighted character
          name.
          <a
            className="text-[#08a4ff] underline"
            target="_blank"
            href="https://www.storygenius.me/docs/troubleshooting/content-policy-violation/"
          >
            {" "}
            See here for more details
          </a>{" "}
          .
          <br />
          <br />
          You probably need to revise your story to remove any names that might
          be copyrighted.
        </p>
      </div>
      <div className="p-4 flex justify-center item-center">
        <TransparentButton
          className="mr-2"
          handleButtonClick={() => {
            navigate("/select-child?tab=story", { replace: true });
          }}
        >
          My Stories
        </TransparentButton>
        <PrimaryButton handleButtonClick={onClose}>
          Revise storyline
        </PrimaryButton>
      </div>
    </DialogWrapper>
  );
};
export default FailedToGeneratedStoryDialog;
