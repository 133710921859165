import Cookies from "js-cookie";
import { Toaster } from "react-hot-toast";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import Header from "./components/Header.jsx";
import { Head } from "./components/Helmet/index.jsx";
import InfoToast from "./components/InfoToast/index.jsx";
import OfflineToast from "./components/Offlinetoast/index.jsx";
import StoryCoinClient from "./components/StoryCoin/index.jsx";
import NotFoundPage from "./pages/404NotFound/index.jsx";
import AddChild from "./pages/AddChild/index.jsx";
import PaymentPage from "./pages/ChildAdventure/manualPayment.jsx";
import ChooseStory from "./pages/ChooseStory/index.jsx";
import CraftingPage from "./pages/Crafting/index.jsx";
import CreditPackPricing from "./pages/CreditPackPricing/index.jsx";
import Customise from "./pages/Customise/index.jsx";
import PaymentFailed from "./pages/Failure/failureV2.jsx";
import Failure from "./pages/Failure/index.jsx";
import Login from "./pages/Login/index.jsx";
import OrderSuccess from "./pages/OrderSuccess/index.jsx";
import { PreviewPage } from "./pages/Preview/PreviewNew.jsx";
import { default as SelectChild } from "./pages/SelectChild/index.jsx";
import StoryBoard from "./pages/StoryBoard/index.jsx";
import StyleEditor from "./pages/StyleEditor/index.jsx";
import Success from "./pages/Success/index.jsx";
import PaymentSuccess from "./pages/Success/paymentSuccessV2.jsx";
import OrderHistory from "./pages/OrderHistory/index.jsx";
import Subscription from "./pages/Subscription/index.jsx";
import useUserDetailsStore from "./store/UserStore/index.js";
import { useEffect } from "react";

const App = () => {
  const tokenExists = Cookies.get("story_token");
  const [searchParams] = useSearchParams();
  const appSumoPromo = searchParams.get("appsumo_code");
  const redirectUrl = searchParams.get("redirect_url")?.split("/").pop()
    ? "/" + searchParams?.get("redirect_url")?.split("/").pop()
    : "";
  const [setPageVisibility] = useUserDetailsStore((state) => [
    state.setPageVisibility,
  ]);

  useEffect(() => {
    //add a visiblitychange event listener to check if the user is online or offline
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        setPageVisibility(true);
      }
      if (document.visibilityState === "hidden") {
        setPageVisibility(false);
      }
    });
    return () => {
      setPageVisibility(false);
      document.removeEventListener("visibilitychange", () => {});
    };
  }, []);

  return (
    <>
      <InfoToast />
      <OfflineToast />
      <Head>
        <StoryCoinClient />
        <Header />
        <Toaster
          position="top-center"
          reverseOrder={true}
          toastOptions={{
            style: {
              width: "auto", // Automatically adjusts width based on content
              minWidth: "fit-content",
              padding: "10px 30px",
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
              fontWeight: "bold",
              boxShadow: "0 0 10px rgba(0,0,0,0.2)",
            },
          }}
        />

        <Routes>
          <Route
            path="/"
            element={
              redirectUrl ? (
                <Navigate to={redirectUrl} />
              ) : tokenExists ? (
                <Navigate
                  to={`/select-child?tab=story${
                    appSumoPromo ? `&appsumo_code=${appSumoPromo}` : ""
                  }`}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={
              redirectUrl ? (
                <Navigate to={redirectUrl} />
              ) : tokenExists ? (
                <Navigate
                  to={`/select-child?tab=story${
                    appSumoPromo ? `&appsumo_code=${appSumoPromo}` : ""
                  }`}
                />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/select-child"
            element={tokenExists ? <SelectChild /> : <Navigate to="/login" />}
          />
          <Route path="/paymentsuccess" element={<Success />} />
          <Route path="/paymentfailure" element={<Failure />} />
          <Route
            path="/add-child"
            element={tokenExists ? <AddChild /> : <Navigate to="/login" />}
          />
          <Route path="/choose-story" element={<ChooseStory />} />
          <Route path="/crafting-page/:storyId" element={<CraftingPage />} />
          <Route path="/customize/:storyId" element={<Customise />} />
          <Route path="/storyboard/:id" element={<StoryBoard />} />
          <Route path="/paymentv2" element={<PaymentPage />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-failure" element={<PaymentFailed />} />
          <Route path="/story/:storyId" element={<PreviewPage />} />
          <Route path="/style-editor/:storyId" element={<StyleEditor />} />
          {/* not fount page */}
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/404" element={<NotFoundPage />} />
          <Route path="/credit-packs" element={<CreditPackPricing />} />
          <Route path="/order-success" element={<OrderSuccess />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/subscription" element={<Subscription />} />
          {/* <Route path="/privacy" element={<Privacy />} /> */}
          {/* <Route path="/offers" element={<Offer />} /> */}
        </Routes>
      </Head>
    </>
  );
};
export default App;
