import { useState } from "react";
import { styleEditorStore } from "../../pages/StyleEditor/styleEditorStore";
import { SubTitle } from "../molecules/subtitle";
import ImageCard from "./ImageCard";
import { SpanText } from "./UploadSection";
import { SettingsSuggestRounded } from "@mui/icons-material";
import ImageViewer from "./ImageViewerModel";
const StylishedSection = () => {
  const styledImageList = styleEditorStore((state) => state.styledImageList);
  const [imageDetails, setShowImageModel] = useState(null);
  return (
    <div className="rounded-lg border border-[#E1E1E1] flex flex-col items-center pt-8 px-4 pb-2">
      {imageDetails && (
        <ImageViewer
          pageId={imageDetails.pageId}
          newImageIndex={imageDetails.imageIndex}
          onClose={() => setShowImageModel(null)}
        />
      )}
      <SubTitle className={"font-bold"}>Stylised Pages</SubTitle>
      <p className="my-2 ">Click on a page to enlarge it </p>
      {styledImageList.length ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 items-center justify-center mt-4">
          {styledImageList?.map((item, index) => {
            let image_url = item.image_urls[item.current_image_index];
            return (
              <ImageCard
                loading={item.is_generation_pending}
                key={index}
                id={index}
                image={image_url}
                size="auto"
                onClick={() => {
                  setShowImageModel({
                    pageId: item.id,
                    imageIndex: item.current_image_index,
                  });
                }}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full max-w-md">
          <div>
            <SettingsSuggestRounded color="primary" style={{ fontSize: 100 }} />
          </div>{" "}
          <SubTitle className="mt-4">
            Apply the <SpanText>Effect</SpanText> from the{" "}
            <SpanText>style section</SpanText> to
            <SpanText> see the changes here</SpanText>
          </SubTitle>
        </div>
      )}
    </div>
  );
};
export default StylishedSection;
