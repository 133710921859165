import { CountrySelector } from "../../components/CreditPricingComponents/chooseCurrency";
import { ProductCard } from "../../components/CreditPricingComponents/productTile";
import { Title } from "../../components/molecules/header";
import { SubTitle } from "../../components/molecules/subtitle";
import "./index.css";

import { CircularProgress } from "@mui/material";
import largeIcon from "../../assets/large.png";
import mediumIcon from "../../assets/medium.png";
import miniIcon from "../../assets/mini.png";
import useGetProductDetails from "../../components/CreditPricingComponents/useGetProductDetails";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { countrycurrencycode } from "../../components/PaymentSection/Assets";
import useUserDetailsStore from "../../store/UserStore";

const coins = [miniIcon, mediumIcon, largeIcon];

const CreditPackPricing = () => {
  const { productDetails, loading } = useGetProductDetails();
  const [currency, setCurrency] = useState("USD");
  const navigate = useNavigate();
  const userDetail = useUserDetailsStore((state) => state.userDetail);
  const searchParams = window.location.search;

  useEffect(() => {
    setCurrency(userDetail?.user?.currency || "USD");
  }, [userDetail]);

  return (
    <div className="pricing-page flex flex-col w-full justify-center items-center mt-16">
      {loading ? (
        <div className="z-50 flex justify-center items-center absolute top-0 left-0 w-[100%] h-[100%] bg-[#dfdfdf] opacity-80 ">
          <CircularProgress sx={{ color: "#3CC3AA" }} />
        </div>
      ) : (
        <>
          <section className="max-w-[518px]">
            <Title className={"text-center"}>Top Up Your StoryCoins</Title>
            <SubTitle className={"mt-8"}>
              Top-up your StoryCoins for more illustrations!
              <br /> Click{" "}
              <a
                className="underline text-blue-300 hover:text-blue-500"
                href="https://storygenius.me/docs/"
              >
                here
              </a>{" "}
              learn more.
            </SubTitle>
            <div className="flex m-8 justify-between items-center">
              Choose your currency
              <CountrySelector
                currency={currency}
                currencyList={Object.keys(
                  productDetails?.products?.[0]?.prices
                )}
                onCurrencyChange={setCurrency}
              />
            </div>
          </section>
          <section className=" container w-[100%] grid grid-cols-1 mg:grid-cols-2 lg:grid-cols-3 justify-items-center m-6">
            {productDetails?.products
              ?.filter(
                (item) => !item.show_story_title && !item.include_shipping
              )
              ?.map((item, index) => (
                <ProductCard
                  key={index}
                  title={item.name}
                  subtitle={item.credit + " StoryCoins"}
                  price={
                    countrycurrencycode[currency] +
                      " " +
                      item.prices?.[currency]?.price || "0"
                  }
                  coinIcon={coins[index]}
                  onClick={() => {
                    navigate(
                      `/paymentv2?currency=${currency}&productId=${item.id}${
                        searchParams ? `${searchParams.replace("?", "&")}` : ""
                      }`
                    );
                  }}
                />
              ))}
          </section>
          <section>
            <SubTitle>
              <strong>Or</strong>{" "}
              <Link
                className="text-blue-400 hover:text-blue-500 underline"
                to="/subscription"
              >
                upgrade
              </Link>{" "}
              to one of our subscription plans to use our power features.
            </SubTitle>
          </section>
        </>
      )}
    </div>
  );
};
export default CreditPackPricing;
