import api from "../../composables/app";
import { API } from "../apis";

export const getStoryDetails = (id) => {
  return api.get(`${API.story.storyDetails}/${id}`);
};

export const getStoryList = () => {
  const url = `${API.story.storyLists}`;
  return api.get(url);
};

export const quickGenerate = (storyId) => {
  const url = `${API.story.quickGenerate}/${storyId}/generate_all_images`;
  return api.post(url);
};

export const getPublicStoryDetails = (id) => {
  return api.get(`${API.story.getPublicStory}/${id}`);
};

export const trackStoryline = (storyId) => {
  return api.post(`${API.story.trackStoryline}/${storyId}/track`);
};
export const trackStory = (storyId) => {
  return api.post(`${API.story.trackStory}${storyId}/track`);
};
export const getCountriesList = () => {
  return api.get(`${API.story.countryList}`);
};

export const getStateAsPerCountry = (countryCode) => {
  return api.get(`${API.story.countryList}?country_code=${countryCode}`);
};
export const printOrderRequest = (data) => {
  return api.post(`${API.story.printOrderRequest}`, data);
};

export const getShippingOptions = (orderId) => {
  return api.get(`${API.story.getShippingOptions}${orderId}`);
};
export const submitShippingOption = (orderId, data) => {
  return api.post(
    `${API.story.getShippingOptions}${orderId}/select_shipping_option`,
    data
  );
};

export const submitDedicationForm = (storyId, data) => {
  return api.post(`${API.story.dedicateRequest}${storyId}/dedicate`, data);
};

export const uploadFile = (fileName, base64Data) => {
  return api.post(`${API.story.addChildImage}`, {
    image_name: fileName,
    image_data: base64Data,
    image_type: "style_input",
  });
};
export const deleteStory = (storyId) => {
  return api.delete(`${API.story.deleteStory}${storyId}`, {
    keep_image: false,
  });
};
