/* eslint-disable react/prop-types */
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { CustomCircularProgress } from "../Spinner/InputSpinner";
const filter = createFilterOptions();

export const AutoCompleteDropdown = ({
  value,
  options = [],
  onSelect,
  creatable = true,
  label = "Font Size",
  ...rest
}) => {
  return (
    <div className="w-[100%] relative">
      <Autocomplete
        value={value}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            onSelect(newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            onSelect(newValue.inputValue);
          } else {
            onSelect(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          // Suggest the creation of a new value
          if (creatable) {
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== "" && !isExisting) {
              filtered.push(inputValue);
            }
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(props, option) => (
          <li key={option} {...props}>
            {option}
          </li>
        )}
        freeSolo
        renderInput={(params) => <TextField {...params} label={label} />}
        {...rest}
      />
      {rest?.loading ? (
        <div className="absolute bottom-0 right-0 p-2">
          <CustomCircularProgress />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
