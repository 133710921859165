import { WarningFilled } from "@ant-design/icons";
import { useEffect } from "react";
import toast from "react-hot-toast";
import useNetworkStatus from "../../services/networkService";

const OfflineToast = () => {
  const { isOnline } = useNetworkStatus();

  const isVisible = !isOnline;

  useEffect(() => {
    if (isOnline) {
      toast.success("Back Online");
    }
  }, [isOnline]);
  return (
    <>
      {isVisible && (
        <div
          className={`sticky top-0 bg-red-500 text-white p-4 text-center font-bold z-50 ${
            isOnline ? "fade-out" : "fade-in"
          }`}
        >
          {/* add a caution icon */}
          <WarningFilled /> You are offline. Please check your internet
          connection.
        </div>
      )}
    </>
  );
};

export default OfflineToast;
