/* eslint-disable react/prop-types */
import { CrownFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const PlanIndicator = ({ plan }) => {
  return (
    <div className="m-auto self-center w-fit my-4">
      <Link
        to="/subscription"
        className="
      transition duration-500 ease-in-out
      relative px-4 rounded-full bg-gradient-to-r hover:from-[#3cc3aa] from-[#3cc3aadc]  hover:to-[#3cc3aa85] to-[#3cc3aa]   text-white font-bold p-2"
      >
        {plan}
        {plan === "Free Plan" ? (
          ""
        ) : (
          <CrownFilled
            style={{ fontSize: "20px" }}
            className="text-yellow-400 absolute  -top-2 -right-1"
          />
        )}
      </Link>
    </div>
  );
};
