/* eslint-disable react/prop-types */
import { LockOutlined } from "@ant-design/icons";
import { PrimaryButton } from "../Button/PrimaryButton";
import { useNavigate } from "react-router-dom";

const ImageGenerationLock = ({ showLockDetails }) => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  return (
    <div className="z-40 w-full h-full flex flex-col items-center bg-slate-10 backdrop-blur-md  justify-center">
      <LockOutlined className="text-[#3cc3aa] text-[32px]" />
      {showLockDetails && (
        <p className="w-[130px] font-[600] text-[10px] text-center leading-4 m-2">
          Purchase more StoryCoins to generate this illustration
        </p>
      )}
      {!isMobile && showLockDetails && (
        <PrimaryButton
          handleButtonClick={() => {
            navigate("/credit-packs");
          }}
          style={{ marginTop: "10px" }}
        >
          Purchase StoryCoins
        </PrimaryButton>
      )}
    </div>
  );
};
export default ImageGenerationLock;
