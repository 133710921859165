import axios from "axios";
import Cookies from "js-cookie";
import { handleErrors } from "../ApiServices/handleErrors";
export const api = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
  timeout: 120000,
});


// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = Cookies.get("story_token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => {
    handleErrors(error);
    return Promise.reject(error);
  }
);

// Response interceptor (remains the same)
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    handleErrors(error);
    return Promise.reject(error);
  }
);

export default api;
