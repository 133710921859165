import { ChevronLeftSharp } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Title } from "../molecules/header";

const Header = () => {
  const navigate = useNavigate();
  const { storyId } = useParams();
  const [searchParms] = useSearchParams();
  const pageId = searchParms.get("pageId");
  return (
    <div className="flex justify-center pt-8 pb-8">
      <div
        onClick={() => {
          navigate("/storyboard/" + storyId + `?pageId=${pageId}`);
        }}
        className="text-[#76777E] cursor-pointer float-left"
      >
        <ChevronLeftSharp fontSize="large" />
        <span className="m-auto pl-2 ">Back to Storyboard</span>
      </div>
      <Title className={"flex-grow text-center"}>Illustration Editor</Title>
    </div>
  );
};
export default Header;
