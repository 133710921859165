import { CopyOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

/* eslint-disable react/prop-types */
export const LinkPreview = ({ link }) => {
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard");
  };
  return (
    <div className="mt-4 mb-8 w-[95%] flex rounded-lg items-center justify-between p-4 bg-[#f5f5f5]">
      <p className="overflow-hidden w-[90%] text-ellipsis text-nowrap">
        {link}
      </p>
      <CopyOutlined onClick={copyToClipBoard} className="cursor-pointer pl-1" />
    </div>
  );
};
