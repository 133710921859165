//@ts-check
import { CloseCircleFilled } from "@ant-design/icons";
import { FAILED_CODE } from "../../FailureCodes";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
// eslint-disable-next-line no-unused-vars
import React from "react";
import LayoutRenderer from "./LayoutRenderer";
const PreviewComponent = ({ isBackgroundChangeable }) => {
  const [story, page, isCoverPage, changedImage, isAuthenticated] =
    useAdvanceStoryStore((state) => [
      state.story,
      state.page,
      state.isCoverPage,
      state.changedImage,
      state.isAuthenticated,
    ]);

  const pageDetails = story?.pages?.[page];

  return (
    <div className="flex flex-col w-full">
      <LayoutRenderer
        pageDetails={pageDetails}
        story={story}
        changedImage={changedImage}
        isCoverPage={isCoverPage}
        page={page}
        showLockDetails={true}
        showChangeBackground={isBackgroundChangeable}
      />

      {pageDetails?.failure_code && isAuthenticated && (
        <div className="my-4 rounded-2xl border-2 border-[#FF0000] bg-[#FFEAEA]  px-4 py-1 flex items-center">
          <CloseCircleFilled
            className="m-4"
            style={{ color: "#FF0000", fontSize: "36px" }}
          />
          <p className="text-[#36324A]   max-w-[100%] lg:max-w-[80%]  text-[14px] p-2">
            Your image generation failed due to{" "}
            {FAILED_CODE[pageDetails?.failure_code ?? "1401"]}. Please try with
            different composition
          </p>
        </div>
      )}
    </div>
  );
};
export default PreviewComponent;
