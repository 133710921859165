/* eslint-disable react/prop-types */
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LayoutRenderer from "../PreviewSection/LayoutRenderer";

const PageTile = ({
  pageDetails,
  story,
  isCoverPage,
  isSelected,
  isDragging,
  isRemovable,
  onRemove,
  onAdd,
  onClick,
}) => {
  const Indicator = () => {
    return (
      <div className="absolute h-[111px] top-2 -left-1 w-3 bg-[#3cc3aa] rounded-r-[50px]"></div>
    );
  };
  const isSplitView = story?.story?.layout === "split";
  return (
    <div
      onClick={onClick}
      className="relative 
       overflow-hidden flex flex-col items-center justify-center pt-2"
    >
      {isSelected && !isDragging && <Indicator />}
      <div>
        <div
          className={`h-[111px] cursor-pointer transition-all ease-in-out duration-300 hover:scale-105 shadow-[#3cc3aa] ${
            !isCoverPage && isSplitView ? "w-[222px]" : "w-[111px]"
          }`}
        >
          <LayoutRenderer
            pageDetails={pageDetails}
            story={story}
            isCoverPage={isCoverPage}
          />
        </div>
        {!isDragging && isRemovable && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className="absolute top-12 right-3 text-slate-300 hover:text-red-500 cursor-pointer"
          >
            <CancelOutlinedIcon />
          </div>
        )}
      </div>
      {!isDragging && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAdd();
          }}
          className="border cursor-pointer items-center flex rounded-md border-[#3cc3aa] bg-white text-[#3cc3aa] hover:bg-[#3cc3aa] hover:text-[#ffff] m-2"
        >
          <AddOutlinedIcon fontSize="small" />
        </div>
      )}
    </div>
  );
};
export default PageTile;
