export const countryFlags = {
  US: "🇺🇸",
  IN: "🇮🇳",
  CA: "🇨🇦",
  AU: "🇦🇺",
  GB: "🇬🇧",
  DE: "🇩🇪",
  FR: "🇫🇷",
  JP: "🇯🇵",
  CN: "🇨🇳",
  BR: "🇧🇷",
  MX: "🇲🇽",
  ES: "🇪🇸",
  IT: "🇮🇹",
  KR: "🇰🇷",
  RU: "🇷🇺",
  SA: "🇸🇦",
  ZA: "🇿🇦",
  AR: "🇦🇷",
  NL: "🇳🇱",
  SE: "🇸🇪",
  CH: "🇨🇭",
  AT: "🇦🇹",
  BE: "🇧🇪",
  NO: "🇳🇴",
  PL: "🇵🇱",
  TR: "🇹🇷",
  PT: "🇵🇹",
  GR: "🇬🇷",
  DK: "🇩🇰",
  FI: "🇫🇮",
  IE: "🇮🇪",
  HU: "🇭🇺",
  CZ: "🇨🇿",
  SG: "🇸🇬",
  HK: "🇭🇰",
  NZ: "🇳🇿",
  MY: "🇲🇾",
  AE: "🇦🇪",
  IS: "🇮🇸",
  EG: "🇪🇬",
  CO: "🇨🇴",
  TH: "🇹🇭",
  PH: "🇵🇭",
  PE: "🇵🇪",
  CL: "🇨🇱",
  ID: "🇮🇩",
  VN: "🇻🇳",
  DO: "🇩🇴",
  CR: "🇨🇷",
  VE: "🇻🇪",
  EC: "🇪🇨",
  SV: "🇸🇻",
  UY: "🇺🇾",
  GT: "🇬🇹",
  PA: "🇵🇦",
  BY: "🇧🇾",
  KZ: "🇰🇿",
  RS: "🇷🇸",
  HR: "🇭🇷",
  BA: "🇧🇦",
  MA: "🇲🇦",
  TN: "🇹🇳",
  JO: "🇯🇴",
  DZ: "🇩🇿",
  KE: "🇰🇪",
  NG: "🇳🇬",
  GH: "🇬🇭",
  CI: "🇨🇮",
  ET: "🇪🇹",
  UG: "🇺🇬",
  SN: "🇸🇳",
  TZ: "🇹🇿",
  ZM: "🇿🇲",
  ZW: "🇿🇼",
  MW: "🇲🇼",
  MG: "🇲🇬",
  MU: "🇲🇺",
  RW: "🇷🇼",
  LS: "🇱🇸",
  BW: "🇧🇼",
  SZ: "🇸🇿",
  NA: "🇳🇦",
  BI: "🇧🇮",
  DJ: "🇩🇯",
  KM: "🇰🇲",
  SO: "🇸🇴",
  IQ: "🇮🇶",
  LB: "🇱🇧",
  SY: "🇸🇾",
  YE: "🇾🇪",
  OM: "🇴🇲",
  PS: "🇵🇸",
  KW: "🇰🇼",
  QA: "🇶🇦",
  BH: "🇧🇭",
  AM: "🇦🇲",
  AZ: "🇦🇿",
  GE: "🇬🇪",
  MD: "🇲🇩",
  KG: "🇰🇬",
  TJ: "🇹🇯",
  TM: "🇹🇲",
  UZ: "🇺🇿",
  AF: "🇦🇫",
  PK: "🇵🇰",
  BD: "🇧🇩",
  LK: "🇱🇰",
  NP: "🇳🇵",
  BT: "🇧🇹",
  MM: "🇲🇲",
  MV: "🇲🇻",
  LA: "🇱🇦",
};

export const countryNames = {
  US: "United States",
  IN: "India",
  CA: "Canada",
  AU: "Australia",
  GB: "United Kingdom",
  DE: "Germany",
  FR: "France",
  JP: "Japan",
  CN: "China",
  BR: "Brazil",
  MX: "Mexico",
  ES: "Spain",
  IT: "Italy",
  KR: "South Korea",
  RU: "Russia",
  SA: "Saudi Arabia",
  ZA: "South Africa",
  AR: "Argentina",
  NL: "Netherlands",
  SE: "Sweden",
  CH: "Switzerland",
  AT: "Austria",
  BE: "Belgium",
  NO: "Norway",
  PL: "Poland",
  TR: "Turkey",
  PT: "Portugal",
  GR: "Greece",
  DK: "Denmark",
  FI: "Finland",
  IE: "Ireland",
  HU: "Hungary",
  CZ: "Czech Republic",
  SG: "Singapore",
  HK: "Hong Kong",
  NZ: "New Zealand",
  MY: "Malaysia",
  AE: "United Arab Emirates",
  IS: "Iceland",
  EG: "Egypt",
  CO: "Colombia",
  TH: "Thailand",
  PH: "Philippines",
  PE: "Peru",
  CL: "Chile",
  ID: "Indonesia",
  VN: "Vietnam",
  DO: "Dominican Republic",
  CR: "Costa Rica",
  VE: "Venezuela",
  EC: "Ecuador",
  SV: "El Salvador",
  UY: "Uruguay",
  GT: "Guatemala",
  PA: "Panama",
  BY: "Belarus",
  KZ: "Kazakhstan",
  RS: "Serbia",
  HR: "Croatia",
  BA: "Bosnia and Herzegovina",
  MA: "Morocco",
  TN: "Tunisia",
  JO: "Jordan",
  DZ: "Algeria",
  KE: "Kenya",
  NG: "Nigeria",
  GH: "Ghana",
  CI: "Ivory Coast",
  ET: "Ethiopia",
  UG: "Uganda",
  SN: "Senegal",
  TZ: "Tanzania",
  ZM: "Zambia",
  ZW: "Zimbabwe",
  MW: "Malawi",
  MG: "Madagascar",
  MU: "Mauritius",
  RW: "Rwanda",
  LS: "Lesotho",
  BW: "Botswana",
  SZ: "Eswatini",
  NA: "Namibia",
  BI: "Burundi",
  DJ: "Djibouti",
  KM: "Comoros",
  SO: "Somalia",
  IQ: "Iraq",
  LB: "Lebanon",
  SY: "Syria",
  YE: "Yemen",
  OM: "Oman",
  PS: "Palestine",
  KW: "Kuwait",
  QA: "Qatar",
  BH: "Bahrain",
  AM: "Armenia",
  AZ: "Azerbaijan",
  GE: "Georgia",
  MD: "Moldova",
  KG: "Kyrgyzstan",
  TJ: "Tajikistan",
  TM: "Turkmenistan",
  UZ: "Uzbekistan",
  AF: "Afghanistan",
  PK: "Pakistan",
  BD: "Bangladesh",
  LK: "Sri Lanka",
  NP: "Nepal",
  BT: "Bhutan",
  MM: "Myanmar",
  MV: "Maldives",
  LA: "Laos",
};
export const countrycurrency = {
  US: "USD $",
  IN: "INR ₹",
  CA: "CAD CA$",
  AU: "AUD A$",
  GB: "GBP £",
  DE: "EUR €",
  FR: "EUR €",
  JP: "USD $",
  CN: "USD $",
  BR: "USD $",
  MX: "USD $",
  ES: "EUR €",
  IT: "EUR €",
  KR: "USD $",
  RU: "USD $",
  SA: "USD $",
  ZA: "USD $",
  AR: "USD $",
  NL: "EUR €",
  SE: "USD $",
  CH: "CHF CHF",
  AT: "EUR €",
  BE: "EUR €",
  NO: "USD $",
  PL: "USD $",
  TR: "USD $",
  PT: "EUR €",
  GR: "EUR €",
  DK: "USD $",
  FI: "EUR €",
  IE: "EUR €",
  HU: "USD $",
  CZ: "USD $",
  SG: "USD $",
  HK: "USD $",
  NZ: "NZD $",
  MY: "USD $",
  AE: "USD $",
  IS: "USD $",
  EG: "USD $",
  CO: "USD $",
  TH: "USD $",
  PH: "USD $",
  PE: "USD $",
  CL: "USD $",
  ID: "USD $",
  VN: "USD $",
  DO: "USD $",
  CR: "USD $",
  VE: "USD $",
  EC: "USD $",
  SV: "USD $",
  UY: "USD $",
  GT: "USD $",
  PA: "USD $",
  BY: "USD $",
  KZ: "USD $",
  RS: "USD $",
  HR: "USD $",
  BA: "USD $",
  MA: "USD $",
  TN: "USD $",
  JO: "USD $",
  DZ: "USD $",
  KE: "USD $",
  NG: "USD $",
  GH: "USD $",
  CI: "USD $",
  ET: "USD $",
  UG: "USD $",
  SN: "USD $",
  TZ: "USD $",
  ZM: "USD $",
  ZW: "USD $",
  MW: "USD $",
  MG: "USD $",
  MU: "USD $",
  RW: "USD $",
  LS: "USD $",
  BW: "USD $",
  SZ: "USD $",
  NA: "USD $",
  BI: "USD $",
  DJ: "USD $",
  KM: "USD $",
  SO: "USD $",
  IQ: "USD $",
  LB: "USD $",
  SY: "USD $",
  YE: "USD $",
  OM: "USD $",
  PS: "USD $",
  KW: "USD $",
  QA: "USD $",
  BH: "USD $",
  AM: "USD $",
  AZ: "USD $",
  GE: "USD $",
  MD: "USD $",
  KG: "USD $",
  TJ: "USD $",
  TM: "USD $",
  UZ: "USD $",
  AF: "USD $",
  PK: "USD $",
  BD: "USD $",
  LK: "USD $",
  NP: "USD $",
  BT: "USD $",
  MM: "USD $",
  MV: "USD $",
  LA: "USD $",
};
export const countrycurrencycode = {
  USD: "$",
  INR: "₹",
  CAD: "CA$",
  AUD: "A$",
  GBP: "£",
  EUR: "€",
  JPY: "¥",
  BRL: "R$",
  KRW: "₩",
  RUB: "₽",
  SAR: "﷼",
  ZAR: "R",
  SEK: "kr",
  CHF: "CHF",
  PLN: "zł",
  TRY: "₺",
  HUF: "Ft",
  CZK: "Kč",
  SGD: "S$",
  HKD: "HK$",
  NOK: "kr",
  MXN: "$",
  NZD: "NZ$",
  MYR: "RM",
  AED: "د.إ",
  EGP: "E£",
  COP: "$",
  THB: "฿",
  PHP: "₱",
  PEN: "S/.",
  CLP: "$",
  IDR: "Rp",
  VND: "₫",
  DOP: "RD$",
  CRC: "₡",
  VES: "Bs.",
  ECV: "ECV",
  SVC: "$",
  UYU: "$U",
  GTQ: "Q",
  PAB: "B/.",
  BYN: "Br",
  KZT: "₸",
  RSD: "дин.",
  HRK: "kn",
  BAM: "KM",
  MAD: "د.م.",
  TND: "د.ت",
  JOD: "د.ا",
  DZD: "د.ج",
  KES: "Ksh",
  NGN: "₦",
  GHS: "GH₵",
  XOF: "CFA",
  ETB: "Br",
  UGX: "USh",
  XAF: "CFA",
  TZS: "TSh",
  ZMW: "ZK",
  ZWL: "Z$",
  MWK: "MK",
  MGA: "Ar",
  MUR: "Rs",
  RWF: "RF",
  LSL: "M",
  BWP: "P",
  SZL: "E",
  NAD: "N$",
  BIF: "FBu",
  DJF: "Fdj",
  KMF: "CF",
  SOS: "Sh",
  IQD: "ع.د",
  LBP: "ل.ل",
  SYP: "£",
  YER: "﷼",
  OMR: "ر.ع.",
  ILS: "₪",
  KWD: "د.ك",
  QAR: "ر.ق",
  BHD: "د.ب",
  AMD: "֏",
  AZN: "₼",
  GEL: "₾",
  MDL: "L",
  KGS: "сом",
  TJS: "ЅМ",
  TMT: "T",
  UZS: "so'm",
  AFN: "؋",
  PKR: "₨",
  BDT: "৳",
  LKR: "Rs",
  NPR: "Rs",
  BTN: "Nu.",
  MMK: "K",
  MVR: "Rf",
  LAK: "₭",
};

export const currencyFlags = {
  USD: "🇺🇸", // United States Dollar
  INR: "🇮🇳", // Indian Rupee
  CAD: "🇨🇦", // Canadian Dollar
  AUD: "🇦🇺", // Australian Dollar
  GBP: "🇬🇧", // British Pound
  EUR: "🇪🇺", // Euro (European Union)
  JPY: "🇯🇵", // Japanese Yen
  BRL: "🇧🇷", // Brazilian Real
  KRW: "🇰🇷", // South Korean Won
  RUB: "🇷🇺", // Russian Ruble
  SAR: "🇸🇦", // Saudi Riyal
  ZAR: "🇿🇦", // South African Rand
  SEK: "🇸🇪", // Swedish Krona
  CHF: "🇨🇭", // Swiss Franc
  PLN: "🇵🇱", // Polish Zloty
  TRY: "🇹🇷", // Turkish Lira
  HUF: "🇭🇺", // Hungarian Forint
  CZK: "🇨🇿", // Czech Koruna
  SGD: "🇸🇬", // Singapore Dollar
  HKD: "🇭🇰", // Hong Kong Dollar
  NOK: "🇳🇴", // Norwegian Krone
  MXN: "🇲🇽", // Mexican Peso
  NZD: "🇳🇿", // New Zealand Dollar
  MYR: "🇲🇾", // Malaysian Ringgit
  AED: "🇦🇪", // UAE Dirham
  EGP: "🇪🇬", // Egyptian Pound
  COP: "🇨🇴", // Colombian Peso
  THB: "🇹🇭", // Thai Baht
  PHP: "🇵🇭", // Philippine Peso
  PEN: "🇵🇪", // Peruvian Sol
  CLP: "🇨🇱", // Chilean Peso
  IDR: "🇮🇩", // Indonesian Rupiah
  VND: "🇻🇳", // Vietnamese Dong
  DOP: "🇩🇴", // Dominican Peso
  CRC: "🇨🇷", // Costa Rican Colón
  VES: "🇻🇪", // Venezuelan Bolívar
  ECV: "🇪🇨", // Ecuadorian Centavo
  SVC: "🇸🇻", // Salvadoran Colón
  UYU: "🇺🇾", // Uruguayan Peso
  GTQ: "🇬🇹", // Guatemalan Quetzal
  PAB: "🇵🇦", // Panamanian Balboa
  BYN: "🇧🇾", // Belarusian Ruble
  KZT: "🇰🇿", // Kazakhstani Tenge
  RSD: "🇷🇸", // Serbian Dinar
  HRK: "🇭🇷", // Croatian Kuna
  BAM: "🇧🇦", // Bosnia-Herzegovina Convertible Mark
  MAD: "🇲🇦", // Moroccan Dirham
  TND: "🇹🇳", // Tunisian Dinar
  JOD: "🇯🇴", // Jordanian Dinar
  DZD: "🇩🇿", // Algerian Dinar
  KES: "🇰🇪", // Kenyan Shilling
  NGN: "🇳🇬", // Nigerian Naira
  GHS: "🇬🇭", // Ghanaian Cedi
  XOF: "🇨🇮", // West African CFA Franc (Ivory Coast)
  ETB: "🇪🇹", // Ethiopian Birr
  UGX: "🇺🇬", // Ugandan Shilling
  XAF: "🇨🇲", // Central African CFA Franc (Cameroon)
  TZS: "🇹🇿", // Tanzanian Shilling
  ZMW: "🇿🇲", // Zambian Kwacha
  ZWL: "🇿🇼", // Zimbabwean Dollar
  MWK: "🇲🇼", // Malawian Kwacha
  MGA: "🇲🇬", // Malagasy Ariary
  MUR: "🇲🇺", // Mauritian Rupee
  RWF: "🇷🇼", // Rwandan Franc
  LSL: "🇱🇸", // Lesotho Loti
  BWP: "🇧🇼", // Botswanan Pula
  SZL: "🇸🇿", // Swazi Lilangeni
  NAD: "🇳🇦", // Namibian Dollar
  BIF: "🇧🇮", // Burundian Franc
  DJF: "🇩🇯", // Djiboutian Franc
  KMF: "🇰🇲", // Comorian Franc
  SOS: "🇸🇴", // Somali Shilling
  IQD: "🇮🇶", // Iraqi Dinar
  LBP: "🇱🇧", // Lebanese Pound
  SYP: "🇸🇾", // Syrian Pound
  YER: "🇾🇪", // Yemeni Rial
  OMR: "🇴🇲", // Omani Rial
  ILS: "🇮🇱", // Israeli New Shekel
  KWD: "🇰🇼", // Kuwaiti Dinar
  QAR: "🇶🇦", // Qatari Riyal
  BHD: "🇧🇭", // Bahraini Dinar
  AMD: "🇦🇲", // Armenian Dram
  AZN: "🇦🇿", // Azerbaijani Manat
  GEL: "🇬🇪", // Georgian Lari
  MDL: "🇲🇩", // Moldovan Leu
  KGS: "🇰🇬", // Kyrgyzstani Som
  TJS: "🇹🇯", // Tajikistani Somoni
  TMT: "🇹🇲", // Turkmenistani Manat
  UZS: "🇺🇿", // Uzbekistani Som
  AFN: "🇦🇫", // Afghan Afghani
  PKR: "🇵🇰", // Pakistani Rupee
  BDT: "🇧🇩", // Bangladeshi Taka
  LKR: "🇱🇰", // Sri Lankan Rupee
  NPR: "🇳🇵", // Nepalese Rupee
  BTN: "🇧🇹", // Bhutanese Ngultrum
  MMK: "🇲🇲", // Myanmar Kyat
  MVR: "🇲🇻", // Maldivian Rufiyaa
  LAK: "🇱🇦", // Lao Kip
};

export const getCurrencySymbol = (currency) => {
  return countrycurrencycode[currency] ?? "$";
};
