import { Check, CheckCircle } from "@mui/icons-material";
import { PrimaryButton } from "../Button/PrimaryButton";

/* eslint-disable react/prop-types */
const SubscriptionTile = ({ subscriptionDetails, isActive, onClick }) => {
  const {
    title,
    period,
    amount,
    characterLimit,
    credits,
    bookLimit,
    buttonType,
    pagesPerBook,
    savedAmount,
    additionalCreditsPricing,
    support,
    newFeatures,
  } = subscriptionDetails;

  const ListTile = ({ children, className }) => (
    <p className={`leading-8 font-[500] ${className ? className : ""}`}>
      <CheckCircle className="text-[#3cc3aa] mr-2" />
      {children}
    </p>
  );
  const isAnnualPlan = period === "yearly";
  const amountPerMonth = isAnnualPlan ? Number(amount / 12).toFixed(2) : amount;
  const subscriptionState = "Change Plan";

  return (
    <div
      className={`mx-4 bg-slate-50 bg-opacity-90 drop-shadow-lg relative max-w-[360px] w-full border-1  rounded-lg flex flex-col justify-center items-center p-2 py-4 md:py-8
         gap-4 ${
           isActive
             ? "border-2 border-[#3cc3aa] lg:scale-110"
             : "border-slate-300"
         }`}
    >
      {isActive && (
        <div className="absolute top-2 right-2 bg-[#3cc3aa] text-white p-1 rounded-full">
          <Check />
        </div>
      )}
      <p className="text-xl font-[800]">{title}</p>
      <p className="text-[#3cc3aa] font-[600] text-[20px]">
        $<span className="text-2xl">{amountPerMonth}</span>
        <span className="text-[18px] text-slate-400">/mo</span>
        {amount > 0 && isAnnualPlan && (
          <p className="text-sm text-slate-400">
            ( ${amount} billed annually. Save ${savedAmount} )
          </p>
        )}
      </p>
      <div className="text-start mt-4">
        <ListTile>
          <strong>{credits}</strong> StoryCoins per month
        </ListTile>

        <ListTile>
          Create <strong>{bookLimit < 0 ? "Unlimited" : bookLimit}</strong>{" "}
          books per month
        </ListTile>

        <ListTile>
          <strong>{pagesPerBook}</strong> book length
        </ListTile>
        <ListTile>
          {characterLimit > 0 ? "Upto " : ""}
          <strong>
            {characterLimit < 0 ? "Unlimited" : characterLimit}
          </strong>{" "}
          characters{" "}
          <span className="text-sm text-slate-500">(Coming soon)</span>
        </ListTile>
        <ListTile>{additionalCreditsPricing}</ListTile>
        <ListTile>{support}</ListTile>
        <ListTile>{newFeatures} </ListTile>
      </div>
      {isActive ? (
        <div className="p-2 px-4 font-bold text-[#3cc3aa] bg-white border-2 border-[#3cc3aa] rounded-lg">
          Active
        </div>
      ) : buttonType === "none" ? (
        <></>
      ) : (
        <PrimaryButton
          className={`rounded-lg`}
          handleButtonClick={(e) => {
            if (isActive) return;
            onClick(e);
          }}
        >
          {isActive ? "Active" : subscriptionState}
        </PrimaryButton>
      )}
    </div>
  );
};
export default SubscriptionTile;
