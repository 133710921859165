export const API = {
  story: {
    storyDetails: "story/v2/story",
    addChild: `story/v1/human`,
    addChildImage: `story/v1/image`,
    getChildDetails: `story/v1/human`,
    getStoryLine: `story/v1/storyline`,
    generateCover: `story/v1/story/generate_cover`,
    storyLists: `story/v1/stories`,
    quickGenerate: "story/v1/story",
    downloadPDF: `story/v2/generate_pdf`,
    generateAllPages: `story/v1/story`,
    getPublicStory: "story/v2/public_story",
    trackStoryline: "story/v1/storyline",
    trackStory: "story/v1/story/",
    countryList: "story/v1/locations",
    printOrderRequest: "story/v1/print_order",
    getShippingOptions: "story/v1/print_order/",
    dedicateRequest: "story/v1/story/",
    deleteStory: "story/v1/story/",
  },
  login: `story/v1/login`,
  payment: {
    productList: "story/v1/products",
    createOrder: "story/v2/razorpay/order/create",
    discount: "story/v1/product/discount",
  },
  user: {
    details: "story/v1/user",
    updateUser: "story/v1/user/update",
  },
  product: {
    getAll: "story/v1/products/",
  },
  history: {
    printOrderHistory: "story/v1/order/history",
    storyCoinsHistory: "story/v1/transaction/history",
  },
  subscriptions: {
    plansList: "story/v1/subscription/plans",
    create: "story/v1/subscription/create",
    cancel: "story/v1/subscription/",
    update: "story/v1/subscription/",
    activeSubscription: "story/v1/subscription",
  },
  appSumo: {
    create: "story/v1/appsumo/subscription/create",
  },
};
