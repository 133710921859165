export const serializeSubscription = (subscription) => {
  const packages = subscription?.packages;
  return {
    id: subscription.id,
    title: subscription?.item?.name,
    period: subscription.period,
    amount: subscription?.item?.amount / 100,
    characterLimit: packages?.num_characters || 0,
    credits: packages?.monthly_credits || 0,
    bookLimit: packages?.monthly_num_books || 0,
    pagesPerBook: packages?.variable_pages ? "Adjustable" : "Fixed",
    additionalCreditsPricing: packages?.discount_on_products
      ? `${packages?.discount_on_products}% discount on StoryCoins`
      : "Standard StoryCoins packs",
    support: packages?.priority_support
      ? "Priority Support"
      : "Standard Support",
    newFeatures: packages?.early_access
      ? "Early access to new Feature"
      : "Upgrade anytime",
    buttonText: "Upgrade",
    buttonType: "primary",
    savedAmount: packages?.annual_savings || 0,
  };
};
