/* eslint-disable react/prop-types */
import { useCallback } from "react";
import Dropzone from "react-dropzone";
import Check from "../../assets/check.png";
import Upload from "../../assets/upload.png";
import { styleEditorStore } from "../../pages/StyleEditor/styleEditorStore";
import { SubTitle } from "../molecules/subtitle";
import ImageCard from "./ImageCard";

export const SpanText = ({ children }) => (
  <span className="text-[#3CC3AA] font-[500]">{children}</span>
);
const UploadSection = () => {
  const [
    imageToCompare,
    setShowStyleReferenceModel,
    setImageToCompare,
    onFileUpload,
    isUploading,
  ] = styleEditorStore((state) => [
    state.imageToCompare,
    state.setShowStyleReferenceModel,
    state.setImageToCompare,
    state.onFileUpload,
    state.isUploading,
  ]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      onFileUpload(file);
    });
  }, []);

  return (
    <>
      {imageToCompare ? (
        <div className="flex flex-col items-start">
          <SubTitle>Style Reference:</SubTitle>
          <div className="flex items-center justify-center w-full">
            <ImageCard
              image={imageToCompare.imageUrl}
              onClick={() => {
                setShowStyleReferenceModel(true);
              }}
              size="medium"
              onDelete={() => {
                setImageToCompare(null);
              }}
              loading={isUploading}
            />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 items-center gap-5 text-[13px] text-center">
          <div
            onClick={() => {
              setShowStyleReferenceModel(true);
            }}
            className="flex flex-col cursor-pointer hover:border-[#3CC3AA] hover:bg-[#3CC3AA] hover:bg-opacity-10 items-center justify-center rounded-lg border min-h-[208px] border-[#E1E1E1] p-2"
          >
            <div className="m-auto flex flex-col  items-center max-w-[114px]">
              <img alt="upload" src={Check} />
              <p className="pt-2">
                <SpanText>Select style from</SpanText> a{" "}
                <SpanText>generated page</SpanText>
              </p>
            </div>
          </div>
          <Dropzone
            onDrop={onDrop}
            multiple={false}
            maxFiles={1}
            noClick={true}
          >
            {({ getRootProps, getInputProps, open }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  onClick={() => {
                    open();
                  }}
                  className="flex flex-col cursor-pointer hover:border-[#3CC3AA] hover:bg-[#3CC3AA] hover:bg-opacity-10 items-center justify-center rounded-lg border min-h-[208px] border-[#E1E1E1] p-2"
                >
                  <img alt="upload" src={Upload} />
                  <p className="pt-2 max-w-64">
                    <SpanText>Drag and drop</SpanText> an image or{" "}
                    <SpanText>Upload an image </SpanText>for{" "}
                    <SpanText>style reference</SpanText>
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      )}
    </>
  );
};
export default UploadSection;
