/* eslint-disable react/prop-types */
// @ts-check
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomCircularProgress } from "../Spinner/InputSpinner";

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
  </div>
);

/*
 * Editor component with custom toolbar and content containers
 */
const TextEditor = ({
  placeholder,
  onChange,
  value,
  title,
  height = "100px",
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const blurTimeout = useRef(null);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    //after pasting the text onBlur is triggered so to avoid that we are using timeout
    if (blurTimeout.current) clearTimeout(blurTimeout.current);
    else {
      // @ts-ignore
      blurTimeout.current = setTimeout(() => {
        setIsFocused(false);
      }, 100);
    }
  };

  const handleChange = (html) => {
    if (isFocused) {
      if (value === html) return;
      onChange(html);
    }
  };
  //create a function to add two numbers

  return (
    <div
      className={`relative text-editor w-full min:h-[100px] rounded-md overflow-hidden `}
    >
      {title && <div className="text-gray-500 text-sm">{title}</div>}
      <CustomToolbar />
      <ReactQuill
        style={{ height: height || "100px" }}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        onFocus={handleFocus}
        onBlur={handleBlur}
        theme={"snow"} // pass false to use minimal theme
      />

      {rest?.loading ? (
        <div className="absolute bottom-0 right-0 p-2">
          <CustomCircularProgress />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
TextEditor.modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
TextEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

export default TextEditor;
