export const HistoryLoader = () => {
  return Array(5)
    .fill(0)
    .map((_, index) => (
      <div
        key={index}
        className="animate-pulse bg-slate-100 m-1 my-2 border border-[#e1e1e1] rounded-lg  h-[93px] w-full"
      ></div>
    ));
};
