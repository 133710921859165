//@ts-check
import Box from "@mui/material/Box";
import useAdvanceStoryStore from "../store/AdvanceStoryStore";
import {
  PAGE_TEXT_LOADING_STATE,
  SOCKET_OPERATIONS,
} from "../services/socketoOperations";
import React from "react";
import { TextFieldWithSpinner } from "./TextEditor/TextFieldWithSpinner";
export default function CompositionDetails() {
  const [formState, handleInputChange] = useAdvanceStoryStore((state) => [
    state.formState,
    state.handleInputChange,
  ]);
  return (
    <React.Fragment>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
      >
        <div className="flex flex-col gap-6 justify-center items-center">
          <TextFieldWithSpinner
            id="outlined-disabled"
            label="Composition"
            name="composition"
            multiline
            rows={14}
            value={formState.composition}
            onChange={(e) => {
              handleInputChange(e, SOCKET_OPERATIONS.PAGE_COMPOSITION_CHANGE);
            }}
            loading={
              formState[
                PAGE_TEXT_LOADING_STATE[
                  SOCKET_OPERATIONS.PAGE_COMPOSITION_CHANGE
                ]
              ]
            }
          />
        </div>
      </Box>
    </React.Fragment>
  );
}
