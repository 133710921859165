/* eslint-disable react/prop-types */
import { CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FailedToLoadImage } from "../ImageNotFound";
import ImageGenerationLock from "../ImageGenerationLock";
import useUserDetailsStore from "../../store/UserStore";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import FloatingColorPalette from "../FloatingColorPalette";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { SOCKET_OPERATIONS } from "../../services/socketoOperations";
import { SubTitle } from "../molecules/subtitle";
const LayoutRenderer = ({
  pageDetails,
  story,
  changedImage,
  isCoverPage,
  page,
  showLockDetails,
  showChangeBackground,
}) => {
  const previewRef = useRef(null);
  const credit = useUserDetailsStore((state) => state.credit);
  const [previewAspectRatio, setPreviewAspectRatio] = useState(0);
  const handleFormEvents = useAdvanceStoryStore(
    (state) => state.handleFormEvents
  );
  const activeBookview = story?.story?.layout;
  const isSplitView = activeBookview === "split";
  const loadingImage = pageDetails?.is_generation_pending;
  const textElements = pageDetails?.text_elements;
  const backgroundImage =
    changedImage != "" && changedImage != undefined
      ? changedImage
      : pageDetails?.image_urls?.[pageDetails?.current_image_index];

  const [showColorPalette, setShowColorPalette] = useState(false);
  const getContainerWidth = () => {
    if (previewRef.current) {
      // @ts-ignore
      const containerWidthPx = previewRef.current.offsetWidth;
      const dpi = 96; // Assume 96 DPI which is standard used in web development
      const widthMm = (containerWidthPx / dpi) * 25.4;

      //calculating aspect ratio:current_width/default_width_in_pdf
      let aspectRatio = widthMm / 281;
      if (activeBookview === "split" && !isCoverPage) {
        aspectRatio = aspectRatio / 2;
      }
      setPreviewAspectRatio(aspectRatio);
    }
  };
  useEffect(() => {
    getContainerWidth();
  }, [activeBookview, previewRef.current, page]);

  useEffect(() => {
    //add a click listener to close the color palette if clicked outside the palette if open\
    const handleClick = (e) => {
      if (showColorPalette && !previewRef.current.contains(e.target)) {
        setShowColorPalette(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
  return (
    <div
      ref={previewRef}
      className={`relative ${
        !isSplitView || isCoverPage ? "cover-padding" : "pt-[50%]"
      } w-[100%] `}
      style={{ boxShadow: ` 0 4px 8px 0 rgb(139,139,139)` }}
    >
      {isSplitView && !isCoverPage ? (
        <div className=" absolute top-0 h-[100%] w-[100%] flex flex-row ">
          <div
            className={`relative group flex-1 flex-grow-1 flex-shrink-1 w-1/2 `}
            style={{ background: pageDetails?.background }}
          >
            {textElements?.map((textElement) => (
              <div
                key={textElement?.id}
                className={`mx-auto craft-heading text-wrap`}
                style={{
                  color: textElement?.text_color,
                  fontFamily: `${textElement?.font}, sans-serif  `,
                  fontSize: `${textElement?.font_size * previewAspectRatio}px`,
                  justifyContent: `${textElement?.text_position}`,
                  // @ts-ignore
                  textAlign: `${textElement?.text_alignment}`,
                  lineHeight: "normal",
                  padding: "15%",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                }}
                dangerouslySetInnerHTML={{ __html: textElement?.text }}
              />
            ))}
            {Boolean(showChangeBackground) && (
              <>
                <div
                  onClick={() => {
                    setShowColorPalette(!showColorPalette);
                  }}
                  className="absolute z-40 hover:bg-slate-100 cursor-pointer right-0 p-2 m-1 bg-white rounded-full flex items-center justify-center opacity-30 group-hover:opacity-100"
                >
                  <Tooltip
                    placement="top-end"
                    arrow
                    title="Click to change background"
                  >
                    <FormatColorFillIcon color="primary" />
                  </Tooltip>
                </div>
                {showColorPalette && (
                  <FloatingColorPalette
                    value={pageDetails?.background}
                    onColorChange={(color) =>
                      handleFormEvents(
                        {
                          key: "background",
                          value: color,
                        },
                        SOCKET_OPERATIONS.PAGE_BACKGROUND_CHANGE
                      )
                    }
                  />
                )}{" "}
              </>
            )}
          </div>
          {
            <div className="relative flex-1 flex-grow-1 flex-shrink-1 w-1/2 overflow-hidden ">
              {!backgroundImage && credit == 0 ? (
                <ImageGenerationLock showLockDetails={showLockDetails} />
              ) : !backgroundImage && pageDetails?.failure_code == 1401 ? (
                <FailedToLoadImage />
              ) : !backgroundImage && Boolean(showChangeBackground) ? (
                <div className="h-full w-full flex justify-center items-center p-4 md:p-12">
                  {" "}
                  <SubTitle>
                    Click <strong>Generate Button </strong>below to illustrate
                    this page
                  </SubTitle>
                </div>
              ) : (
                backgroundImage && (
                  <img
                    className="object-cover h-[100%]"
                    src={backgroundImage}
                    alt="backgound image"
                  />
                )
              )}
              {loadingImage && (
                <div className=" flex justify-center items-center absolute top-0 left-0 w-[100%] h-[100%] bg-[#dfdfdf] opacity-80 ">
                  <CircularProgress sx={{ color: "#3CC3AA" }} />
                </div>
              )}
            </div>
          }
        </div>
      ) : (
        <div className="absolute top-0 h-[100%] w-[100%] overflow-hidden">
          {!backgroundImage && pageDetails?.failure_code == 1401 ? (
            <FailedToLoadImage />
          ) : (
            backgroundImage && (
              <img
                src={backgroundImage}
                alt="backgound image"
                className="object-cover h-[100%] w-[100%]"
              />
            )
          )}
          {textElements?.map((textElement) => (
            <div
              key={textElement?.id}
              className=" mx-auto craft-heading  absolute bottom-0 text-wrap"
              style={{
                width: "100%",
                color: textElement?.text_color,
                fontFamily: `${textElement?.font}, sans-serif  `,
                fontSize: `${textElement?.font_size * previewAspectRatio}px`,
                justifyContent: `${textElement?.text_position}`,
                // @ts-ignore
                textAlign: `${textElement?.text_alignment}`,
                textShadow: `0 0 3px ${textElement?.shadow_color}, 0 0 5px #fffe, 0 0 10px #3c3b3bdd`,
                padding: `15%`,
                lineHeight: "normal",
              }}
              dangerouslySetInnerHTML={{ __html: textElement?.text }}
            ></div>
          ))}

          {loadingImage && (
            <div className=" flex justify-center items-center absolute top-0 left-0 w-[100%] h-[100%] bg-[#dfdfdf] opacity-80 ">
              <CircularProgress sx={{ color: "#3CC3AA" }} />
            </div>
          )}
          {!backgroundImage && credit == 0 ? (
            <ImageGenerationLock showLockDetails={showLockDetails} />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default LayoutRenderer;
