/* eslint-disable react/prop-types */
import { ChevronRight } from "@mui/icons-material";
import { Card } from "@mui/material";
import { useState } from "react";
import $ from "jquery";
import useCheckDevice from "../../services/checkDeviceHook";

const LeftDrawer = ({ children }) => {
  const [expand, setExpand] = useState(false);
  const { isMobile } = useCheckDevice();
  return (
    <section
      className={`${expand ? "w-[341px]" : "w-10"} z-40 h-[85vh] fixed ${
        isMobile && !expand ? "-left-8" : "left-0"
      } ${
        isMobile ? "top-20" : "top-28"
      } rounded-2xl min-w-10 bg-white drop-shadow-2xl ease-in-out duration-300`}
    >
      <div className="relative w-full h-full ">
        <div
          className={`z-50 bg-yellow-400 px-4 py-1 font-bold rounded-md absolute -top-2 ${
            !isMobile ? "-right-8" : "right-0"
          }`}
        >
          beta
        </div>
        <Card
          onClick={() => {
            $("#expand-icon")
              .css({ transition: "transform 1s" })
              .toggleClass("rotate-180");
            setExpand(!expand);
          }}
          className={`
          hover:bg-slate-50 cursor-pointer
          z-50 absolute ${
            isMobile ? "w-[40px] h-[111px]" : "w-[45px] h-[126px]"
          } top-1/2 -mt-14 bg-white flex items-center justify-center rounded-xl -right-5 `}
        >
          <div id="expand-icon">
            <ChevronRight fontSize="large" />
          </div>
        </Card>
        <div
          className={`ease-linear duration-300 h-full ${
            expand ? "opacity-100" : "opacity-0"
          }`}
        >
          {children}
        </div>
      </div>
    </section>
  );
};
export default LeftDrawer;
