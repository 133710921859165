/* eslint-disable react/prop-types */
import { Card } from "@mui/material";
import { PrimaryButton } from "../Button/PrimaryButton";
import { ChevronRight } from "@mui/icons-material";

export const ProductCard = ({
  title,
  subtitle,
  price,
  coinIcon,
  onClick,
  isActive,
}) => {
  const checkIsMobile = () => {
    return window.matchMedia("(max-width: 770px)").matches;
  };
  return (
    <Card
      onClick={() => (checkIsMobile() ? onClick() : null)}
      sx={{
        borderRadius: "25px",
        background: isActive ? "#3CC3AA" : "white",
      }}
      className={`w-100% px-8 py-8 m-4 min-w-[295px] md:min-w-[394px] min-h[295px] md:min-h-[433px] flex`}
    >
      <div className="flex flex-col justify-between w-full">
        <div className=" w-[100%] flex items-center">
          <img src={coinIcon} alt="coin" className="w-20 h-24 " />
          <div className="ml-4">
            <p className="text[12px] md:text-[18px] text-[#6F6C90] font-bold">
              {title}
            </p>
            <p className="text[18px] md:text-[24px] font-bold">{subtitle}</p>
          </div>
        </div>
        <h1
          className={`text-[32px] md:text-[54px] ${isActive && "text-[#ffff]"}`}
        >
          {price}
        </h1>
        <div className="hidden md:block w-full">
          <PrimaryButton
            className={isActive && "bg-[#ffff] text-[#3CC3AA]"}
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              width: "100%",
              color: isActive && "#3CC3AA",
            }}
            handleButtonClick={onClick}
          >
            Top Up
          </PrimaryButton>
        </div>
      </div>
      <div className="flex items-center px-2 md:hidden">
        <ChevronRight fontSize="large" />
      </div>
    </Card>
  );
};
