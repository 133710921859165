import { useEffect } from "react";
import historyStore from "../../store/HistoryStore";
import { convertDate } from "../../services/serviceFunction";
import { HistoryLoader } from "./HistoryLoader";
import { countrycurrencycode } from "../PaymentSection/Assets";
import CustomPagination from "../../pages/SelectChild/pagination";
import { SubTitle } from "../molecules/subtitle";
import StoryEmptyImg from "../../assets/storyEmpty.svg";
import { Tooltip } from "@mui/material";

const PRODUCT_TYPE = {
  DIGITAL_STORYBOOK: "DIGITAL_STORYBOOK",
  PRINT_ORDER: "PRINT_ORDER",
  CREDIT_PACK: "CREDIT_PACK",
};
/* eslint-disable react/prop-types */
const PrintOrder = () => {
  const [getPrintOrderList, printOrderHistory, getFilter, setFilter] =
    historyStore((state) => [
      state.getPrintOrderList,
      state.printOrderHistory,
      state.getFilter,
      state.setFilter,
    ]);
  const filters = getFilter();
  const page = filters.page;

  useEffect(() => {
    getPrintOrderList(filters);
  }, [getPrintOrderList, filters]);

  const ListTile = ({
    title,
    orderNo,
    date,
    amount,
    coverImage,
    storyTitle,
    storyId,
  }) => {
    return (
      <section className="p-2 md:p-8 m-1 md:my-2 my-1 border border-[#e1e1e1] rounded-lg ">
        <div className="hidden md:grid grid-cols-5 items-center font-[600] text-[18px]">
          <Tooltip title={"Product"} placement="top" arrow>
            <div className="flex items-center gap-2">{title}</div>
          </Tooltip>
          <Tooltip title={"Order No"} placement="top" arrow>
            <p className="text-center">{orderNo}</p>
          </Tooltip>
          <Tooltip title={"Order Date"} placement="top" arrow>
            <p className="text-center">{convertDate(date)}</p>
          </Tooltip>
          <Tooltip title={"Amount"} placement="top" arrow>
            <p className="text-center">{amount}</p>
          </Tooltip>

          {storyId ? (
            <Tooltip title={"Click to go to storyboard"} placement="top" arrow>
              <a
                className="flex items-center gap-2 cursor-pointer "
                href={`storyboard/${storyId}`}
              >
                <img
                  src={coverImage}
                  alt="cover image"
                  className="object-cover  h-14 w-14 rounded-lg "
                />
                <p>{storyTitle}</p>
              </a>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
        <a
          href={storyId ? `storyboard/${storyId}` : "#"}
          className="md:hidden flex gap-4"
        >
          {storyId ? (
            <div className="flex items-center gap-2 min-w-[125px] cursor-pointer ">
              <img
                src={coverImage}
                alt="cover image"
                className="object-cover  h-[125px] w-[125px] rounded-lg "
              />
            </div>
          ) : (
            <></>
          )}
          <div className="grid grid-cols-2 gap-2 grow self-stretch">
            <div className="col-span-2 font-[600] flex items-center">
              {title}
            </div>
            <div className="col-span-2">
              <SubTitle className="text-start">
                {storyTitle && (
                  <p>
                    {storyTitle?.slice(0, 20)}
                    {storyTitle?.length > 20 ? "..." : ""}
                  </p>
                )}
              </SubTitle>
            </div>

            <p>
              <span className="font-[600]">Order No:</span> {orderNo}
            </p>
            <p className="font-[600]">
              <span className="font-[600]">Date:</span> {convertDate(date)}{" "}
            </p>
            <p className="font-[600]">
              <span className="font-[600]">Price:</span> {amount}
            </p>
          </div>
        </a>
      </section>
    );
  };

  const tileTitle = (order) => {
    if (order?.product?.type === PRODUCT_TYPE.DIGITAL_STORYBOOK)
      return "Digital Storybook";
    if (order?.product?.type === PRODUCT_TYPE.PRINT_ORDER)
      return `Print - ${order?.product?.name}`;
    if (order?.product?.type === PRODUCT_TYPE.CREDIT_PACK)
      return `Credit Pack - ${order?.product?.name}`;
    if (order?.subscription && order?.product === null) {
      return `${order?.subscription.plan_name} - ${order?.subscription.plan_period}`;
    }
  };

  if (
    !printOrderHistory.loading &&
    printOrderHistory.data?.orders?.length === 0
  ) {
    return (
      <div className="flex flex-col items-center justify-center">
        <img src={StoryEmptyImg} alt="empty story" />
        <p className="text-md my-4">You currently have no Order History!</p>
      </div>
    );
  }
  return (
    <div className="flex flex-col w-full">
      {printOrderHistory.loading ? (
        <HistoryLoader />
      ) : (
        <>
          {printOrderHistory.data?.orders?.map((order) => (
            <ListTile
              key={order.id}
              title={tileTitle(order)}
              orderNo={order?.email_order_id || "N/A"}
              date={order?.created_date}
              storyTitle={order?.story?.title}
              amount={` ${
                countrycurrencycode[order?.currency]
                  ? countrycurrencycode[order?.currency]
                  : ""
              }${order?.amount}${
                countrycurrencycode[order?.currency] ? "" : order?.currency
              }`}
              coverImage={order?.story?.cover_image_url}
              storyId={order?.story?.id}
            />
          ))}
          <CustomPagination
            currentPage={page}
            totalPages={printOrderHistory.data?.pages}
            onPageChange={(e, page) => {
              setFilter({ ...filters, page });
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </>
      )}
    </div>
  );
};
export default PrintOrder;
