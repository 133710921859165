import { create } from "zustand";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import { SOCKET_OPERATIONS } from "../../services/socketoOperations";
import { uploadFile } from "../../ApiServices/Story/storyApis";
import socketStore from "../../store/SocketStore";

const styleEditorStore = create((set, get) => ({
  isUploading: false,
  showStyleReferenceModel: false,
  setShowStyleReferenceModel: (show) => {
    set({ showStyleReferenceModel: show });
  },
  imageToCompare: null, //{id:1, imageUrl:""}
  setImageToCompare: (image) => {
    set({ imageToCompare: image });
  },
  imageSelected: [],
  setImageSelected: (id) => {
    set((state) => {
      if (state.imageSelected.includes(id)) {
        return { imageSelected: state.imageSelected.filter((i) => i !== id) };
      }
      return { imageSelected: [...state.imageSelected, id] };
    });
  },
  toggleImageSelect: (pages) => {
    const pageWithImages = pages.filter((page) => page.image_urls.length > 0);
    set((state) => {
      if (state.imageSelected.length === pageWithImages.length) {
        return { imageSelected: [] };
      }
      return {
        imageSelected: pageWithImages.map((page) => page.id),
      };
    });
  },
  resetStyledImageList: () => {
    set({ styledImageList: [] });
  },
  compareConfig: {},
  styledImageList: [],
  setStyledImageList: (pageDetail) => {
    const list = [...get().styledImageList];
    //if the pageDetail is already in the list, update it

    const index = list.findIndex((item) => item.id === pageDetail.id);
    if (index !== -1) {
      list[index] = pageDetail;
    }
    //if the pageDetail is not in the list, add it
    else {
      list.push(pageDetail);
    }
    set({ styledImageList: list });
  },
  reset: () => {
    set({
      imageToCompare: null,
      imageSelected: [],
      styledImageList: [],
    });
  },
  generateStyleImages: () => {
    //styled image id from image to compare
    const style_image_id = get().imageToCompare.id;
    //no of page to generate style images for
    const imageSelected = get().imageSelected;

    const advanceStore = useAdvanceStoryStore.getState();
    const pages = advanceStore.story.pages;
    const compareConfig = { ...get().compareConfig };

    imageSelected.forEach((page_id) => {
      // find index from page_id
      let index = pages.findIndex((page) => page.id === page_id);
      if (index === -1) return;

      //add the recent image to compareConfig with the page id as a key and the current image as a value
      const currentImageIndex = pages[index].current_image_index;
      compareConfig[page_id] = currentImageIndex;

      let input_image_id =
        pages[index].image_ids[pages[index].current_image_index];
      socketStore.getState().storySocket?.sendMessage({
        op: SOCKET_OPERATIONS.STYLE_IMAGE_GENERATION,
        data: {
          style_image_id,
          input_image_id,
          page_id,
        },
      });
    });
    set({
      imageSelected: [],
      imageToCompare: null,
      compareConfig: compareConfig,
    });
  },
  onFileUpload: async (fileUploaded) => {
    const file = new File([fileUploaded], `${fileUploaded.name}`, {
      type: fileUploaded.type,
    });
    const fileUrl = URL.createObjectURL(file);
    let base64String = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (error) {
        reject(error);
      };
      xhr.open("GET", fileUrl);
      xhr.responseType = "blob";
      xhr.send();
    });
    get().setImageToCompare({
      imageUrl: base64String,
    });
    //upload file in background
    set({ isUploading: true });
    base64String = base64String.replace(
      /^data:image\/(png|jpeg|webp|svg);base64,/,
      ""
    );
    uploadFile(file.name, base64String).then((response) => {
      if (response.data["status-code"] === 1) {
        set({ isUploading: false });
        get().setImageToCompare({
          imageUrl: response.data.data.image.url,
          id: response.data.data.image.id,
        });
      }
    });
  },
}));
export { styleEditorStore };
