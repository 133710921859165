//@ts-check
var interval;
export const initializeIdleCheck = (page, callback, idleMessageCallback) => {
    const isCoverPage = page === 0;
    if (interval) { disposeCallbacks() };
    if (!isCoverPage) {
        disposeCallbacks();
        callback(false);
        idleMessageCallback(false);
        return;
    }
    interval = setInterval(checkIdleStatus, 1000);

    let idleTime = 0;
    const idleThreshold = 2000; // 2 seconds
    const showIdleMessageThreshold = 5000;
    function resetIdleTimer() {
        idleTime = 1;
        callback(false)
        idleMessageCallback(false);
    }

    function checkIdleStatus() {
        idleTime += 1000; // Increase by 1 second
        if(sessionStorage.getItem("HAS_NAVIGATED") === "true") {
            disposeCallbacks();
            return;
        }
        if (idleTime >= idleThreshold) {
            callback(true);
        } else {
            callback(false)
        }

        if (idleTime >= showIdleMessageThreshold) {
            idleMessageCallback(true);
        } else {
            idleMessageCallback(false);
        }
    }


    // Track user activity
    document.onmousemove = resetIdleTimer;
    document.onkeypress = resetIdleTimer;
    document.ontouchstart = resetIdleTimer; // For touch devices
    document.onclick = resetIdleTimer;
    document.onscroll = resetIdleTimer// Add scroll listener


    function disposeCallbacks() {
        document.onmousemove = null;
        document.onkeypress = null;
        document.ontouchstart = null;
        document.onclick = null;
        document.onscroll = null;
        clearInterval(interval);
        interval = null;
    }
}
