// eslint-disable-next-line react/prop-types
const PreviewButton = ({ icon, active, className, disabled, ...rest }) => {
  return (
    <button
      disabled={disabled}
      className={`${className} flex items-center justify-center w-10 h-10 rounded-full border-2 border-[#3CC3AA] text-[#3CC3AA] hover:bg-[#3CC3AA] hover:text-white focus:outline-none mx-2 p-2 w-[60px] h-[60px] text-lg ${
        active ? "bg-[#3CC3AA] text-black" : ""
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      {...rest}
    >
      {icon}
    </button>
  );
};

export default PreviewButton;
