/* eslint-disable react/prop-types */
import { useFormikContext } from "formik";
import { AutoCompleteDropdown } from "../../AutoCompleteDropdown";
import FormInput from "../../FormInput";
import { useCountryHook } from "./hooks/useCountryHook";

export const DetailsForm = ({ parent }) => {
  const formik = useFormikContext();
  const { stateList, countryList, countries, states } = useCountryHook(
    formik?.values?.[parent]?.country_code
  );
  return (
    <div className="w-[100%]">
      <h2 className="text-xl font-bold  mb-4">
        {parent === "shipping_address" ? "Shipping Address" : "Billing Address"}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 ">
        <FormInput
          label="Name*"
          name={`${parent}.recipient_name`}
          placeholder="Your Name"
        />
        <AutoCompleteDropdown
          creatable={false}
          label="Country*"
          name={`${parent}.country`}
          options={countryList}
          onSelect={(newValue) => {
            formik.setFieldValue(`${parent}.country`, newValue);
            formik.setFieldValue(`${parent}.country_code`, countries[newValue]);
            formik.setFieldValue(`${parent}.state`, "");
            formik.setFieldValue(`${parent}.state_code`, "");
          }}
          value={formik.values?.[parent].country}
          formik={formik}
          renderInput={(params) => {
            return (
              <FormInput
                label="Country*"
                name={`${parent}.country`}
                placeholder="Select Country"
                {...params}
              />
            );
          }}
        />
        <AutoCompleteDropdown
          creatable={false}
          label="State*"
          options={stateList}
          onSelect={(newValue) => {
            formik.setFieldValue(`${parent}.state`, newValue);
            formik.setFieldValue(`${parent}.state_code`, states[newValue]);
          }}
          value={formik.values?.[parent].state}
          renderInput={(params) => {
            return (
              <FormInput
                label="State*"
                name={`${parent}.state`}
                placeholder="Select State"
                {...params}
              />
            );
          }}
        />
        <FormInput
          label="Address Line 1*"
          name={`${parent}.street1`}
          placeholder="Address Line 1"
        />
        <FormInput label="Address Line 2" name={`${parent}.street2`} />

        <FormInput
          label="City*"
          name={`${parent}.city`}
          placeholder="City Name"
        />
        <FormInput
          label="Postal Code*"
          name={`${parent}.postcode`}
          placeholder="123456"
        />
        <FormInput
          label="Phone Number*"
          name={`${parent}.phone`}
          placeholder="+91 1234567890"
        />
      </div>
    </div>
  );
};
