import { create } from "zustand";
import { getUserDetails } from "../../ApiServices/User/userApis";
import { SOCKET_OPERATIONS } from "../../services/socketoOperations";
import { useStoryCoinHelper } from "../../components/StoryCoin/storyCoinHook";
import socketStore from "../SocketStore";
import { subscriptionStore } from "../SubscriptionStore";

const useUserDetailsStore = create((set, get) => ({
  isOnline: true,
  setIsOnline: (isOnline) => set({ isOnline }),
  pageVisibility: true,
  setPageVisibility: (pageVisibility) => {
    const socketState = socketStore.getState();
    const { userSocket, storySocket } = socketState;
    const now = Date.now();

    if (pageVisibility) {
      if (userSocket) {
        userSocket.startConnectionChecker(now);
      }
      if (storySocket) {
        storySocket.startConnectionChecker(now);
      }
    } else {
      if (userSocket) {
        userSocket.stopConnectionChecker();
      }
      if (storySocket) {
        storySocket.stopConnectionChecker();
      }
    }
    set({ pageVisibility });
  },
  creditSocket: null,
  userDetail: null,
  credit: 0,
  canAddHuman: true,
  canAddBook: true,
  canAddCharacter: true,
  fixedLength: true,
  setCredits: (credits) => set({ credits }),
  fetchUserDetail: async () => {
    const setDontShowConfirmation =
      useStoryCoinHelper.getState().setDontShowConfirmation;
    try {
      const response = await getUserDetails();
      set({
        userDetail: response.data.data,
        credit: response.data.data.user.credit,
        canAddHuman: response.data.data.actions.can_add_human,
        canAddBook: response.data.data.actions.can_add_book,
        canAddCharacter: response.data.data.actions.can_add_character,
        fixedLength: response.data.data.actions.is_fixed_length,
      });
      localStorage.setItem("u_d", response?.data?.data?.user?.id);
      setDontShowConfirmation(!response.data.data.user.show_credit_dialog);
      get().initailizeCreditSocket(response.data.data.user.id);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  },
  initailizeCreditSocket: (userId) => {
    const socketSt = socketStore.getState();
    const roomId = `user_${userId}`;
    socketSt.initializeUserSocket(roomId, [
      function userSocketCallback(message) {
        switch (message.op) {
          case SOCKET_OPERATIONS.USER_CREDIT_BALANCE:
            set({ credit: message.data.credit });
            break;
          case SOCKET_OPERATIONS.SUBSCRIPTION_STATUS_CHANGE:
            var subscriptionState = subscriptionStore.getState();
            subscriptionState.fetchActiveSubscription();
            useUserDetailsStore.getState().fetchUserDetail();

            set({ credit: message.data.credit });
            break;
          default:
            break;
        }
      },
    ]);
  },
}));

export default useUserDetailsStore;
