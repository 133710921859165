//function to handle all the error message  cases and logout the user and redirect to login page if error code 401 or console log if not 401
import Cookies from "js-cookie";
import * as Sentry from "@sentry/browser";
import toast from "react-hot-toast";

export function handleErrors(error) {
  Sentry.captureException(error);
  if (error.response) {
    if (error.response.status === 401) {
      //logout the user and redirect to login page
      localStorage.clear();
      Cookies.remove("story_token");
      window.location.href = "/login";
    }
    //if 404 the navigate the user to 404 page
    else if (error.response.status === 404) {
      window.location.href = "/404";
      console.log("Internal Server Error", error);
      //   //403 forbidden
    } else if (error.response.status === 403) {
      window.location.href = "/select-child?tab=story";
    } else {
      console.log("Internal Server Error", error);
      toast.dismiss();
      toast.error(error.response?.data?.["status-message"]);
    }
  }
}

export function logSentryMessage(message) {
  Sentry.captureMessage(message);
}

export const checkSuccessStatus = (res) => {
  return res?.data?.["status-code"] === 1;
};
