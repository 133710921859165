//@ts-check
import { Progress } from "antd";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import useAdvanceStoryStore from "../../store/AdvanceStoryStore";
import useThemeStore from "../../store/Theme";
import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { initializeIdleCheck } from "../../services/idleCheck";
import CloseIcon from "@mui/icons-material/Close";
const HAS_NAVIGATED = "HAS_NAVIGATED";

const PaginationProgress = () => {
  const page = useAdvanceStoryStore((state) => state.page);
  const setPage = useAdvanceStoryStore((state) => state.setPage);
  const story = useAdvanceStoryStore((state) => state.story);
  const isCoverPage = useAdvanceStoryStore((state) => state.isCoverPage);
  const activeBookview = useAdvanceStoryStore((state) => state.activeBookview);
  const [, setSearchParams] = useSearchParams();
  const [isIdle, setIsIdle] = useState(false);
  const [showIdleMessage, setShowIdleMessage] = useState(
    sessionStorage.getItem(HAS_NAVIGATED)
      ? Boolean(sessionStorage.getItem(HAS_NAVIGATED))
      : false
  );

  const totalItems = story?.pages?.length - 1;
  const itemsPerPage = 1;

  const isSpiltView = activeBookview === "split";
  let totalPages = Math.ceil(totalItems / itemsPerPage);
  if (isSpiltView) {
    totalPages = totalPages * 2;
  }
  const pageId = (pageNO) => story?.pages[pageNO]?.id;

  const handleNext = () => {
    setIsIdle(false);
    setShowIdleMessage(false);
    sessionStorage.setItem(HAS_NAVIGATED, "true");

    if (page < totalPages) {
      let newPage = page + 1;
      setSearchParams({ pageId: pageId(newPage) });
      setPage(newPage);
    }
  };

  const handlePrev = () => {
    if (page >= 1) {
      setIsIdle(false);
      setShowIdleMessage(false);
      let newPage = page - 1;
      setSearchParams({ pageId: pageId(newPage) });
      setPage(newPage);
    }
  };

  const percent = (page / (isSpiltView ? totalPages / 2 : totalPages)) * 100;
  const customColor = "#3CC3AA";
  const isFirstPage = page === 0;
  const isLastPage = percent === 100 || page === totalPages;
  const theme = useThemeStore((state) => state.theme);
  const isDarkTheme = theme === "dark";

  const alterPagingationView = () => {
    const currentPage = !isSpiltView
      ? page
      : `${page * 2 - 1} - ${Number(page) * 2}`;

    return isCoverPage
      ? "Cover Page"
      : `Page ${currentPage}/${!isSpiltView ? totalPages : totalPages}`;
  };

  useEffect(() => {
    initializeIdleCheck(
      page,
      (status) => {
        if (status === isIdle) return;
        setIsIdle(status);
      },
      (status) => {
        if (status === showIdleMessage) return;
        setShowIdleMessage(status);
      }
    );
  }, [page]);

  return (
    <div className="relative">
      {showIdleMessage && isFirstPage ? (
        <div className="absolute -top-10 -right-10 w-full  bg-[#3cc3aa] rounded-lg p-4 text-white min-w-[300px]">
          <div className="relative flex justify-between">
            <center>Click here to read the next page</center>
            <div
              className="cursor-pointer"
              onClick={() => {
                setShowIdleMessage(false);
              }}
            >
              <CloseIcon />
            </div>
            <div
              className="absolute -bottom-7 right-10 w-0 h-0  border-l-[10px] border-l-transparent
  border-t-[12px] border-t-[#3cc3aa]
  border-r-[10px] border-r-transparent"
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Progress
        percent={percent}
        status="active"
        strokeColor={customColor}
        showInfo={false}
      />
      <div className="relative w-full flex justify-between items-center mt-4">
        <button
          className={
            `${isFirstPage && "cursor-not-allowed opacity-50"}` +
            "  px-2 py-2 rounded-[50%] text-[#000]  font-bold text-lg border-2 border-solid border-[#E3E9ED] quicksand-text hover:bg-[#3CC3AA] hover:text-[#fff]"
          }
          onClick={handlePrev}
          disabled={isFirstPage}
        >
          <MdNavigateBefore
            color={isDarkTheme ? "white" : ""}
            className="text-3xl"
          />
        </button>
        <span
          className={`${isDarkTheme ? "text-white" : "text-black"}`}
          style={{ margin: "0 10px" }}
        >
          {alterPagingationView()}
        </span>

        <button
          className={
            `${isLastPage && "cursor-not-allowed opacity-50"} ${
              isIdle && isFirstPage
                ? "animate-pulse border-[#3cc3aa] bg-[#3cc3aa] text-white"
                : "border-[#E3E9ED]"
            }` +
            " px-2 py-2 rounded-[50%] text-[#000]  font-bold text-lg border-2 border-solid  quicksand-text hover:bg-[#3CC3AA] hover:text-[#fff]"
          }
          onClick={handleNext}
          disabled={isLastPage}
        >
          <MdNavigateNext
            color={isDarkTheme ? "white" : ""}
            className="text-3xl"
          />
        </button>
      </div>
    </div>
  );
};

export default PaginationProgress;
