import { Title } from "../../components/molecules/header";
import {
  OrderHistoryContainer,
  OrderHistoryFilters,
  OrderHistoryList,
} from "../../components/OrderHistory";
import { SubTitle } from "../../components/molecules/subtitle";
import { subscriptionStore } from "../../store/SubscriptionStore";
import { TransparentButton } from "../../components/Button/TransparentButton";
import { DialogWrapper } from "../../components/Dialog";
import { useState } from "react";

const OrderHistory = () => {
  const {
    currentsubscriptionStatusDetails,
    cancelSubscription,
    cancelingSubscription,
  } = subscriptionStore();

  const canCancelSubscription =
    currentsubscriptionStatusDetails?.actions?.can_cancel_subscription;
  const planDetails =
    currentsubscriptionStatusDetails?.subscription?.plan_details;
  const status = currentsubscriptionStatusDetails?.subscription?.status;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  return (
    <OrderHistoryContainer>
      <DialogWrapper
        isOpen={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
        }}
      >
        <div className="flex flex-col items-center gap-4 p-8">
          <SubTitle className="font-[600]">
            Are you sure you want to cancel subscription?
          </SubTitle>
          <SubTitle>
            Your plan will be removed automatically in the next billing cycle.
          </SubTitle>

          <div className="flex gap-4">
            <TransparentButton
              handleButtonClick={() => {
                cancelSubscription(
                  currentsubscriptionStatusDetails?.subscription?.id
                );
                setShowDeleteDialog(false);
              }}
              theme="red"
              style={{ marginTop: 0 }}
            >
              Yes
            </TransparentButton>
            <TransparentButton
              handleButtonClick={() => {
                setShowDeleteDialog(false);
              }}
              style={{ marginTop: 0 }}
            >
              No
            </TransparentButton>
          </div>
        </div>
      </DialogWrapper>

      <div className="flex justify-between items-center">
        <Title> My Account</Title>
        <div className="flex items-center justify-center gap-2">
          <SubTitle>{planDetails?.item?.name || "Free Plan"}</SubTitle>{" "}
          <div
            className={`h-3 w-3 ml-4 mr-2 ${
              status
                ? status === "ACTIVE"
                  ? "bg-[#3cc3aa]"
                  : "bg-slate-300"
                : "bg-[#3cc3aa]"
            } rounded-full`}
          ></div>{" "}
          {currentsubscriptionStatusDetails?.subscription?.status || "ACTIVE"}
          {canCancelSubscription && (
            <TransparentButton
              disabled={cancelingSubscription}
              theme="red"
              style={{ marginTop: 0, marginLeft: "5px" }}
              handleButtonClick={() => {
                setShowDeleteDialog(true);
              }}
            >
              {cancelingSubscription ? (
                <span>Cancelling...</span>
              ) : (
                <span>Cancel Plan</span>
              )}
            </TransparentButton>
          )}
        </div>
      </div>

      <OrderHistoryFilters />
      <OrderHistoryList />
    </OrderHistoryContainer>
  );
};
export default OrderHistory;
