//@ts-check
import { HexColorPicker } from "react-colorful";
import { COLOR_PICKER, colorOptions } from "../textDetailsConstants";
import React, { useState } from "react";

const FloatingColorPalette = ({ onColorChange, value }) => {
    const [showColorPicker, setShowColorPicker] = useState("");
    return <div className="absolute x-40 -top-28 left-[50%] z-40 bg-white drop-shadow-xl rounded-xl p-2 ">
        <div className=" relative color-options flex flex-col gap-4">
            <center className="font-bold">Change Background</center>
            <div className="flex">
                {colorOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`color-circle ${index === colorOptions.length - 1 && "picker"
                            } ${value === option.color
                                ? "border-2 border-[#00ff00]"
                                : ""
                            }`}
                        style={{
                            background: option.color,
                            backgroundSize: option.gradient ? "cover" : "",
                        }}
                        onClick={() => {
                            if (index === colorOptions.length - 1)
                                return setShowColorPicker(
                                    COLOR_PICKER.BACKGROUND_COLOR
                                );
                            onColorChange(option.color);
                        }}
                    ></div>
                ))}
            </div>
            {showColorPicker === COLOR_PICKER.BACKGROUND_COLOR && (
                <div
                    className={`${showColorPicker === COLOR_PICKER.BACKGROUND_COLOR
                        ? "opacity-100"
                        : "opacity-0"
                        } transition-all ease-in-out duration-300 absolute top-full left-36  color-picker z-50 p-2 `}
                >
                    <HexColorPicker
                        color={value}
                        onChange={(colorHashValue) =>
                            onColorChange(colorHashValue)
                        }
                    />
                </div>
            )}
        </div>
    </div>
}
export default FloatingColorPalette;