// eslint-disable-next-line react/prop-types
export function SubTitle({ children, className = "", ...rest }) {
  return (
    <p
      className={`text-lg text-[#36324A] font-medium text-center quicksand-text ${className}`}
      {...rest}
    >
      {children}
    </p>
  );
}
