export const SUBSCRIPTION_CONFIG = Object.freeze({
  monthly: [
    {
      title: "Free Plan",
      period: "monthly",
      credits: 0,
      amount: 0,
      characterLimit: "2",
      bookLimit: "5",
      pagesPerBook: "Fixed",
      additionalCreditsPricing: "Standard StoryCoins packs",
      support: "Standard Support",
      newFeatures: "Upgrade anytime",
      buttonText: "Try Now",
      buttonType: "none",
    },
    {
      type: "Hobby",
      period: "monthly",
      amount: 29.99,
      characterLimit: "5",
      credits: "200",
      bookLimit: "10",
      pagesPerBook: "Variable",
      additionalCreditsPricing: "10% Discount",
      support: "Priority",
      newFeatures: "None",
      buttonText: "Activate",
      buttonType: "primary",
    },
    {
      type: "Professional",
      period: "monthly",
      amount: 49.99,
      characterLimit: "unlimited",
      credits: "400",
      bookLimit: "unlimited",
      pagesPerBook: "Variable",
      additionalCreditsPricing: "20% Discount",
      support: "Priority",
      newFeatures: "Early Access",
      buttonText: "Upgrade",
      buttonType: "primary",
    },
  ],
  yearly: [
    {
      title: "Free Plan",
      type: "Free",
      period: "yearly",
      amount: 0,
      characterLimit: "2",
      credits: 0,
      bookLimit: "5",
      pagesPerBook: "Fixed",
      additionalCreditsPricing: "Standard StoryCoins packs",
      support: "Standard Support",
      newFeatures: "Upgrade anytime",
      buttonText: "Try Now",
      buttonType: "none",
    },
    {
      type: "Hobby",
      period: "yearly",
      amount: 300,
      characterLimit: "5",
      credits: "200",
      bookLimit: "10",
      pagesPerBook: "Variable",
      additionalCreditsPricing: "10% Discount",
      support: "Priority",
      newFeatures: "Upgrade anytime",
      buttonText: "Activate",
      buttonType: "primary",
    },
    {
      type: "Professional",
      period: "yearly",
      amount: 500,
      characterLimit: "unlimited",
      credits: "400",
      bookLimit: "unlimited",
      pagesPerBook: "Variable",
      additionalCreditsPricing: "20% Discount",
      support: "Priority",
      newFeatures: "Early Access",
      buttonText: "Upgrade",
      buttonType: "primary",
    },
  ],
});
