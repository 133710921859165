import { Radio } from "@mui/material";
import { getCurrencySymbol } from "../../PaymentSection/Assets";
import { useSearchParams } from "react-router-dom";

/* eslint-disable react/prop-types */
const DeliveryOptionItem = ({ item, checked, onClick }) => {
  const Title = ({ children }) => {
    return (
      <h2 className="text-md md:text-[20px] font-bold flex overflow-clip">
        {children}
      </h2>
    );
  };
  const [params] = useSearchParams();
  const currencyFromParmas = params.get("currency");
  const currencySymbol = getCurrencySymbol(currencyFromParmas || item.currency);
  return (
    <div
      onClick={onClick}
      className={`max-w-[637px] border cursor-pointer quicksand-text w-full rounded-xl text-[#36324A] my-2 px-1 md:px-4 py-8 ${
        checked ? "border-[3px] border-[#3CC3AA] " : "border-[#E5E5E5]"
      }`}
    >
      <div className="flex justify-center items-center ">
        <div className="hidden md:flex">
          <Radio color="primary" checked={checked} size="medium" />
        </div>

        <div className="flex flex-grow flex-col pl-2 md:pl-4">
          <Title> {item.carrier_service_name}</Title>
          <p className="text-sm md:text-[16px] font-[500]">
            Estimated delivery in {item.total_days_min} to {item.total_days_max}{" "}
            business days
          </p>
        </div>
        <Title>
          {`${currencySymbol} ${
            item.prices[currencyFromParmas]?.shipping_cost ||
            item.prices[item.currency]?.shipping_cost
          }`}{" "}
        </Title>
      </div>
    </div>
  );
};
export default DeliveryOptionItem;
