import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

import useAdvanceStoryStore from "../store/AdvanceStoryStore";
import { AutoCompleteDropdown } from "./AutoCompleteDropdown";
import { FontAutoCompleteDropdown } from "./FontAutoCompleteDropdown";
import {
  COLOR_PICKER,
  FontOption,
  FontSizeOption,
  colorOptions,
  shadowOption,
} from "./textDetailsConstants";
import TextEditor from "./TextEditor";
import { TransparentButton } from "./Button/TransparentButton";
import { DeleteOutline } from "@mui/icons-material";
import { DialogWrapper } from "./Dialog";
import { SubTitle } from "./molecules/subtitle";

export default function TextDetails() {
  const [
    formState,
    handleTextDetailsChange,
    activeBookview,
    page,
    setActiveTextElementId,
    addNewTextElement,
    deleteTextElement,
  ] = useAdvanceStoryStore((state) => [
    state.formState,
    state.handleTextDetailsChange,
    state.activeBookview,
    state.page,
    state.setActiveTextElementId,
    state.addNewTextElement,
    state.deleteTextElement,
  ]);
  const [activeCollapsibleIndex, setActiveCollapsibleIndex] = useState([0]);
  const [showColorPicker, setShowColorPicker] = useState("");
  const isCoverPage = page === 0;
  const isSplitVew = activeBookview !== "merged" && !isCoverPage;
  const textElements = formState?.text_elements;
  const [deleteElementId, setDeleteElementId] = useState(null);
  // eslint-disable-next-line react/prop-types
  const Chip = ({ onClick, children, active }) => {
    return (
      <button
        className={`p-3 w-[100%] max-w-[135px] rounded-lg  mx-1 border-2 bg-[#F4F7FA]   ${
          active && " border-[#3CC3AA] text-[#3CC3AA]"
        }`}
        onClick={() => onClick()}
      >
        {children}
      </button>
    );
  };
  const toggleCollapsible = (newIndex) => {
    if (activeCollapsibleIndex.includes(newIndex)) {
      closeCollapsible(newIndex);
    } else {
      openCollapsible(newIndex);
    }
  };
  const openCollapsible = (newIndex) => {
    setActiveCollapsibleIndex((prev) => {
      return [...prev, newIndex];
    });
  };
  const closeCollapsible = (newIndex) => {
    setActiveCollapsibleIndex((prev) => {
      return prev.filter((index) => index !== newIndex);
    });
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      //set showColorPicker to empty string to hide the color picker if clicked outside the color-picker
      if (
        e.target.classList.contains("react-colorful__interactive") ||
        e.target.classList.contains("picker") ||
        e.target.classList.contains("react-colorful__pointer")
      ) {
        return;
      } else {
        setShowColorPicker("");
      }
    });
    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);
  const handleDeleteTextElement = () => {
    deleteTextElement(deleteElementId);
    setDeleteElementId(null);
  };
  return (
    <div className="relative">
      <DialogWrapper
        isOpen={Boolean(deleteElementId)}
        onClose={() => setDeleteElementId(null)}
      >
        <SubTitle>Do you want to delete this text section?</SubTitle>
        <div className="w-full flex justify-center my-4">
          <TransparentButton
            handleButtonClick={() => setDeleteElementId(null)}
            className={"mx-2"}
          >
            Cancel
          </TransparentButton>
          <TransparentButton
            handleButtonClick={handleDeleteTextElement}
            theme="red"
            className={"mx-2"}
          >
            Delete
          </TransparentButton>
        </div>
      </DialogWrapper>
      {textElements?.map((textElement, index) => {
        const isActive = activeCollapsibleIndex.includes(index);
        return (
          <div
            className="mt-2 transition-all ease-in-out duration-300 "
            key={textElement?.id}
          >
            <button
              className=" flex items-center justify-between w-full focus:outline-none bg-[#F4F7FA] p-4 rounded-t-lg "
              onClick={() => {
                setActiveTextElementId(textElement?.id);
                toggleCollapsible(index);
              }}
            >
              <div className="flex item-center justify-center ">
                <div className="h-10 w-10 mr-2 text-md bg-[#3cc3aa] text-white font-bold p-2 rounded-md">
                  {String(index + 1).padStart(2, "0")}
                </div>
                <div
                  className="text-lg font-bold flex items-center"
                  dangerouslySetInnerHTML={{
                    __html:
                      textElement.text.length > 50
                        ? textElement.text.slice(0, 50) + "..."
                        : textElement.text,
                  }}
                ></div>
              </div>
              <div className="flex items-center">
                <DeleteOutline
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteElementId(textElement.id);
                  }}
                  className="hover:text-red-500 hover:scale-110"
                />
                <svg
                  className={`w-5 h-5 transition-transform ${
                    activeCollapsibleIndex.includes(index)
                      ? "transform rotate-180"
                      : ""
                  }`}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </button>
            <div
              className={` bg-[#3cc3aa] bg-opacity-5 ${
                isActive
                  ? "max-h-[900px] p-4 "
                  : "max-h-0 bg-white overflow-hidden"
              } transition-all duration-300 ease-in-out transition-max-height rounded-b-lg `}
            >
              <div className="flex flex-col gap-6 justify-center items-center">
                <TextEditor
                  title="Text"
                  placeholder={"Text"}
                  onChange={(value) => {
                    handleTextDetailsChange(
                      {
                        key: "text",
                        value: value,
                      },
                      textElement.id
                    );
                  }}
                  value={textElement.text}
                />
                <div className="flex gap-x-12 justify-between items-center w-[100%] dropdown-div">
                  <FontAutoCompleteDropdown
                    name="font"
                    value={textElement.font}
                    onSelect={async (newValue) => {
                      if (newValue)
                        handleTextDetailsChange(
                          {
                            key: "font",
                            value: newValue,
                          },
                          textElement.id
                        );
                    }}
                    label="Font"
                    options={FontOption}
                    exampleText={textElement.text}
                  />
                  <AutoCompleteDropdown
                    id="font-size"
                    name="font_size"
                    value={textElement?.font_size}
                    options={FontSizeOption}
                    onSelect={(newValue) => {
                      if (newValue)
                        handleTextDetailsChange(
                          {
                            key: "font_size",
                            value: newValue,
                          },
                          textElement.id
                        );
                    }}
                  />
                </div>
              </div>

              <div className="relative flex md:flex-row flex-col md:gap-16 gap-6 mt-6">
                <div className="color-options flex flex-col gap-4">
                  <div>Text Color</div>
                  <div className="flex">
                    <div
                      className={`color-circle ${
                        textElement.text_color === "#000000"
                          ? "border-2 border-[#3CC3AA] p-2"
                          : ""
                      }`}
                      style={{ background: "#000000" }}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_color",
                            value: "#000000",
                          },
                          textElement.id
                        )
                      }
                    ></div>
                    <div
                      className={`color-circle ${
                        textElement.text_color === "#FFFFFF"
                          ? "border-2 border-[#3CC3AA]"
                          : " border-2 border-[#dfdfdf]"
                      }`}
                      style={{ background: "#FFFFFF" }}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_color",
                            value: "#FFFFFF",
                          },
                          textElement.id
                        )
                      }
                    ></div>
                    <div
                      className={`color-circle picker ${
                        textElement.text_color === colorOptions[6].color
                          ? "border-2 border-[#00ff00]"
                          : ""
                      }`}
                      style={{
                        background: colorOptions[6].color,
                        backgroundSize: colorOptions[6].gradient ? "cover" : "",
                      }}
                      onClick={() => {
                        setShowColorPicker(COLOR_PICKER.TEXT_COLOR);
                      }}
                    ></div>
                  </div>
                  {showColorPicker === COLOR_PICKER.TEXT_COLOR && (
                    <div
                      className={`${
                        showColorPicker === COLOR_PICKER.TEXT_COLOR
                          ? "opacity-100"
                          : "opacity-0 "
                      } transition-all ease-in-out duration-300 absolute bottom-10  color-picker z-50 `}
                    >
                      <HexColorPicker
                        color={textElement.text_color}
                        onChange={(colorHashValue) => {
                          handleTextDetailsChange(
                            {
                              key: "text_color",
                              value: colorHashValue,
                            },
                            textElement.id
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="grid lg:grid-cols-2 gap-4">
                <div className="color-options flex flex-col gap-4 mt-6">
                  <div>Alignment</div>
                  <div className="flex">
                    <Chip
                      active={textElement.text_alignment === "start"}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_alignment",
                            value: "start",
                          },
                          textElement.id
                        )
                      }
                    >
                      <AlignLeftOutlined style={{ fontSize: "23px" }} />
                    </Chip>
                    <Chip
                      active={textElement.text_alignment === "center"}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_alignment",
                            value: "center",
                          },
                          textElement.id
                        )
                      }
                    >
                      <AlignCenterOutlined style={{ fontSize: "23px" }} />
                    </Chip>
                    <Chip
                      active={textElement.text_alignment === "end"}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_alignment",
                            value: "end",
                          },
                          textElement.id
                        )
                      }
                    >
                      <AlignRightOutlined style={{ fontSize: "23px" }} />
                    </Chip>
                  </div>
                </div>
                <div className="color-options flex flex-col gap-4 mt-6">
                  <div>Text Position</div>
                  <div className="flex">
                    <Chip
                      active={textElement.text_position === "start"}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_position",
                            value: "start",
                          },
                          textElement.id
                        )
                      }
                    >
                      Top
                    </Chip>
                    <Chip
                      active={textElement.text_position === "center"}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_position",
                            value: "center",
                          },
                          textElement.id
                        )
                      }
                    >
                      Middle
                    </Chip>
                    <Chip
                      active={textElement.text_position === "flex-end"}
                      onClick={() =>
                        handleTextDetailsChange(
                          {
                            key: "text_position",
                            value: "flex-end",
                          },
                          textElement.id
                        )
                      }
                    >
                      Bottom
                    </Chip>
                  </div>
                </div>
              </div>
              {!isSplitVew && (
                <div className="relative color-options flex flex-col gap-4 mt-6">
                  <div>Shadow Color</div>
                  <div className="flex">
                    {shadowOption.map((option, index) => (
                      <div
                        key={index}
                        className={` ${
                          index === shadowOption.length - 1 && "picker"
                        } color-circle ${
                          textElement.shadow_color === option.color
                            ? "border-2 border-[#00ff00]"
                            : ""
                        }`}
                        style={{
                          background: option.color,
                          backgroundSize: option.gradient ? "cover" : "",
                        }}
                        onClick={() => {
                          if (index === shadowOption.length - 1)
                            return setShowColorPicker(
                              COLOR_PICKER.SHADOW_COLOR
                            );
                          handleTextDetailsChange(
                            {
                              key: "shadow_color",
                              value: option.color,
                            },
                            textElement.id
                          );
                        }}
                      ></div>
                    ))}
                  </div>
                  {showColorPicker === COLOR_PICKER.SHADOW_COLOR && (
                    <div
                      className={`${
                        showColorPicker === COLOR_PICKER.SHADOW_COLOR
                          ? "opacity-100"
                          : "opacity-0"
                      } transition-all ease-in-out duration-300 absolute bottom-10 left-26  color-picker z-40 `}
                    >
                      <HexColorPicker
                        color={textElement.shadow_color}
                        onChange={(colorHashValue) =>
                          handleTextDetailsChange(
                            {
                              key: "shadow_color",
                              value: colorHashValue,
                            },
                            textElement.id
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className="w-full flex justify-center sticky bottom-4">
        <TransparentButton handleButtonClick={addNewTextElement}>
          Add New Text
        </TransparentButton>
      </div>
    </div>
  );
}
