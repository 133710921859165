/* eslint-disable react/prop-types */
import { CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import UploadIcon from "../../assets/Upload.svg";
import JPG from "../../assets/bi_filetype-jpg.svg";
import Image from "../../assets/bi_filetype-pdf.svg";
import PNG from "../../assets/bi_filetype-png.svg";
import SVG from "../../assets/bi_filetype-svg.svg";
import "./CustomUpload.css";

const CustomerFileUploader = ({
  autoFocus,
  title,
  selectedFile = "",
  id = uuid(),
  subTitle,
  className,
  multiple = true,
  onFileSelect,
  fileType,
  information,
  required,
  error,
  infoName,
  width,
  labelTitle,
  disabled,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const [image, setImage] = useState();
  const inputRef = useRef(null);
  const [fileName, setFileName] = useState(undefined);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length) {
      onFileSelect && onFileSelect(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    if (target.files && target.files.length) {
      setFileName(target.files[0].name);
      onFileSelect && onFileSelect(Array.prototype.slice.call(target.files));
    }
  };

  const handleRemoveFile = (e) => {
    e.preventDefault();
    setFileName(undefined);
    setDragActive(false);
    onFileSelect && onFileSelect([""]);
  };

  const onButtonClick = () => {
    inputRef.current && inputRef.current.click();
  };

  useEffect(() => {
    const fileEditExtension = getEditExtension(infoName || "");
    if (fileEditExtension === ".jpg" || fileEditExtension === ".jpeg") {
      setImage(JPG);
    } else if (fileEditExtension === ".pdf") {
      setImage(Image);
    } else if (fileEditExtension === ".png") {
      setImage(PNG);
    } else if (fileEditExtension === ".svg") {
      setImage(SVG);
    }
  }, [infoName]);

  const getEditExtension = (infoName) => {
    const fileEditExtension = infoName.match(/\.(\w+)(?=\?|$)/);
    return fileEditExtension ? fileEditExtension[0].toLowerCase() : null;
  };

  useEffect(() => {
    const fileExtension = getFileExtension(fileName || "");
    if (fileExtension === ".jpg" || fileExtension === ".jpeg") {
      setImage(JPG);
    } else if (fileExtension === ".pdf") {
      setImage(Image);
    } else if (fileExtension === ".png") {
      setImage(PNG);
    } else if (fileExtension === ".svg") {
      setImage(SVG);
    }
  }, [fileName]);

  const getFileExtension = (url) => {
    const fileExtension = url.match(/\.(\w+)(?=\?|$)/);
    return fileExtension ? fileExtension[0].toLowerCase() : null;
  };

  return (
    <div className={`${width}`}>
      {labelTitle && (
        <div className="labelDiv">
          <p className={`font-medium text-base text-wrap  text-[#666] `}>
            {labelTitle}
            <label className="text-[#407BFF]">{required && "*"}</label>
          </p>
        </div>
      )}
      <form
        id={`form-file-upload_form_${id}`}
        className={`form-file-upload h-32 ${className}`}
        autoFocus={autoFocus}
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        {selectedFile !== "" ? (
          <div className="py-2">
            <div className="flex caret-none flex-col justify-center items-center">
              <div className="relative">
                <button
                  className="absolute top-0 right-0 remove-button"
                  onClick={(e) => handleRemoveFile(e)}
                >
                  <CloseCircleOutlined />
                </button>
                <img
                  src={infoName}
                  style={{ height: "4rem" }}
                  autoFocus={autoFocus}
                  alt="file"
                />
              </div>
              <p className="text-sm text-wrap text-center">
                {fileName ?? infoName}
              </p>
            </div>
          </div>
        ) : (
          <>
            <input
              ref={inputRef}
              required={required}
              autoFocus={autoFocus}
              type="file"
              className={`form-file-upload_input`}
              id={`form-file-upload_input_${id}`}
              multiple={multiple}
              onChange={handleChange}
              accept={fileType ?? ""}
              disabled={disabled}
            />

            <label
              id="label-file-upload"
              htmlFor={`form-file-upload_input_${id}`}
              className={dragActive ? "drag-active" : ""}
            >
              {!selectedFile && (
                <div className="py-4 w-full flex justify-center flex-col items-center">
                  <img src={UploadIcon} alt="upload" className="w-8" />
                  {title && (
                    <p className="textfiledrop text-center">{title} </p>
                  )}
                  {subTitle && (
                    <p className="textfiledrop text-wrap">{subTitle}</p>
                  )}
                </div>
              )}
              <button
                className="upload-button"
                onClick={onButtonClick}
              ></button>
            </label>

            {dragActive && (
              <div
                id="drag-file-element"
                className={`drag-file-element_${id}`}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </>
        )}
      </form>
      {error && <div className="text-red-500">{error}</div>}
      {information && (
        <div className="text-[#666] font-normal text-sm text-wrap text-center flex">
          {information}
        </div>
      )}
    </div>
  );
};

export default CustomerFileUploader;
